import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

const delay = ms => new Promise(res => setTimeout(res, ms));

// unsure if action.payload is needed, for now: action is removed as parameter
function* fetchCompanies(action) {
    try {
        let response = [];
        response = yield axios.get('api/companies', { params: action.payload });
        yield put({ type: 'SET_COMPANIES', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchCompanyDropdownList(action) {
    try {
        let response = [];
        response = yield axios.get('api/companies/list/all', { params: action.payload });
        yield put({ type: 'SET_COMPANIES_DROP_DOWN_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchRecruiterDropdownList(action) {
    try {
        let response = [];
        response = yield axios.get('api/companies/recruiters/list/all', { params: action.payload });
        yield put({ type: 'SET_RECRUITERS_DROP_DOWN_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchCompanyDetails(action) {
    try {
        const response = yield axios.get(`api/companies/details/${action.payload.companyId}`);
        yield put({ type: 'SET_COMPANY', payload: response.data });
        yield put({ type: 'FETCH_COMPANY_NOTES', payload: action.payload.companyId });
        yield put({ type: 'FETCH_COMPANY_PLACEMENTS', payload: { companyId: action.payload.companyId, sortOrder: action.payload.sortOrder } });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchCompanyForEdit(action) {
    try {
        const response = yield axios.get(`api/companies/details/${action.payload.companyId}`);
        yield put({ type: 'SET_EDITING_COMPANY', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchCompanyNotes(action) {
    try {
        const response = yield axios.get(`api/companies/notes/${action.payload}`);
        yield put({ type: 'SET_COMPANY_NOTES', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* createCompany(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const { data: newCompany } = yield axios.post('/api/companies', action.payload);

        yield put({ type: 'FETCH_COMPANY_DETAILS', payload: { companyId: newCompany.id } });
        // Move to page for company created
        yield delay(250);
        yield action.history.replace(`/companies/${newCompany.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* updateCompany(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const { data: newCompany } = yield axios.put('/api/companies/update', action.payload);

        // Move to page for student created
        yield delay(250);
        yield action.history.replace(`/companies/${newCompany.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* createNote(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.post('/api/companies/notes', action.payload);
        yield put({ type: 'SET_EDITING_COMPANY_NOTE', payload: { display: false } });
        yield put({ type: 'FETCH_COMPANY_DETAILS', payload: { companyId: action.payload.company_id } });
        // Add delay to allow loading indicator to appear
        yield delay(250);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* deleteNote(action) {
    try {
        // yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put(`api/companies/notes/archive/${action.payload.noteId}`);
        // Add delay to allow loading indicator to appear
        yield delay(250);
        yield put({ type: 'FETCH_COMPANY_DETAILS', payload: { companyId: action.payload.companyId } });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* companySaga() {
    yield takeLatest('FETCH_COMPANIES', fetchCompanies);
    yield takeLatest('FETCH_COMPANY_DROPDOWN_LIST', fetchCompanyDropdownList);
    yield takeLatest('FETCH_RECRUITER_DROPDOWN_LIST', fetchRecruiterDropdownList);
    yield takeLatest('FETCH_COMPANY_DETAILS', fetchCompanyDetails);
    yield takeLatest('FETCH_COMPANY_NOTES', fetchCompanyNotes);
    yield takeLatest('CREATE_COMPANY', createCompany);
    yield takeLatest('FETCH_COMPANY_FOR_EDIT', fetchCompanyForEdit);
    yield takeLatest('PUT_COMPANY', updateCompany);
    yield takeLatest('CREATE_COMPANY_NOTE', createNote);
    yield takeLatest('DELETE_COMPANY_NOTE', deleteNote);
}
export default companySaga;
