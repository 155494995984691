import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import BaseGeneralTab from '../../ApplicantPortal/Application/Tabs/GeneralTab/BaseGeneralTab';
import PageTitle from '../../PageTitle/PageTitle';

function EditApplicationPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    // Grab application ID from URL route params
    const { id: applicationId } = useParams();

    // Grab application and applicant from redux store
    const application = useSelector(store => store.application.editingApplicationDetails || {});
    const applicant = application.user;

    // Fetch this application on page load
    useEffect(() => {
        dispatch({
            type: 'FETCH_APPLICATION_DETAILS',
            payload: {
                id: applicationId,
            },
        });
    }, [applicationId, dispatch]);

    const dispatchChange = (action) => {
        if (action) {
            dispatch(action);
        }
        dispatch({ type: 'SET_UNSAVED_GENERAL_CHANGE', payload: true });
    };

    const save = () => {
        dispatch({
            type: 'PUT_APPLICANT_GENERAL_INFORMATION',
            payload: {
                applicant,
                courseId: application.course_id,
                nextTab: () => {
                    dispatch({
                        type: 'UNSET_APPLICATION_DETAILS',
                    });
                    history.push(`/admissions_applications/${application.application_id}`);
                },
            },
        });
    };

    return (
        <>
            <PageTitle
                title={`Edit Application :: ${applicant.first_name} ${applicant.last_name}`}
                actions={[
                    {
                        label: 'Save Application',
                        onClick: save,
                        color: 'primary',
                        variant: 'contained',
                    },
                    {
                        label: 'Cancel',
                        // Dispatches this action...
                        type: 'UNSET_APPLICATION_DETAILS',
                        payload: {},
                        // ...and navigates to this page
                        path: `/admissions_applications/${application.application_id}`,
                    },
                ]}
            />
            <BaseGeneralTab
                // General Tab view expects a slightly modified
                // applicant object
                applicant={{
                    ...applicant,
                    level_of_interest: (
                        applicant.level_of_interest
                        || application.level_of_interest
                        || ''
                    ),
                }}
                courseId={application.course_id}
                onApplicantChange={(property, val) => {
                    dispatchChange({
                        type: 'SET_EDITING_APPLICATION_DETAILS',
                        payload: {
                            ...application,
                            user: {
                                ...application.user,
                                [property]: val,
                            },
                        },
                    });
                }}
                onErrorMessage={(title, body) => {
                    dispatch({
                        type: 'SET_GLOBAL_MODAL',
                        payload: {
                            modalOpen: true,
                            title,
                            body,
                        },
                    });
                }}
                // Disable resume upload (TODO: allow resume upload)
                resumeUploadEnabled={false}
                // eslint-disable-next-line no-shadow
                onSubmit={save}
            />
        </>
    );
}

export default EditApplicationPage;
