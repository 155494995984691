import React from 'react';
import {
    TableRow,
    TableCell,
    Hidden,
    Link,
} from '@mui/material';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import { shortStatusText } from '../../../helpers/CourseWorkTextHelper';
import { shortDateTime } from '../../../helpers/DateFormatHelper';
import FeedbackTooltip from '../../General/FeedbackTooltip';
import { getCourseWorkUrl } from '../../../helpers/PhotoHelper';
import FaIcon from '../../../helpers/FaIcon';
import TableCellLink from '../../General/TableCellLink';

const styles = {
    assignmentTitleText: {
        width: '100%',
        padding: '0px 15px',
    },
    actionButtons: {
        marginLeft: '15px',
    },
    tableRow: {
        cursor: 'pointer',
    },
};

const getGradeForCourseWork = (courseWork) => {
    let result = '';
    if (!Number.isNaN(courseWork.grade)) {
        result = courseWork.grade;
    }
    return result;
};

const getDefaultStatus = (assignment) => {
    let defaultStatus = <span>Not assigned</span>;
    if (assignment.workflow_state === 'assigned') {
        // Assigned but nothing has been turned in
        defaultStatus = <span className="status-text status-missing">Missing</span>;
    }
    return defaultStatus;
};

const getEmployeeFullname = employee => (
    `${employee.first_name} ${employee.last_name}`
);

const CohortAssignmentRow = ({
    student,
    viewCourseWork,
    assignment,
    classes,
    user,
}) => (
    <TableRow
        className={classes.tableRow}
        hover
    >
        {
            student && student.course_works && student.course_works.length > 0 ? (
                <>
                    <TableCellLink to={`/coursework/${student.course_works[0].id}/grade`} first>
                        {`${student.first_name} ${student.last_name}`}
                    </TableCellLink>
                    {
                        assignment.assignment_type === 'graded'
                        && (
                            <>
                                <TableCellLink to={`/coursework/${student.course_works[0].id}/grade`}>
                                    {
                                        student.course_works.length > 0 ? (
                                            getGradeForCourseWork(
                                                student.course_works[0],
                                            )
                                        ) : (
                                            <span />
                                        )
                                    }
                                </TableCellLink>
                                <TableCellLink to={`/coursework/${student.course_works[0].id}/grade`}>
                                    {
                                        student.course_works.length > 0
                                        && student.course_works[0].drafted_by
                                        && student.course_works[0].workflow_state !== 'graded'
                                        && student.course_works[0].workflow_state !== 'completed'
                                        && (
                                            <span>
                                                {student.course_works[0].drafted_by}
                                                {' '}
                                                (Draft)
                                            </span>
                                        )
                                    }
                                    {
                                        student.course_works.length > 0
                                            && student.course_works[0].employee
                                            && student.course_works[0].workflow_state === 'graded'
                                            ? (
                                                <span>
                                                    {
                                                        getEmployeeFullname(
                                                            student.course_works[0].employee,
                                                        )
                                                    }
                                                </span>
                                            ) : (
                                                <span />
                                            )
                                    }
                                </TableCellLink>
                            </>
                        )
                    }
                    <TableCell onClick={viewCourseWork(student)}>
                        {
                            student.course_works.length > 0
                            && student.course_works[0].instructor_comment
                            && (
                                // Not sure if it makes sense to have a feedback bubble here
                                <FeedbackTooltip
                                    title="Instructor Comments"
                                    tooltip={
                                        `${student.course_works[0].instructor_comment.substring(0, 120)}... (click to view more)`
                                    }
                                />
                            )
                        }
                        {
                            student.course_works.length > 0
                            && student.course_works[0].drafted_by
                            && student.course_works[0].workflow_state !== 'graded'
                            && student.course_works[0].workflow_state !== 'completed'
                            && (
                                <span>
                                    (Draft)
                                </span>
                            )
                        }
                    </TableCell>
                    <Hidden mdDown>
                        <TableCellLink to={`/coursework/${student.course_works[0].id}/grade`}>
                            {
                                student.course_works.length > 0
                                && student.course_works[0].completed_at
                                && (
                                    shortDateTime(
                                        student.course_works[0].completed_at,
                                    )
                                )
                            }
                        </TableCellLink>
                    </Hidden>

                    <TableCell>
                        {
                            student.course_works.length > 0
                            && student.course_works[0].file_name
                            && student.course_works[0].file_content_type
                            && (
                                <span>
                                    {student.course_works[0].file_content_type.slice(-3)}
                                    <Link href={getCourseWorkUrl(
                                        user.s3_bucket,
                                        student.course_works[0].id,
                                        student.course_works[0].file_name,
                                    )}
                                    >
                                        <FaIcon
                                            icon="link"
                                            iconType="fas"
                                            style={{
                                                color: '#555555',
                                                fontSize: '18px',
                                                marginBottom: '-3px',
                                                marginLeft: '12px',
                                            }}
                                        />
                                    </Link>
                                </span>
                            )
                        }
                    </TableCell>
                    <TableCellLink to={`/coursework/${student.course_works[0].id}/grade`} align="right">
                        {
                            student.course_works.length > 0 ? (
                                shortStatusText(
                                    student.course_works[0],
                                    assignment,
                                )
                            ) : (
                                getDefaultStatus(assignment)
                            )
                        }
                    </TableCellLink>
                </>
            ) : (
                <TableCell>
                    {`${student.first_name} ${student.last_name} - No course work found`}
                </TableCell>
            )
        }
    </TableRow>
);

CohortAssignmentRow.propTypes = {
    student: PropTypes.instanceOf(Object).isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    viewCourseWork: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(CohortAssignmentRow);
