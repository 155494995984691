import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageTitle from '../../PageTitle/PageTitle';
import ApplicationTabs from './ApplicationTabs';

const FullStackApplication = ({
    dispatch,
    description,
    history,
    match,
    user,
}) => {
    const stableDispatch = useCallback(dispatch, []);
    const stableHistory = useCallback(history, []);

    useEffect(() => {
        stableDispatch({ type: 'SET_APPLICANT_COURSE_ID', payload: { course_id: 1 } });
        stableDispatch({ type: 'FETCH_APPLICANT_APPLICATION_DETAILS', payload: { course_id: 1, stableHistory } });
    }, [user, stableDispatch, stableHistory]);

    return (
        <>
            <PageTitle
                title="Application for Full Stack Engineering"
            />
            <ApplicationTabs
                logicTitle="Logic"
                technicalTitle="Technical"
                startTabText={description}
                programType="engineering"
                history={history}
                match={match}
            />

        </>
    );
};

FullStackApplication.propTypes = {
    dispatch: PropTypes.func.isRequired,
    description: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    description: state.applicant.startTabDetails,
    user: state.user,
});

export default connect(mapStateToProps)(FullStackApplication);
