import React from 'react';
import {
    Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import makeSideNavStyles from './makeSideNavStyles';
import ConfirmationModal from '../Modal/ConfirmationModal';

const useStyles = makeSideNavStyles();

const UserInfo = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(store => store.user);
    const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);
    const logout = () => {
        setLogoutModalOpen(false);
        dispatch({ type: 'LOGOUT' });
        history.push('/login');
    };

    return (
        <>
            <p className={classes.userName}>{`${user.first_name} ${user.last_name}`}</p>
            <Button onClick={() => setLogoutModalOpen(true)} className={classes.logoutButton} variant="outlined">Logout</Button>
            {/* Logout Confirmation */}
            <ConfirmationModal
                open={logoutModalOpen}
                dialogTitle="Logout"
                dialogMessage="Are you sure you want to leave?"
                onCancel={() => setLogoutModalOpen(false)}
                onConfirm={logout}
            />
        </>
    );
};

export default UserInfo;
