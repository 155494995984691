import React from 'react';
import {
    TableCell,
    TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import FeedbackTooltip from '../../General/FeedbackTooltip';

const styles = ({
    feedbackScore: {
        fontSize: '16px',
        textAlign: 'center',
        minWidth: '80px',
    },
    wideColumn: {
        minWidth: '150px',
    },
    scoreContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    tableRow: {
        cursor: 'pointer',
    },
});

/**
 * Feedback is returned from the server can be null. This function
 * checks to see if an entry exists and format the entry accordingly.
 */
function formatFeedbackNumber(entry) {
    let result = '';
    if (entry) {
        result = `${Number(entry).toFixed(0)}`;
    }
    return result;
}

function formatFeedbackText(entry) {
    let result = '';
    if (entry) {
        result = entry;
    }
    return result;
}

const StudentFeedbackRow = ({
    studentFeedback,
    viewFeedbackDetails,
    classes,
}) => (
    <TableRow
        className={classes.tableRow}
        hover
        onClick={viewFeedbackDetails(studentFeedback)}
    >
        <TableCell className={classes.wideColumn}>
            <div className={classes.scoreContainer}>
                {
                    studentFeedback.feedback_on
                        && (
                            <div>
                                <p className={classes.feedbackScore}>
                                    {formatFeedbackText(studentFeedback.feedback_on)}
                                </p>
                            </div>
                        )
                }

            </div>
        </TableCell>
        <TableCell>
            <div className={classes.scoreContainer}>
                {
                    studentFeedback.understanding_score
                        && (
                            <div>
                                {formatFeedbackNumber(studentFeedback.understanding_score)}
                                <FeedbackTooltip
                                    title="Comprehension"
                                    tooltip={
                                        // eslint-disable-next-line max-len
                                        formatFeedbackText(studentFeedback.understanding_explanation)
                                    }
                                />
                            </div>
                        )
                }

            </div>
        </TableCell>
        <TableCell>
            <div className={classes.scoreContainer}>
                {
                    studentFeedback.support_score
                        && (
                            <div>
                                {formatFeedbackNumber(studentFeedback.support_score)}
                                <FeedbackTooltip
                                    title="Support"
                                    tooltip={
                                        formatFeedbackText(studentFeedback.support_explanation)
                                    }
                                />
                            </div>
                        )
                }

            </div>
        </TableCell>
        <TableCell>
            <div className={classes.scoreContainer}>
                {
                    studentFeedback.feeling_explanation
                        && (
                            <div>
                                {formatFeedbackText(studentFeedback.feeling_explanation)}
                            </div>
                        )
                }

            </div>
        </TableCell>
        <TableCell>
            <div className={classes.scoreContainer}>
                {
                    studentFeedback.thanks
                    && (
                        <FeedbackTooltip
                            title="Thanks"
                            tooltip={formatFeedbackText(studentFeedback.thanks)}
                        />
                    )
                }

            </div>
        </TableCell>
        <TableCell>
            <div className={classes.scoreContainer}>
                {
                    studentFeedback.recommended_experiences
                    && (
                        <FeedbackTooltip
                            title="Suggest"
                            tooltip={formatFeedbackText(studentFeedback.recommended_experiences)}
                        />
                    )
                }

            </div>
        </TableCell>
        <TableCell>
            <div className={classes.scoreContainer}>
                {
                    studentFeedback.accomplishment
                    && (
                        <FeedbackTooltip
                            title="Accomplishment"
                            tooltip={formatFeedbackText(studentFeedback.accomplishment)}
                        />
                    )
                }

            </div>
        </TableCell>

    </TableRow>
);

StudentFeedbackRow.propTypes = {
    studentFeedback: PropTypes.instanceOf(Object).isRequired,
    viewFeedbackDetails: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(StudentFeedbackRow);
