import { DateRangeSearchBy } from '../redux/reducers/placementsReducer';

export const getPlacementQueryParams = (payload) => {
    const params = {
        order: payload.sortOrder.order,
        orderBy: payload.sortOrder.orderBy,
        // Pagination
        offset: payload.searchOffset,
        limit: payload.rowsPerPage,

        searchText: payload.searchText,
        firstPlacementsOnly: payload.firstPlacementsOnly,
        ...(payload.dateRange.searchBy === DateRangeSearchBy.GraduationDate
            // Search by graduation date
            ? {
                graduationStartDate: payload.dateRange.startDate,
                graduationEndDate: payload.dateRange.endDate,
            }
            // Search by placement date
            : {
                placementStartDate: payload.dateRange.startDate,
                placementEndDate: payload.dateRange.endDate,
            }
        ),
    };
    return params;
};

export default {
    getPlacementQueryParams,
};
