import React from 'react';
import {
    Card,
    CardContent,
    Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

const styles = {

    statCardTitle: {
        fontSize: '16px',
        fontWeight: '600',
    },
};
const CohortVeteranStats = ({ cohort, classes }) => {
    const chartData = {

        // These labels appear in the legend and in the tooltips when hovering different arcs

    };
    const chartOptions = {
        title: {
            display: false,
            text: 'Gender',
            align: 'start',
        },
        tooltips: {
            callbacks: {
                label(tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = cohort.students ? cohort.students.length : 0;
                    const currentLabel = chartData.labels[tooltipItem.index];
                    const currentValue = dataset.data[tooltipItem.index];
                    const percentage = Math.floor(((currentValue / total) * 100) + 0.5);

                    return `${currentLabel}: ${currentValue} (${percentage}%)`;
                },
            },
        },
    };

    const studentList = cohort.students;
    if (studentList) {
        const data = {
            data: [
                studentList.filter(student => student.veteran).length,
                studentList.filter(student => !student.veteran).length,
            ],
            backgroundColor: [
                '#1F6B77',
                '#009AA1',
            ],
        };
        chartData.datasets = [data];
        chartData.labels = ['Identifies as a Veteran', 'Does not identify as a Veteran'];
    }
    return (
        <Grid item style={{ display: 'flex' }} xs={12} sm={6} lg={4}>
            <Card elevation={1} style={{ width: '100%' }}>
                <CardContent>
                    <span className={classes.statCardTitle}>Veteran status</span>
                    <br />
                    <br />
                    <Pie data={chartData} options={chartOptions} />
                </CardContent>
            </Card>
        </Grid>
    );
};

CohortVeteranStats.propTypes = {
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default withStyles(styles)(CohortVeteranStats);
