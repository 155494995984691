import React, { useEffect, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    Paper,
    Button,
} from '@mui/material';
import PageTitle from '../PageTitle/PageTitle';
import ReportSummary from './ReportSummary';

const styles = makeStyles({
    feedbackContainer: {
        padding: '20px',
    },
    textField: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
    summaryTitle: {
        position: 'relative',
    },
    cardContent: {
        padding: '15px 15px 0 15px',
    },
});

const ReportsPage = () => {
    const classes = styles();
    const data = useSelector(store => store.report.summary);
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    // renders submit button if any of the profile questions have answers
    useEffect(() => {
        stableDispatch({ type: 'FETCH_REPORT_SUMMARY' });
    }, [stableDispatch]);

    const downloadTechHireCSV = () => {
        const params = {};
        params.t = new Date().getTime();
        const qs = Object.keys(params)
            .filter(key => params[key] && params[key] !== '')
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
        let prefix = '';
        if (process.env.NODE_ENV !== 'production') {
            prefix = 'http://localhost:5000/';
        }
        window.open(`${prefix}api/reports/techhire?${qs}`);
    };

    const downloadFirstPlacementCSV = () => {
        const params = {};
        params.t = new Date().getTime();
        const qs = Object.keys(params)
            .filter(key => params[key] && params[key] !== '')
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
        let prefix = '';
        if (process.env.NODE_ENV !== 'production') {
            prefix = 'http://localhost:5000/';
        }
        window.open(`${prefix}api/reports/first/placements?${qs}`);
    };

    return (
        <div className="container">
            <PageTitle title="Reports" />
            <Grid className={classes.cardContent} container>
                <Grid item xs={12}>
                    <ReportSummary title="Full Stack Engineering" data={data.filter(i => i.code === 'engineering')} />
                    <ReportSummary title="User Experience" data={data.filter(i => i.code === 'ux')} />
                </Grid>
            </Grid>
            <Paper>
                <Typography variant="h3" style={{ fontWeight: 'normal' }}>
                    Download Reports
                </Typography>
                <ul>
                    <li>
                        <Button onClick={downloadTechHireCSV}>
                            Tech Hire Report
                        </Button>
                    </li>
                    <li>
                        <Button onClick={downloadFirstPlacementCSV}>
                            First Placement CSV
                        </Button>
                    </li>
                </ul>
            </Paper>

        </div>
    );
};

ReportsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default ReportsPage;
