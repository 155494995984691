import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Button,
    Hidden,
    TablePagination,
    Tooltip,
    Typography,
    Icon,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import PageTitle from '../../PageTitle/PageTitle';
import CohortStatsRow from '../../Cohort/CohortStudentsPage/CohortStatsRow';
import TableCellLink from '../../General/TableCellLink';

const LIMIT = 20;

const styles = {
    cohortList: {
        width: '100%',
    },
    searchInput: {
        width: '350px',
    },
    tableRow: {
        cursor: 'pointer',
    },
    pagination: {
        textAlign: 'right',
        width: '100%',
    },
    content: {
        padding: '12px 0px 12px 0px',
        // margin: '0px 15px',
        display: 'flex',
        alignItems: 'center',
    },
};

class StudentListPage extends Component {
    constructor(props) {
        super(props);
        this.searchTimeout = null;
        this.state = {
            hoverText: 'Copy email to clipboard.',
        };
    }

    componentDidMount() {
        const { dispatch, searchText, searchOffset } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_STUDENT_LIST', payload: { searchText, offset: searchOffset } });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
        dispatch({ type: 'FETCH_STUDENT_STAT_LIST', payload: { searchText, offset: searchOffset } });
    }

    componentWillUnmount() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = null;
    }

    viewStudentDetails = student => () => {
        const { dispatch } = this.props;
        const action = { type: 'SET_STUDENT', payload: student };
        dispatch(action);
        const { history } = this.props;
        history.push(`/students/${student.id}`);
    };

    handleSearchChange = (event) => {
        const { dispatch } = this.props;
        const searchText = event.target.value;
        dispatch({ type: 'SET_SEARCH_TEXT', payload: { text: searchText } });
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => { this.runSearch(searchText); }, 250);
    };

    runSearch = (searchText) => {
        const { dispatch } = this.props;
        dispatch({ type: 'SET_SEARCH_OFFSET', payload: { offset: 0 } });
        dispatch({ type: 'FETCH_STUDENT_LIST', payload: { searchText, offset: 0 } });
        this.searchTimeout = null;
    };

    handleChangePage = (event, page) => {
        const {
            dispatch,
            searchText,
        } = this.props;
        const offset = page * LIMIT;
        dispatch({ type: 'SET_SEARCH_OFFSET', payload: { offset } });
        dispatch({ type: 'FETCH_STUDENT_LIST', payload: { searchText, offset } });
    };

    copyEmailToClipboard = email => async (event) => {
        event.stopPropagation();
        if (!navigator.clipboard) {
            this.setState({ hoverText: 'Unable to copy.' });
            return;
        }
        try {
            await navigator.clipboard.writeText(email);
            this.setState({ hoverText: 'Copied!' });
        } catch (e) {
            this.setState({ hoverText: 'Unable to copy.' });
        }
    };

    downloadCSV = () => {
        const {
            // sortOrder,
            searchText,
        } = this.props;
        const params = {
            // order: sortOrder.order,
            // orderBy: sortOrder.orderBy,
            searchText,
        };
        params.t = new Date().getTime();
        const qs = Object.keys(params)
            .filter(key => params[key] && params[key] !== '')
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
        let prefix = '';
        if (process.env.NODE_ENV !== 'production') {
            prefix = 'http://localhost:5000/';
        }
        window.open(`${prefix}api/students/csv?${qs}`);
    };

    render() {
        const {
            studentList,
            classes,
            searchText,
            searchOffset,
            studentStatList,
        } = this.props;
        const { hoverText } = this.state;
        const page = searchOffset / LIMIT;
        const start = new Date();
        const listObj = {
            classroom_start: start,
            students: studentStatList,
        };
        const actions = [{
            label: 'Download CSV',
            onClick: this.downloadCSV,
        }];
        if (process.env.REACT_APP_HAS_STUDENT_APPLICATIONS === 'false'
            || process.env.REACT_APP_HAS_STUDENT_APPLICATIONS === false) {
            actions.push({
                label: 'Add New Student',
                // Dispatch an action...
                type: 'UNSET_EDITING_STUDENT',
                // ...and navigate to this page.
                path: '/students/new',
            });
        }

        return (
            <div className="container">
                <PageTitle
                    title="Students Home"
                    actions={actions}
                />
                <div className="content">
                    {studentStatList
                && studentStatList.length
                && (
                    <CohortStatsRow cohort={listObj} />
                )}
                </div>
                <Paper>
                    <div className="search-bar">
                        <TextField
                            helperText="Search by first name, last name, email or cohort."
                            className={classes.searchInput}
                            label="Search"
                            margin="dense"
                            variant="outlined"
                            value={searchText}
                            onChange={this.handleSearchChange}
                        />
                    </div>
                    <br />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Cohort</TableCell>
                                <Hidden mdDown>
                                    <TableCell align="right">Action</TableCell>
                                </Hidden>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                studentList
                                && studentList.rows
                                && studentList.rows.map(student => (
                                    <TableRow
                                        className={classes.tableRow}
                                        onClick={this.viewStudentDetails(student)}
                                        hover
                                        key={student.id}
                                    >
                                        <TableCellLink to={`/students/${student.id}`} first>
                                            <Typography>
                                                {`${student.first_name} ${student.last_name}`}
                                                { student.pronouns && student.pronouns !== 'Prefer-not-to-say'
                                                    && ` (${student.pronouns})`
                                                }
                                            </Typography>
                                        </TableCellLink>
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                                            <Tooltip title={hoverText}>
                                                <Icon
                                                    onClick={
                                                        this.copyEmailToClipboard(student.email)
                                                    }
                                                    onMouseOver={
                                                        () => this.setState({ hoverText: 'Copy to Clipboard' })
                                                    }
                                                    onFocus={() => this.setState({ hoverText: 'Copy to Clipboard' })}
                                                    onMouseDown={e => e.stopPropagation()}
                                                    className="far fa-copy"
                                                    style={{
                                                        color: '#555555',
                                                        fontSize: '18px',
                                                        marginBottom: '-3px',
                                                        marginRight: '12px',
                                                    }}
                                                />
                                            </Tooltip>
                                            {student.email}
                                        </TableCell>
                                        <TableCell>
                                            <Link onClick={e => e.stopPropagation()} to={`/cohorts/${student.cohort_id}`}>
                                                {student.cohort_name}
                                            </Link>
                                        </TableCell>
                                        <Hidden mdDown>
                                            <TableCell align="right">
                                                <Button variant="outlined" onClick={this.viewStudentDetails(student)}>View</Button>
                                            </TableCell>
                                        </Hidden>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                    {/* Limit could be moved to the reducer to allow
                                    rows per page changes */}
                    {
                        studentList
                        && studentList.count
                        && (
                            <TablePagination
                                rowsPerPageOptions={[LIMIT]}
                                component="div"
                                count={studentList.count}
                                rowsPerPage={LIMIT}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onPageChange={this.handleChangePage}
                            // onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        )
                    }
                </Paper>
            </div>
        );
    }
}

StudentListPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    searchText: PropTypes.string.isRequired,
    searchOffset: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    studentList: PropTypes.instanceOf(Object).isRequired,
    studentStatList: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// Instead of taking everything from state, we just want the user info.
// if you wanted you could write this code like this:
// const mapStateToProps = ({user}) => ({ user });
const mapStateToProps = state => ({
    user: state.user,
    studentList: state.student.studentList,
    searchText: state.student.searchText,
    searchOffset: state.student.searchOffset,
    studentStatList: state.student.studentStatList,
});

// this allows us to use <App /> in index.js
export default connect(mapStateToProps)(withStyles(styles)(StudentListPage));
