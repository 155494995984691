import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Icon,
} from '@mui/material';

// Font awesome icon
// See https://fontawesome.com/v5.15/icons
function FaIcon({
    icon,
    iconType,
    className,
    style,
}) {
    return (
        <Icon
            className={classNames(className, iconType, `fa-${icon}`)}
            style={style}
        />
    );
}

FaIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    iconType: PropTypes.string,
    className: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
};

FaIcon.defaultProps = {
    iconType: 'fa',
    className: null,
    style: {},
};

export default FaIcon;
