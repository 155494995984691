import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    FormControlLabel, Checkbox, FormGroup, Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { grey } from '@mui/material/colors';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CodeBlock from '../CodeBlock';

const styles = theme => ({
    secondaryText: {
        fontWeight: 500,
        padding: '5px 0',
    },
    container: {
        margin: '5px 0',
    },

});
const InterviewPrepNote = ({
    label,
    classes,
    dispatch,
    editingApplication,
    applicationInterviewReview,
    questionId,

}) => {
    const [checked, setChecked] = useState(false);
    const [selectedTab, setSelectedTab] = useState('write');
    const [content, setContent] = useState('');

    const handleChange = (value) => {
        const review = applicationInterviewReview.get(questionId);
        applicationInterviewReview.set(questionId,
            { ...review, profile_note: value, questionId });

        dispatch({ type: 'UPDATE_APPLICATION_INTERVIEW_REVIEW', payload: applicationInterviewReview });
        setContent(value);
    };

    useEffect(() => {
        const profileNote = applicationInterviewReview.get(questionId)?.profile_note;
        if (profileNote) {
            setContent(profileNote);
            setChecked(true);
        }
    }, []);

    return (
        <div className={classes.container}>
            <FormGroup>
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={checked}
                            onChange={(e) => { setChecked(e.target.checked); }}
                            sx={{
                                color: grey[900],
                                '&.Mui-checked': {
                                    color: grey[900],
                                },
                            }}
                        />
                    )}
                    label={label}
                />
            </FormGroup>
            {
                checked && (
                    <>

                        <Typography variant="h6" className={classes.secondaryText}>
                            What should the interviewer ask about, or be aware of,
                            during the admissions interview?
                        </Typography>
                        <ReactMde
                            value={content}
                            onChange={handleChange}
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            minEditorHeight={120}
                            minPreviewHeight={120}
                            generateMarkdownPreview={markdown => (
                                Promise.resolve(
                                    <ReactMarkdown
                                        components={{ code: CodeBlock }}
                                        remarkPlugins={[remarkGfm]}
                                    >
                                        {markdown}
                                    </ReactMarkdown>,
                                )
                            )}
                        />
                    </>
                )
            }
        </div>
    );
};

InterviewPrepNote.propTypes = {
    dispatch: PropTypes.instanceOf(Object).isRequired,
    editingApplication: PropTypes.instanceOf(Object).isRequired,
    applicationInterviewReview: PropTypes.instanceOf(Map).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    label: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired,
};
const mapStateToProps = state => ({
    dispatch: state.dispatch,
    editingApplication: state.application.editingApplicationDetails,
    applicationInterviewReview: state.application.applicationInterviewReview,
});
export default connect(mapStateToProps)(withStyles(styles)(InterviewPrepNote));
