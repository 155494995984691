import { all } from 'redux-saga/effects';
import loginSaga from './loginSaga';
import userSaga from './userSaga';
import cohortSaga from './cohortSaga';
import courseSaga from './courseSaga';
import studentSaga from './studentSaga';
import assignmentSaga from './assignmentSaga';
import campusSaga from './campusSaga';
import employeeSaga from './employeeSaga';
import studentPortalSaga from './studentPortalSaga';
import companySaga from './companySaga';
import placementSaga from './placementSaga';
import hubspotSaga from './hubspotSaga';
import applicantSaga from './applicantSaga';
import applicationSaga from './applicationSaga';
import reportSaga from './reportSaga';

// rootSaga is the primary saga.
// It bundles up all of the other sagas so our project can use them.
// This is imported in index.js as rootSaga

// some sagas trigger other sagas, as an example
// the registration triggers a login
// and login triggers setting the user
export default function* rootSaga() {
    yield all([
        loginSaga(),
        userSaga(),
        cohortSaga(),
        courseSaga(),
        studentSaga(),
        assignmentSaga(),
        campusSaga(),
        employeeSaga(),
        studentPortalSaga(),
        companySaga(),
        placementSaga(),
        hubspotSaga(),
        applicantSaga(),
        applicationSaga(),
        reportSaga(),
    ]);
}
