import React, { useState } from 'react';
import classNames from 'classnames';
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../Modal/ConfirmationModal';

const styles = {
    text: {
        fontSize: '12px',
    },
    title: {
        fontSize: '20px',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    status: {
        float: 'right',
    },
    card: {
        height: '94%',
        display: 'flex',
        flexDirection: 'column',
    },
    spacer: {
        flexGrow: 1,
    },
};

const ApplicationCard = ({
    image,
    imageTitle,
    programDetails,
    applicationType,
    programBlurb,
    buttonText,
    action,
    classes,
    history,
    dispatch,
}) => {
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const applicationClickHandler = () => {
        if (action.type === 'UX_INQUIRY') {
            const mailto = 'mailto:admissions@primeacademy.io?subject=Admissions Question - User Experience Design';
            window.open(mailto);
        } else if (action.type === 'ENGINEERING_INQUIRY') {
            const mailto = 'mailto:admissions@primeacademy.io?subject=Admissions Question - Full Stack Engineering';
            window.open(mailto);
        } else if (action.type === 'UX_ACADEMY_INQUIRY') {
            const mailto = 'mailto:admissions@primeacademy.io?subject=Admissions Question - UX Academy';
            window.open(mailto);
        } else if (action.type) {
            if (action.course === 'ux-academy' && action.type === 'CREATE_APPLICATION') {
                setConfirmationModalOpen(true);
            } else {
                dispatch(action);
            }
        } else {
            history.push(action.path);
        }
    };

    const cardStyle = () => {
        if (action.type === 'CREATE_APPLICATION') {
            return { filter: 'grayscale(100%)' };
        }
        return {};
    };

    return (
        <>
            <ConfirmationModal
                open={confirmationModalOpen}
                dialogTitle="Before you continue:"
                dialogMessage="You may only apply to UX Academy if you have met eligibility criteria for city sponsorships or have been invited to apply."
                onCancel={() => setConfirmationModalOpen(false)}
                onConfirm={() => dispatch(action)}
            />
            <Card className={classes.card}>
                <CardActionArea onClick={applicationClickHandler}>
                    <CardMedia
                        className={classes.media}
                        style={cardStyle()}
                        image={image}
                        title={imageTitle}
                    />
                    <CardContent>
                        {
                            action
                            && action.status
                            && (
                                <span className={classNames('status-text status-complete', classes.status)} color="textSecondary">
                                    {action.status}
                                </span>
                            )
                        }
                        <Typography className={classes.text} color="textSecondary" component="p">
                            {programDetails}
                        </Typography>
                        <Typography className={classes.title} component="h2">
                            {applicationType}
                        </Typography>
                        <Typography className={classes.text} color="textSecondary" component="p">
                            {programBlurb}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <div className={classes.spacer} />
                <CardActions>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={applicationClickHandler}
                    >
                        {buttonText}
                    </Button>
                </CardActions>
            </Card>
        </>
    );
};

ApplicationCard.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    image: PropTypes.string.isRequired,
    imageTitle: PropTypes.string.isRequired,
    programDetails: PropTypes.string.isRequired,
    applicationType: PropTypes.string.isRequired,
    programBlurb: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    action: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect()(withStyles(styles)(ApplicationCard));
