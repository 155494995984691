import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { STUDENT, APPLICANT } from '../../components/General/UserTypes';

// worker Saga: will be fired on "FETCH_USER" actions
function* fetchUser(action) {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        };

        // the config includes credentials which
        // allow the server session to recognize the user
        // If a user is logged in, this will return their information
        // from the server session (req.user)
        const response = yield axios.get('api/users', config);

        // now that the session has given us a user object
        // with an id and username set the client-side user object to let
        // the client-side code know the user is logged in
        const loggedInUser = response.data;
        yield put({ type: 'SET_USER', payload: loggedInUser });
        if (loggedInUser.role === STUDENT) {
            yield put({ type: 'FETCH_ALL_STUDENT_PORTAL_INFO' });
        } else if (loggedInUser.role === APPLICANT) {
            yield put({ type: 'FETCH_USER_PROFILE_INFORMATION' });
        }
    } catch (error) {
        if (action.payload && action.payload.location === 'login') {
            // do not redirect if we came from the login page
        } else if (error.response && error.response.status === 403) {
            localStorage.removeItem('hasUser');
            yield put({ type: 'LOGOUT' });
            yield put({ type: 'UNSET_USER' });
            // With protected routes, this should be unncessary
            // window.location.href = '/#/login';
        }
    }
}

function* userSaga() {
    yield takeLatest('FETCH_USER', fetchUser);
}

export default userSaga;
