import React from 'react';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

} from '@mui/material';

import StudentPlacementTableRow from './StudentPlacementTableRow';

function StudentPlacementTable(props) {
    const { placements } = props;
    return (
        <Paper>
            <h5>Placements</h5>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Company</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Title</TableCell>
                        {/* <TableCell>Salary</TableCell> */}
                        <TableCell>Offer Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {placements.map(placement => (
                        <StudentPlacementTableRow placement={placement} key={placement.id} />
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

StudentPlacementTable.propTypes = {
    placements: PropTypes.instanceOf(Array).isRequired,
};

export default StudentPlacementTable;
