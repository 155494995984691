import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Hidden,
    TableBody,
    TableSortLabel,
    TablePagination,
} from '@mui/material';
import PageTitle from '../../PageTitle/PageTitle';
import StudentAssignmentRow from './StudentAssignmentRow';
import { getAssignmentOrDeliverable } from '../../../helpers/CourseTypeHelper';

const LIMIT = 20;

class StudentAssignmentsPage extends Component {
    componentDidMount() {
        const {
            dispatch,
            sortOrder,
            searchOffset,
        } = this.props;
        const payload = {
            ...sortOrder,
            offset: searchOffset,
        };
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
        dispatch({ type: 'FETCH_STUDENT_PORTAL_ASSIGNMENTS', payload });
    }

    showCourseWork = (selectedCourseWork) => {
        const { history } = this.props;
        history.push(`/student/assignments/${selectedCourseWork.assignment.id}`);
    };

    sortBy = orderBy => () => {
        const {
            dispatch,
            sortOrder,
            searchOffset,
        } = this.props;
        let { order } = sortOrder;
        // Existing column selected, reverse the sort
        if (orderBy === sortOrder.orderBy) {
            if (order === 'asc') {
                order = 'desc';
            } else {
                order = 'asc';
            }
        }
        const payload = {
            orderBy,
            order,
            offset: searchOffset,
        };
        dispatch({ type: 'SET_ASSIGNMENT_SORT_ORDER', payload });
        dispatch({ type: 'FETCH_STUDENT_PORTAL_ASSIGNMENTS', payload });
    };

    handleChangePage = (event, page) => {
        const {
            dispatch,
            sortOrder,
        } = this.props;
        const offset = page * LIMIT;
        dispatch({ type: 'SET_ASSIGNMENT_OFFSET', payload: { offset } });
        const payload = {
            ...sortOrder,
            offset,
        };
        dispatch({ type: 'FETCH_STUDENT_PORTAL_ASSIGNMENTS', payload });
    };

    render() {
        const {
            assignments = [], sortOrder = {}, searchOffset = 0, courseId = 1,
        } = this.props;
        const page = searchOffset / LIMIT;
        const assignmentOrDeliverable = getAssignmentOrDeliverable(courseId);

        return (
            <div className="container">
                <PageTitle
                    title={`${assignmentOrDeliverable}s`}
                />
                {
                    assignments.rows
                    && assignments.rows.length > 0
                        ? (
                            <Paper>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sortDirection={sortOrder.orderBy === 'name' && sortOrder.order}>
                                                <TableSortLabel
                                                    active={sortOrder.orderBy === 'name'}
                                                    direction={sortOrder.order}
                                                    onClick={this.sortBy('name')}
                                                >
                                                    Name
                                                </TableSortLabel>
                                            </TableCell>
                                            <Hidden mdDown>
                                                <TableCell sortDirection={sortOrder.orderBy === 'workflow_state' && sortOrder.order}>
                                                    <TableSortLabel
                                                        active={sortOrder.orderBy === 'workflow_state'}
                                                        direction={sortOrder.order}
                                                        onClick={this.sortBy('workflow_state')}
                                                    >
                                                        Submission
                                                    </TableSortLabel>
                                                </TableCell>
                                            </Hidden>
                                            <TableCell sortDirection={sortOrder.orderBy === 'graded_at' && sortOrder.order}>
                                                <TableSortLabel
                                                    active={sortOrder.orderBy === 'graded_at'}
                                                    direction={sortOrder.order}
                                                    onClick={this.sortBy('graded_at')}
                                                >
                                                    Feedback
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sortDirection={sortOrder.orderBy === 'due_at' && sortOrder.order}>
                                                <TableSortLabel
                                                    active={sortOrder.orderBy === 'due_at'}
                                                    direction={sortOrder.order}
                                                    onClick={this.sortBy('due_at')}
                                                >
                                                    Due Date
                                                </TableSortLabel>
                                            </TableCell>
                                            <Hidden mdDown>
                                                <TableCell sortDirection={sortOrder.orderBy === 'assigned_at' && sortOrder.order}>
                                                    <TableSortLabel
                                                        active={sortOrder.orderBy === 'assigned_at'}
                                                        direction={sortOrder.order}
                                                        onClick={this.sortBy('assigned_at')}
                                                    >
                                                        Assigned At
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell align="right">Action</TableCell>
                                            </Hidden>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            assignments.rows
                                            && assignments.rows.map(courseWork => (
                                                <StudentAssignmentRow
                                                    key={courseWork.id}
                                                    courseWork={courseWork}
                                                    viewAssignmentDetails={this.showCourseWork}
                                                />
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[LIMIT]}
                                    component="div"
                                    count={assignments.count}
                                    rowsPerPage={LIMIT}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onPageChange={this.handleChangePage}
                                />
                            </Paper>
                        ) : (
                            <Paper>
                                <p>
                                    {'No '}
                                    {assignmentOrDeliverable.toLowerCase()}
                                    s.
                                </p>
                            </Paper>
                        )
                }
            </div>
        );
    }
}

StudentAssignmentsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    assignments: PropTypes.instanceOf(Array).isRequired,
    searchOffset: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    sortOrder: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    assignments: state.studentPortal.assignments,
    sortOrder: state.assignments.sortOrder,
    searchOffset: state.assignments.searchOffset,
    courseId: state.studentPortal.cohort.course_id,
});

export default connect(mapStateToProps)(StudentAssignmentsPage);
