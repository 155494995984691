import { combineReducers } from 'redux';

// Employee list used to populate drop down menus
const employeeList = (state = [], action) => {
    switch (action.type) {
    case 'SET_EMPLOYEE_LIST':
        return action.payload;
    case 'UNSET_EMPLOYEE_LIST':
        return [];
    default:
        return state;
    }
};

const employeeStatuses = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
];

const statuses = (state = employeeStatuses, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const defaultStatus = { value: 'Active', label: 'Active' };

const employeeStatus = (state = defaultStatus, action) => {
    switch (action.type) {
    case 'SET_EMPLOYEE_STATUS':
        if (action.payload === 'Active') {
            return employeeStatuses[0];
        } if (action.payload === 'Inactive') {
            return employeeStatuses[1];
        }
        return state;
    case 'UNSET_EMPLOYEE_STATUS':
        return defaultStatus;
    case 'UPDATE_EMPLOYEE_STATUS':
        return action.payload;
    default:
        return state;
    }
};

const employeeRoles = [
    { value: 'grader', label: 'Grader' },
    { value: 'adjunct', label: 'Adjunct' },
    { value: 'career', label: 'Career Develoment' },
    { value: 'student_life', label: 'Student Life' },
    { value: 'faculty', label: 'Faculty' },
    { value: 'director', label: 'Academic Director' },
    { value: 'admin', label: 'Admin' },
];

const roles = (state = employeeRoles, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const employeeRole = (state = { value: 'faculty', label: 'Faculty' }, action) => {
    switch (action.type) {
    case 'SET_EMPLOYEE_ROLE':
        if (action.payload === 'grader') {
            return employeeRoles[0];
        } if (action.payload === 'adjunct') {
            return employeeRoles[1];
        } if (action.payload === 'career') {
            return employeeRoles[2];
        } if (action.payload === 'student_life') {
            return employeeRoles[3];
        } if (action.payload === 'faculty') {
            return employeeRoles[4];
        } if (action.payload === 'director') {
            return employeeRoles[5];
        } if (action.payload === 'admin') {
            return employeeRoles[6];
        }
        return state;
    case 'UNSET_EMPLOYEE_ROLE':
        return { value: 'faculty', label: 'Faculty' };
    case 'UPDATE_EMPLOYEE_ROLE':
        return action.payload;
    default:
        return state;
    }
};
const defaultEmployee = {
    first_name: '',
    last_name: '',
    email: '',
    role: 'faculty',
    status: 'Active',
};

const editingEmployee = (state = defaultEmployee, action) => {
    switch (action.type) {
    case 'SET_EDITING_EMPLOYEE':
        return action.payload;
    case 'UNSET_EDITING_EMPLOYEE':
        return defaultEmployee;
    case 'UNSET_USER':
        return defaultEmployee;
    default:
        return state;
    }
};

// Academic Events
const allAcademicEvents = (state = [], action) => {
    switch (action.type) {
    case 'SET_EMPOLOYEE_DASHBOARD_ACADEMIC_EVENT_LIST':
        return action.payload;
    default:
        return state;
    }
};

export default combineReducers({
    employeeList,
    editingEmployee,
    allAcademicEvents,
    roles,
    statuses,
    employeeStatus,
    employeeRole,
});
