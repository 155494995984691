import { connect } from 'react-redux';
import {
    Route,
} from 'react-router-dom';
import StudentAssignments from '../StudentPortal/StudentAssignments/StudentAssignments';
import CohortListPage from '../Cohort/CohortListPage/CohortListPage';
import LandingPage from '../LoginPage/LandingPage';
import ApplicantAppListPage from '../ApplicantPortal/ApplicationListPage/ApplicantAppListPage';
import LoadingPage from './LoadingPage';

const React = require('react');
const PropTypes = require('prop-types');
const {
    STUDENT,
    ALL_STAFF,
    ADJUNCT,
    APPLICANT,
} = require('./UserTypes');

const AuthRoute = (props) => {
    const {
        user,
        Component,
        path,
        exact = false,
        requiredRoles = [],
        dispatch,
    } = props;

    let pageToRender = Component;
    if (user.loading === true) {
        dispatch({ type: 'FETCH_USER' });
        // Waiting on user response
        pageToRender = LoadingPage;
    } else if (!user.email) {
        pageToRender = LandingPage;
    } else if (requiredRoles.includes(user.role)) {
        pageToRender = Component;
    } else if (user.role === STUDENT) {
        pageToRender = StudentAssignments;
    } else if ([...ALL_STAFF, ADJUNCT].indexOf(user.role) >= 0) {
        pageToRender = CohortListPage;
    } else if (user.role === APPLICANT) {
        pageToRender = ApplicantAppListPage;
    }
    return (
        <Route
            exact={exact}
            path={path}
            component={pageToRender}
        />
    );
};

AuthRoute.propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
    Component: PropTypes.elementType.isRequired,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool.isRequired,
    requiredRoles: PropTypes.instanceOf(Array).isRequired,
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
});

export default connect(mapStateToProps)(AuthRoute);
