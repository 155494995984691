import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Divider, IconButton, Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import {
    collaborationInfo,
    environmentInfo, growthInfo,
    learningInfo,
    strengthInfo,
} from '../InterviewSection/InterviewSectionCopy';
import ConfirmationModal from '../../Modal/ConfirmationModal';

const interviewSections = [
    learningInfo,
    collaborationInfo,
    strengthInfo,
    growthInfo,
    environmentInfo,
];
const styles = theme => ({
    mainHeading: {
        fontWeight: 500,
        color: 'black',
        lineHeight: '51px',
        textAlign: 'center',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
    },
    bold: {
        fontWeight: 'bold',
        color: 'black',
        padding: '8px 0',
    },
    wrapper: {
        padding: '8px 0',
    },
    score: {
        maxWidth: '200px',
        marginBottom: '10px ',
    },
    headerWrapper: {
        display: 'flex',
        gap: '30px',
    },
});
function InterviewNote({
    editingApplication,
    editingInterviewScore,
    applicationInterviewReview,
    dispatch,
    classes,
}) {
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [expand, setExpand] = useState(true);

    const deleteNote = () => {
        dispatch({
            type: 'PUT_APPLICATION_DETAILS',
            payload: { ...editingApplication, interview_completed: false },
        });
    };
    const date = new Date(editingApplication.interview_submitted_at).toLocaleString();
    const toggleExpand = () => {
        setExpand((prevState) => !prevState);
    };
    return (
        <>
            {editingApplication.interview_completed
        && (
            <div>
                <div className="applicantNote">
                    <div className={classes.header}>
                        <IconButton onClick={toggleExpand}>
                            {expand
                                ? <ExpandMoreIcon fontSize="large" fontVariant="caption" />
                                : <ExpandLessIcon fontSize="large" fontVariant="caption" />
                            }
                        </IconButton>
                        <Typography className={classes.mainHeading} variant="h6">{`By: ${editingApplication?.employee?.first_name} ${editingApplication?.employee?.last_name}`}</Typography>

                    </div>
                    <div className={classes.header}>
                        <Typography variant="caption">Interview Note</Typography>
                        <Typography variant="caption">
                            {`${' — '}`}
                        </Typography>
                        <Typography variant="caption">{date}</Typography>

                    </div>
                    {expand
                       && (
                           <>

                               <div className={classes.wrapper}>
                                   <div className={classes.headerWrapper}>
                                       <Typography className={classes.heading} variant="h5">
                                           {`Overall Score — ${editingInterviewScore.value}`}
                                       </Typography>
                                   </div>
                                   <div>
                                       <ReactMarkdown
                                           align="left"
                                           linkTarget="_blank"
                                           rehypePlugins={[rehypeRaw]}
                                           remarkPlugins={[remarkGfm]}
                                       >
                                           {editingApplication?.interview_summary}
                                       </ReactMarkdown>

                                   </div>
                               </div>

                               {interviewSections.map((section) => {
                                   const review = applicationInterviewReview.get(
                                       section.questionId,
                                   );

                                   return (
                                       <div key={section.questionId} className={classes.wrapper}>
                                           <div className={classes.headerWrapper}>
                                               <Typography className={classes.heading} variant="h5">
                                                   {`${section.header} — ${review?.score}`}
                                               </Typography>
                                           </div>
                                           <div>
                                               <ReactMarkdown
                                                   align="left"
                                                   linkTarget="_blank"
                                                   rehypePlugins={[rehypeRaw]}
                                                   remarkPlugins={[remarkGfm]}
                                               >
                                                   {review?.interview_note}
                                               </ReactMarkdown>

                                           </div>
                                       </div>
                                   );
                               })}
                               <Button variant="outlined" onClick={() => { setConfirmationModalOpen(true); }}>Delete</Button>
                           </>
                       )
                    }
                </div>

                <Divider />
                <ConfirmationModal
                    open={confirmationModalOpen}
                    dialogTitle="Delete Note"
                    dialogMessage={"You're about to delete a note. Would you like to continue?"}
                    onCancel={() => setConfirmationModalOpen(false)}
                    onConfirm={() => { deleteNote(); setConfirmationModalOpen(false); }}
                />
            </div>
        )

            }
        </>
    );
}

InterviewNote.propTypes = {
    editingApplication: PropTypes.instanceOf(Object).isRequired,
    editingInterviewScore: PropTypes.instanceOf(Object).isRequired,
    applicationInterviewReview: PropTypes.instanceOf(Map).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,

};
const mapStateToProps = state => ({
    dispatch: state.dispatch,
    editingApplication: state.application.editingApplicationDetails,
    applicationInterviewReview: state.application.applicationInterviewReview,
    editingInterviewScore: state.application.editingInterviewScore,

});
export default connect(mapStateToProps)(withStyles(styles)(InterviewNote));
