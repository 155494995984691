import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import PageTitle from '../PageTitle/PageTitle';
import CourseWork from './CourseWork';

function GradeCourseWorkPage() {
    const { id: courseWorkId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const assignment = useSelector(store => store.assignments.selectedAssignment);
    const student = useSelector(store => store.assignments.selectedStudent);

    const stableDispatch = useCallback(dispatch, []);
    const stableHistory = useCallback(history, []);

    useEffect(() => {
        if (stableHistory.length > 1) {
            stableDispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        } else {
            stableDispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
        }
    }, [stableDispatch, stableHistory]);

    return (
        <div className="container">
            <PageTitle
                title={`
                    Assignment :: ${assignment.name} :: ${student.first_name} ${student.last_name}
                `}
            />
            <CourseWork
                courseWorkId={Number(courseWorkId)}
                handleClose={() => {
                    if (history.length > 1) {
                        dispatch({ type: 'UNSET_ASSIGNMENT_COURSE_WORK' });
                        history.goBack();
                    }
                }}
                history={history}
            />
        </div>
    );
}

export default GradeCourseWorkPage;
