import { Container, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    headingContainer: {
        background: '#ECEFF1',
        margin: '0 10px',
        padding: ' 0 3px',

    },
    heading: {
        fontWeight: 500,
        color: 'black',
        padding: '10px 5px',
    },
    wrapper: {
        display: 'flex',
        gap: '10px',
    },
    bold: {
        fontWeight: 'bold',
    },
    body: {
        fontSize: '15px',
    },
    container: {
        padding: '12px',
    },
});

const rubricText = [
    {
        primaryText: 'Applicant does not demonstrate awareness of program structure, outcomes, or value. Applicant '
            + 'is not able to articulate their learning style, goals, or how they align with the program.',
        secondaryText: 'Score of 1',
    },
    {
        primaryText: 'Applicant demonstrates basic awareness of program structure, outcomes, and value.'
            + ' Applicant is able to demonstrate how their learning style and/or goals align with the program.',
        secondaryText: 'Score of 3',
    },
    {
        primaryText: 'Applicant demonstrates significant awareness of program structure, outcomes, and value. Applicant'
            + ' is able to specifically demonstrate how their learning style and/or goals align with the program.',
        secondaryText: 'Score of 5',
    },

];
const InterviewRubric = ({
    classes,
}) => (
    <Container className={classes.container}>
        <div className={classes.headingContainer}>
            <Typography className={classes.heading} variant="h4">
                Rubric
            </Typography>
        </div>

        <Container className={classes.wrapper}>
            {rubricText.map((text) => (
                <div key={text.secondaryText}>
                    <Typography className={classes.bold}>{text.secondaryText}</Typography>
                    <Typography className={classes.body} variant="body1">{text.primaryText}</Typography>

                </div>
            ))}
        </Container>

    </Container>

);

InterviewRubric.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,

};

export default withStyles(styles)(InterviewRubric);
