import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    textField: {
        width: '100%',
    },
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
        minWidth: '768px',
        [theme.breakpoints.down('md')]: {
            minWidth: '100px',
        },
        padding: '0px',
    },
    modalContainer: {
        // overflow: 'scroll',
        minWidth: '1000px',
    },
    feedbackContainer: {
        backgroundColor: '#f8fafb',
        margin: '0px',
        padding: '20px',
        borderTop: '1px solid #bbb',
        borderBottom: '1px solid #bbb',
    },
    feedback: {
        backgroundColor: '#fff',
    },
    gridItem: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
});

class AcademicsEventModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventNotes: '',
        };
    }

    handleChangeFor = prop => (event) => {
        this.setState({
            [prop]: event.target.value,
        });
    };

    handleSave = () => {
        const { dispatch, handleClose, academicEvent } = this.props;
        const { eventNotes } = this.state;
        dispatch({
            type: 'PUT_ACADEMIC_EVENT',
            payload: {
                id: academicEvent.id,
                studentId: academicEvent.user.id,
                comment: eventNotes,
            },
        });
        handleClose();
    };

    onClose = () => {
        const { handleClose } = this.props;
        handleClose();
        this.setState({
            eventNotes: '',
        });
    };

    render() {
        const {
            open = false,
            academicEvent,
            classes = {},
        } = this.props;
        const {
            eventNotes,
        } = this.state;
        const student = academicEvent.user;
        return (
            <>
                {
                    student
                    && (
                        <Dialog open={open} className="modalContainer">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <DialogTitle id="responsive-dialog-title" style={{ flex: 1 }}>
                                    {`${student.first_name} ${student.last_name}'s feedback included a score that requires attention.`}
                                </DialogTitle>
                                <Button
                                    style={{ height: '44px', margin: '20px' }}
                                    onClick={this.onClose}
                                >
                                    Close
                                </Button>
                            </div>
                            <div className={classes.feedbackContainer}>
                                <DialogContent align="left">
                                    <form>
                                        <h6>
                                            What context can you provider about the student
                                            feedback?
                                            What actions are being taken to address it?
                                        </h6>
                                        <TextField
                                            id="event_notes"
                                            type="text"
                                            value={eventNotes}
                                            onChange={this.handleChangeFor('eventNotes')}
                                            className={classes.textField}
                                            multiline
                                            rows="5"
                                            variant="outlined"
                                            inputProps={{ maxLength: 2048 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </form>
                                </DialogContent>
                            </div>
                            <br />
                            <DialogActions>
                                <Button
                                    onClick={this.handleSave}
                                    disabled={!eventNotes.length}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={this.onClose}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
            </>
        );
    }
}

AcademicsEventModal.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    academicEvent: PropTypes.instanceOf(Object).isRequired,
    open: PropTypes.bool.isRequired,
};

export default connect()(withStyles(styles)(AcademicsEventModal));
