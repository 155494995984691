import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    Paper,
    Typography,
    Grid,
    AppBar,
    Tabs,
    Tab,
    Button,
    Select,
    MenuItem,
    FormControl,
    OutlinedInput,
    TextField,
} from '@mui/material';
import ReactSelect from 'react-select';
import { Prompt, Link } from 'react-router-dom';
import PageTitle from '../../PageTitle/PageTitle';
import GeneralTab from './Tabs/GeneralTab';
import TechnicalTab from './Tabs/TechnicalTab';
import ProfileTab from './Tabs/ProfileTab';
import LogicTab from './Tabs/LogicTab';
import InterviewTab from './Tabs/InterviewTab';
import ChangeLogTab from './Tabs/ChangeLogTab';
import ConditionalTypography from './ConditionalTypography';
import { shortDateSlash } from '../../../helpers/DateFormatHelper';
import { cohortOptionSelect } from '../../../helpers/CohortTextHelper';
import HubspotIcon from '../../General/Icons/HubpostIcon';

const styles = theme => ({
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
    },
    /**
     * React doesn't provide an auto complete input field. Styling this third
     * party component takes a bit more work that standard Material UI components.
     * https://react-select.com/styles#using-classnames
     */
    cohortSelect: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '0px',
        },
        '& .react-select__control': {
            height: '39px',
            borderRadius: '3px',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
        '& .react-select__multi-value__label': {
            fontSize: '16px',
        },
        '& .react-select__multi-value__remove': {
            cursor: 'pointer',
        },
        '& .react-select__menu': {
            zIndex: '1000',
        },
    },
    halfWidth: {
        width: '50%',
    },
    passwordText: {
        marginTop: '36px',
        fontSize: '16px',
    },
    selectContainer: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
});

const scoreValue = (score) => {
    if (score !== undefined && score !== null) {
        return score;
    }
    return '';
};

class ApplicationDetailPage extends Component {
    state = {
        selectedTab: 0,
        generalChanges: false,
        technicalChanges: false,
        interviewChanges: false,
        profileChanges: false,
    };

    componentDidMount() {
        const { dispatch, match, history } = this.props;
        dispatch({ type: 'UNSET_APPLICATION_DETAILS' });
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_COHORT_LIST' });
        dispatch({ type: 'FETCH_APPLICATION_DETAILS', payload: { id: match.params.id } });
        if (match.params.tab) {
            switch (match.params.tab) {
            case 'technical':
            case 'creative':
                this.setState({
                    selectedTab: 1,
                });
                break;
            case 'personal':
                this.setState({
                    selectedTab: 2,
                });
                break;
            case 'reasoning':
                this.setState({
                    selectedTab: 3,
                });
                break;
            case 'interview':
                this.setState({
                    selectedTab: 4,
                });
                break;
            default:
                break;
            }
        }
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        this.unlisten = history.listen(this.onRouteChange);
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_APPLICATION_DETAILS' });
        dispatch({ type: 'UNSET_STUDENT_NOTES' });
        dispatch({ type: 'UNSET_EDITING_GENERAL_NOTE' });
        dispatch({ type: 'UNSET_EDITING_TECHNICAL_NOTE' });
        dispatch({ type: 'UNSET_EDITING_INTERVIEW_NOTE' });
        this.unlisten();
    }

    handleChangeFor = propertyName => (event) => {
        const { dispatch, editingApplication } = this.props;
        const updatedApplication = {
            ...editingApplication,
            [propertyName]: event.target.value,
        };
        dispatch({ type: 'SET_EDITING_APPLICATION_DETAILS', payload: updatedApplication });
    };

    deleteNote = (noteId) => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'DELETE_APPLICATION_NOTE', payload: { noteId, applicationId: match.params.id } });
    };

    handleNoteChange = (value, action) => {
        const { dispatch, match, applicant } = this.props;
        let noteType;
        if (action === 'SET_EDITING_GENERAL_NOTE') {
            noteType = 'call';
            this.setState({
                generalChanges: true,
            });
        } else if (action === 'SET_EDITING_INTERVIEW_NOTE') {
            noteType = 'interview';
            this.setState({
                interviewChanges: true,
            });
        } else if (action === 'SET_EDITING_TECHNICAL_NOTE') {
            noteType = 'technical';
            this.setState({
                technicalChanges: true,
            });
        }
        if (noteType) {
            dispatch({
                type: action,
                payload: {
                    content: value,
                    admissionId: parseInt(match.params.id, 10),
                    type: noteType,
                    userId: applicant.id,
                },
            });
        }
    };

    addNote = (action) => {
        const {
            dispatch,
            newGeneralNote,
            newInterviewNote,
            newTechnicalNote,
        } = this.props;
        if (action === 'CREATE_GENERAL_NOTE') {
            dispatch({ type: 'CREATE_APPLICATION_NOTE', payload: newGeneralNote });
            this.setState({
                generalChanges: false,
            });
        }
        if (action === 'CREATE_INTERVIEW_NOTE') {
            dispatch({ type: 'CREATE_APPLICATION_NOTE', payload: newInterviewNote });
            this.setState({
                interviewChanges: false,
            });
        }
        if (action === 'CREATE_TECHNICAL_NOTE') {
            dispatch({ type: 'CREATE_APPLICATION_NOTE', payload: newTechnicalNote });
            this.setState({
                technicalChanges: false,
            });
        }
    };

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = () => {
        const {
            dispatch,
            match,
            application,
        } = this.props;
        if (Number(match.params.id) !== application.application_id) {
            dispatch({ type: 'UNSET_APPLICATION_DETAILS' });
            dispatch({ type: 'UNSET_EDITING_GENERAL_NOTE' });
            dispatch({ type: 'UNSET_EDITING_TECHNICAL_NOTE' });
            dispatch({ type: 'UNSET_EDITING_INTERVIEW_NOTE' });
            this.setState({
                profileChanges: false,
                technicalChanges: false,
                generalChanges: false,
                interviewChanges: false,
            });
            dispatch({ type: 'FETCH_APPLICATION_DETAILS', payload: { id: match.params.id } });
        }
    };

    handleChange = (event, newValue) => {
        this.setState({
            selectedTab: newValue,
        });
    };

    handleScoreChange = (event, type, id) => {
        const { dispatch } = this.props;
        if (type === 'technical') {
            dispatch({ type: 'UPDATE_TECHNICAL_SCORE', payload: event });
            this.setState({
                technicalChanges: true,
            });
        }
        if (type === 'interview') {
            dispatch({ type: 'UPDATE_INTERVIEW_SCORE', payload: event });
            this.setState({
                interviewChanges: true,
            });
        }
        if (type === 'profile') {
            dispatch({ type: 'UPDATE_PROFILE_SCORE', payload: { event, id } });
            this.setState({
                profileChanges: true,
            });
        }
    };

    handleScoreSubmission = (type, score) => {
        const {
            dispatch,
            application,
            applicationProfile,
            editingApplication,
        } = this.props;
        if (type === 'technical') {
            if (!score) {
                return;
            }
            dispatch({
                type: 'PUT_TECHNICAL_SCORE',
                payload:
                    {
                        applicationId: application.application_id,
                        resume_score: score,
                    },
            });
            this.setState({
                technicalChanges: false,
            });
        }
        if (type === 'interview') {
            if (score === null || score === undefined) {
                return;
            }
            dispatch({
                type: 'PUT_INTERVIEW_SCORE',
                payload:
                    {
                        applicationId: application.application_id,
                        interview_score: score,
                    },
            });

            this.setState({
                interviewChanges: false,
            });
        }
        if (type === 'profile') {
            let profileQuestionValues = applicationProfile.questions.filter(
                profileQuestion => profileQuestion.selectValue,
            );
            profileQuestionValues = profileQuestionValues.map(question => ({
                id: question.id,
                value: question.selectValue.value,
            }));
            dispatch({
                type: 'PUT_PROFILE_SCORE',
                payload:
                    {
                        applicationId: application.application_id,
                        scores: profileQuestionValues,
                    },
            });

            dispatch({
                type: 'PUT_APPLICATION_DETAILS',
                payload: editingApplication,
            });
            this.setState({
                profileChanges: false,
            });
        }
    };

    handleWaitlistCohortSelect = (selectedOption) => {
        const { dispatch, editingApplication } = this.props;
        dispatch({
            type: 'SET_EDITING_APPLICATION_DETAILS',
            payload: {
                ...editingApplication,
                waitlist_cohort: selectedOption,
                waitlist_cohort_id: selectedOption.value,
            },
        });
    };

    handlePreferredCohortSelect = (selectedOption) => {
        const { dispatch, editingApplication } = this.props;
        dispatch({
            type: 'SET_EDITING_APPLICATION_DETAILS',
            payload: {
                ...editingApplication,
                preferred_cohort: selectedOption,
                preferred_cohort_id: selectedOption.value,
            },
        });
    };

    handleCohortSelect = (selectedOption) => {
        const { dispatch, editingApplication } = this.props;
        let assignedCohortId = null;
        if (selectedOption.value && selectedOption.value !== '') {
            assignedCohortId = selectedOption.value;
        }
        dispatch({
            type: 'SET_EDITING_APPLICATION_DETAILS',
            payload: {
                ...editingApplication,
                assigned_cohort: selectedOption,
                assigned_cohort_id: assignedCohortId,
            },
        });
    };

    formatStatus = (status) => {
        switch (status) {
        case 'not_started':
            return 'Not Started';
        case 'started':
            return 'Started';
        case 'completed':
            return 'Completed';
        case 'on_hold':
            return 'Deferred';
        case 'scheduled':
            return 'Scheduled';
        case 'second_review':
            return 'Second Review';
        case 'needs_scheduling':
            return 'Needs Scheduling';
        case 'placed':
            return 'Accepted';
        case 'confirmed':
            return 'Confirmed';
        case 'declined':
            return 'Declined';
        case 'declined_by_applicant':
            return 'Withdrew Application';
        case 'dropped_out':
            return 'Withdrew from Cohort';
        case 'declined_reapplicant':
            return 'Declined - Reapplicant';
        default:
            return '';
        }
    };

    a11yProps = index => ({
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    });

    submitApplicationDetails = () => {
        const { editingApplication, dispatch } = this.props;
        dispatch({
            type: 'PUT_APPLICATION_DETAILS',
            payload: editingApplication,
        });
    };

    submitInterviewReview = () => {
        const {
            applicationInterviewReview, dispatch, application,
        } = this.props;
        dispatch({
            type: 'PUT_INTERVIEW_REVIEW',
            payload: {
                applicationId: application.id,
                applicationInterviewReview,
            },
        });
    };

    getSubscriptionPreferences = (userHubspotPreferences) => {
        let result = (
            <Typography variant="body1">
                Unknown
            </Typography>
        );
        if (userHubspotPreferences && userHubspotPreferences.subscriptionStatuses) {
            const subscribedCount = userHubspotPreferences.subscriptionStatuses.filter(item => item.status === 'SUBSCRIBED').length;
            const unsubscribedCount = userHubspotPreferences.subscriptionStatuses.filter(item => item.status !== 'SUBSCRIBED').length;
            if (subscribedCount === 0 && unsubscribedCount === 0) {
                result = (
                    <Typography variant="body1">
                        No records found
                    </Typography>
                );
            } else if (subscribedCount === 0) {
                result = (
                    <Typography variant="body1">
                        <span role="img" aria-label="X">❌</span>
                        {' '}
                        Unsubscribed from ALL communication
                    </Typography>
                );
            } else if (unsubscribedCount === 0) {
                result = (
                    <Typography variant="body1">
                        <span role="img" aria-label="Check">✅</span>
                        {' '}
                        Subscribed to all communication
                    </Typography>
                );
            } else {
                result = userHubspotPreferences.subscriptionStatuses.map(
                    item => (
                        <>
                            <Typography variant="body1">
                                {item.status === 'SUBSCRIBED' ? '✅' : '❌'}
                                {' '}
                                {item.name}
                            </Typography>
                        </>
                    ),
                );
            }
        }
        return result;
    };

    render() {
        const {
            application,
            editingApplication,
            applicant,
            user,
            applicationLogic,
            applicationProfile,
            applicationChangeLog,
            newGeneralNote,
            newTechnicalNote,
            cohorts,
            userHubspotPreferences,
            classes,
        } = this.props;

        const {
            generalChanges,
            interviewChanges,
            technicalChanges,
            profileChanges,
        } = this.state;

        const {
            selectedTab,
        } = this.state;
        let pronounValue = '';
        if (applicant && applicant.pronouns && applicant.pronouns !== 'Prefer-not-to-say') {
            pronounValue = `(${applicant.pronouns})`;
        }
        let technicalTabTitle = 'Technical';
        let logicTabTitle = 'Logic';
        let profileTabTitle = 'Personal';
        let programType = 'Full Stack Engineering';
        if (application.course_id === 2) {
            profileTabTitle = 'Personal';
            logicTabTitle = 'Reasoning';
            technicalTabTitle = 'Creative';
            programType = 'User Experience Design';
        }
        if (application.course_id === 3) {
            profileTabTitle = 'Personal';
            logicTabTitle = 'Reasoning';
            technicalTabTitle = 'Creative';
            programType = 'UX Academy';
        }

        const changedTabs = [];
        if (generalChanges === true) {
            changedTabs.push('General Tab');
        }
        if (interviewChanges === true) {
            changedTabs.push('Interview Tab');
        }
        if (profileChanges === true) {
            changedTabs.push(`${profileTabTitle} Tab`);
        }
        if (technicalChanges === true) {
            changedTabs.push(`${technicalTabTitle} Tab`);
        }

        const message = `You have unsaved edits in the ${changedTabs}. Are you sure you want to leave? `;
        const cohortOptions = cohortOptionSelect(cohorts);
        cohortOptions.unshift({ value: '', label: 'Unassigned' });
        const waitlistCohortOptions = cohortOptionSelect(cohorts);
        waitlistCohortOptions.unshift({ value: '', label: 'None' });
        const preferredCohortOptions = cohortOptionSelect(cohorts);
        preferredCohortOptions.unshift({ value: '', label: 'None' });
        // Nav bar buttons
        const actions = [{
            label: 'Edit',
            // Dispatches this action...
            type: 'SET_EDITING_APPLICATION_DETAILS',
            payload: { ...application },
            // ...and navigates to this page
            path: `/admissions_applications/${application.application_id}/edit`,
        }];
        return (
            <>
                <Prompt
                    // Use a standard alert dialog if the user leaves the page when
                    // changes are detected.
                    when={generalChanges || interviewChanges || technicalChanges || profileChanges}
                    message={message}
                />
                {
                    applicant
                    && applicant.first_name
                    && (
                        <>
                            <PageTitle
                                title={`Application :: ${applicant.first_name} ${applicant.last_name}`}
                                actions={actions}
                            />
                            <Paper>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography variant="h5">
                                            {
                                                (
                                                    application.application_status === 'placed'
                                                    || application.application_status === 'confirmed'
                                                    || application.application_status === 'dropped_out'
                                                )
                                                    ? (
                                                        <Link to={`/students/${applicant.id}`}>
                                                            {applicant.first_name}
                                                            {' '}
                                                            {applicant.last_name}
                                                            {' '}
                                                            {pronounValue}
                                                        </Link>
                                                    ) : (
                                                        <>
                                                            {applicant.first_name}
                                                            {' '}
                                                            {applicant.last_name}
                                                            {' '}
                                                            {pronounValue}
                                                        </>
                                                    )
                                            }
                                            {
                                                (
                                                    applicant.hubspot_url
                                                    && applicant.hubspot_url
                                                )
                                                && (
                                                    <a
                                                        href={`${applicant.hubspot_url}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <HubspotIcon />
                                                    </a>
                                                )
                                            }
                                        </Typography>
                                        <ConditionalTypography
                                            title="User Id"
                                            displayValue={applicant.id}
                                            defaultValue="Not provided"
                                        />
                                        <ConditionalTypography
                                            title="Email"
                                            displayValue={applicant.email}
                                            defaultValue="Not provided"
                                        />
                                        <ConditionalTypography
                                            title="Phone"
                                            displayValue={applicant.phone}
                                            defaultValue="Not provided"
                                        />
                                        {
                                            applicant.address
                                            || applicant.city
                                            || applicant.state
                                                ? (
                                                    <>
                                                        <Typography variant="h6">
                                                            Address
                                                        </Typography>
                                                        <Typography>
                                                            {applicant.address}
                                                            {', '}
                                                            {applicant.city}
                                                            {', '}
                                                            {applicant.state}
                                                            {' '}
                                                            {applicant.zip_code}
                                                        </Typography>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography variant="h6">
                                                            Address
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            Not provided
                                                        </Typography>
                                                    </>
                                                )}
                                        <ConditionalTypography
                                            title="Date of Birth"
                                            displayValue={
                                                applicant.birthdate
                                                && shortDateSlash(applicant.birthdate)
                                            }
                                            defaultValue="Not provided"
                                        />
                                        {
                                            userHubspotPreferences
                                            && userHubspotPreferences.subscriptionStatuses
                                            && (
                                                <Typography variant="h6">
                                                    Hubspot Communication Preferences
                                                </Typography>
                                            )
                                        }
                                        {
                                            this.getSubscriptionPreferences(userHubspotPreferences)
                                        }
                                        <Typography variant="h6">Preferred Cohorts</Typography>

                                        {editingApplication.preferred_cohort
                                            && (
                                                <ReactSelect
                                                    placeholder="Cohort..."
                                                    value={editingApplication.preferred_cohort}
                                                    onChange={this.handlePreferredCohortSelect}
                                                    options={preferredCohortOptions}
                                                    className={`${classes.cohortSelect} ${classes.halfWidth}`}
                                                    classNamePrefix="react-select"
                                                />
                                            )
                                        }
                                        {!editingApplication.preferred_cohort
                                        && (
                                            application.cohorts
                                            && application.cohorts.map(cohort => (
                                                <div key={`cohort_${cohort.cohort_id}`}>
                                                    <Typography>
                                                        {`${cohort.cohort_name} - Prework: ${shortDateSlash(cohort.prework_start)}`}
                                                    </Typography>
                                                </div>
                                            )))
                                        }

                                    </Grid>
                                    <Grid item xs={6}>
                                        <ConditionalTypography
                                            title="Status"
                                            displayValue={
                                                this.formatStatus(
                                                    application.application_status,
                                                )}
                                            defaultValue="Not provided"
                                        />
                                        <ConditionalTypography
                                            title="Level of Interest"
                                            displayValue={{
                                                curious: 'Curious, but still unsure',
                                                interested: 'Interested, have things to figure out',
                                                very_interested: 'Very interested, wants to attend',
                                            }[application.level_of_interest]}
                                            defaultValue="Not provided"
                                        />
                                        <ConditionalTypography
                                            title="Referral Source"
                                            displayValue={applicant.referral_source}
                                            defaultValue="Not Provided"
                                        />
                                        <ConditionalTypography
                                            title="Program"
                                            displayValue={programType}
                                            defaultValue="Not provided"
                                        />
                                        <Typography variant="h6">
                                            Waitlist Cohort
                                        </Typography>
                                        <ReactSelect
                                            placeholder="Cohort..."
                                            value={editingApplication.waitlist_cohort}
                                            onChange={this.handleWaitlistCohortSelect}
                                            options={waitlistCohortOptions}
                                            className={classes.cohortSelect}
                                            classNamePrefix="react-select"
                                        />
                                        <br />
                                        <Typography variant="h6">Application Status</Typography>
                                        <FormControl>
                                            <Select
                                                labelid="application_status_label"
                                                id="application_status"
                                                input={<OutlinedInput margin="dense" />}
                                                value={editingApplication.application_status ? editingApplication.application_status : ''}
                                                onChange={this.handleChangeFor('application_status')}
                                            >
                                                <MenuItem value="not_started">Not Started</MenuItem>
                                                <MenuItem value="started">Started</MenuItem>
                                                <MenuItem value="completed">Completed</MenuItem>
                                                <MenuItem value="on_hold">Deferred</MenuItem>
                                                <MenuItem value="scheduled">Scheduled</MenuItem>
                                                <MenuItem value="second_review">Second Review</MenuItem>
                                                <MenuItem value="placed">Accepted</MenuItem>
                                                <MenuItem value="confirmed">Confirmed</MenuItem>
                                                <MenuItem value="declined">Declined</MenuItem>
                                                <MenuItem value="declined_by_applicant">Withdrew Application</MenuItem>
                                                <MenuItem value="dropped_out">Withdrew from Cohort</MenuItem>
                                                <MenuItem value="declined_reapplicant">Declined - Reapplicant</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <br />
                                        <br />
                                        {
                                            (
                                                application.application_status === 'placed'
                                                || application.application_status === 'confirmed'
                                            ) && (
                                                editingApplication.assigned_cohort_id
                                                && application.assigned_cohort_id
                                                && application.assigned_cohort_id
                                                !== editingApplication.assigned_cohort_id
                                            ) && (
                                                application.assigned_cohort_start
                                                && moment(
                                                    application.assigned_cohort_start,
                                                ).isBefore(moment())
                                            ) && (
                                                <>
                                                    <Typography variant="h6">
                                                        Transfer Date
                                                    </Typography>
                                                    <TextField
                                                        required
                                                        id="movement_date"
                                                        type="date"
                                                        className={classes.textField}
                                                        value={editingApplication.movement_date ? editingApplication.movement_date : ''}
                                                        onChange={this.handleChangeFor('movement_date')}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    <br />
                                                    <br />
                                                    <Typography variant="h6">
                                                        Note
                                                    </Typography>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="text"
                                                        value={editingApplication.movement_notes ? editingApplication.movement_notes : ''}
                                                        margin="dense"
                                                        onChange={this.handleChangeFor('movement_notes')}
                                                    />
                                                    <br />
                                                    <br />
                                                </>
                                            )
                                        }
                                        {
                                            (
                                                application.application_status === 'placed'
                                                || application.application_status === 'confirmed'
                                            ) && (
                                                editingApplication.application_status === 'dropped_out'
                                                || editingApplication.assigned_cohort_id === ''
                                                || editingApplication.assigned_cohort_id === null
                                            ) && (
                                                application.assigned_cohort_id !== ''
                                                && application.assigned_cohort_id !== null
                                            ) && (
                                                application.assigned_cohort_start
                                                && moment(
                                                    application.assigned_cohort_start,
                                                ).isBefore(moment())
                                            ) && (
                                                <>
                                                    <Typography variant="h6">
                                                        Drop Date
                                                    </Typography>
                                                    <TextField
                                                        required
                                                        id="movement_date"
                                                        type="date"
                                                        className={classes.textField}
                                                        value={editingApplication.movement_date ? editingApplication.movement_date : ''}
                                                        onChange={this.handleChangeFor('movement_date')}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    <br />
                                                    <br />
                                                    <Typography variant="h6">
                                                        Note
                                                    </Typography>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="text"
                                                        value={editingApplication.movement_notes ? editingApplication.movement_notes : ''}
                                                        margin="dense"
                                                        onChange={this.handleChangeFor('movement_notes')}
                                                    />
                                                    <br />
                                                    <br />
                                                </>
                                            )
                                        }
                                        {
                                            (
                                                editingApplication.application_status === 'placed'
                                                || editingApplication.application_status === 'confirmed'
                                            )
                                            && (
                                                <>
                                                    <Typography variant="h6">
                                                        Assigned Cohort
                                                    </Typography>
                                                    <ReactSelect
                                                        placeholder="Cohort..."
                                                        value={editingApplication.assigned_cohort}
                                                        onChange={this.handleCohortSelect}
                                                        options={cohortOptions}
                                                        className={classes.cohortSelect}
                                                        classNamePrefix="react-select"
                                                    />
                                                    <br />
                                                </>
                                            )
                                        }
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.submitApplicationDetails}
                                        >
                                            Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <AppBar position="static">
                                <Tabs indicatorColor="primary" value={selectedTab} onChange={this.handleChange}>
                                    <Tab label="General" {...this.a11yProps(0)} />
                                    <Tab
                                        label={`${technicalTabTitle} (${scoreValue(application.resume_score)})`}
                                        {...this.a11yProps(1)}
                                    />
                                    <Tab
                                        label={`${profileTabTitle} (${scoreValue(applicationProfile.profile_score)})`}
                                        {...this.a11yProps(2)}
                                    />
                                    <Tab
                                        label={`${logicTabTitle} (${scoreValue(applicationLogic.logic_score)})`}
                                        {...this.a11yProps(3)}
                                    />
                                    <Tab
                                        label={`Interview (${scoreValue(application.interview_score)})`}
                                        {...this.a11yProps(4)}
                                    />
                                    <Tab label="Change Log" {...this.a11yProps(5)} />
                                </Tabs>
                            </AppBar>
                            <GeneralTab
                                value={selectedTab}
                                index={0}
                                applicant={applicant}
                                application={application}
                                handleNoteChange={this.handleNoteChange}
                                addNote={this.addNote}
                                editingNote={newGeneralNote}
                                deleteNote={this.deleteNote}
                            />
                            <TechnicalTab
                                value={selectedTab}
                                index={1}
                                application={application}
                                handleScoreChange={this.handleScoreChange}
                                handleScoreSubmission={this.handleScoreSubmission}
                                handleNoteChange={this.handleNoteChange}
                                addNote={this.addNote}
                                editingNote={newTechnicalNote}
                                deleteNote={this.deleteNote}
                            />
                            <ProfileTab
                                value={selectedTab}
                                index={2}
                                applicationProfile={applicationProfile}
                                handleScoreChange={this.handleScoreChange}
                                handleScoreSubmission={this.handleScoreSubmission}
                                application={application}
                                submitInterviewReview={this.submitInterviewReview}
                            />
                            <LogicTab
                                value={selectedTab}
                                index={3}
                                user={user}
                                applicationLogic={applicationLogic}
                                application={application}
                            />
                            <InterviewTab
                                value={selectedTab}
                                index={4}
                                handleScoreChange={this.handleScoreChange}
                                handleScoreSubmission={this.handleScoreSubmission}
                                handleNoteChange={this.handleNoteChange}
                                addNote={this.addNote}
                                deleteNote={this.deleteNote}
                                submitInterviewReview={this.submitInterviewReview}
                            />
                            <ChangeLogTab
                                value={selectedTab}
                                index={5}
                                changeLog={applicationChangeLog}
                                applicant={applicant}
                            />
                        </>
                    )
                }
            </>
        );
    }
}

ApplicationDetailPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            tab: PropTypes.string,
        }),
    }).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    cohorts: PropTypes.instanceOf(Array).isRequired,
    applicant: PropTypes.instanceOf(Object),
    application: PropTypes.instanceOf(Object).isRequired,
    editingApplication: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    applicationLogic: PropTypes.instanceOf(Object).isRequired,
    applicationProfile: PropTypes.instanceOf(Object).isRequired,
    applicationChangeLog: PropTypes.instanceOf(Array).isRequired,
    newGeneralNote: PropTypes.instanceOf(Object).isRequired,
    newTechnicalNote: PropTypes.instanceOf(Object).isRequired,
    newInterviewNote: PropTypes.instanceOf(Object).isRequired,
    userHubspotPreferences: PropTypes.instanceOf(Object).isRequired,
    applicationInterviewReview: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

ApplicationDetailPage.defaultProps = {
    applicant: {},
};

const mapStateToProps = state => ({
    application: state.application.applicationDetails,
    applicant: state.application.applicationDetails.user,
    applicationLogic: state.application.applicationLogicQuestions,
    user: state.user,
    applicationProfile: state.application.applicationProfileQuestions,
    applicationChangeLog: state.application.applicationChangeLog,
    newGeneralNote: state.application.editingGeneralNote,
    newTechnicalNote: state.application.editingTechnicalNote,
    newInterviewNote: state.application.editingInterviewNote,
    applicationInterviewReview: state.application.applicationInterviewReview,
    cohorts: state.cohort.cohortList,
    editingApplication: state.application.editingApplicationDetails,
    userHubspotPreferences: state.hubspot.userCommunication,
});

export default connect(mapStateToProps)(withStyles(styles)(ApplicationDetailPage));
