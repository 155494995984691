import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import {
    Paper,
    Button,
    DialogActions,
    Typography,
} from '@mui/material';
import remarkGfm from 'remark-gfm';
import CodeBlock from '../components/General/CodeBlock';

const CreateNote = ({
    handleChangeFor,
    handleCancel,
    handleSubmit,
    editingNote,
}) => {
    const [selectedTab, setSelectedTab] = useState('write');
    return (
        <Paper>
            <Typography variant="h5">
                Add Note
            </Typography>
            <ReactMde
                value={editingNote.content}
                onChange={event => handleChangeFor(event)}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                minEditorHeight={120}
                minPreviewHeight={120}
                textAreaProps={{ autoFocus: true }}
                generateMarkdownPreview={markdown => (
                    Promise.resolve(
                        <ReactMarkdown
                            components={{ code: CodeBlock }}
                            remarkPlugins={[remarkGfm]}
                        >
                            {markdown}
                        </ReactMarkdown>,
                    )
                )}
            />
            <DialogActions>
                <Button
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </Paper>

    );
};

CreateNote.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChangeFor: PropTypes.func.isRequired,
    editingNote: PropTypes.instanceOf(Object).isRequired,
};
export default (CreateNote);
