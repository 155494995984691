/* eslint-disable no-console */
import axios from 'axios';
import {
    put, takeLatest, select,
} from 'redux-saga/effects';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* fetchAssignmentList(action) {
    try {
        const response = yield axios.get('api/assignments/list', { params: action.payload });
        yield put({ type: 'SET_ASSIGNMENT_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment list - get request failed', error);
    }
}

// Fetch list of assignment ids for a given cohort. This is used to copy assignments to
// another cohort.
function* fetchAssignmentsToCopy(action) {
    try {
        const response = yield axios.get('api/assignments/list/all', { params: action.payload });
        yield put({ type: 'SELECT_ALL_ASSIGNMENT_CHECKBOX', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignments to copy - get request failed', error);
    }
}

function* copyManyAssignments(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.post('api/assignments/copy', action.payload);
        yield delay(1000);
        yield put({ type: 'SET_SENDING_ASSIGNMENT_COMPLETE', payload: { modalOpen: true } });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Copy many assignments - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* fetchAssignmentDetails(action) {
    try {
        const response = yield axios.get(`api/assignments/detail/${action.payload.id}`);
        yield put({ type: 'SET_ASSIGNMENT', payload: response.data });
        const payload = {
            assignmentId: action.payload.id,
            cohortId: response.data.cohort_id,
        };
        yield put({ type: 'FETCH_COURSE_WORK', payload });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment details - get request failed', error);
    }
}

function* fetchCourseWork(action) {
    try {
        const response = yield axios.get(`api/assignments/coursework/list/${action.payload.assignmentId}/${action.payload.cohortId}`);
        yield put({ type: 'SET_COURSE_WORK_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment course work - get request failed', error);
    }
}

function* fetchCohortCourseWork(action) {
    try {
        const response = yield axios.get('api/assignments/coursework', {
            params: {
                cohortId: action.payload.cohortId,
            },
        });
        yield put({ type: 'SET_COHORT_COURSE_WORK_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Cohort course work - get request failed', error);
    }
}

function* fetchCourseWorkDetail(action) {
    try {
        const response = yield axios.get(`api/assignments/coursework/detail/${action.payload.id}`);
        yield put({ type: 'SET_ASSIGNMENT_COURSE_WORK', payload: response.data });
        yield put({ type: 'SET_ASSIGNMENT_STUDENT', payload: response.data.user });
        yield put({ type: 'SET_ASSIGNMENT_SELECTED', payload: response.data.assignment });
        if (action.showModal) {
            yield put({
                type: 'SET_ASSIGNMENT_MODAL',
                payload: { modalOpen: true },
            });
        }
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment course work - get request failed', error);
    }
}

const getDashboardSortOrder = state => state.assignments.dashboardAssignmentSortOrder;

function* gradeCourseWork(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        // When grading, pass the course work id
        const { courseWork, onComplete } = action.payload;
        yield axios.put(`api/assignments/grade/${courseWork.id}`, courseWork);
        const dashboardSortOrder = yield select(getDashboardSortOrder);
        yield put({ type: 'FETCH_EMPLOYEE_DASHBOARD_ASSIGNMENTS', payload: dashboardSortOrder });
        yield put({ type: 'FETCH_ASSIGNMENT_DETAILS', payload: { id: courseWork.assignmentId } });
        yield delay(250);
        if (onComplete) {
            onComplete();
        }
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment course work - put request failed', error);
    } finally {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* draftCourseWork(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const { courseWork, onComplete } = action.payload;
        // When grading, pass the course work id
        yield axios.put(`api/assignments/draft/${courseWork.id}`, courseWork);
        const dashboardSortOrder = yield select(getDashboardSortOrder);
        yield put({ type: 'FETCH_EMPLOYEE_DASHBOARD_ASSIGNMENTS', payload: dashboardSortOrder });
        yield put({ type: 'FETCH_ASSIGNMENT_DETAILS', payload: { id: courseWork.assignmentId } });
        yield delay(250);
        if (onComplete) {
            onComplete();
        }
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment course work - put request failed', error);
    } finally {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}
function* approveAllDrafts(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const { assignment, onComplete } = action.payload;
        // When grading, pass the course work id
        yield axios.put('api/assignments/approve_all_drafts', assignment);
        const dashboardSortOrder = yield select(getDashboardSortOrder);
        yield put({ type: 'FETCH_EMPLOYEE_DASHBOARD_ASSIGNMENTS', payload: dashboardSortOrder });
        yield put({ type: 'FETCH_ASSIGNMENT_DETAILS', payload: { id: assignment.id } });
        yield delay(250);
        if (onComplete) {
            onComplete();
        }
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment course work - put request failed', error);
    } finally {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}
function* updateAssignment(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put('api/assignments/update', action.payload);
        yield delay(250);
        yield action.history.replace(`/assignments/${action.payload.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment create - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* createAssignment(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const response = yield axios.post('api/assignments', action.payload);
        // Store the newly created assignment so we can display it on the page
        yield put({ type: 'SET_ASSIGNMENT', payload: response.data });
        yield delay(300);
        yield action.history.replace(`/assignments/${response.data.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment create - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* deleteAssignment(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.delete(`api/assignments/${action.payload.id}`);
        yield delay(250);
        yield action.history.replace('/assignments');
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment delete - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* assignAssignment(action) {
    try {
        yield axios.put(`api/assignments/assign/${action.payload.id}`, { dueAt: action.payload.dueAt });
        // Refresh the assignment
        yield put({ type: 'FETCH_ASSIGNMENT_DETAILS', payload: { id: action.payload.id } });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment assign - post request failed', error);
    }
}

function* unassignAssignment(action) {
    try {
        yield axios.put(`api/assignments/unassign/${action.payload.id}`);
        // Refresh the assignment
        yield put({ type: 'FETCH_ASSIGNMENT_DETAILS', payload: { id: action.payload.id } });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment unassign - put request failed', error);
    }
}

function* fetchTagList() {
    try {
        const response = yield axios.get('api/assignments/tags');
        yield put({ type: 'SET_TAG_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment tags - get request failed', error);
    }
}

function* fetchEmployeeDashboardAssignments(action) {
    try {
        const response = yield axios.get('api/assignments/coursework/ungraded', { params: action.payload });
        yield put({ type: 'SET_EMPOLOYEE_DASHBOARD_ASSIGNMENT_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Assignment tags - get request failed', error);
    }
}

function* userSaga() {
    yield takeLatest('FETCH_ASSIGNMENT_LIST', fetchAssignmentList);
    yield takeLatest('FETCH_ASSIGNMENT_DETAILS', fetchAssignmentDetails);
    yield takeLatest('FETCH_COURSE_WORK', fetchCourseWork);
    yield takeLatest('FETCH_COHORT_COURSE_WORK', fetchCohortCourseWork);
    yield takeLatest('GRADE_COURSE_WORK', gradeCourseWork);
    yield takeLatest('DRAFT_COURSE_WORK', draftCourseWork);
    yield takeLatest('APPROVE_ALL_DRAFTS', approveAllDrafts);
    yield takeLatest('POST_ASSIGNMENT', createAssignment);
    yield takeLatest('DELETE_ASSIGNMENT', deleteAssignment);
    yield takeLatest('PUT_ASSIGNMENT', updateAssignment);
    yield takeLatest('ASSIGN_ASSIGNMENT', assignAssignment);
    yield takeLatest('UNASSIGN_ASSIGNMENT', unassignAssignment);
    yield takeLatest('FETCH_TAG_LIST', fetchTagList);
    yield takeLatest('SELECT_ALL_ASSIGNMENTS_FOR_COHORT', fetchAssignmentsToCopy);
    yield takeLatest('COPY_MANY_ASSIGNMENTS', copyManyAssignments);
    yield takeLatest('FETCH_EMPLOYEE_DASHBOARD_ASSIGNMENTS', fetchEmployeeDashboardAssignments);
    yield takeLatest('FETCH_COURSE_WORK_DETAIL', fetchCourseWorkDetail);
}

export default userSaga;
