// const formatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
// });

function formatter(amount) {
    if (amount && typeof amount === 'number') {
        return `$${amount.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1,')}`;
    }
    return 'n/a';
}

function formatDollars(amount) {
    if (amount) {
        return formatter(amount);
    }
    return '';
}

export default formatDollars;
