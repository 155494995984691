import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Grid,
    Paper,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Hidden,
    TableSortLabel,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PageTitle from '../../PageTitle/PageTitle';
import CourseWorkModal from '../../Modal/CourseWorkModal';
import DashboardCourseWorkRow from './DashboardCourseWorkRow';
import AcademicEventsTable from '../../General/AcademicEventsTable';

const styles = {
    feedbackContainer: {
        padding: '20px',
    },
    textField: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
    // chartPaper: {
    //     height: '200px',
    //     width: '250px',
    // },
};

class InstructorDashboardPage extends Component {
    componentDidMount() {
        const {
            dispatch,
            sortOrder,
        } = this.props;
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
        dispatch({ type: 'FETCH_EMPLOYEE_DASHBOARD_ASSIGNMENTS', payload: sortOrder });
        dispatch({ type: 'FETCH_EMPLOYEE_ACADEMIC_EVENTS', payload: { resolved: false } });
    }

    handleRefresh = () => {
        // Course work refresh happens in the saga
    };

    viewCourseWork = (student, courseWork) => () => {
        const { dispatch } = this.props;
        dispatch({ type: 'SET_ASSIGNMENT_COURSE_WORK', payload: courseWork });
        dispatch({ type: 'SET_ASSIGNMENT_STUDENT', payload: student });
        dispatch({ type: 'SET_ASSIGNMENT_SELECTED', payload: courseWork.assignment });
        const { history } = this.props;
        history.push(`/coursework/${courseWork.id}/grade`);
    };

    navigateToFeedback = academicEvent => () => {
        const { history } = this.props;
        const feedbackId = academicEvent.academic_eventable_id;
        history.push(`/students/${academicEvent.user.id}/feedback/${feedbackId}`);
    };

    handleUserCohortOnly = () => {
        const {
            dispatch,
            sortOrder,
        } = this.props;
        const payload = {
            ...sortOrder,
            userCohortsOnly: !sortOrder.userCohortsOnly,
        };
        dispatch({ type: 'SET_DASHBOARD_ASSIGNMENTS_SORT_ORDER', payload });
        dispatch({ type: 'FETCH_EMPLOYEE_DASHBOARD_ASSIGNMENTS', payload });
    };

    sortBy = orderBy => () => {
        const {
            dispatch,
            sortOrder,
        } = this.props;
        let { order } = sortOrder;
        // Existing column selected, reverse the sort
        if (orderBy === sortOrder.orderBy) {
            if (order === 'asc') {
                order = 'desc';
            } else {
                order = 'asc';
            }
        }
        const payload = {
            ...sortOrder,
            orderBy,
            order,
        };
        dispatch({ type: 'SET_DASHBOARD_ASSIGNMENTS_SORT_ORDER', payload });
        dispatch({ type: 'FETCH_EMPLOYEE_DASHBOARD_ASSIGNMENTS', payload });
    };

    render() {
        const {
            classes,
            dashboardList,
            selectedAssignment,
            modalOpen,
            selectedStudent,
            selectedCourseWork,
            academicEvents = [],
            sortOrder,
            user,
        } = this.props;

        return (
            <div className="container">
                <PageTitle title="Dashboard" />
                <Grid className={classes.cardContent} container>
                    <Grid item xs={12}>
                        <AcademicEventsTable
                            title="Unresolved Academic Events"
                            academicEvents={academicEvents}
                            navigateToFeedback={this.navigateToFeedback}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <Typography variant="h6">Ungraded Course Work</Typography>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        checked={sortOrder.userCohortsOnly === true}
                                        onClick={this.handleUserCohortOnly}
                                    />
                                )}
                                label="Only my cohorts."
                            />
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sortDirection={sortOrder.orderBy === 'cohort_name' && sortOrder.order}
                                        >
                                            <TableSortLabel
                                                active={sortOrder.orderBy === 'cohort_name'}
                                                direction={sortOrder.order}
                                                onClick={this.sortBy('cohort_name')}
                                            >
                                                Cohort
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            sortDirection={sortOrder.orderBy === 'assignment_name' && sortOrder.order}
                                        >
                                            <TableSortLabel
                                                active={sortOrder.orderBy === 'assignment_name'}
                                                direction={sortOrder.order}
                                                onClick={this.sortBy('assignment_name')}
                                            >
                                                Assignment Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <Hidden mdDown>
                                            <TableCell
                                                sortDirection={sortOrder.orderBy === 'due_at' && sortOrder.order}
                                            >
                                                <TableSortLabel
                                                    active={sortOrder.orderBy === 'due_at'}
                                                    direction={sortOrder.order}
                                                    onClick={this.sortBy('due_at')}
                                                >
                                                    Due At
                                                </TableSortLabel>
                                            </TableCell>
                                        </Hidden>
                                        <TableCell
                                            sortDirection={sortOrder.orderBy === 'student_name' && sortOrder.order}
                                        >
                                            <TableSortLabel
                                                active={sortOrder.orderBy === 'student_name'}
                                                direction={sortOrder.order}
                                                onClick={this.sortBy('student_name')}
                                            >
                                                Student Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            File
                                        </TableCell>
                                        <Hidden mdDown>
                                            <TableCell align="right">Status</TableCell>
                                            <TableCell align="right">Action</TableCell>
                                        </Hidden>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dashboardList
                                        && dashboardList.map(listItem => (
                                            <DashboardCourseWorkRow
                                                key={listItem.id}
                                                listItem={listItem}
                                                viewCourseWork={this.viewCourseWork}
                                                user={user}
                                            />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
                {
                    selectedCourseWork
                    && selectedAssignment
                    && selectedStudent
                    && (
                        <CourseWorkModal
                            student={selectedStudent}
                            courseWork={selectedCourseWork}
                            open={modalOpen}
                            handleRefresh={this.handleRefresh}
                            assignment={selectedAssignment}
                            closeActionType="SET_ASSIGNMENT_MODAL"
                            user={user}
                        />
                    )
                }
            </div>
        );
    }
}

InstructorDashboardPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    modalOpen: PropTypes.bool.isRequired,
    selectedStudent: PropTypes.instanceOf(Object).isRequired,
    academicEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
    dashboardList: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedAssignment: PropTypes.instanceOf(Object).isRequired,
    selectedCourseWork: PropTypes.instanceOf(Object).isRequired,
    sortOrder: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    assignments: state.assignments,
    selectedAssignment: state.assignments.selectedAssignment,
    selectedStudent: state.assignments.selectedStudent,
    selectedCourseWork: state.assignments.selectedCourseWork,
    modalOpen: state.assignments.modalOpen,
    dashboardList: state.assignments.employeeDashboardAssignments,
    sortOrder: state.assignments.dashboardAssignmentSortOrder,
    academicEvents: state.employee.allAcademicEvents,
    user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(InstructorDashboardPage));
