import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
    },
};

// Used to ask the user to confirm an action. Actions are 'Cancel' and 'Continue'.
const ConfirmationModal = (props) => {
    const {
        classes,
        open,
        onCancel,
        onConfirm,
        dialogTitle,
        dialogMessage,
        buttonColor,
        buttonText,
    } = props;
    return (
        <Dialog open={open}>
            <DialogTitle id="responsive-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent align="left" className={classes.feedbackContainer}>
                {dialogMessage}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    onClick={onConfirm}
                    variant="contained"
                    color={buttonColor || 'primary'}
                >
                    {buttonText || 'Continue'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmationModal.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    open: PropTypes.bool.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    dialogMessage: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    buttonColor: PropTypes.string,
    buttonText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
    buttonColor: 'primary',
    buttonText: 'Continue',
};
export default withStyles(styles)(ConfirmationModal);
