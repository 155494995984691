import { combineReducers } from 'redux';
import user from './userReducer';
import cohort from './cohortReducer';
import course from './courseReducer';
import employee from './employeeReducer';
import student from './studentReducer';
import assignments from './assignmentReducer';
import navigation from './navigationReducer';
import feedback from './feedbackReducer';
import drawer from './drawerReducer';
import error from './errorReducer';
import modal from './modalReducer';
import campus from './campusReducer';
import studentPortal from './studentPortalReducer';
import company from './companyReducer';
import placement from './placementsReducer';
import hubspot from './hubspotReducer';
import applicant from './applicantReducer';
import application from './applicationsReducer';
import settings from './settingsReducer';
import report from './reportReducer';

// rootReducer is the primary reducer for our entire project
// It bundles up all of the other reducers so our project can use them.
// This is imported in index.js as rootSaga

// Lets make a bigger object for our store, with the objects from our reducers.
// This is what we get when we use 'state' inside of 'mapStateToProps'
const rootReducer = combineReducers({
    cohort,
    course,
    employee,
    student,
    assignments,
    user, // will have an id and username if someone is logged in
    navigation,
    feedback,
    drawer,
    error,
    modal,
    campus,
    studentPortal,
    company,
    placement,
    hubspot,
    applicant,
    application,
    settings,
    report,
});

export default rootReducer;
