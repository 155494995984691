import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    useTheme,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import FeedbackTooltip from '../General/FeedbackTooltip';

const styles = {
    feedbackContainer: {
        backgroundColor: '#eee',
        padding: '20px',
    },
    feedback: {
        backgroundColor: '#fff',
        padding: '20px',
    },
    feedbackScore: {
        fontSize: '18px',
        textAlign: 'center',
        minWidth: '120px',
    },
    scoreContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
};

function formatFeedbackNumber(entry) {
    let result = '';
    if (entry) {
        result = `${Number(entry).toFixed(0)}`;
    }
    return result;
}

function formatFeedbackText(entry) {
    let result = '';
    if (entry) {
        result = entry;
    }
    return result;
}

// checks to see if student has submitted feedback and renders JSX appropriately
function checkFeedback(classes, feedback) {
    let feedbackExists = true;
    let jsxToRender = '';
    if (!feedback.support_score && !feedback.understanding_score) {
        feedbackExists = false;
    }
    if (feedbackExists) {
        jsxToRender = (
            <div className={classes.scoreContainer}>
                <div>
                    <p className={classes.feedbackScore}>
                        {formatFeedbackNumber(feedback.feeling_score)}
                    </p>
                    <p className="text-center">
                        Feeling
                        <FeedbackTooltip
                            title="Feeling"
                            tooltip={
                                formatFeedbackText(feedback.feeling_explanation)
                            }
                        />
                    </p>
                </div>
                <div>
                    <p className={classes.feedbackScore}>
                        {formatFeedbackNumber(feedback.understanding_score)}
                    </p>
                    <p className="text-center">
                        Understanding
                        <FeedbackTooltip
                            title="Understanding"
                            tooltip={
                                formatFeedbackText(
                                    feedback.understanding_explanation,
                                )
                            }
                        />
                    </p>
                </div>
                <div>
                    <p className={classes.feedbackScore}>
                        {formatFeedbackNumber(feedback.support_score)}
                    </p>
                    <p className="text-center">
                        Support
                        <FeedbackTooltip
                            title="Support"
                            tooltip={
                                formatFeedbackText(feedback.support_explanation)
                            }
                        />
                    </p>
                </div>
            </div>
        );
    } else {
        jsxToRender = (
            <div>
                <p> Student has not submitted feedback  </p>
            </div>
        );
    }
    return jsxToRender;
}

const FeedbackModal = (props) => {
    const {
        feedbackOn,
        dispatch,
        closeActionType,
        classes,
        open,
        feedback,
        previous,
        next,
    } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            {
                feedback
                && (
                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth
                        maxWidth="md"
                    >

                        <DialogTitle id="responsive-dialog-title">
                            {`Student Feedback :: ${feedbackOn}`}
                        </DialogTitle>
                        <div>
                            <strong>Student:</strong>
                        </div>
                        <div>
                            <Link to={`/students/${feedback.student_id}`}>
                                {`${feedback.first_name} ${feedback.last_name}`}
                            </Link>
                        </div>
                        <br />
                        <DialogContent align="left" className={classes.feedbackContainer}>
                            <h6>Reflection</h6>
                            <hr />
                            <br />
                            {checkFeedback(classes, feedback)}
                            <br />
                            <div>
                                {
                                    feedback.feeling_explanation
                                    && feedback.feeling_explanation.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>Feeling Comment</strong>
                                            <div>
                                                {feedback.feeling_explanation}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    feedback.understanding_explanation
                                    && feedback.understanding_explanation.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>Understanding Comment</strong>
                                            <div>
                                                {feedback.understanding_explanation}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    feedback.support_explanation
                                    && feedback.support_explanation.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>Support Comment</strong>
                                            <div>
                                                {feedback.support_explanation}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    feedback.thanks
                                    && feedback.thanks.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>Thanks</strong>
                                            <div>
                                                {feedback.thanks}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    feedback.accomplishment
                                    && feedback.accomplishment.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>Accomplishment</strong>
                                            <div>
                                                {feedback.accomplishment}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    feedback.recommended_experiences
                                    && feedback.recommended_experiences.length > 0
                                    && (
                                        <div>
                                            <br />
                                            <strong>Suggest</strong>
                                            <div>
                                                {feedback.recommended_experiences}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </DialogContent>
                        <br />
                        <DialogActions>
                            {
                                previous
                                && <Button onClick={previous}>Previous</Button>
                            }
                            {
                                next
                                && <Button onClick={next}>Next</Button>
                            }
                            <Button
                                onClick={() => dispatch(
                                    {
                                        type: closeActionType,
                                        payload: { modalOpen: false },
                                    },
                                )}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    );
};

FeedbackModal.defaultProps = {
    previous: undefined,
    next: undefined,
};

FeedbackModal.propTypes = {
    feedbackOn: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    feedback: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    open: PropTypes.bool.isRequired,
    closeActionType: PropTypes.string.isRequired,
    previous: PropTypes.func,
    next: PropTypes.func,
};

export default connect()(withStyles(styles)(FeedbackModal));
