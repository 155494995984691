import React from 'react';
import {
    Card,
    CardContent,
    Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';

const styles = {
    statCardTitle: {
        fontSize: '16px',
        fontWeight: '600',
    },
};
const CohortBIPOCStats = ({ cohort, classes }) => {
    const chartOptions = {
        title: {
            display: false,
            text: 'BIPOC breakdown',
            align: 'start',
        },
        indexAxis: 'y',
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        suggestedMax: 5,
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                label(tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = cohort.students ? cohort.students.length : 0;
                    const currentValue = dataset.data[tooltipItem.index];
                    const percentage = Math.floor(((currentValue / total) * 100) + 0.5);

                    return `${currentValue} (${percentage}%)`;
                },
            },
        },
    };

    const chartData = {};

    const studentList = cohort.students;
    let studentCount = 0;
    let bipocPercent = 0;
    let bipocCount = 0;
    if (studentList) {
        studentCount = studentList.length;
        const counts = [
            { label: 'White', count: studentList.filter(student => student.race_white).length },
            { label: 'Asian', count: studentList.filter(student => student.race_asian).length },
            { label: 'Hispanic', count: studentList.filter(student => student.race_hispanic).length },
            { label: 'Black', count: studentList.filter(student => student.race_black).length },
            { label: 'Native', count: studentList.filter(student => student.race_nativeamerican).length },
            { label: 'Pacific Islander', count: studentList.filter(student => student.race_islander).length },
            { label: 'Other', count: studentList.filter(student => student.race_other).length },
            {
                label: 'Did not respond',
                count: studentList.filter(student => !student.race_hispanic
                && !student.race_nativeamerican
                && !student.race_asian
                && !student.race_black
                && !student.race_islander
                && !student.race_white
                && !student.race_other).length,
            },
        ];
        bipocCount = studentList.filter(student => student.race_hispanic
            || student.race_nativeamerican
            || student.race_asian
            || student.race_black
            || student.race_islander
            || student.race_other).length;
        counts.sort((a, b) => ((a.count < b.count) ? 1 : -1));
        const sortedData = counts.map(count => count.count);
        const sortedLabels = counts.map(count => count.label);
        chartData.labels = sortedLabels;
        bipocPercent = Math.floor((bipocCount / studentCount) * 100);
        const data = {
            data: sortedData,
            axis: 'y',
            backgroundColor: '#5569D0',
        };
        chartData.datasets = [data];
    }
    return (
        <Grid item style={{ display: 'flex' }} xs={12} sm={6} lg={4}>
            <Card elevation={1} style={{ width: '100%' }}>
                <CardContent>
                    <span className={classes.statCardTitle}>BIPOC breakdown</span>
                    <br />
                    {`${bipocCount} / ${studentCount} students are from BIPOC backgrounds (${bipocPercent}% of students)`}
                    <br />
                    <HorizontalBar data={chartData} options={chartOptions} />
                </CardContent>
            </Card>
        </Grid>
    );
};

CohortBIPOCStats.propTypes = {
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default withStyles(styles)(CohortBIPOCStats);
