import React from 'react';
import {
    Button,
    TableRow,
    Hidden,
} from '@mui/material';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import { shortDateTime } from '../../../helpers/DateFormatHelper';
import TableCellLink from '../../General/TableCellLink';
import {
    completedText,
    gradedText,
    draftedText,
    dueAtText,
} from '../../../helpers/CourseWorkTextHelper';

const styles = {
    actionButtons: {
        marginLeft: '15px',
    },
    assignmentTitleText: {
        width: '100%',
        padding: '0px 15px',
    },
    tableRow: {
        cursor: 'pointer',
    },
};

const CohortAssignmentRow = ({
    assignment,
    viewAssignmentDetails,
    classes,
}) => (
    <TableRow
        className={classes.tableRow}
        hover
    >
        <TableCellLink to={`/assignments/${assignment.id}`} first>
            {assignment.name}
            <br />
            <Hidden mdDown>
                {
                    assignment.tags
                    && assignment.tags.map((tag, i, arr) => {
                        // The last tag won't have a comma
                        let result = <span key={tag.id} className={classes.smallText}>{`${tag.name}`}</span>;
                        // All other tags are followed by a comma
                        if (i < arr.length - 1) {
                            result = <span key={tag.id} className={classes.smallText}>{`${tag.name}, `}</span>;
                        }
                        return result;
                    })
                }
            </Hidden>
        </TableCellLink>
        <TableCellLink to={`/assignments/${assignment.id}`}>
            {
                completedText(assignment)
            }
        </TableCellLink>
        <Hidden mdDown>
            <TableCellLink to={`/assignments/${assignment.id}`}>
                {
                    gradedText(assignment)
                }
            </TableCellLink>
            <TableCellLink to={`/assignments/${assignment.id}`}>
                {
                    draftedText(assignment)
                }
            </TableCellLink>
        </Hidden>
        <TableCellLink to={`/assignments/${assignment.id}`}>
            {
                dueAtText(assignment)
            }
        </TableCellLink>
        <Hidden mdDown>
            <TableCellLink to={`/assignments/${assignment.id}`}>
                {
                    assignment.assigned_at ? (
                        shortDateTime(assignment.assigned_at)
                    ) : (
                        'Not assigned'
                    )
                }
            </TableCellLink>
        </Hidden>
        <Hidden mdDown>
            <TableCellLink to={`/assignments/${assignment.id}`} align="right">
                <Button variant="outlined" onClick={viewAssignmentDetails(assignment)} type="button">View</Button>
            </TableCellLink>
        </Hidden>
    </TableRow>
);

CohortAssignmentRow.propTypes = {
    assignment: PropTypes.instanceOf(Object).isRequired,
    viewAssignmentDetails: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(CohortAssignmentRow);
