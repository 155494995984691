import { createTheme, adaptV4Theme } from '@mui/material/styles';

/**
 * Light theme with dark sidebar example:
 * https://codesandbox.io/s/61z38zjr7w
 */
const lightTheme = createTheme(adaptV4Theme({
    overrides: {
        MuiTabs: {
            root: {
                backgroundColor: 'white',
                color: 'black',
                marginBottom: '0px',
                fontSize: '16px',
            },
            indicator: {
                height: '4px',
            },
        },
        MuiToggleButton: {
            root: { // All variants will get these styles
                textTransform: 'none',
            },
        },
        MuiButton: { // Name of the component / style sheet
            root: { // All variants will get these styles
                textTransform: 'none',
            },
            text: { // Variant specific styling
                color: '#337277',
                size: '16px',
                '&:hover': {
                    color: '#1c3f41',
                },
            },
            contained: { // Variant specific styling
                color: 'white',
                fontSize: '16px',
                backgroundColor: '#07AA9E',
                '&:hover': {
                    backgroundColor: '#069B90',
                },
                '&:active': {
                    backgroundColor: '#19383A',
                    borderColor: '#050B0C',
                },
                padding: '5px 10px',
            },
            outlined: { // Name of the rule
                color: '#676a6c',
                fontSize: '16px',
                '&:hover': {
                    color: '#333333',
                },
                padding: '5px 10px',
            },
        },
        MuiDivider: {
            root: {
                marginTop: '15px',
                marginBottom: '15px',
            },
        },
        MuiDialogTitle: {
            root: {
                fontSize: '24px',
                fontWeight: '400',
                color: '#676a6c',
                paddingLeft: '20px',
                paddingRight: '20px',
            },
        },
        MuiListItem: {
            root: {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
            },
        },
        MuiPaper: {
            rounded: {
                margin: '15px',
                padding: '15px',
            },
        },
        MuiTableRow: {
            root: {
                '&:nth-child(even)': {
                    background: '#FAFAFA',
                },
                '&:nth-child(odd)': {
                    background: '#FFF',
                },
            },
        },
        MuiTableSortLabel: {
            root: {
                fontSize: '14px',
            },
        },
        MuiFormControl: {
            root: {
                width: '100%',
            },
        },
        MuiFormHelperText: {
            contained: {
                margin: '2px 14px -15px',
            },
        },
        MuiTableCell: {
            root: {
                minWidth: '50px',
                padding: '5px 5px',
            },
            body: {
                color: '#676a6c',
            },
        },
        MuiTab: {
            fullWidth: {
                minWidth: '50px',
                padding: '5px 5px',
            },
        },
        MuiTypography: {
            root: {
                color: '#676a6c',
                fontSize: '14px',
            },
            h1: {
                fontSize: '39px',
                fontWeight: '400',
                color: '#676a6c',
            },
            h2: {
                fontSize: '31px',
                fontWeight: '400',
                color: '#676a6c',
            },
            h3: {
                fontSize: '25px',
                fontWeight: '700',
                color: '#676a6c',
            },
            h4: {
                fontSize: '20px',
                fontWeight: '700',
                color: '#676a6c',
            },
            h5: {
                fontSize: '18px',
                fontWeight: '700',
                color: '#676a6c',
                marginBottom: '5px',
                marginTop: '5px',
            },
            h6: {
                fontSize: '16px',
                fontWeight: '600',
                color: '#676a6c',
            },
            body1: {
                fontSize: '16px',
                color: '#1b1b1b',
            },
            body2: {
                fontSize: '16px',
                fontStyle: 'italic',
                color: '#1b1b1b',
            },
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: '\'Open Sans\', sans- serif',
    },
    palette: {
        primary: {
            // light: '#63ccff',
            main: '#07AA9E',
            dark: '#222222',
            contrastText: '#fff',
        },
        secondary: {
            main: '#AB3E07',
            contrastText: '#fff',
        },
    },
    // Set default border radius for all shapes
    shape: {
        borderRadius: 3,
    },
    props: {
        MuiDialogTitle: {
            disableTypography: true,
        },
        MuiButton: {
            disableRipple: true,
        },
        MuiTab: {
            disableRipple: true,
        },
    },
}));

const darkTheme = createTheme(adaptV4Theme({
    overrides: {
        MuiListItem: {
            root: {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
                '&:focus': {
                    backgroundColor: '#333',
                },
            },
            dense: {
                paddingTop: '8px',
                paddingBottom: '8px',
            },
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: '\'Open Sans\', sans- serif',
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#07AA9E',
            dark: '#222222',
            contrastText: '#fff',
        },
    },
    // Set default border radius for all shapes
    shape: {
        borderRadius: 3,
    },
    props: {
        MuiButton: {
            disableRipple: true,
        },
    },
}));

const theme = {
    lightTheme,
    darkTheme,
};

export default theme;
