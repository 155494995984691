import React from 'react';
import {
    Paper,
    Typography,
    Icon,
    Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CodeBlock from '../../../General/CodeBlock';
import styles from '../../../../helpers/TabStylesHelper';
import { getLogicQuestionUrl } from '../../../../helpers/PhotoHelper';

const checkAnswer = (studentAnswer, correctAnswer, classes) => {
    if (studentAnswer === 'I don\'t know.') {
        return (
            <>
                <Icon
                    className={classNames(classes.icon, 'fa fa-question')}
                    style={{ color: '#f8ac59', marginBottom: -5, marginRight: 10 }}
                />
                <Typography component="span">
                    I don&apos;t know
                </Typography>
            </>
        );
    }
    if (studentAnswer === correctAnswer) {
        return (
            <>
                <Icon
                    className={classNames(classes.icon, 'fa fa-check')}
                    color="primary"
                    style={{ marginBottom: -5, marginRight: 10 }}
                />
                <Typography component="span">
                    {studentAnswer}
                </Typography>
            </>
        );
    }

    return (
        <>
            <Icon
                className={classNames(classes.icon, 'fa fa-times')}
                color="secondary"
                style={{ marginBottom: -5, marginRight: 10 }}
            />
            <Typography component="span">
                {studentAnswer}
            </Typography>
        </>
    );
};

const LogicTab = ({
    value, index, classes, applicationLogic, user, application,
}) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
    >
        {value === index && (
            <Paper>
                <Typography variant="h5">
                    {`${application.course_id === 2 ? 'Reasoning Questions' : 'Logic Questions'}`}
                </Typography>
                <Divider />
                {applicationLogic.questions
                        && (
                            applicationLogic.questions.map(logicQuestion => (
                                <div key={logicQuestion.id}>
                                    {application.course_name === 'Full Stack Engineering'
                                      && (
                                          <Typography variant="h6"> Question </Typography>
                                      )}
                                    <ReactMarkdown
                                        align="left"
                                        remarkPlugins={[remarkGfm]}
                                        components={{ code: CodeBlock }}
                                        rehypePlugins={[rehypeRaw]}
                                        linkTarget="_blank"
                                    >
                                        {logicQuestion.question}
                                    </ReactMarkdown>
                                    <Typography variant="h6"> Answer </Typography>

                                    {logicQuestion.logic_question_answers.length > 0
                                        && logicQuestion.logic_question_answers[0].answer ? (
                                            <Typography>
                                                {' '}
                                                {checkAnswer(
                                                    logicQuestion.logic_question_answers[0].answer,
                                                    logicQuestion.solution,
                                                    classes,
                                                )}
                                            </Typography>
                                        )
                                        : (
                                            <Typography className={classes.incomplete}>
                                                Not answered
                                            </Typography>
                                        )
                                    }
                                    {logicQuestion.question_image_file_name
                                    && (
                                        <img
                                            src={getLogicQuestionUrl(
                                                user.s3_bucket,
                                                logicQuestion.id,
                                                logicQuestion.question_image_file_name,
                                            )}
                                            alt={logicQuestion.question}
                                        />
                                    )}
                                    <Divider />
                                </div>
                            ))
                        )
                }

            </Paper>
        )}
    </div>
);

LogicTab.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    applicationLogic: PropTypes.instanceOf(Object).isRequired,
    application: PropTypes.instanceOf(Object).isRequired,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
};

export default withStyles(styles)(LogicTab);
