import React, { Component } from 'react';
import {
    Grid,
    TextField,
    Typography,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const styles = ({
    input: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontSize: 13,
    },
    header: {
        fontSize: '25px',
    },
});

class LoginForm extends Component {
    state = {
        username: '',
        password: '',
        errorTextLogin: '',
    };

    handleInputChangeFor = propertyName => (event) => {
        this.setState({
            [propertyName]: event.target.value,
            errorTextLogin: '',
        });
    };

    handleLogin = (event) => {
        const { dispatch } = this.props;
        const { username, password } = this.state;
        event.preventDefault();
        if (username && username.length > 3 && password && password.length > 1) {
            dispatch({ type: 'LOGIN', payload: { username, password } });
        } else {
            this.setState({
                errorTextLogin: 'Please enter a username and password.',
            });
        }
    };

    resetPassword = () => {
        const { dispatch } = this.props;
        const { username } = this.state;
        if (username && username.length > 3) {
            dispatch({ type: 'REQUEST_PASSWORD_RESET', payload: { email: username } });
            this.setState({
                errorTextLogin: '',
            });
        } else {
            this.setState({
                errorTextLogin: 'Please enter a username.',
            });
        }
    };

    render() {
        const { classes } = this.props;
        const {
            username,
            password,
            errorTextLogin,
        } = this.state;
        return (
            <form onSubmit={this.handleLogin}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={classes.header}>ALREADY STARTED? LOG IN</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="email-existing"
                            label="Email"
                            className={classes.input}
                            value={username}
                            onChange={this.handleInputChangeFor('username')}
                            margin="dense"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="password-existing"
                            label="Password"
                            className={classes.input}
                            value={password}
                            onChange={this.handleInputChangeFor('password')}
                            margin="dense"
                            variant="outlined"
                            type="password"
                        />
                        {
                            errorTextLogin && errorTextLogin !== '' && (
                                <Typography className={classes.errorText}>
                                    {errorTextLogin}
                                </Typography>
                            )
                        }
                    </Grid>
                    <br />
                    <br />
                    <Grid justifyContent="space-between" container>
                        <Button onClick={this.resetPassword}>Reset Password</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Continue Application
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

LoginForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default connect()(withStyles(styles)(LoginForm));
