const learningInfo = {
    color: '#FDF2D0',
    id: 'learning',
    questionId: 1,
    header: 'Learning style and program alignment',
    bullets: [
        'Understand their goals, level of prior exploration, intent, and needs for study.',
        'Get to know candidates.',
        'Use the checkboxes to keep track of which questions have been asked.',
    ],
    secondary: 'Use the checkboxes to keep track of which questions have been asked.',
    noteField: 'applicationInterviewLearningNotes',
    profileNoteField: 'profile_notes_1',
    interviewNoteField: 'interview_notes_1',
    editingNoteField: 'editingInterviewLearningNote',
    editingScoreField: 'editingInterviewLearningScore',
    checkList: [
        {
            followUp: [
                ' What about FSE in particular speaks to you as opposed to other areas of the tech industry?',
                'What have you done to know you’d enjoy coding as a career?',
                'How long have you looked into this as a career?',
            ],
            boxes: [
                {
                    id: 1,
                    label: 'Tell me a bit about yourself and why you are interested in transitioning into software engineering.',
                },
            ],
        },
        {
            followUp: [
                'How have you prepared yourself and your life for a 60-hour work commitment?',
                'What does your financing, transportation, care plan etc. look like?',
            ],
            boxes: [
                {
                    id: 2,
                    label: 'What do you know about our program? What interests you about our program or stands out to you about Prime specifically?',
                },
            ],
        },
        {
            followUp: [
                'How have you prepared yourself for remote work?',
            ],
            boxes: [
                {
                    id: 3,
                    label: 'If remote: Tell me about your experience with remote work/learning.',
                },
            ],
        },
        {
            followUp: [
                'Can you provide an example?',
            ],
            boxes: [
                {
                    id: 4,
                    label: 'What type of working/learning environments are most conducive to your success?',
                },
            ],
        },
    ],
};

const collaborationInfo = {
    color: '#DCE9D5',
    id: 'collaboration',
    questionId: 2,
    header: 'Collaboration, teamwork, and communication',
    bullets: [
        'Assess incoming skill, awareness and experience.',
    ],
    noteField: 'applicationInterviewCollaborationNotes',
    profileNoteField: 'profile_notes_2',
    interviewNoteField: 'interview_notes_2',
    editingNoteField: 'editingInterviewCollaborationNote',
    editingScoreField: 'editingInterviewCollaborationScore',
    checkList: [
        {
            followUp: [
                'What roles do you normally play on a team?',
                'How do you go about helping teammates when they are struggling or falling behind?',
            ],
            boxes: [
                {
                    id: 1,
                    label: 'Prime is highly collaborative and working and learning together is critical. Tell me about your experience working on teams.',
                },
            ],
        },
        {
            followUp: [
                'How do you advocate for yourself when learning or working on a team and you are the one struggling?',
                'What strategies do you utilize to bridge your own learning gap?',
            ],
            boxes: [
                {
                    id: 2,
                    label: 'Tell me how you advocate for yourself in a team/work setting.',
                },
            ],
        },

    ],
};

const strengthInfo = {
    color: '#D9D9D9',
    id: 'strength',
    questionId: 3,
    header: 'Personal strengths and career goals',
    bullets: [
        'Demonstrated understanding of self, strengths and areas of growth.',
    ],
    noteField: 'applicationInterviewStrengthNotes',
    profileNoteField: 'profile_notes_3',
    interviewNoteField: 'interview_notes_3',
    editingNoteField: 'editingInterviewStrengthNote',
    editingScoreField: 'editingInterviewStrengthScore',
    checkList: [
        {
            followUp: [
                'What perspectives or experiences do you bring to Prime and software engineering?',
            ],
            boxes: [
                {
                    id: 1,
                    label: 'Part of Prime’s mission is to diversify the tech industry. We focus part of our curriculum on diversity,'
                        + ' equity and inclusion in the tech industry. Where are you on your equity journey?',
                },
            ],
        },
        {
            followUp: [
                'What do you recognize as an area of growth for yourself?',
            ],
            boxes: [
                {
                    id: 2,
                    label: 'What’s a personal characteristic that you’re working to improve upon?',
                },
            ],
        },

    ],
};

const growthInfo = {
    color: '#D3E1F1',
    header: 'Resilience and Growth Mindset',
    id: 'growth',
    questionId: 4,
    bullets: [
        ' Uncover personal preparedness and/or barriers.',
    ],
    noteField: 'applicationInterviewGrowthNotes',
    profileNoteField: 'profile_notes_4',
    interviewNoteField: 'interview_notes_4',
    editingNoteField: 'editingInterviewGrowthNote',
    editingScoreField: 'editingInterviewGrowthScore',
    checkList: [
        {
            boxes: [
                {
                    label: 'Tell us about a time you overcame a challenge. What steps did you take to persevere when things got tough?',
                    id: 1,
                },
            ],

        },
        {
            boxes: [
                {
                    label: 'Tell me about a time you received constructive feedback. How did you respond to that situation?',
                    id: 2,
                },
            ],

        },
        {
            followUp: [
                'How do you manage your stress?',
            ],
            boxes: [
                {
                    id: 3,
                    label: 'Tell me about a time you had to manage stress, change and uncertainty.',
                },
            ],
        },

    ],
};

const environmentInfo = {
    color: '#D8D2E7',
    header: 'External and environmental factors',
    id: 'environment',
    questionId: 5,
    noteField: 'applicationInterviewEnvironmentNotes',
    profileNoteField: 'profile_notes_5',
    interviewNoteField: 'interview_notes_5',
    editingNoteField: 'editingInterviewEnvironmentNote',
    editingScoreField: 'editingInterviewEnvironmentScore',
    checkList: [
        {
            boxes: [
                {
                    label: 'When youʼre not at work/school, what are some things you like to do? (fun/personal interest)',
                    id: 1,
                },
            ],
        },
    ],

};
const endInfo = {
    color: '#E0F7FA',
    header: 'End of interview checklist (all questions are required to be asked)',
    questionId: 6,
    checkList: [
        {
            title: 'Vaccination status',
            boxes: [
                {
                    label: 'Candidate is aware they are required to provide proof of vaccination to study.',
                    id: 1,
                }, {
                    label: 'N/A — Cohort is remote',
                    id: 2,
                },
            ],
        },
        {
            title: 'Mac requirement',
            boxes: [
                {
                    id: 3,
                    label: 'Candidate is aware of our Mac requirement',
                },
            ],

        },
        {
            title: 'Tools and direction',
            boxes: [
                {
                    id: 4,
                    label: 'Candidate was told about tools to prepare for immersion (learning resources), and given direction, if applicable.',

                },
            ],
        },
        {
            title: 'Next steps',
            boxes: [
                {
                    id: 5,
                    label: 'Candidate was told the next steps ( ~ 1 week after interviewing).',
                },
            ],
        }],
};

export {
    learningInfo,
    collaborationInfo,
    strengthInfo,
    growthInfo,
    environmentInfo,
    endInfo,
};
