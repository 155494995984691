import { makeStyles } from '@mui/styles';

export default makeStyles({
    th: {
        verticalAlign: 'bottom',
        fontWeight: 'bold',
        color: '#676a6c',
        borderBottom: '1px solid #4f4f4f',
    },
    root: {
        '& .MuiTableCell-body, .MuiTableCell-head': {
            minWidth: 40,
            height: 37,
        },
    },
    gradeCell: {
        // I don't know why this needs to be important...🤷‍♂️
        backgroundSize: '12px 12px !important',
        textAlign: 'center',
        fontWeight: 'bold',
        border: '1px solid #4f4f4f',
        minWidth: 35,
    },
    stickyColumn: {
        position: 'sticky',
        background: 'white',
        whiteSpace: 'nowrap',
        zIndex: 10,
        left: 0,
    },
    legendRow: {
        paddingTop: 10,
    },
    legendLabel: {
        display: 'table-cell',
        color: '#676a6c',
        fontWeight: 'bold',
        paddingLeft: 15,
    },
});
