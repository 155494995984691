import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

/**
 * Wrapper around react-select, with an easier to use interface.
 *
 * @param {string} value Selected value
 * @param {Array<{label: string, value: string}>} options Array of drop-down options
 * @param {Function} onChange Called with selected value
 * @param {string} className
 */
function Select({
    value,
    options,
    onChange = (value) => {}, // eslint-disable-line no-shadow
    className = '',
}) {
    return (
        <ReactSelect
            styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999 }),
            }}
            value={options.find(opt => value === opt.value)}
            onChange={({ value }) => onChange(value)} // eslint-disable-line no-shadow
            options={options}
            classNamePrefix="react-select"
            className={className}
        />
    );
}

Select.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
};

export default Select;
