import React from 'react';
import {
    Typography, FormLabel, FormControlLabel, Radio,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
    monthName,
    year,
} from '../../../../../helpers/DateFormatHelper';

const getCohortType = (type) => {
    let result = '';
    switch (type) {
    case 'full_time':
        result = 'Full-time';
        break;
    case 'part_time':
        result = 'Part-time';
        break;
    default:
        result = '';
    }
    return result;
};

const getCohortName = (name) => (
    name.split(' (')[0]
);

const ProfileInfoRow = ({
    cohort, classes, handleCohortChange,
}) => (
    <>
        <FormControlLabel
            control={(
                <Radio
                    checked={cohort.selected}
                    value
                    onClick={handleCohortChange(cohort.id)}
                    color="primary"
                />
            )}
        />
        <FormLabel style={{ cursor: 'pointer' }} onClick={handleCohortChange(cohort.id)} component="label" className={classes.campus}>
            {`${getCohortName(cohort.name)} `}
            <span className={classes.italicText}>
                {`(${monthName(cohort.prework_start)} - ${monthName(cohort.graduation)} ${year(cohort.graduation)})`}
            </span>
        </FormLabel>
        <Typography className={classes.boldPadding}>
            {getCohortType(cohort.cohort_type)}
        </Typography>
        <Typography className={classes.cohort}>
            {cohort.schedule}
        </Typography>
        <br />
    </>
);

ProfileInfoRow.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    handleCohortChange: PropTypes.func.isRequired,
};

export default ProfileInfoRow;
