import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    ListItem,
    Button,
    Hidden,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { shortStatusText, longStatusText } from '../../../helpers/CohortTextHelper';
import { extendedDate } from '../../../helpers/DateFormatHelper';
import ProgressBar from '../../General/ProgressBar';

// Create our number formatter.
// const formatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
// });

function formatter(amount) {
    if (amount && typeof amount === 'number') {
        return `$${amount.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1,')}`;
    }
    return 'n/a';
}

const styles = theme => ({
    cohortStatusText: {
        minWidth: '140px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingBottom: '5px',
        },
    },
    listRow: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        position: 'relative',
    },
    cohortTitleText: {
        maxWidth: '320px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            maxWidth: '100%',
        },
    },
    placementText: {
        maxWidth: '100px',
    },
    listRowLink: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
});

const CohortListRow = ({ cohort, classes, viewCohortDetails }) => (
    <ListItem
        divider
        button
        className={classes.listRow}
    >
        <Hidden mdDown>
            <div className={classes.cohortStatusText}>
                {shortStatusText(cohort)}
            </div>
        </Hidden>
        <div className={classes.cohortTitleText}>
            <h6>{cohort.cohort_name}</h6>
            <Hidden mdUp>
                <br />
                <div className={classes.cohortStatusText}>
                    {shortStatusText(cohort)}
                </div>
                <br />
            </Hidden>
            <p>
                {cohort.campus}
                &nbsp;-&nbsp;
                {cohort.course}
            </p>
            <p>
                {extendedDate(cohort.prework_start)}
                &nbsp;-&nbsp;
                {extendedDate(cohort.graduation)}
                &nbsp;(
                {longStatusText(cohort)}
                )
            </p>
        </div>
        <Hidden mdDown>
            <div>
                <p>
                    Confirmed:&nbsp;
                    {cohort.confirmed_students}
                </p>
                <ProgressBar
                    fillPercent={(cohort.confirmed_students / cohort.target_size) * 100}
                />
            </div>
            <div>
                <p>
                    Placed:&nbsp;
                    {cohort.placed_students}
                    /
                    {cohort.total_students}
                </p>
                <ProgressBar
                    fillPercent={(cohort.placed_students / cohort.total_students) * 100}
                />
            </div>
            <div className={classes.placementText}>
                <p>
                    Avg. Days to Place:&nbsp;
                    {cohort.days_to_place}
                </p>
            </div>
            <div className={classes.placementText}>
                <p>
                    Avg. Salary:&nbsp;
                    {formatter(parseInt(cohort.average_salary, 10))}
                </p>
            </div>
            <div>
                <Button variant="outlined" onClick={viewCohortDetails(cohort)} type="button">View</Button>
            </div>
        </Hidden>
        <Link
            className={classes.listRowLink}
            hover
            to={`/cohorts/${cohort.id}`}
        />
    </ListItem>
);

CohortListRow.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    viewCohortDetails: PropTypes.func.isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default connect()(withStyles(styles)(CohortListRow));
