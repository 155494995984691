import React, { useState, useEffect } from 'react';
import {
    Typography, Grid, Divider, TextareaAutosize,
} from '@mui/material';
import PropTypes from 'prop-types';

const ProfileInfoRow = ({
    profileInfo, classes, handleChange, submitButtonPressed, questionIndex,
}) => {
    const [questionError, setQuestionError] = useState('');

    useEffect(() => {
        if (submitButtonPressed && !profileInfo.answer) {
            setQuestionError('Please answer question');
        } else {
            setQuestionError('');
        }
    }, [submitButtonPressed, profileInfo]);

    return (
        <>
            <Grid item xs={12} className={classes.questionContainer}>
                <Typography className={classes.questionLabel}>
                    Question
                    {' '}
                    {questionIndex + 1}
                </Typography>
                <Typography>
                    {profileInfo.question}
                </Typography>
                {profileInfo.secondary_text
                && (
                    <Typography className={classes.secondaryQuestionText}>
                        {profileInfo.secondary_text}
                    </Typography>
                )
                }

                <TextareaAutosize
                    required
                    style={{
                        width: '100%',
                        marginTop: '30px',
                        marginBottom: '30px',
                        fontSize: 18,
                        padding: '5px',
                    }}
                    multiline
                    minRows={4}
                    maxRows={10}
                    value={profileInfo.answer === null ? '' : profileInfo.answer}
                    onChange={handleChange(profileInfo.id)}
                    className={classes.input}
                    variant="outlined"
                />
                <Typography className={classes.errorText}>
                    {questionError}
                </Typography>
                <Divider />
            </Grid>
        </>
    );
};

ProfileInfoRow.propTypes = {
    profileInfo: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    handleChange: PropTypes.func.isRequired,
    submitButtonPressed: PropTypes.bool.isRequired,
    questionIndex: PropTypes.number.isRequired,

};

export default ProfileInfoRow;
