import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
    passwordInput: {
        margin: '5px',
    },

};

class ChangePasswordModal extends Component {
    state = {
        password: '',
        newPassword: '',
        retypeNewPassword: '',
        errorText: '',
        statusText: '',
    };

    changePassword = (event) => {
        event.preventDefault();
        const { dispatch, type, studentId } = this.props;
        const {
            password,
            newPassword,
            retypeNewPassword,
        } = this.state;
        if (retypeNewPassword !== newPassword) {
            this.setState({
                errorText: 'Passwords do not match.',
            });
        } else if (newPassword && newPassword.length < 8) {
            this.setState({
                errorText: 'Password must be at least 8 characters.',
            });
        } else if (newPassword && newPassword.length > 7) {
            if (type === 'userChange') {
                dispatch({ type: 'CHANGE_PASSWORD', payload: { newPassword, oldPassword: password } });
            } else {
                dispatch({ type: 'CHANGE_STUDENT_PASSWORD', payload: { newPassword, studentId } });
            }
            this.setState({
                password: '',
                newPassword: '',
                retypeNewPassword: '',
                errorText: '',
                statusText: '',
            });
            dispatch({ type: 'UNSET_CHANGE_PASSWORD_MODAL' });
        } else {
            this.setState({
                errorText: 'Please enter a password.',
            });
        }
    }; // end login

    handleCancel = () => {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_CHANGE_PASSWORD_MODAL' });
    };

    handleInputChangeFor = propertyName => (event) => {
        this.setState({
            [propertyName]: event.target.value,
            errorText: '',
        });
    };

    render() {
        const {
            password,
            errorText,
            statusText,
            newPassword,
            retypeNewPassword,
        } = this.state;
        const {
            open,
            classes,
            type,
        } = this.props;
        return (

            <Dialog
                open={open}
                maxWidth="md"
            >
                <DialogTitle id="responsive-dialog-title">
                    Change Password
                </DialogTitle>
                <DialogContent>
                    { type === 'userChange' && (
                        <TextField
                            id="current-password"
                            type="password"
                            label="Current Password"
                            margin="dense"
                            variant="outlined"
                            value={password}
                            onChange={this.handleInputChangeFor('password')}
                            className={classes.passwordInput}
                        />
                    )}
                    <TextField
                        id="new-password"
                        type="password"
                        label="New Password"
                        helperText="Minimum 8 characters"
                        margin="dense"
                        variant="outlined"
                        value={newPassword}
                        onChange={this.handleInputChangeFor('newPassword')}
                        className={classes.passwordInput}
                    />
                    <TextField
                        id="retype-new-password"
                        type="password"
                        label="Confirm New Password"
                        margin="dense"
                        variant="outlined"
                        value={retypeNewPassword}
                        onChange={this.handleInputChangeFor('retypeNewPassword')}
                        className={classes.passwordInput}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.changePassword}
                    >
                        Update
                    </Button>
                </DialogActions>
                <br />
                {
                    errorText && errorText !== '' && (
                        <div style={{ color: 'red', width: '100%' }}>{errorText}</div>
                    )
                }
                {
                    statusText && statusText !== '' && (
                        <div style={{ width: '100%' }}>{statusText}</div>
                    )
                }
            </Dialog>
        );
    }
}

ChangePasswordModal.defaultProps = {
    studentId: undefined,
};

ChangePasswordModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    open: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    studentId: PropTypes.number,
};

export default connect()(withStyles(styles)(ChangePasswordModal));
