import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import { connect } from 'react-redux';

const styles = theme => ({
    wrapper: {
        borderRadius: '16px',
        padding: '10px 20px',
        display: 'flex',
        background: grey['50'],
        flexDirection: 'column',
        gap: '10px',
        margin: '16px',
        whiteSpace: 'pre-wrap',
    },
    bold: {
        fontWeight: 'bold',
    },
    followUp: {
        marginLeft: '30px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },

});
const InterviewChecklistItem = ({
    followUp,
    classes,
    applicationInterviewReview,
    boxes,
    dispatch,
    questionId,
    title,
}) => {
    const [checked, setChecked] = useState([]);

    const handleChange = (event, boxId) => {
        const review = applicationInterviewReview.get(questionId);

        let localReviewChecks = [];
        if (review?.interview_review_checks) {
            localReviewChecks = [...review.interview_review_checks];
        }
        const objIndex = review?.interview_review_checks?.findIndex(
            (checkObj => checkObj.check_id === boxId));

        // -1 if index is not found and undefined if interview_review_checks does not exits
        // in either case we want to create the new object so that when it is created in the DB
        // we can see it.

        if (objIndex !== -1 && objIndex !== undefined) {
            localReviewChecks = [...review.interview_review_checks];
            localReviewChecks[objIndex].checked = event.target.checked;
        } else {
            localReviewChecks.push({
                checked: event.target.checked,
                check_id: boxId,
            });
        }

        applicationInterviewReview.set(questionId,
            {
                ...review,
                questionId,
                interview_review_checks: localReviewChecks,
            });

        dispatch({
            type: 'UPDATE_APPLICATION_INTERVIEW_REVIEW',
            payload: applicationInterviewReview,
        });

        setChecked((prevState) => (
            { ...prevState, [boxId]: event.target.checked }
        ));
    };

    useEffect(() => {
        const review = applicationInterviewReview.get(questionId);

        if (!review?.interview_review_checks) return;

        boxes.forEach((box) => {
            const objIndex = review.interview_review_checks.findIndex(
                (checkObj => checkObj.check_id === box.id));
            const checkedState = review.interview_review_checks[objIndex]?.checked;

            setChecked((prevState) => (
                { ...prevState, [box.id]: checkedState }
            ));
        });
    }, [applicationInterviewReview]);

    return (
        <div className={classes.wrapper}>
            {title && <Typography className={classes.bold}>{title}</Typography>}

            {boxes?.map((box, index) => (
                <FormControlLabel
                    key={box.id}
                    control={(
                        <Checkbox
                            checked={!!checked[box.id]}
                            onChange={(e) => {
                                handleChange(e, box.id);
                            }}
                            style={{ color: '#212121' }}
                        />
                    )}
                    label={box.label}
                />
            ))}
            <div className={classes.followUp}>
                {followUp.length > 0 && (
                    <Typography className={classes.bold}>
                        Optional followup
                    </Typography>
                )}
                {followUp && followUp.map((item) => (
                    <Typography key={item}>
                        {item}
                    </Typography>
                ))}
            </div>

        </div>

    );
};
InterviewChecklistItem.defaultProps = {
    title: null,
    followUp: [],
};

InterviewChecklistItem.propTypes = {
    title: PropTypes.string,
    questionId: PropTypes.number.isRequired,
    followUp: PropTypes.array,
    boxes: PropTypes.array.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.instanceOf(Object).isRequired,
    applicationInterviewReview: PropTypes.instanceOf(Map).isRequired,

};
const mapStateToProps = state => ({
    dispatch: state.dispatch,
    applicationInterviewReview: state.application.applicationInterviewReview,

});
export default connect(mapStateToProps)(withStyles(styles)(InterviewChecklistItem));
