import React from 'react';
import PropTypes from 'prop-types';
import {
    TableRow,
    TableCell,
    Hidden,
    Button,
    Link,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { shortStatusText } from '../../../helpers/CourseWorkTextHelper';
import { shortDateTime } from '../../../helpers/DateFormatHelper';
import { getCourseWorkUrl } from '../../../helpers/PhotoHelper';
import FaIcon from '../../../helpers/FaIcon';

const styles = {
    tableRow: {
        cursor: 'pointer',
    },
};

const DashboardCourseWorkRow = ({
    listItem,
    viewCourseWork,
    classes,
    user,
}) => (
    <TableRow
        className={classes.tableRow}
        hover
        onClick={viewCourseWork(listItem.user, listItem)}
    >
        <TableCell>
            <RouterLink onClick={e => e.stopPropagation()} to={`/cohorts/${listItem.assignment.cohort.id}`}>
                {listItem.assignment.cohort.name}
            </RouterLink>
        </TableCell>
        <TableCell>
            <RouterLink onClick={e => e.stopPropagation()} to={`/assignments/${listItem.assignment.id}`}>
                {listItem.assignment.name}
            </RouterLink>
        </TableCell>
        <Hidden mdDown>
            <TableCell>
                {
                    shortDateTime(
                        listItem.assignment.due_at,
                    )
                }
            </TableCell>
        </Hidden>
        <TableCell>
            <RouterLink onClick={e => e.stopPropagation()} to={`/students/${listItem.user.id}`}>
                {`${listItem.user.first_name} ${listItem.user.last_name}`}
            </RouterLink>
        </TableCell>

        <TableCell>
            {
                listItem.file_name
                && listItem.file_content_type
                && (
                    <span>
                        {listItem.file_content_type.slice(-3)}
                        <Link href={getCourseWorkUrl(
                            user.s3_bucket,
                            listItem.id,
                            listItem.file_name,
                        )}
                        >

                            <FaIcon
                                icon="link"
                                iconType="fas"
                                style={{
                                    color: '#555555',
                                    fontSize: '18px',
                                    marginBottom: '-3px',
                                    marginLeft: '12px',
                                }}
                            />
                        </Link>
                    </span>
                )
            }
        </TableCell>
        <Hidden mdDown>
            <TableCell align="right" onClick={viewCourseWork(listItem.user, listItem)}>
                {
                    shortStatusText(
                        listItem,
                        listItem.assignment,
                    )
                }
            </TableCell>
            <TableCell align="right" onClick={viewCourseWork(listItem.user, listItem)}>
                <Button variant="outlined" onClick={viewCourseWork(listItem.user, listItem)}>Grade</Button>
            </TableCell>
        </Hidden>
    </TableRow>
);

DashboardCourseWorkRow.propTypes = {
    viewCourseWork: PropTypes.func.isRequired,
    listItem: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(DashboardCourseWorkRow);
