import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import {
    Grid,
    TextField,
    Typography,
    Button,
    Icon,
    ListItemIcon,
    ListItem,
    List,
    ListItemText,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoginForm from './LoginForm';
import ReactPixel from '../../../helpers/FacebookPixel';

const {
    STUDENT,
    APPLICANT,
} = require('../../General/UserTypes');

if (process.env.REACT_APP_FACEBOOK_PIXEL) {
    const initialized = (typeof window !== 'undefined') && !!window.fbq;
    if (!initialized) {
        ReactPixel.init(
            process.env.REACT_APP_FACEBOOK_PIXEL,
            {},
            { debug: false, autoConfig: true },
        );
    }
    ReactPixel.pageView();
}

if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

const styles = theme => ({
    header: {
        fontSize: '25px',
    },
    titleContainer: {
        textAlign: 'center',
    },
    gridContainer: {
        padding: '10px',
    },
    summary: {
        textAlign: 'left',
    },
    input: {
        float: 'left',
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontSize: 13,
    },
    title: {
        fontSize: '2.4rem',
        marginBottom: '2rem',
    },
    layoutFooter: {
        background: '#222222',
        height: '244px',
    },
    footerUpdates: {
        background: 'rgb(7, 170, 158)',
        color: 'white',
    },
    navText: {
        margin: 0,
        padding: 0,
        color: 'white',
    },
    icon: {
        margin: 0,
        padding: theme.spacing(),
        fontSize: '22px',
        height: 'auto',
        width: 'auto',
        color: 'white',
    },
    verticalCenter: {
        textAlign: 'center',
    },
    legalContainer: {
        fontSize: '10px',
        textAlign: 'center',
    },
    socialContainer: {
        textAlign: 'center',
        fontSize: '16px',
        padding: '12px',
    },
    socialIcon: {
        marginBottom: '-5px',
        color: 'white',
        padding: 0,
    },
    signUp: {
        '&:normal': {
            color: 'white',
        },
        '&:link': {
            color: 'white',
        },
        '&:hover': {
            color: 'white',
        },
    },
});

class RegistrationForm extends Component {
    state = {
        errorTextEmail: '',
        errorTextPassword: '',
        errorTextPasswordConfirmation: '',
        errorTextFirstName: '',
        errorTextLastName: '',
        errorTextPhone: '',
    };

    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();
    }

    componentDidMount() {
        const { location } = this.props;
        const currentPath = location.pathname + location.search;
        if (process.env.REACT_APP_GA_TRACKING_ID) {
            ReactGA.pageview(currentPath);
        }
        if (process.env.REACT_APP_HUBSPOT_TRACKING
            && (typeof window !== 'undefined')
            && !window.HubSpotConversations) {
            const hubspotScript = document.createElement('script');
            hubspotScript.src = `https://js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_TRACKING}.js`;
            hubspotScript.async = true;
            document.body.appendChild(hubspotScript);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { history } = this.props;
        if (nextProps.user.email
            && nextProps.user.role
            && nextProps.user.email.length > 3) {
            localStorage.setItem('hasUser', true);
            if (nextProps.user.role === STUDENT) {
                history.replace('/student/assignments');
            } else if (nextProps.user.role === APPLICANT) {
                history.replace('/applications');
            } else {
                history.replace('/cohorts');
            }
        }
    }

    componentWillUnmount() {
        // No longer used. We want to keep the hubspot widget on the page.
        // if (hubspotScript) {
        //     if (window && window.HubSpotConversations && window.HubSpotConversations.widget) {
        //         window.HubSpotConversations.widget.remove();
        //         document.body.removeChild(hubspotScript);
        //         hubspotScript = undefined;
        //     }
        // }
    }

    handleChangeFor = (propertyName, ...errorName) => (event) => {
        const { dispatch, applicant } = this.props;
        const { errorTextPassword } = this.state;
        if (errorName.length > 1 && errorTextPassword === 'Passwords do not match.') {
            for (let i = 0; i < errorName.length; i += 1) {
                this.setState({
                    [errorName[i]]: '',
                });
            }
        } else if (errorName.length > 1) {
            this.setState({
                [errorName[0]]: '',
            });
        } else {
            this.setState({
                [errorName]: '',
            });
        }
        const updatedApplicant = {
            ...applicant,
            [propertyName]: event.target.value,
        };
        dispatch({ type: 'SET_EDITING_APPLICANT', payload: updatedApplicant });
    };

    handleSubmit = async (event) => {
        const { dispatch, applicant } = this.props;
        event.preventDefault();
        const valid = this.validateForm();
        if (!valid) {
            return;
        }
        let token;
        try {
            dispatch({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
            token = await this.recaptchaRef.current.executeAsync();
        } catch (e) {
            dispatch({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
            dispatch({ type: 'SET_ERROR', payload: 'Unable to validate recaptcha.' });
            dispatch({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
            return;
        }
        dispatch({ type: 'CREATE_APPLICANT', payload: { ...applicant, token } });
    };

    checkIfEmail = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return true;
        }
        return false;
    };

    // checkIfValidPhone = (phoneNumber) => {
    //     if (/^(1-)?\d{3}-\d{3}-\d{4}$/.test(phoneNumber)) {
    //         return true;
    //     }

    //     return false;
    // }

    validateForm = () => {
        const { applicant } = this.props;
        let valid = true;
        const isEmail = this.checkIfEmail(applicant.email);
        if (!applicant.email || applicant.email === '') {
            valid = false;
            this.setState({
                errorTextEmail: 'Email is required.',
            });
        } else if (!isEmail) {
            valid = false;
            this.setState({
                errorTextEmail: 'Please enter a valid email.',
            });
        }

        if (!applicant.password || applicant.password === '') {
            valid = false;
            this.setState({
                errorTextPassword: 'Password is required.',
            });
        } else if (applicant.password.length < 8) {
            valid = false;
            this.setState({
                errorTextPassword: 'Password must be more than 8 characters.',
            });
        } else if (applicant.password !== applicant.retypePassword) {
            valid = false;
            this.setState({
                errorTextPassword: 'Passwords do not match.',
            });
        }

        if (!applicant.retypePassword || applicant.retypePassword === '') {
            valid = false;
            this.setState({
                errorTextPasswordConfirmation: 'Password confirmation is required.',
            });
        } else if (applicant.retypePassword.length < 8) {
            valid = false;
            this.setState({
                errorTextPasswordConfirmation: 'Password must be more than 8 characters.',
            });
        } else if (applicant.password !== applicant.retypePassword) {
            valid = false;
            this.setState({
                errorTextPasswordConfirmation: 'Passwords do not match.',
            });
        }

        if (!applicant.first_name || applicant.first_name === '') {
            valid = false;
            this.setState({
                errorTextFirstName: 'First name is required.',
            });
        }
        if (!applicant.last_name || applicant.last_name === '') {
            valid = false;
            this.setState({
                errorTextLastName: 'Last name is required.',
            });
        }
        if (!applicant.phone || applicant.phone === '') {
            valid = false;
            this.setState({
                errorTextPhone: 'Phone number is required.',
            });
        }
        return valid;
    };

    render() {
        const { classes, applicant } = this.props;
        const {
            errorTextEmail,
            errorTextFirstName,
            errorTextLastName,
            errorTextPassword,
            errorTextPasswordConfirmation,
            errorTextPhone,
        } = this.state;
        return (
            <>
                <Grid container justifyContent="space-evenly" className={classes.gridContainer}>
                    <Grid item xs={12} className={classes.titleContainer}>
                        <Typography className={classes.title}>Apply to Study at Prime</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.title}>
                        <form onSubmit={this.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className={classes.header}>
                                        START YOUR APPLICATION HERE
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.summary}>
                                        Prime&apos;s application will
                                        give you a chance to show the passion that you&apos;ll bring
                                        to your
                                        study at Prime. Sign up below to look at the application -
                                        you&apos;ll be able to save your progress and complete it
                                        whenever you have time.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="email"
                                        label="Email"
                                        className={classes.input}
                                        value={applicant.email}
                                        onChange={this.handleChangeFor('email', 'errorTextEmail')}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                    {errorTextEmail.length > 0 && (
                                        <Typography className={classes.errorText}>
                                            {errorTextEmail}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="password"
                                        type="password"
                                        label="Password"
                                        helperText="Minimum 8 characters"
                                        className={classes.input}
                                        value={applicant.password}
                                        onChange={this.handleChangeFor('password', 'errorTextPassword', 'errorTextPasswordConfirmation')}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                    {errorTextPassword.length > 0 && (
                                        <Typography className={classes.errorText}>
                                            {errorTextPassword}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="password-confirmation"
                                        type="password"
                                        label="Confirm Password"
                                        className={classes.input}
                                        value={applicant.retypePassword}
                                        onChange={this.handleChangeFor('retypePassword', 'errorTextPasswordConfirmation', 'errorTextPassword')}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                    {errorTextPasswordConfirmation.length > 0 && (
                                        <Typography className={classes.errorText}>
                                            {errorTextPasswordConfirmation}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="first-name"
                                        label="First Name"
                                        className={classes.input}
                                        value={applicant.first_name}
                                        onChange={this.handleChangeFor('first_name', 'errorTextFirstName')}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                    {errorTextFirstName.length > 0 && (
                                        <Typography className={classes.errorText}>
                                            {errorTextFirstName}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="last-name"
                                        label="Last Name"
                                        className={classes.input}
                                        value={applicant.last_name}
                                        onChange={this.handleChangeFor('last_name', 'errorTextLastName')}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                    {errorTextLastName.length > 0 && (
                                        <Typography className={classes.errorText}>
                                            {errorTextLastName}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="phone"
                                        label="Phone"
                                        helperText="Format xxx-xxx-xxxx"
                                        className={classes.input}
                                        value={applicant.phone}
                                        onChange={this.handleChangeFor('phone', 'errorTextPhone')}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                    {errorTextPhone.length > 0 && (
                                        <Typography className={classes.errorText}>
                                            {errorTextPhone}
                                        </Typography>
                                    )}
                                </Grid>
                                {
                                    process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                                        <ReCAPTCHA
                                            ref={this.recaptchaRef}
                                            size="invisible"
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                            badge="bottomleft"
                                        />
                                    )
                                }
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Start Application
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.title}>
                        <LoginForm />
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-evenly" alignItems="center" className={classes.layoutFooter}>
                    <Grid item xs={10} sm={4}>
                        <img src="./prime_logo_white.png" alt="Logo primefullwhite" />
                    </Grid>
                    <Grid item xs={10} sm={4}>
                        <List>
                            <ListItem button dense onClick={() => { window.location = 'tel:1-952-222-8108'; }}>
                                <ListItemIcon>
                                    <Icon
                                        className={classNames(classes.icon, 'fa fa-phone')}
                                    />
                                </ListItemIcon>
                                <ListItemText classes={{ primary: classes.navText }} primary="(952) 222-8108" />
                            </ListItem>
                            <ListItem button dense onClick={() => { window.location = 'mailto:hello@primeacademy.io'; }}>
                                <ListItemIcon>
                                    <Icon
                                        className={classNames(classes.icon, 'fa fa-envelope')}
                                    />
                                </ListItemIcon>
                                <ListItemText classes={{ primary: classes.navText }} primary="hello@primeacademy.io" />
                            </ListItem>
                            <ListItem button dense onClick={() => { window.location = 'https://www.google.com/maps/place/301+4th+Ave+S,+Minneapolis,+MN+55415/@44.9780352,-93.2656949,17z/data=!3m1!4b1!4m5!3m4!1s0x52b3329db5f67097:0xd4e542bd8bfa515a!8m2!3d44.9780314!4d-93.2635009'; }}>
                                <ListItemIcon>
                                    <Icon
                                        className={classNames(classes.icon, 'fa fa-map-marker')}
                                    />
                                </ListItemIcon>
                                <ListItemText classes={{ primary: classes.navText }} primary="301 4TH AVE S., SUITE 577, MINNEAPOLIS, MN 55415" />
                            </ListItem>
                        </List>
                    </Grid>
                    {/*
                    */}
                </Grid>
                <Grid container className={classes.footerUpdates}>
                    <Grid item sm={12} className={classes.socialContainer}>
                        WANT TO KEEP TABS ON WHAT WE’RE UP TO? FOLLOW US &nbsp;&nbsp;
                        <a href="http://twitter.com/goprimeacademy">
                            <Icon
                                className={classNames(classes.socialIcon, 'fab fa-twitter social-link')}
                            />
                        </a>
                        &nbsp;&nbsp;
                        <a href="http://facebook.com/primedigitalacademy">
                            <Icon
                                className={classNames(classes.socialIcon, 'fab fa-facebook social-link')}
                            />
                        </a>
                        &nbsp;&nbsp;
                        <a href="https://www.linkedin.com/edu/prime-digital-academy-170037">
                            <Icon
                                className={classNames(classes.socialIcon, 'fab fa-linkedin social-link')}
                            />
                        </a>
                        &nbsp;&nbsp;
                        AND
                        &nbsp;&nbsp;
                        <a className={classes.signUp} href="http://eepurl.com/bbnAin">
                            SIGN UP FOR UPDATES
                        </a>
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-evenly" alignItems="center">
                    <Grid item sm={10} className={classes.legalContainer}>
                        <p>
                            Prime Digital Academy is licensed as a private career
                            school with the Missouri Department of Higher Education
                            and the Minnesota Office of Higher Education pursuant
                            to Minnesota Statutes, sections 136A.821 to 136A.832.
                            Licensure is not an endorsement of the institution.
                            Credits earned may not transfer to all other institutions.
                            Office of Higher Education, 1450 Energy Park Dr. Ste.
                            #350, St. Paul, MN 55108.
                        </p>
                        <p className="copyright">
                            © 2021 Prime Digital Academy. All rights reserved.
                            <a href="//primeacademy.io/terms"> Terms of Use</a>
                            |
                            <a href="//www.iubenda.com/privacy-policy/773399" className="iubenda-black iubenda-embed" title="Privacy Policy">
                                Privacy Policy
                            </a>
                        </p>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    applicant: state.applicant.editingApplicant,
});

RegistrationForm.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    applicant: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(RegistrationForm)));
