import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoginPage from './LoginPage';
import ApplicantPortalRegistrationPage from '../ApplicantPortal/RegistrationPage/RegistrationForm';

const {
    STUDENT,
    APPLICANT,
    ALL_STAFF,
    ADJUNCT,
    GRADER,
    CAREER,
    STUDENT_LIFE,
} = require('../General/UserTypes');

class LandingPage extends Component {
    state = {
        subdomain: '',
    };

    componentDidMount() {
        const { dispatch, user, history } = this.props;
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
        if (user.email
            && user.role
            && user.email.length > 3) {
            localStorage.setItem('hasUser', true);
            if (user.role === STUDENT) {
                history.push('/student/assignments');
            } else if (user.role === APPLICANT) {
                history.push('/applications');
            } else if (user.role === STUDENT_LIFE || user.role === CAREER) {
                history.push('/career');
            } else if ([...ALL_STAFF, ADJUNCT].indexOf(user.role) >= 0) {
                history.push('/cohorts');
            } else if ([GRADER].indexOf(user.role) >= 0) {
                history.push('/assignments');
            }
        }
        const hasUser = localStorage.getItem('hasUser');
        if (hasUser) {
            dispatch({ type: 'FETCH_USER', payload: { location: 'login' } });
        }
        const { host } = window.location;
        const subdomain = host.split('.')[0];
        this.setState({
            subdomain,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { history } = this.props;
        if (nextProps.user.email
            && nextProps.user.role
            && nextProps.user.email.length > 3) {
            localStorage.setItem('hasUser', true);
            if (nextProps.user.role === STUDENT) {
                history.push('/student/assignments');
            } else if (nextProps.user.role === APPLICANT) {
                history.push('/applications');
            } else if (nextProps.user.role === STUDENT_LIFE || nextProps.user.role === CAREER) {
                history.push('/career');
            } else if ([...ALL_STAFF, ADJUNCT].indexOf(nextProps.user.role) >= 0) {
                history.push('/cohorts');
            } else if ([GRADER].indexOf(nextProps.user.role) >= 0) {
                history.push('/assignments');
            }
            // If role is not defined, stay on log in page.
        }
    }

    render() {
        const {
            subdomain,
        } = this.state;
        const {
            history,
        } = this.props;
        return (
            <>
                {
                    (subdomain === 'apply' || subdomain === 'applyqa')
                        ? (
                            <ApplicantPortalRegistrationPage history={history} />
                        ) : (
                            <LoginPage history={history} />
                        )
                }
            </>
        );
    }
}

LandingPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
};
const mapStateToProps = state => ({
    user: state.user,
});

export default connect(mapStateToProps)(LandingPage);
