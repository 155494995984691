import React from 'react';
import PropTypes from 'prop-types';

import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import StudentCohortTableRow from './StudentCohortTableRow';

function StudentCohortTable({ cohortHistory }) {
    return (
        <Paper>
            <h5>Cohorts</h5>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Name</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Graduation Date</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        cohortHistory.map((history, index) => (
                            <StudentCohortTableRow
                                key={history.id ?? index}
                                history={history}
                            />
                        ))
                    }
                </TableBody>
            </Table>
        </Paper>
    );
}

StudentCohortTable.propTypes = {
    cohortHistory: PropTypes.instanceOf(Object).isRequired,
};

export default StudentCohortTable;
