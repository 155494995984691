import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    TableRow,
    TableCell,
    Hidden,
    Button,
    Tooltip,
    Icon,
} from '@mui/material';
import PropTypes from 'prop-types';
import { shortDateSlash } from '../../../helpers/DateFormatHelper';
import TableCellLink from '../../General/TableCellLink';

const formatStatus = (status) => {
    if (status) {
        let applicantStatus = status.replace(/_/g, ' ');
        const words = applicantStatus.split(' ');
        for (let i = 0; i < words.length; i += 1) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        applicantStatus = words.join(' ');
        return applicantStatus;
    }
    return status;
};

const scoreValue = (score) => {
    if (score !== undefined && score !== null) {
        return score;
    }
    return '-';
};

const ApplicationListRow = ({
    viewApplicationDetails,
    applicationInfo,
    classes,
    overdueApplications,
}) => {
    const [hoverText, setHoverText] = useState('Copy email to clipboard.');

    const copyEmailToClipboard = email => async (event) => {
        event.stopPropagation();
        if (!navigator.clipboard) {
            setHoverText('Unable to copy.');
            return;
        }
        try {
            await navigator.clipboard.writeText(email);
            setHoverText('Copied!');
        } catch (e) {
            setHoverText('Unable to copy.');
        }
    };
    return (
        <TableRow
            hover
            className={classes.tableRow}
        >
            <TableCellLink color={overdueApplications ? '#DB5652' : 'unset'} to={`/admissions_applications/${applicationInfo.application_id}`} first>
                {`${applicationInfo.first_name} ${applicationInfo.last_name}`}
            </TableCellLink>
            <Hidden mdDown>
                <TableCell
                    onClick={() => viewApplicationDetails(applicationInfo)}
                    className={classes.emailColumn}
                    style={{ whiteSpace: 'nowrap' }}
                >
                    <Tooltip title={hoverText}>
                        <Icon
                            onClick={copyEmailToClipboard(applicationInfo.email)}
                            onMouseOver={() => setHoverText('Copy to Clipboard')}
                            onFocus={() => setHoverText('Copy to Clipboard')}
                            onMouseDown={e => e.stopPropagation()}
                            className="far fa-copy"
                            style={{
                                color: '#555555',
                                fontSize: '18px',
                                marginBottom: '-3px',
                                marginRight: '12px',
                            }}
                        />
                    </Tooltip>
                    {applicationInfo.email}
                </TableCell>
            </Hidden>
            <Hidden lgDown>
                <TableCellLink to={`/admissions_applications/${applicationInfo.application_id}`}>
                    {scoreValue(applicationInfo.logic_score)}
                </TableCellLink>
                <TableCellLink to={`/admissions_applications/${applicationInfo.application_id}`}>
                    {scoreValue(applicationInfo.profile_score)}
                </TableCellLink>
                <TableCellLink to={`/admissions_applications/${applicationInfo.application_id}`}>
                    {scoreValue(applicationInfo.resume_score)}
                </TableCellLink>
                <TableCellLink to={`/admissions_applications/${applicationInfo.application_id}`}>
                    {scoreValue(applicationInfo.interview_score)}
                </TableCellLink>

            </Hidden>
            <TableCellLink to={`/admissions_applications/${applicationInfo.application_id}`} className={classes.statusColumn}>
                {
                    !overdueApplications ? formatStatus(applicationInfo.application_status)
                        : <Link onClick={e => e.stopPropagation()} to={`/cohorts/${applicationInfo.assigned_cohort_id}`}>{applicationInfo.cohort_name}</Link>
                }
            </TableCellLink>
            <TableCellLink to={`/admissions_applications/${applicationInfo.application_id}`}>
                {overdueApplications ? shortDateSlash(applicationInfo.completed_at)
                    : <Link onClick={e => e.stopPropagation()} to={`/cohorts/${applicationInfo.assigned_cohort_id}`}>{applicationInfo.cohort_name}</Link>
                }
            </TableCellLink>
            <Hidden lgDown>
                <TableCellLink to={`/admissions_applications/${applicationInfo.application_id}`}>
                    {shortDateSlash(overdueApplications
                        ? applicationInfo.resubmission_completed_at : applicationInfo.created_at)}
                </TableCellLink>
                {!overdueApplications
                && (
                    <TableCellLink to={`/admissions_applications/${applicationInfo.application_id}`}>
                        {shortDateSlash(applicationInfo.updated_at)}
                    </TableCellLink>
                )
                }
                {!overdueApplications
                && (
                    <TableCellLink to={`/admissions_applications/${applicationInfo.application_id}`}>
                        {applicationInfo.completed_at ? shortDateSlash(applicationInfo.completed_at) : ''}
                    </TableCellLink>
                )
                }
            </Hidden>
            {!overdueApplications
            && (
                <Hidden xlDown>
                    <TableCellLink to={`/admissions_applications/${applicationInfo.application_id}`} align="right">
                        <Button variant="outlined" type="button" onClick={() => viewApplicationDetails(applicationInfo)}> View </Button>
                    </TableCellLink>
                </Hidden>
            )
            }
        </TableRow>
    );
};
ApplicationListRow.defaultProps = {
    overdueApplications: false,
};

ApplicationListRow.propTypes = {
    applicationInfo: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    viewApplicationDetails: PropTypes.func.isRequired,
    overdueApplications: PropTypes.bool,
};
export default (ApplicationListRow);
