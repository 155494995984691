import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
    },
};

const SavingModal = (props) => {
    const {
        classes,
        open,
    } = props;
    return (
        <Dialog open={open}>
            <DialogTitle id="responsive-dialog-title">
                Please wait...
            </DialogTitle>
            <DialogContent align="center" className={classes.feedbackContainer}>
                <span role="img" aria-label="loading">⌛</span>
                {/* TODO: Add animation */}
            </DialogContent>
            {/* This modal can't be closed. */}
        </Dialog>
    );
};

SavingModal.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    open: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
    open: state.modal.modalOpen,
});
const saveModal = withStyles(styles)(SavingModal);
export default connect(mapStateToProps)(saveModal);
