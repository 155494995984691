import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    TextField,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import PageTitle from '../../PageTitle/PageTitle';

const styles = {
    searchInput: {
        width: '350px',
    },
    tableRow: {
        cursor: 'pointer',
    },
};

class CompanyListPage extends Component {
    columns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'city',
            headerName: 'City',
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'state',
            headerName: 'State',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'placements',
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            headerName: 'Placements',
            minWidth: 110,
            flex: 1,
        },
        {
            field: 'last_placement',
            headerName: 'Last Placement',
            minWidth: 130,
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'action',
            renderCell: (params) => (
                <strong>
                    <Button
                        onClick={() => {
                            this.viewCompanyDetails(params.row);
                        }}
                        variant="outlined"
                        type="button"
                    >
                        {' '}
                        View
                    </Button>
                </strong>
            ),
        },

    ];

    componentDidMount() {
        const { dispatch } = this.props;

        this.fetchCompanies();

        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
    }

    downloadCSV = () => {
        const {
            sortOrder,
            searchText,
        } = this.props;
        const params = {
            order: sortOrder.order,
            orderBy: sortOrder.orderBy,
            searchText,
        };
        params.t = new Date().getTime();
        const qs = Object.keys(params)
            .filter(key => params[key] && params[key] !== '')
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
        let prefix = '';
        if (process.env.NODE_ENV !== 'production') {
            prefix = 'http://localhost:5000/';
        }
        window.open(`${prefix}api/companies/csv?${qs}`);
    };

    handleSearchChange = (event) => {
        const { dispatch } = this.props;
        const searchText = event.target.value;
        dispatch({ type: 'SET_COMPANY_SEARCH_TEXT', payload: { text: searchText } });
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
            this.runSearch(searchText);
        }, 250);
    };

    runSearch = (searchText) => {
        const { dispatch } = this.props;

        // Go back to page 0 on search
        dispatch({ type: 'SET_COMPANY_SEARCH_OFFSET', payload: { offset: 0 } });

        this.fetchCompanies({
            searchText,
            searchOffset: 0,
        });

        // Clear debounce on search input
        this.searchTimeout = null;
    };

    fetchCompanies = (params = null) => {
        const {
            dispatch,
            sortOrder,
            searchOffset,
            searchText,
            // rowsPerPage,
        } = {
            // Merge params w/props
            ...this.props,
            ...params,
        };

        dispatch({
            type: 'FETCH_COMPANIES',
            payload: {
                order: sortOrder.order,
                orderBy: sortOrder.orderBy,
                offset: searchOffset,
                limit: 1000, // rowsPerPage,
                searchText,
            },
        });
    };

    viewCompanyDetails = (selectedCompany) => {
        const { history } = this.props;
        history.push(`/companies/${selectedCompany.id}`);
    };

    render() {
        const {
            company,
            classes,
            searchText,
        } = this.props;
        const actions = [{
            label: 'Download CSV',
            onClick: this.downloadCSV,
        }, {
            label: 'Add New Company',
            // Dispatch an action...
            type: 'UNSET_EDITING_COMPANY',
            // ...and navigate to this page.
            path: '/companies/new',
        }];
        return (
            <div className="container">
                <PageTitle
                    title="Companies"
                    actions={actions}
                />
                <Paper>
                    <div className="search-bar">
                        <TextField
                            helperText="Search by company name, city, or state."
                            className={classes.searchInput}
                            label="Search"
                            margin="dense"
                            variant="outlined"
                            value={searchText}
                            onChange={this.handleSearchChange}
                        />
                    </div>
                    <br />
                    <DataGrid
                        rows={company.rows ? company.rows : []}
                        columns={this.columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 100,
                                },
                            },
                        }}
                        pageSizeOptions={[25, 50, 100]}
                        disableRowSelectionOnClick
                    />

                </Paper>
            </div>
        );
    }
}

CompanyListPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    company: PropTypes.instanceOf(Object).isRequired,
    sortOrder: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    searchText: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    company: state.company.company,
    sortOrder: state.company.sortOrder,
    searchOffset: state.company.searchOffset,
    searchText: state.company.searchText,
    rowsPerPage: state.settings.rowsPerPage,
});
export default connect(mapStateToProps)(withStyles(styles)(CompanyListPage));
