/* eslint-disable no-console */
import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

const delay = ms => new Promise(res => setTimeout(res, ms));

// function* createCourse(action) {
//     try {
//         yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
//         const { data: newCourse } = yield axios.post('/api/courses', action.payload);
//         yield put({ type: 'FETCH_COURSE_LIST' });
//         // Move to page for student created
//         yield delay(250);
//         yield action.history.replace(`/courses/${newCourse.id}`);
//     } catch (error) {
//         yield put({ type: 'SET_ERROR', payload: error.response });
//         yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
//         console.log('Create Student - post request failed', error);
//     } finally {
//         // Hide the loading indicator
//         yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
//     }
// }

function* fetchCourseList() {
    try {
        const response = yield axios.get('api/courses/list');
        yield put({ type: 'SET_COURSE_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Course list - get request failed', error);
    }
}

function* fetchCourseDetails(action) {
    try {
        const response = yield axios.get(`api/courses/${action.payload.id}`);
        yield put({ type: 'SET_COURSE', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Course details - get request failed', error);
    }
}

// function* fetchCourseForEdit(action) {
//     try {
//         const response = yield axios.get(`api/courses/${action.payload.courseId}`);
//         yield put({ type: 'SET_EDITING_COURSE', payload: response.data });
//     } catch (error) {
//         yield put({ type: 'SET_ERROR', payload: error.response });
//         yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
//         console.log('Fetch Course For Edit - get request failed', error);
//     }
// }

function* saveCourse(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put('/api/courses/update', action.payload);
        yield delay(250);
        yield action.history.replace('/courses');
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.message });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Update Course - put request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

// function* deleteCourse(action) {
//     try {
//         yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
//         yield axios.put(`api/courses/archive/${action.payload.courseId}`);
//         // Add delay to allow loading indicator to appear
//         yield delay(250);
//         yield action.history.replace('/courses');
//     } catch (error) {
//         yield put({ type: 'SET_ERROR', payload: error.response });
//         yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
//     } finally {
//         // Hide the loading indicator
//         yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
//     }
// }

function* userSaga() {
    // yield takeLatest('CREATE_COURSE', createCourse);
    yield takeLatest('PUT_COURSE', saveCourse);
    yield takeLatest('FETCH_COURSE_LIST', fetchCourseList);
    yield takeLatest('FETCH_COURSE_DETAILS', fetchCourseDetails);
    // yield takeLatest('FETCH_COURSE_FOR_EDIT', fetchCourseForEdit);
    // yield takeLatest('ARCHIVE_COURSE', deleteCourse);
}

export default userSaga;
