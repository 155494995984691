import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox,
    Button,
    Grid,
    TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import StudentFeedbackRadioGroup from './StudentFeedbackRadioGroup';

const styles = {
    feedbackContainer: {
        padding: '20px',
    },
    textField: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
};

const handleChangeFor = (key, dispatch) => (event) => {
    dispatch({
        type: 'SET_STUDENT_DAILY_FEEDBACK',
        payload: {
            key,
            value: event.target.value,
        },
    });
};

const handleCheckboxChangeFor = (key, dispatch) => (event) => {
    dispatch({
        type: 'SET_STUDENT_DAILY_FEEDBACK',
        payload: {
            key,
            value: event.target.checked,
        },
    });
};

const handleFormSubmit = (payload, dispatch) => () => {
    if (!payload.understanding_score || !payload.support_score) {
        dispatch({
            type: 'SET_STUDENT_DAILY_FEEDBACK_ERROR',
        });
        return;
    }
    const dataToSend = Object.assign({}, payload);
    dataToSend.feeling_score = dataToSend.understanding_score;
    dispatch({
        type: 'PUT_STUDENT_PORTAL_FEEDBACK',
        payload: dataToSend,
    });
    if (dataToSend.additional_conversation_wanted === true) {
        dispatch({
            type: 'STUDENT_ADDITIONAL_CONVERSATION_MODAL',
            payload: {
                modalOpen: true,
            },
        });
    }
};

const materialPlaceholderText = (value) => {
    if (value < 4) {
        return 'Please take a moment to share your thoughts on which topics are most challenging and any ideas about actions that could boost your understanding.';
    }
    if (value >= 4) {
        return 'Please take a moment to share what went well and which topics were the most helpful.';
    }
    return '';
};

const supportPlaceholderText = (value) => {
    if (value < 4) {
        return 'If you\'d like to share, we\'d like to hear any specifics about ways you could feel more supported.';
    }
    if (value >= 4) {
        return 'If you\'d like to share, we\'d like to hear any specifics about things that helped you feel supported today.';
    }
    return '';
};

const StudentFeedbackForm = ({
    classes,
    studentFeedback,
    dispatch,
}) => (
    <form className={classes.feedbackContainer}>
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <FormControl component="fieldset">
                    <FormLabel htmlFor="reflection-accomplishment">
                        <strong>
                            Share one thing that you&apos;re proud of accomplishing today.
                        </strong>
                    </FormLabel>
                </FormControl>
                <TextField
                    id="reflection-accomplishment"
                    placeholder="Accomplishment"
                    className={classes.textField}
                    value={studentFeedback.accomplishment}
                    onChange={handleChangeFor('accomplishment', dispatch)}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="2"
                    inputProps={{ maxLength: 480 }}
                />
            </Grid>
            <Grid item xs={12} lg={1} />
            <Grid item xs={12} lg={5}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        <strong>
                            What is one word you would use to describe today?
                        </strong>
                    </FormLabel>
                </FormControl>
                <TextField
                    id="reflection-feeling"
                    placeholder="Word of the day"
                    className={classes.textField}
                    value={studentFeedback.feeling_explanation}
                    onChange={handleChangeFor('feeling_explanation', dispatch)}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="2"
                    inputProps={{ maxLength: 27 }}
                />
            </Grid>
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12} lg={7}>
                <FormControl component="fieldset">
                    <FormLabel
                        component="legend"
                        error={studentFeedback.formError && !studentFeedback.understanding_score}
                    >
                        <strong>
                            How well do you feel you understood today&apos;s material?
                        </strong>
                    </FormLabel>
                    <StudentFeedbackRadioGroup
                        startLabel="Not well at all"
                        endLabel="Extremely well"
                        value={studentFeedback.understanding_score}
                        handleRadioChange={handleChangeFor('understanding_score', dispatch)}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={5}>
                <TextField
                    required
                    id="reflection-understanding"
                    placeholder={materialPlaceholderText(studentFeedback.understanding_score)}
                    className={classes.textField}
                    value={studentFeedback.understanding_explanation}
                    onChange={handleChangeFor('understanding_explanation', dispatch)}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="4"
                    inputProps={{ maxLength: 480 }}
                />
            </Grid>
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12} lg={7}>
                <FormControl component="fieldset">
                    <FormLabel
                        component="legend"
                        error={studentFeedback.formError && !studentFeedback.support_score}
                    >
                        <strong>
                            How well supported did you feel today?
                        </strong>
                    </FormLabel>
                    <StudentFeedbackRadioGroup
                        startLabel="Not supported"
                        endLabel="Extremely supported"
                        value={studentFeedback.support_score}
                        handleRadioChange={handleChangeFor('support_score', dispatch)}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={5}>
                <TextField
                    required
                    id="reflection-support"
                    placeholder={supportPlaceholderText(studentFeedback.support_score)}
                    className={classes.textField}
                    value={studentFeedback.support_explanation}
                    onChange={handleChangeFor('support_explanation', dispatch)}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="4"
                    inputProps={{ maxLength: 480 }}
                />
            </Grid>
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        <strong>
                            Would you like to give thanks to someone today?
                        </strong>
                    </FormLabel>
                </FormControl>
                <TextField
                    id="reflection-thanks"
                    placeholder="Say a few words about how they made your day better!"
                    className={classes.textField}
                    value={studentFeedback.thanks}
                    onChange={handleChangeFor('thanks', dispatch)}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="2"
                />
            </Grid>
            <Grid item xs={1}>
                <br />
            </Grid>
            <Grid item xs={12} lg={5}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        <strong>
                            Is there anything else you&apos;d like us to know?
                        </strong>
                    </FormLabel>
                </FormControl>
                <TextField
                    id="reflection-recommend"
                    placeholder=""
                    className={classes.textField}
                    value={studentFeedback.recommended_experiences}
                    onChange={handleChangeFor('recommended_experiences', dispatch)}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="2"
                    inputProps={{ maxLength: 480 }}
                />
            </Grid>
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid container item xs={12} md={9}>
                <FormControlLabel
                    control={(
                        <Checkbox
                            color="primary"
                            onChange={handleCheckboxChangeFor('additional_conversation_wanted', dispatch)}
                            value="1"
                            checked={
                                Number(studentFeedback.additional_conversation_wanted) === 1
                            }
                        />
                    )}
                    label="I'd like to request a 1:1 with a staff member who is not my instructor"
                />
                <Grid item xs={12}>
                    <br />
                </Grid>
                {
                    Number(studentFeedback.additional_conversation_wanted) === 1
                    && (
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    <strong>
                                        {`Would you like to share any 
                                        more information about this request?`}
                                    </strong>
                                </FormLabel>
                            </FormControl>
                            <TextField
                                id="reflection-additional-info"
                                placeholder="Additional details about the 1:1 request."
                                className={classes.textField}
                                value={studentFeedback.additional_information}
                                onChange={handleChangeFor('additional_information', dispatch)}
                                margin="normal"
                                variant="outlined"
                                multiline
                                rows="2"
                                inputProps={{ maxLength: 480 }}
                            />
                        </Grid>
                    )
                }
            </Grid>
            <Grid container item xs={12} md={3} justifyContent="flex-end" alignItems="flex-end">
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFormSubmit(studentFeedback, dispatch)}
                    >
                        <span>Submit Reflection</span>
                    </Button>
                </div>
            </Grid>
            {studentFeedback.formError && (
                <Grid item xs={12} justifyContent="flex-end">
                    <p className={classes.errorText}>Please complete all required fields.</p>
                </Grid>
            )}
        </Grid>
    </form>
);

StudentFeedbackForm.propTypes = {
    dispatch: PropTypes.instanceOf(Function).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    studentFeedback: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    cohort: state.studentPortal.cohort,
    studentFeedback: state.studentPortal.studentFeedback,
    feedbackDateCompare: state.studentPortal.feedbackDateCompare,
});

const studentFeedbackPage = withStyles(styles)(StudentFeedbackForm);
export default connect(mapStateToProps)(studentFeedbackPage);
