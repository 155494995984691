import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
    Paper,
    Grid,
    Button,
    Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import PageTitle from '../../PageTitle/PageTitle';
import AcademicEventsModal from '../../Modal/AcademicEventsModal';
import AcademicEventsTable from '../../General/AcademicEventsTable';

const styles = {
    feedbackContainer: {
        backgroundColor: '#eee',
        padding: '20px',
    },
    feedbackScore: {
        fontSize: '18px',
        textAlign: 'left',
        minWidth: '120px',
    },
    scoreContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    icon: {
        margin: 0,
        padding: 2,
        paddingRight: 10,
        fontSize: '14px',
        height: 'auto',
        width: 'auto',
    },
};

class StudentFeedbackDetailsPage extends Component {
    state = {
        academicEventModalOpen: false,
        academicEventData: {
            eventId: 0,
            studentName: '',
            eventNotes: '',
        },
    };

    componentDidMount() {
        const { dispatch, match, history } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'UNSET_STUDENT' });
        dispatch({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: match.params.id } });
        dispatch({ type: 'FETCH_SELECTED_STUDENT_FEEDBACK', payload: { studentId: match.params.id, feedbackId: match.params.feedbackId } });
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        this.unlisten = history.listen(() => {
            this.onRouteChange();
        });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_STUDENT' });
        this.unlisten();
    }

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: match.params.id } });
        dispatch({ type: 'FETCH_SELECTED_STUDENT_FEEDBACK', payload: { studentId: match.params.id, feedbackId: match.params.feedbackId } });
    };

    toggleAcademicEventModal = academicEvent => () => {
        const { dispatch, match } = this.props;
        const { academicEventModalOpen } = this.state;
        if (!academicEventModalOpen) {
            this.setState({
                academicEventModalOpen: true,
                academicEventData: academicEvent,
            });
        } else {
            this.setState({
                academicEventModalOpen: false,
                academicEventData: {},
            });
            dispatch({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: match.params.id } });
            dispatch({ type: 'FETCH_SELECTED_STUDENT_FEEDBACK', payload: { studentId: match.params.id, feedbackId: match.params.feedbackId } });
        }
    };

    formatFeedbackNumber = (entry) => {
        let result = '';
        if (entry) {
            result = `${Number(entry).toFixed(0)}`;
        }
        return result;
    };

    formatFeedbackText = (entry) => {
        let result = '';
        if (entry) {
            result = entry;
        }
        return result;
    };

    hasUnresolvedOfType = (type) => {
        const {
            feedback,
        } = this.props;
        let result;
        if (feedback.events && feedback.events.length > 0) {
            const foundEvents = feedback.events.filter((event) => {
                const match = event.event_type === type && event.resolved === false;
                return match;
            });
            if (foundEvents.length > 0) {
                // Array destructuring, same as result = foundEvents[0]
                [result] = foundEvents;
            }
        }
        return result;
    };

    resolvedEvents = () => {
        const {
            feedback,
        } = this.props;
        if (feedback.events && feedback.events.length > 0) {
            return feedback.events.filter(event => event.resolved === true);
        }
        return [];
    };

    render() {
        const {
            student,
            feedback,
            classes,
            history,
        } = this.props;
        const {
            academicEventModalOpen,
            academicEventData,
        } = this.state;
        const pageTitle = `Daily Reflection :: ${student.first_name} ${student.last_name} :: ${feedback.feedback_on}`;
        const understandingEvent = this.hasUnresolvedOfType('low_understanding_score');
        const supportEvent = this.hasUnresolvedOfType('low_support_score');
        return (
            <>
                <PageTitle title={pageTitle} />
                <Paper>
                    <div className={classes.feedbackContainer}>
                        <div>
                            <br />
                            <Link to={`/students/${student.id}`}>
                                View student profile
                            </Link>
                            <br />
                            <br />
                            <strong>
                                How well do you feel you understood today&apos;s material?
                            </strong>
                            <p className={classes.feedbackScore}>
                                {`${this.formatFeedbackNumber(feedback.understanding_score)} / 6`}
                            </p>
                            <p>
                                {this.formatFeedbackText(feedback.understanding_explanation)}
                            </p>
                            {
                                understandingEvent
                                && (
                                    <>
                                        <Button variant="outlined" color="secondary" onClick={this.toggleAcademicEventModal(understandingEvent)}>
                                            <Icon
                                                className={classNames(classes.icon, 'fa fa-exclamation-triangle')}
                                            />
                                            Resolve
                                        </Button>
                                    </>
                                )
                            }
                        </div>
                        <div>
                            <br />
                            <strong>
                                How well supported did you feel today?
                            </strong>
                            <p className={classes.feedbackScore}>
                                {`${this.formatFeedbackNumber(feedback.support_score)} / 6`}
                            </p>
                            <p>
                                {this.formatFeedbackText(feedback.support_explanation)}
                            </p>
                            {
                                supportEvent
                                && (
                                    <>
                                        <Button variant="outlined" color="secondary" onClick={this.toggleAcademicEventModal(supportEvent)}>
                                            <Icon
                                                className={classNames(classes.icon, 'fa fa-exclamation-triangle')}
                                            />
                                            Resolve
                                        </Button>
                                    </>
                                )
                            }
                        </div>
                        { feedback.feeling_explanation && (
                            <>
                                <br />
                                <strong>One Word </strong>
                                <div>
                                    {feedback.feeling_explanation}
                                </div>
                            </>
                        )
                        }
                        {feedback.understanding_explanation && (
                            <div>
                                <br />
                                <strong>Understanding Comment </strong>
                                <div>
                                    {feedback.understanding_explanation}
                                </div>
                            </div>
                        )
                        }
                        {feedback.support_explanation && (
                            <div>
                                <br />
                                <strong> Support Comment </strong>
                                <div>
                                    {feedback.support_explanation}
                                </div>
                            </div>
                        )
                        }

                        {feedback.thanks && (
                            <div>
                                <br />
                                <strong> Thanks </strong>
                                <div>
                                    {feedback.thanks}
                                </div>
                            </div>
                        )}
                        {feedback.accomplishment && (
                            <div>
                                <br />
                                <strong> Accomplishment </strong>
                                <div>
                                    {feedback.accomplishment}
                                </div>
                            </div>
                        )}
                        {feedback.recommended_experiences && (
                            <div>
                                <br />
                                <strong> Suggestion </strong>
                                <div>
                                    {feedback.recommended_experiences}
                                </div>
                            </div>
                        )}
                        <br />
                    </div>
                </Paper>
                <Grid className={classes.cardContent} container>
                    <Grid item xs={12}>
                        <AcademicEventsTable
                            title={`Resolved Academic Events for ${feedback.feedback_on}`}
                            academicEvents={this.resolvedEvents()}
                            navigateToFeedback={() => {}}
                        />
                    </Grid>
                </Grid>
                <AcademicEventsModal
                    open={academicEventModalOpen}
                    academicEvent={academicEventData}
                    history={history}
                    handleClose={this.toggleAcademicEventModal()}
                />
            </>
        );
    }
}

StudentFeedbackDetailsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            feedbackId: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    student: PropTypes.instanceOf(Object).isRequired,
    feedback: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    student: state.student.student,
    feedback: state.feedback.selectedFeedback,
    feedbackIndex: state.feedback.selectedFeedbackIndex,
    academicEvent: state.student.selectedAcademicEvent,
});

export default connect(mapStateToProps)(withStyles(styles)(StudentFeedbackDetailsPage));
