import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import { Box, Divider } from '@mui/material';
import InterviewHeader from './InterviewHeader';
import InterviewNotes from './InterviewNotes';
import InterviewRubric from './InterviewRubric';
import InterviewNoteEditor from './InterviewNoteEditor';
import InterviewChecklistItem from './InterviewChecklistItem';

const styles = theme => ({
    wrapper: {
        boxShadow: 2,
        borderRadius: '8px',
    },
});

const InterviewSection = ({
    classes,
    section,
    submitInterviewReview,

}) => (
    <Box sx={{ boxShadow: 2 }} className={classes.wrapper}>
        <InterviewHeader
            header={section.header}
            bullets={section.bullets}
            secondary={section.secondary}
            color={section.color}
        />
        <InterviewNotes questionId={section.questionId} />
        {section.checkList
            && (
                <>
                    {section.checkList.map((item, index) => (
                        <div key={Math.random()}>
                            <InterviewChecklistItem
                                questionId={section.questionId}
                                boxes={item.boxes}
                                title={item.title}
                                followUp={item.followUp}
                            />
                        </div>
                    ),
                    )}
                </>
            )
        }
        <InterviewRubric />
        <InterviewNoteEditor
            questionId={section.questionId}
            submitInterviewReview={submitInterviewReview}
        />
        <Divider />
    </Box>
);

InterviewSection.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    section: PropTypes.instanceOf(Object).isRequired,
    submitInterviewReview: PropTypes.func.isRequired,

};

export default (withStyles(styles)(InterviewSection));
