import React, { useState } from 'react';
import {
    ListItem,
    Tooltip,
    Typography,
    Icon,
    Hidden,
    Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { getPhotoUrl } from '../../../helpers/PhotoHelper';
import { shortDateTime } from '../../../helpers/DateFormatHelper';

const styles = {
    studentTitleText: {
        width: '100%',
        padding: '0px 15px',
    },
    feedbackScore: {
        fontSize: '18px',
        textAlign: 'center',
        minWidth: '80px',
    },
    placementText: {
        minWidth: '200px',
    },
    actionButtons: {
        marginLeft: '15px',
    },
};

const CohortWaitlistRow = ({
    user,
    student,
    waitlist,
    viewStudentDetails,
    classes,
}) => {
    const [hoverText, setHoverText] = useState('Copy email to clipboard.');

    const copyEmailToClipboard = email => async (event) => {
        event.stopPropagation();
        if (!navigator.clipboard) {
            setHoverText('Unable to copy.');
            return;
        }
        try {
            await navigator.clipboard.writeText(email);
            setHoverText('Copied!');
        } catch (e) {
            setHoverText('Unable to copy.');
        }
    };
    return (
        <ListItem
            divider
            button
            onClick={viewStudentDetails(student)}
        >
            <div>
                <img
                    className="placeholder-photo-sm"
                    src={getPhotoUrl(user.s3_bucket, student.id, student.photo_file_name, 'thumb')}
                    alt={student.first_name}
                />
            </div>
            <div className={classes.studentTitleText}>
                <h6>
                    {`${student.first_name} ${student.last_name}`}
                    {student.pronouns && student.pronouns !== 'Prefer-not-to-say' && ` (${student.pronouns})`}
                </h6>
                <Tooltip title={hoverText}>
                    <Icon
                        onClick={copyEmailToClipboard(student.email)}
                        onMouseOver={() => setHoverText('Copy to Clipboard')}
                        onFocus={() => setHoverText('Copy to Clipboard')}
                        onMouseDown={e => e.stopPropagation()}
                        className="far fa-copy"
                        style={{
                            color: '#555555',
                            fontSize: '18px',
                            marginBottom: '-3px',
                            marginRight: '12px',
                        }}
                    />
                </Tooltip>
                {student.email}
            </div>
            <div className={classes.placementText}>
                <Typography variant="h6">
                    Added On
                </Typography>
                <Typography variant="body2">
                    {shortDateTime(waitlist.created_at)}
                </Typography>
            </div>
            <Hidden smDown>
                {
                    waitlist.assigned_cohort_id
                        ? (
                            <div className={classes.placementText}>
                                <Typography variant="h6">
                                    Assigned Cohort
                                </Typography>
                                <Typography variant="body2">
                                    <Link onClick={e => e.stopPropagation()} to={`/cohorts/${waitlist.assigned_cohort_id}`}>
                                        {waitlist.assigned_cohort_name}
                                    </Link>
                                </Typography>
                            </div>
                        ) : (
                            <div className={classes.placementText} />
                        )
                }
                <div className={classes.actionButtons}>
                    <Button variant="outlined" onClick={viewStudentDetails(student)} type="button">View</Button>
                </div>
            </Hidden>
        </ListItem>
    );
};

CohortWaitlistRow.propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
    waitlist: PropTypes.instanceOf(Object).isRequired,
    student: PropTypes.instanceOf(Object).isRequired,
    viewStudentDetails: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(CohortWaitlistRow);
