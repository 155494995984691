import React from 'react';
import {
    Paper, Button, Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import CodeBlock from '../../../General/CodeBlock';

const styles = {
    title: {
        fontSize: '30px',
    },
    buttonContainer: {
        padding: '20px',
    },
    content: {
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '64px',
        marginBottom: '250px',
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontSize: 13,
    },
};

const StartTab = ({
    value, index, applicationOverviewText, classes, nextTab, dispatch, courseId,
}) => {
    const startApplication = (event) => {
        event.preventDefault();
        dispatch({
            type: 'START_APPLICATION',
            payload: {
                courseId,
                nextTab,
            },
        });
    };

    return (
        <>
            {value === index && (
                <>
                    <Paper className={classes.content}>
                        <ReactMarkdown
                            align="left"
                            remarkPlugins={[remarkGfm]}
                            components={{ code: CodeBlock }}
                            rehypePlugins={[rehypeRaw]}
                            linkTarget="_blank"
                        >
                            {applicationOverviewText.start_description}
                        </ReactMarkdown>
                        <form onSubmit={startApplication}>
                            <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Start Your Application
                                </Button>
                            </Grid>
                        </form>
                    </Paper>
                </>
            )}
        </>
    );
};

StartTab.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    applicationOverviewText: PropTypes.instanceOf(Object).isRequired,
    courseId: PropTypes.number.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    nextTab: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    courseId: state.applicant.editingCourseId,
});

export default connect(mapStateToProps)(withStyles(styles)(StartTab));
