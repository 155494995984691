import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    TableRow,
    TableCell,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import ExpandArrowIcon from '../../../General/ExpandArrowIcon';

/**
 * Stats returned from the server can be null. This function
 * checks for a valid numner and formats the entry accordingly.
 */
function formatStatNumber(entry) {
    let result = '0.00';
    // We have an entry and it's a valid number
    if (entry && !Number.isNaN(Number(entry))) {
        result = `${Number(entry).toFixed(2)}`;
    }
    return result;
}

const styles = theme => ({
    icon: {
        margin: 0,
        padding: theme.spacing(),
        fontSize: '14px',
    },
    feedbackContainer: {
        backgroundColor: '#eee',
        padding: '20px',
    },
    feedback: {
        backgroundColor: '#fff',
        padding: '20px',
    },
    tableRow: {
        cursor: 'pointer',
    },
});

function getEventByType(eventType) {
    let result = '';
    switch (eventType) {
    case 'dropped_out':
        result = 'Dropped Out';
        break;
    case 'transfer_out':
        result = 'Transfered Out';
        break;
    case 'graduated':
        result = 'Graduated';
        break;
    case 'in_progress':
        result = 'In Progress';
        break;
    default:
        result = eventType;
    }
    return result;
}

function StudentCohortTableRow(props) {
    const { history, classes } = props;
    const [expanded, setExpanded] = useState(false);

    function handleExpansionClick() {
        setExpanded(!expanded);
    }

    return (
        <>
            <TableRow
                key={history.id}
                className={classes.tableRow}
                onClick={handleExpansionClick}
                hover
            >
                <TableCell>
                    <ExpandArrowIcon expanded={expanded} />
                </TableCell>
                <TableCell>
                    <Link to={`/cohorts/${history.cohort_id}`}>{history.cohort_name}</Link>
                </TableCell>
                <TableCell>
                    {
                        history.started_at
                        && `${history.started_at}`
                    }
                </TableCell>
                <TableCell>
                    {
                        history.ended_at
                        && `${history.ended_at}`
                    }
                </TableCell>
                <TableCell>
                    {
                        history.graduated_at
                        && `${history.graduated_at}`
                    }
                </TableCell>
                <TableCell>
                    {
                        history.event_type
                        && `${getEventByType(history.event_type)}`
                    }
                </TableCell>
            </TableRow>
            {expanded && (
                <TableRow>
                    <TableCell colSpan={1} />
                    <TableCell>
                        <Typography variant="caption">Feeling:</Typography>
                        <Typography>{formatStatNumber(history.feeling_score)}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="caption">Understanding:</Typography>
                        <Typography>{formatStatNumber(history.understanding_score)}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="caption">Support:</Typography>
                        <Typography>{formatStatNumber(history.support_score)}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="caption">Assignment:</Typography>
                        <Typography>{formatStatNumber(history.assignment_score)}</Typography>
                    </TableCell>
                    <TableCell colSpan={2}>
                        <Typography variant="caption">Notes:</Typography>
                        <Typography style={{ whiteSpace: 'pre-line' }}>{history.employee_comment}</Typography>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

StudentCohortTableRow.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(StudentCohortTableRow);
