import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    Divider,
    Grid,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Prompt } from 'react-router-dom';
import StudentProfile from './StudentProfile/StudentProfile';
import StudentFeedbackGraph from './StudentFeedbackGraph/StudentFeedbackGraph';
import StudentCourseWork from './StudentCourseWork/StudentCourseWork';
import StudentCohortTable from './StudentCohortTable/StudentCohortTable';
import StudentPlacementTable from './StudentPlacementTable/StudentPlacementTable';
import PageTitle from '../../PageTitle/PageTitle';
import CourseWorkModal from '../../Modal/CourseWorkModal';
import { getTimestamp } from '../../../helpers/DateFormatHelper';
import AcademicEventsTable from '../../General/AcademicEventsTable';
import CreateNote from '../../../helpers/CreateNote';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import UserNote from '../../General/Notes/UserNote';

const styles = {
    actionButton: {
        float: 'right',
    },
    notes: {
        paddingTop: '10px',
    },
};

class StudentDetailPage extends Component {
    state = {
        changes: false,
        confirmationModalOpen: false,
        noteId: '',
    };

    componentDidMount() {
        const { dispatch, match, history } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'UNSET_STUDENT' });
        dispatch({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: match.params.id } });
        dispatch({ type: 'FETCH_STUDENT_ACADEMIC_EVENTS', payload: { studentId: match.params.id } });
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        this.unlisten = history.listen(() => {
            this.onRouteChange();
        });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_STUDENT' });
        this.unlisten();
        dispatch({ type: 'SET_EDITING_STUDENT_NOTE', payload: { display: false } });
    }

    addNote = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'SET_EDITING_STUDENT_NOTE', payload: { studentId: match.params.id, display: true } });
    };

    deleteNote = (noteId) => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'DELETE_STUDENT_NOTE', payload: { noteId, studentId: match.params.id } });
        this.setState({ confirmationModalOpen: false });
    };

    handleCancel = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'SET_EDITING_STUDENT_NOTE', payload: { studentId: match.params.id, display: false } });
    };

    handleChangeFor = (value) => {
        const { dispatch, match } = this.props;
        this.setState({
            changes: true,
        });
        dispatch({
            type: 'SET_EDITING_STUDENT_NOTE',
            payload: {
                content: value, user_id: parseInt(match.params.id, 10), sub_type: 'student', display: true,
            },
        });
    };

    handleSubmit = () => {
        const { dispatch, editingNote } = this.props;
        this.setState({
            changes: false,
        });
        dispatch({ type: 'CREATE_STUDENT_NOTE', payload: editingNote });
    };

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: match.params.id } });
    };

    viewCourseWork = (courseWork, assignment) => () => {
        const { dispatch, student } = this.props;
        dispatch({ type: 'SET_ASSIGNMENT_STUDENT', payload: student });
        dispatch({ type: 'SET_ASSIGNMENT_SELECTED', payload: assignment });
        dispatch({ type: 'SET_ASSIGNMENT_COURSE_WORK', payload: courseWork });
        const { history } = this.props;
        history.push(`/coursework/${courseWork.id}/grade`);
    };

    handleRefresh = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: match.params.id } });
    };

    navigateToFeedback = academicEvent => () => {
        const { history } = this.props;
        const feedbackId = academicEvent.academic_eventable_id;
        history.push(`/students/${academicEvent.user.id}/feedback/${feedbackId}`);
    };

    render() {
        const {
            user,
            student,
            cohort,
            feedbackDataset,
            courseWork,
            notes,
            hubSpotNotes,
            modalOpen,
            selectedAssignment,
            selectedCourseWork,
            academicEvents,
            classes = {},
            editingNote,
            placements,
            // tags,
            cohortHistory,
        } = this.props;
        const {
            changes,
            confirmationModalOpen,
            noteId,
        } = this.state;

        const pageTitle = `Student :: ${student.first_name} ${student.last_name}`;

        const actions = [{
            label: 'Edit',
            // Dispatch an action...
            type: 'SET_EDITING_STUDENT',
            payload: student,
            // ...and navigate to this page.
            path: `/students/${student.id}/edit`,
        }];

        let allNotes = [];
        if (notes && notes.length > 0) {
            allNotes = [...allNotes, ...notes];
        }

        if (hubSpotNotes.comments && hubSpotNotes.comments.length > 0) {
            allNotes = [...allNotes, ...hubSpotNotes.comments];
        }

        allNotes.sort((a, b) => {
            const aDate = getTimestamp(a.created_at);
            const bDate = getTimestamp(b.created_at);
            return bDate - aDate;
        });

        return (
            <>
                <Prompt
                // Use a standard alert dialog if the user leaves the page when
                // changes are detected.
                    when={changes}
                    message="You have unsaved edits. Are you sure you want to leave?"
                />
                <div className="container">
                    <PageTitle
                        title={pageTitle}
                        // Action buttons are created by the PageTitle component
                        actions={actions}
                        navigation={
                            [
                                { name: 'profile', path: `/students/${student.id}`, selected: true },
                                { name: 'feedback', path: `/students/${student.id}/feedback` },
                            ]
                        }
                    />
                    {
                        academicEvents
                        && academicEvents.unresolved
                        && (
                            <Grid className={classes.cardContent} container>
                                <Grid item xs={12}>
                                    <AcademicEventsTable
                                        title="Unresolved Academic Events"
                                        academicEvents={academicEvents.unresolved}
                                        navigateToFeedback={this.navigateToFeedback}
                                    />
                                </Grid>
                            </Grid>
                        )
                    }
                    <StudentProfile user={user} student={student} cohort={cohort} />
                    {
                        student
                        && cohortHistory
                        && cohortHistory.length > 0
                        && (
                            <StudentCohortTable cohortHistory={cohortHistory} />
                        )
                    }
                    {
                        student
                        && student.placement_count !== '0'
                        && placements
                        && placements.length > 0
                        && (
                            <StudentPlacementTable placements={placements} />
                        )
                    }
                    {
                        feedbackDataset
                        && feedbackDataset.datasets
                        // At least two days of feedback to show a trend
                        && feedbackDataset.datasets.length > 1
                        && (
                            <StudentFeedbackGraph feedbackDataset={feedbackDataset} />
                        )
                    }
                    <br />
                    {
                        courseWork
                        && courseWork.course_works
                        && courseWork.course_works.length > 0
                        && (
                            <StudentCourseWork
                                viewCourseWork={this.viewCourseWork}
                                courseWork={courseWork}
                                user={user}
                            />
                        )
                    }
                    <br />
                    {
                        editingNote.display
                        && (
                            <CreateNote
                                handleChangeFor={this.handleChangeFor}
                                handleCancel={this.handleCancel}
                                handleSubmit={this.handleSubmit}
                                editingNote={editingNote}
                            />
                        )
                    }
                    <Paper>
                        <div style={{ marginBottom: '20px' }}>
                            <h5 style={{ display: 'inline' }}>Notes</h5>
                            <Button
                                className={classes.actionButton}
                                variant="outlined"
                                type="button"
                                onClick={this.addNote}
                            >
                                Add Note
                            </Button>
                        </div>
                        <Divider />
                        {
                            allNotes.map(note => (
                                <UserNote
                                    key={note.id}
                                    applicantNote={note}
                                    deleteNote={this.deleteNote}
                                />
                            ))
                        }
                    </Paper>
                    {/* TODO: Add this back when we're ready to transition away from Trello */}
                    {/* <Paper>
                        <div style={{ marginBottom: '10px' }}>
                            <h5 style={{ display: 'inline' }}>Status History</h5>
                        </div>
                        {
                            (
                                tags
                                && tags.length > 0
                            )
                                ? (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell> Status  </TableCell>
                                                <TableCell> Updated By </TableCell>
                                                <TableCell> Date </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tags.map(tag => (
                                                <TableRow key={tag.id}>
                                                    <TableCell>
                                                        {' '}
                                                        {this.translateStatuses(tag.content)}
                                                        {' '}
                                                    </TableCell>
                                                    <TableCell>
                                                        {' '}
                                                        {tag.employee.first_name}
                                                        {' '}
                                                        {tag.employee.last_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {shortDateTime(tag.created_at)}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <p className={classes.notes}>
                                    No status updates for this student.
                                    </p>
                                )
                        }
                    </Paper> */}
                    <CourseWorkModal
                        handleRefresh={this.handleRefresh}
                        student={student}
                        open={modalOpen}
                        assignment={selectedAssignment}
                        courseWork={selectedCourseWork}
                        closeActionType="SET_ASSIGNMENT_MODAL"
                        user={user}
                    />
                    <ConfirmationModal
                        open={confirmationModalOpen}
                        dialogTitle="Delete Note"
                        dialogMessage={"You're about to delete a student note. Would you like to continue?"}
                        onCancel={() => this.setState({ confirmationModalOpen: false })}
                        onConfirm={() => this.deleteNote(noteId)}
                    />
                </div>
            </>
        );
    }
}

StudentDetailPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
    }).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    student: PropTypes.instanceOf(Object).isRequired,
    feedbackDataset: PropTypes.instanceOf(Object).isRequired,
    courseWork: PropTypes.instanceOf(Object).isRequired,
    notes: PropTypes.instanceOf(Object).isRequired,
    hubSpotNotes: PropTypes.instanceOf(Object).isRequired,
    // TODO: Add this back when we're ready to transition away from Trello
    // tags: PropTypes.instanceOf(Array).isRequired,
    modalOpen: PropTypes.bool.isRequired,
    selectedAssignment: PropTypes.instanceOf(Object).isRequired,
    selectedCourseWork: PropTypes.instanceOf(Object).isRequired,
    academicEvents: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    editingNote: PropTypes.instanceOf(Object).isRequired,
    placements: PropTypes.instanceOf(Array).isRequired,
    cohortHistory: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    cohort: state.cohort.cohort,
    student: state.student.student,
    feedbackDataset: state.student.feedbackDataset,
    courseWork: state.student.courseWork,
    notes: state.student.notes,
    hubSpotNotes: state.hubspot.studentNotes,
    // TODO: Add this back when we're ready to transition away from Trello
    // tags: state.student.tags,
    modalOpen: state.assignments.modalOpen,
    selectedAssignment: state.assignments.selectedAssignment,
    selectedCourseWork: state.assignments.selectedCourseWork,
    academicEvents: state.student.academicEvents,
    editingNote: state.student.editingNote,
    placements: state.student.placements,
    cohortHistory: state.student.cohortHistory,
});

export default connect(mapStateToProps)(withStyles(styles)(StudentDetailPage));
