import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';

import {
    Icon,
} from '@mui/material';

const styles = theme => ({
    icon: {
        margin: 0,
        padding: theme.spacing(),
        fontSize: '8px',
        height: 'auto',
        width: 'auto',
    },
});

function ExpandArrowIcon(props) {
    const {
        expanded,
        expandedIcon,
        collapsedIcon,
        classes,
    } = props;
    return (
        <>
            {expanded ? (
                <Icon
                    className={classNames(classes.icon, 'fa', expandedIcon)}
                />
            ) : (
                <Icon
                    className={classNames(classes.icon, 'fa', collapsedIcon)}
                />
            )
            }
        </>
    );
}

ExpandArrowIcon.propTypes = {
    expanded: PropTypes.bool.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    expandedIcon: PropTypes.string,
    collapsedIcon: PropTypes.string,
};

ExpandArrowIcon.defaultProps = {
    expandedIcon: 'fa-chevron-down',
    collapsedIcon: 'fa-chevron-right',
};

export default withStyles(styles)(ExpandArrowIcon);
