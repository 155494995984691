import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Divider, Typography, Button } from '@mui/material';
import { shortDateTime } from '../../../helpers/DateFormatHelper';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import ExpandArrowIcon from '../ExpandArrowIcon';

const jsUcfirst = string => string.charAt(0).toUpperCase() + string.slice(1);

function ApplicantNote({
    applicantNote,
    deleteNote,
}) {
    const [height, setHeight] = useState(0);
    const ref = useRef(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [noteId, setNoteId] = useState('');

    useEffect(() => {
        if (ref.current && ref.current.clientHeight) {
            setHeight(ref.current.clientHeight);
        }
    }, []);

    return (
        <>
            <div className="applicantNote" style={{ display: 'flex' }}>
                {
                    height > 150 ? (
                        <div
                            style={{ cursor: 'pointer', width: '25px' }}
                            onClick={() => setExpanded(!expanded)}
                            onKeyPress={() => setExpanded(!expanded)}
                            role="button"
                            tabIndex={0}
                        >
                            <ExpandArrowIcon expanded={expanded} />
                        </div>
                    ) : (
                        <div style={{ width: '25px' }} />
                    )
                }
                <div style={{ flex: 1, maxWidth: '95%' }}>
                    {
                        applicantNote.sub_type !== 'hubspot'
                        && (
                            <Button style={{ float: 'right' }} variant="outlined" onClick={() => { setConfirmationModalOpen(true); setNoteId(applicantNote.id); }}>Delete</Button>

                        )
                    }
                    <Typography variant="h6" onClick={() => setExpanded(!expanded)} style={{ cursor: 'pointer' }}>
                        {`By: ${applicantNote.employee.first_name} ${applicantNote.employee.last_name}`}
                    </Typography>
                    <div
                        ref={ref}
                        style={expanded ? { maxWidth: '100%' } : { maxHeight: '151px', overflow: 'hidden', wordWrap: 'break-word' }}
                    >
                        <ReactMarkdown
                            align="left"
                            linkTarget="_blank"
                            rehypePlugins={[rehypeRaw]}
                            remarkPlugins={[remarkGfm]}
                        >
                            {applicantNote.content}
                        </ReactMarkdown>
                    </div>
                    {
                        !expanded && height > 150 && (
                            <div
                                className="fade"
                                onClick={() => setExpanded(!expanded)}
                                onKeyPress={() => setExpanded(!expanded)}
                                role="button"
                                tabIndex={0}
                            />
                        )
                    }
                    <Typography variant="body2">{` ${jsUcfirst(applicantNote.sub_type)} note - ${shortDateTime(applicantNote.created_at)}`}</Typography>
                </div>
            </div>
            <Divider />
            <ConfirmationModal
                open={confirmationModalOpen}
                dialogTitle="Delete Note"
                dialogMessage={"You're about to delete a note. Would you like to continue?"}
                onCancel={() => setConfirmationModalOpen(false)}
                onConfirm={() => { deleteNote(noteId); setConfirmationModalOpen(false); }}
            />
        </>
    );
}

ApplicantNote.propTypes = {
    applicantNote: PropTypes.instanceOf(Object).isRequired,
    deleteNote: PropTypes.func.isRequired,
};
export default ApplicantNote;
