import { combineReducers } from 'redux';

const studentNotes = (state = { comments: [] }, action) => {
    switch (action.type) {
    case 'SET_HUBSPOT_STUDENT_NOTES':
        return action.payload;
    case 'UNSET_STUDENT_NOTES':
    case 'UNSET_USER':
    case 'UNSET_STUDENT':
        return { comments: [] };
    default:
        return state;
    }
};

const userCommunication = (state = { subscriptionStatuses: [] }, action) => {
    switch (action.type) {
    case 'SET_HUBSPOT_USER_COMMUNICATION':
        return action.payload;
    case 'UNSET_STUDENT_NOTES':
    case 'UNSET_USER':
    case 'UNSET_STUDENT':
        return { subscriptionStatuses: [] };
    default:
        return state;
    }
};

export default combineReducers({
    studentNotes,
    userCommunication,
});
