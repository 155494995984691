import React, { useState } from 'react';
import rehypeRaw from 'rehype-raw';
import {
    Paper, TextField, Typography, Button, Grid,
} from '@mui/material';
import { HashLink } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CodeBlock from '../../../General/CodeBlock';

const styles = {
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontSize: 13,
    },
    button: {
        marginTop: '10px',
        float: 'right',
    },
    label: {
        marginTop: '40px',
    },
    sublabel: {
        fontSize: 13,
    },
    textField: {
        marginTop: '15px',
    },
    content: {
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '64px',
        marginBottom: '250px',
    },
};

const TechnicalTab = ({
    value, index, editingTechnical, dispatch, classes, nextTab,
}) => {
    const [resumeLinkError, setResumeLinkError] = useState('');
    const [resumeExplanationError, setResumeExplanationError] = useState('');

    const handleChangeFor = propertyName => (event) => {
        const updatedApplicant = {
            ...editingTechnical,
            [propertyName]: event.target.value,
        };
        dispatch({ type: 'SET_EDITING_TECHNICAL_APPLICANT', payload: updatedApplicant });
        dispatch({ type: 'SET_UNSAVED_TECHNICAL_CHANGE', payload: true });
    };

    const validateForm = () => {
        let valid = true;
        if (!editingTechnical.resume_link || editingTechnical.link === '') {
            valid = false;
            setResumeLinkError('Link is required.');
            dispatch({
                type: 'SET_GLOBAL_MODAL',
                payload: {
                    modalOpen: true,
                    title: 'Missing Fields',
                    body: 'Please complete all required fields before continuing.',
                },
            });
        }
        if (!editingTechnical.resume_explanation || editingTechnical.resume_explanation === '') {
            valid = false;
            setResumeExplanationError('Explanation is required.');
            dispatch({
                type: 'SET_GLOBAL_MODAL',
                payload: {
                    modalOpen: true,
                    title: 'Missing Fields',
                    body: 'Please complete all required fields before continuing.',
                },
            });
        }
        return valid;
    };

    const submitTechnicalInformation = (event) => {
        event.preventDefault();
        const valid = validateForm();
        if (!valid) {
            return;
        }
        setResumeLinkError('');
        setResumeExplanationError('');

        dispatch({
            type: 'PUT_APPLICANT_TECHNICAL_INFORMATION',
            payload: {
                editingTechnical,
                nextTab,
            },
        });
        dispatch({ type: 'UNSET_UNSAVED_TECHNICAL_CHANGE', payload: false });
    };

    return (
        <>
            { value === index && (
                <Paper className={classes.content}>
                    {
                        editingTechnical.course && (
                            <>
                                {
                                    editingTechnical.course_id === 1
                                    && (
                                        <>
                                            <Typography variant="h2">
                                                Admission: Resume challenge
                                            </Typography>
                                            <br />
                                            <HashLink to="#about">
                                                <span role="img" aria-label="cloud">💭 </span>
                                                About the challenge
                                            </HashLink>
                                            <br />
                                            <HashLink to="#before-you-start">
                                                <span role="img" aria-label="clock">⏰ </span>
                                                Before you start
                                            </HashLink>
                                            <br />
                                            <HashLink to="#requirements">
                                                <span role="img" aria-label="mouse">🖱️ </span>
                                                Submission Requirements
                                            </HashLink>
                                            <br />
                                            <HashLink to="#resources">
                                                <span role="img" aria-label="green circle">🟢 </span>
                                                Coding resources to explore
                                            </HashLink>
                                            <br />
                                            <HashLink to="#submission-guide">
                                                <span role="img" aria-label="paper clip">🖇️ </span>
                                                Submission Guide
                                            </HashLink>
                                            <br />
                                            <HashLink to="#take-it-further">
                                                <span role="img" aria-label="graph">📈 </span>
                                                Take it further
                                            </HashLink>
                                            <br />
                                            <HashLink to="#submit">
                                                <span role="img" aria-label="check mark">✅ </span>
                                                What to submit
                                            </HashLink>
                                            <br />
                                            <br />
                                        </>
                                    )
                                }
                                <ReactMarkdown
                                    align="left"
                                    remarkPlugins={[remarkGfm]}
                                    components={{ code: CodeBlock }}
                                    rehypePlugins={[rehypeRaw]}
                                    linkTarget="_blank"
                                >
                                    {editingTechnical.course.technical_challenge_description}
                                </ReactMarkdown>
                                {
                                    editingTechnical.course_id === 2
                                    && (
                                        <Typography variant="h4" className={classes.label}>
                                            Show us your work
                                        </Typography>
                                    )
                                }
                                <form onSubmit={submitTechnicalInformation}>
                                    <TextField
                                        className={classes.textField}
                                        label={editingTechnical.course_id === 1
                                            ? 'https://exampleuser.github.io/'
                                            : 'https://spark.adobe.com/video/'
                                        }
                                        required
                                        variant="outlined"
                                        type="url"
                                        fullWidth
                                        helperText={editingTechnical.course_id === 1
                                            ? 'Example: https://exampleuser.github.io/'
                                            : 'Example: https://spark.adobe.com/video/'
                                        }
                                        value={editingTechnical.resume_link}
                                        onChange={handleChangeFor('resume_link')}
                                    />
                                    <>
                                        {
                                            editingTechnical.course_id === 1
                                            && (
                                                <>
                                                    <Typography variant="h4" className={classes.label}>
                                                        What Did You Learn?
                                                    </Typography>
                                                    <Typography>
                                                        Please tell us a bit about
                                                        what you learned through this
                                                        project. Was it all new to you?
                                                        If so, how’d it go? Did you already
                                                        know this stuff? If so, what did
                                                        you try that was new? Reflecting on
                                                        your learning will be a big part of
                                                        day-to-day life at Prime.
                                                    </Typography>
                                                </>
                                            )
                                        }
                                        {
                                            (
                                                editingTechnical.course_id === 2
                                                || editingTechnical.course_id === 3
                                            ) && (
                                                <>
                                                    <Typography variant="h4" className={classes.label}>
                                                        Provide rationale
                                                    </Typography>
                                                    <Typography>
                                                        Explain your submission. Why did you make
                                                        the decisions
                                                        (e.g., layout, structure, content)
                                                        that you made?
                                                    </Typography>
                                                </>
                                            )
                                        }

                                        <TextField
                                            multiline
                                            required
                                            maxRows={10}
                                            variant="outlined"
                                            fullWidth
                                            helperText="Please limit your response to five sentences or less."
                                            value={editingTechnical.resume_explanation}
                                            onChange={handleChangeFor('resume_explanation')}
                                        />
                                        {
                                            resumeExplanationError && resumeExplanationError !== '' && (
                                                <Typography className={classes.errorText}>
                                                    {resumeExplanationError}
                                                </Typography>
                                            )
                                        }
                                    </>
                                    {
                                        resumeLinkError && resumeLinkError !== '' && (
                                            <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                                                <Typography className={classes.errorText}>
                                                    {resumeLinkError}
                                                </Typography>
                                            </Grid>
                                        )
                                    }
                                    <br />
                                    <br />
                                    <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                        >
                                            Save Your Application and Continue
                                        </Button>
                                    </Grid>
                                </form>
                            </>
                        )
                    }
                </Paper>
            )}
        </>
    );
};

TechnicalTab.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    editingTechnical: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    nextTab: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    editingTechnical: state.applicant.editingTechnicalInformation,
});

export default connect(mapStateToProps)(withStyles(styles)(TechnicalTab));
