import { combineReducers } from 'redux';

// Modal that appears on the assignment page
const drawerOpen = (state = false, action) => {
    switch (action.type) {
    case 'SET_DRAWER_STATE':
        return action.payload.drawerOpen;
    default:
        return state;
    }
};

export default combineReducers({
    drawerOpen,
});
