import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import ReactSelect from 'react-select';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

const styles = theme => ({
    wrapper: {
        boxShadow: 2,
        borderRadius: '8px',
        margin: '16px 0',
    },
    heading: {
        fontWeight: 500,
        color: 'black',
        padding: '16px',
    },
    copy: {
        padding: '28px 16px',
    },
    score: {
        maxWidth: '200px',
        marginBottom: '10px ',
        paddingLeft: '16px',
        paddingBottom: '16px',
    },
});

const InterviewSummaryItem = ({
    classes,
    section,
    interviewScores,
    applicationInterviewReview,
}) => {
    const [score, setScore] = useState(0);
    const [content, setContent] = useState('');

    useEffect(() => {
        const review = applicationInterviewReview.get(section.questionId);
        if (review?.interview_note) {
            setContent(review.interview_note);
        }
        if (review?.score) {
            const scoreToSet = interviewScores.find((item) => item.value === review.score);
            setScore(scoreToSet);
        }
    }, []);
    return (
        <Box sx={{ boxShadow: 2 }} className={classes.wrapper}>
            <Box sx={{ background: section.color }} className={classes.headingContainer}>
                <Typography className={classes.heading} variant="h4">
                    {section.header}
                </Typography>
            </Box>
            <Box className={classes.copy}>
                <ReactMarkdown
                    align="left"
                    linkTarget="_blank"
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                >
                    {content}
                </ReactMarkdown>
            </Box>
            <ReactSelect
                placeholder=""
                isDisabled
                value={score}
                options={interviewScores}
                className={classes.score}
                styles={{
                // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                }}
            />

        </Box>
    );
};

InterviewSummaryItem.propTypes = {

    classes: PropTypes.instanceOf(Object).isRequired,
    section: PropTypes.instanceOf(Object).isRequired,
    interviewScores: PropTypes.instanceOf(Array).isRequired,
    applicationInterviewReview: PropTypes.instanceOf(Map).isRequired,

};
const mapStateToProps = state => ({
    application: state.application,
    interviewScores: state.application.interviewScores,
    applicationInterviewReview: state.application.applicationInterviewReview,

});
export default connect(mapStateToProps)(withStyles(styles)(InterviewSummaryItem));
