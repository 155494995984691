import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Hidden,
    Button,
    FormControlLabel,
    Typography,
    Grid,
} from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CohortFeedbackRow from './CohortFeedbackRow';
import CohortFeedbackModule from './CohortFeedbackModule';
import FeedbackModal from '../../Modal/FeedbackModal';
import StudentFeedbackGraph from '../../Student/StudentDetailPage/StudentFeedbackGraph/StudentFeedbackGraph';
import CohortPageTitle from '../CohortPageTitle';
import { getPhotoUrl } from '../../../helpers/PhotoHelper';

const styles = {
    feedbackTable: {
        width: '100%',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        width: 200,
    },
    participation: {
        alignSelf: 'flex-start',
        alignItems: 'flex-start',
        marginTop: -2,
        lineHeight: 1,
        '& .MuiFormControlLabel-label': {
            fontSize: 12,
        },
        '& > div': {
            padding: '6px 3px',
            fontSize: 16,
        },
    },
    userColumn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    nameText: {
        padding: '5px 10px',
    },
};

class CohortStudentsPage extends Component {
    state = {
        feedbackOn: moment(new Date()).subtract(12, 'hours').format('YYYY-MM-DD'),
        view: 'list',
    };

    componentDidMount() {
        const { dispatch, match } = this.props;
        const { feedbackOn } = this.state;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_COHORT_DETAILS', payload: { id: match.params.id } });
        dispatch({ type: 'FETCH_COHORT_FEEDBACK_SUMMARY', payload: { id: match.params.id } });
        dispatch({ type: 'FETCH_COHORT_FEEDBACK', payload: { id: match.params.id, feedbackOn } });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { feedbackOn } = this.state;
        if (nextProps.cohort) {
            const graduation = moment(nextProps.cohort.graduation);
            if (moment(feedbackOn) > graduation) {
                this.setState({
                    feedbackOn: graduation.format('YYYY-MM-DD'),
                });
            }
        }
    }

    previousDay = () => {
        const { dispatch, match } = this.props;
        const { feedbackOn } = this.state;
        let daysBackward = 1;
        if (moment(feedbackOn).weekday() === 1) {
            // Current day is Monday, subtract an extra two days.
            daysBackward += 2;
        }
        const nextDate = moment(feedbackOn).subtract(daysBackward, 'day').format('YYYY-MM-DD');
        this.setState({
            feedbackOn: nextDate,
        });
        dispatch({ type: 'FETCH_COHORT_FEEDBACK', payload: { id: match.params.id, feedbackOn: nextDate } });
    };

    nextDay = () => {
        const { dispatch, match } = this.props;
        const { feedbackOn } = this.state;
        let daysForward = 1;
        if (moment(feedbackOn).weekday() === 5) {
            // Current day is Monday, subtract an extra two days.
            daysForward += 2;
        }
        const nextDate = moment(feedbackOn).add(daysForward, 'day').format('YYYY-MM-DD');
        this.setState({
            feedbackOn: nextDate,
        });
        dispatch({ type: 'FETCH_COHORT_FEEDBACK', payload: { id: match.params.id, feedbackOn: nextDate } });
    };

    handleInputChange = (event) => {
        const { dispatch, match } = this.props;
        const nextDate = moment(event.target.value).format('YYYY-MM-DD');
        this.setState({
            feedbackOn: nextDate,
        });
        dispatch({ type: 'FETCH_COHORT_FEEDBACK', payload: { id: match.params.id, feedbackOn: nextDate } });
    };

    viewFeedbackDetails = (selectedFeedback, index) => () => {
        const { dispatch } = this.props;
        dispatch({ type: 'SET_SELECTED_DAILY_FEEDBACK_INDEX', payload: { index } });
        dispatch({ type: 'SET_DAILY_FEEDBACK_MODAL', payload: { modalOpen: true } });
        dispatch({ type: 'SET_SELECTED_DAILY_FEEDBACK', payload: selectedFeedback });
    };

    previousFeedback = () => {
        const { dispatch, feedback, selectedFeedbackIndex } = this.props;
        let nextIndex = selectedFeedbackIndex - 1;
        if (nextIndex < 0) {
            nextIndex = feedback.length - 1;
        }
        dispatch({ type: 'SET_SELECTED_DAILY_FEEDBACK_INDEX', payload: { index: nextIndex } });
        const selectedFeedback = feedback[nextIndex];
        dispatch({ type: 'SET_SELECTED_DAILY_FEEDBACK', payload: selectedFeedback });
    };

    nextFeedback = () => {
        const { dispatch, feedback, selectedFeedbackIndex } = this.props;
        let nextIndex = selectedFeedbackIndex + 1;
        if (nextIndex >= feedback.length) {
            nextIndex = 0;
        }
        dispatch({ type: 'SET_SELECTED_DAILY_FEEDBACK_INDEX', payload: { index: nextIndex } });
        const selectedFeedback = feedback[nextIndex];
        dispatch({ type: 'SET_SELECTED_DAILY_FEEDBACK', payload: selectedFeedback });
    };

    handleToggleChange = () => {
        const { view } = this.state;
        if (view === 'list') {
            this.setState({
                view: 'module',
            });
        } else {
            this.setState({
                view: 'list',
            });
        }
    };

    render() {
        // const { students } = this.props;
        const {
            user,
            cohort,
            feedback,
            modalOpen,
            selectedFeedback,
            feedbackDataset,
            classes,
        } = this.props;
        const { feedbackOn, view } = this.state;
        const completedFeedback = feedback.filter(item => item.understanding_score);
        const incompleteFeedback = feedback.filter(item => !item.understanding_score);
        return (
            <div className="container">
                <CohortPageTitle
                    cohort={cohort}
                    title="Reflection"
                />
                <Hidden mdDown>
                    <>
                        {
                            feedbackDataset
                            && feedbackDataset.datasets
                            // At least two days of feedback to show a trend
                            && feedbackDataset.datasets.length > 1
                            && <StudentFeedbackGraph feedbackDataset={feedbackDataset} />
                        }
                    </>
                </Hidden>
                <Paper>
                    <div>
                        <ToggleButtonGroup
                            style={{ float: 'right' }}
                            value={view}
                            exclusive
                            onChange={this.handleToggleChange}
                        >
                            <ToggleButton value="list" aria-label="list">
                                <ViewListIcon />
                                <Typography style={{ paddingLeft: 8 }}>List</Typography>
                            </ToggleButton>
                            <ToggleButton value="module" aria-label="module">
                                <ViewModuleIcon />
                                <Typography style={{ paddingLeft: 8 }}>Grid</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <form className={classes.container} noValidate>
                            <TextField
                                id="date"
                                label="Reflection Date"
                                type="date"
                                value={feedbackOn}
                                onChange={this.handleInputChange}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <FormControlLabel
                                className={classes.participation}
                                label="Participation"
                                labelPlacement="top"
                                control={(
                                    <div>
                                        {feedback.filter(f => f.feedback_id !== null).length}
                                        {' / '}
                                        {feedback.length}
                                    </div>
                                )}
                            />
                        </form>
                        <div style={{ padding: 8 }}>
                            <Button onClick={this.previousDay}>Previous</Button>
                            <Button
                                onClick={this.nextDay}
                                disabled={moment(feedbackOn) >= moment(cohort.graduation)}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                    { view === 'module' ? (
                        <div>
                            <Grid container spacing={2}>
                                {
                                    completedFeedback.map((studentFeedback, i) => (
                                        <CohortFeedbackModule
                                            key={studentFeedback.student_id}
                                            user={user}
                                            studentFeedback={studentFeedback}
                                            index={i}
                                        />
                                    ))
                                }
                            </Grid>
                            {incompleteFeedback.length !== 0 && (
                                <>
                                    <Typography style={{ paddingLeft: 5, paddingTop: 30 }} variant="h5">No Response</Typography>
                                    <Table>
                                        <TableBody>
                                            {
                                                incompleteFeedback.map((studentFeedback, i) => (
                                                    <TableRow
                                                        key={studentFeedback.student_id}
                                                        className={classes.tableRow}
                                                    >
                                                        <TableCell className={classes.wideColumn}>
                                                            <div className={classes.userColumn}>
                                                                <img
                                                                    className="placeholder-photo-xs"
                                                                    src={getPhotoUrl(user.s3_bucket, studentFeedback.student_id, studentFeedback.photo_file_name, 'thumb')}
                                                                    alt={studentFeedback.first_name}
                                                                />
                                                                <Link onClick={e => e.stopPropagation()} to={`/students/${studentFeedback.student_id}`}>
                                                                    <p className={classes.nameText}>
                                                                        {studentFeedback.first_name}
                                                                        {' '}
                                                                        {studentFeedback.last_name}
                                                                        {
                                                                            studentFeedback.pronouns
                                                                            && ` (${studentFeedback.pronouns})`
                                                                        }
                                                                    </p>
                                                                </Link>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </>
                            )}
                        </div>
                    ) : (
                        <Table className={classes.feedbackTable}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.wideColumn}>Name</TableCell>
                                    <Hidden mdDown>
                                        <TableCell>Comprehension</TableCell>
                                        <TableCell>Support</TableCell>
                                        <TableCell>One Word</TableCell>
                                        <TableCell>Thanks</TableCell>
                                        <TableCell>Suggest</TableCell>
                                        <TableCell>Accomplishment</TableCell>
                                    </Hidden>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    feedback.map((studentFeedback, i) => (
                                        <CohortFeedbackRow
                                            key={studentFeedback.student_id}
                                            user={user}
                                            studentFeedback={studentFeedback}
                                            index={i}
                                            viewFeedbackDetails={this.viewFeedbackDetails}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    )}
                </Paper>
                <FeedbackModal
                    feedbackOn={feedbackOn}
                    open={modalOpen}
                    feedback={selectedFeedback}
                    closeActionType="SET_DAILY_FEEDBACK_MODAL"
                    next={this.nextFeedback}
                    previous={this.previousFeedback}
                />
            </div>
        );
    }
}

CohortStudentsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        s3_bucket: PropTypes.string.isRequired,
    }).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    feedback: PropTypes.instanceOf(Array).isRequired,
    modalOpen: PropTypes.bool.isRequired,
    selectedFeedbackIndex: PropTypes.number.isRequired,
    selectedFeedback: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    feedbackDataset: PropTypes.instanceOf(Object).isRequired,
};

// Instead of taking everything from state, we just want the user info.
// if you wanted you could write this code like this:
// const mapStateToProps = ({user}) => ({ user });
const mapStateToProps = state => ({
    user: state.user,
    cohort: state.cohort.cohort,
    feedback: state.cohort.feedback,
    selectedFeedback: state.feedback.selectedFeedback,
    selectedFeedbackIndex: state.feedback.selectedFeedbackIndex,
    modalOpen: state.feedback.modalOpen,
    feedbackDataset: state.cohort.feedbackDataset,
});

// this allows us to use <App /> in index.js
export default connect(mapStateToProps)(withStyles(styles)(CohortStudentsPage));
