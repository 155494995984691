import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prompt } from 'react-router-dom';
import {
    Paper,
    DialogActions,
    Button,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import 'react-mde/lib/styles/css/react-mde-editor.css';
import 'react-mde/lib/styles/css/react-mde-toolbar.css';
import 'react-mde/lib/styles/css/react-mde.css';
import PageTitle from '../../PageTitle/PageTitle';
import { cohortOptionSelect } from '../../../helpers/CohortTextHelper';
import CodeBlock from '../../General/CodeBlock';

const styles = theme => ({
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
    },
    /**
    * React doesn't provide an auto complete input field. Styling this third
    * party component takes a bit more work that standard Material UI components.
    * https://react-select.com/styles#using-classnames
    */
    cohortSelect: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '0px',
        },
        '& .react-select__control': {
            height: '39px',
            borderRadius: '3px',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
        '& .react-select__multi-value__label': {
            fontSize: '16px',
        },
        '& .react-select__multi-value__remove': {
            cursor: 'pointer',
        },
        '& .react-select__menu': {
            zIndex: '1000',
        },
    },
    selectContainer: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
});

class CreateAssignmentPage extends Component {
    constructor(props) {
        super(props);
        const { match, history, dispatch } = this.props;
        let existing = false;
        if (match.params.id) {
            existing = true;
        }
        this.state = {
            changes: false,
            // editing is set to true if editing an existing assignment
            existing,
            errorText: '',
            selectedTab: 'write',
        };

        if (existing) {
            // TODO: Fetch assignment details, just in case we
            // navigated here directly.
        }

        if (history.state) {
            dispatch({ type: 'SET_NEW_ASSIGNMENT_COHORT', payload: history.state });
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_COHORT_LIST' });
        dispatch({ type: 'FETCH_TAG_LIST' });
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            dispatch,
            assignment,
        } = this.props;
        if (this.isNewAssignment()
            && !assignment.cohortId
            && nextProps.newAssignmentCohort
            && nextProps.newAssignmentCohort.value > 0) {
            const updatedAssignment = {
                ...assignment,
                cohortId: nextProps.newAssignmentCohort.value,
                cohort: nextProps.newAssignmentCohort,
            };
            dispatch({ type: 'SET_EDITING_ASSIGNMENT', payload: updatedAssignment });
        }
    }

    handleSubmit = () => {
        const { dispatch, assignment, history } = this.props;
        const valid = this.validateForm();
        if (!valid) {
            return;
        }
        // TODO: Update changes after a successful save. Right now we're assuming
        // success.
        this.setState({
            changes: false,
        });
        if (this.isNewAssignment()) {
            const action = {
                type: 'POST_ASSIGNMENT',
                payload: assignment,
                history,
            };
            dispatch(action);
        } else {
            const action = {
                type: 'PUT_ASSIGNMENT',
                payload: assignment,
                history,
            };
            dispatch(action);
        }
    };

    handleCancel = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleMdeChange = (value) => {
        const { dispatch, assignment } = this.props;
        const updatedAssignment = {
            ...assignment,
            notes: value,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_EDITING_ASSIGNMENT', payload: updatedAssignment });
    };

    setSelectedTab = (value) => {
        this.setState({
            selectedTab: value,
        });
    };

    handleChangeFor = propertyName => (event) => {
        const { dispatch, assignment } = this.props;
        const updatedAssignment = {
            ...assignment,
            [propertyName]: event.target.value,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_EDITING_ASSIGNMENT', payload: updatedAssignment });
    };

    handleCohortSelect = (selectedOption) => {
        const { dispatch, assignment } = this.props;
        const updatedAssignment = {
            ...assignment,
            cohortId: selectedOption.value,
            cohort: selectedOption,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_EDITING_ASSIGNMENT', payload: updatedAssignment });
    };

    handleTagSelect = (selectedTags) => {
        const { dispatch, assignment } = this.props;
        const updatedAssignment = {
            ...assignment,
            tags: selectedTags,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_EDITING_ASSIGNMENT', payload: updatedAssignment });
    };

    validateForm = () => {
        const { assignment } = this.props;
        let valid = true;
        if (assignment.name === '') {
            valid = false;
            this.setState({
                errorText: 'Assignment name is required.',
            });
        } else if (!assignment.cohortId || assignment.cohortId === '') {
            valid = false;
            this.setState({
                errorText: 'Cohort is required.',
            });
        }
        return valid;
    };

    isNewAssignment() {
        const { match } = this.props;
        if (match.params.id) {
            return false;
        }
        return true;
    }

    render() {
        const {
            classes,
            cohorts,
            assignment,
            tags,
        } = this.props;
        const {
            errorText,
            changes,
            existing,
            selectedTab,
        } = this.state;
        const cohortOptions = cohortOptionSelect(cohorts);
        let pageTitle = 'Create New Assignment';
        if (existing) {
            pageTitle = `Edit Assignment: ${assignment.name}`;
        }
        return (
            <>
                {/*
                    For a custom rendered modal, we may want to switch to this:
                    https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
                */}
                <Prompt
                    // Use a standard alert dialog if the user leaves the page when
                    // changes are detected.
                    when={changes}
                    message="You have unsaved edits. Are you sure you want to leave?"
                />
                <div className="container">
                    <PageTitle
                        title={pageTitle}
                    />
                    <Paper>
                        {errorText.length > 0 && (
                            <div className={classes.errorText}>{errorText}</div>
                        )}
                        <div className={classes.feedbackContainer}>
                            <form>
                                <TextField
                                    required
                                    id="standard-name"
                                    label="Assignment Name"
                                    className={classes.textField}
                                    value={assignment.name}
                                    onChange={this.handleChangeFor('name')}
                                    margin="normal"
                                />
                                <RadioGroup
                                    required
                                    aria-label="assignment_type"
                                    name="assignment_type"
                                    value={assignment.assignment_type}
                                    onChange={this.handleChangeFor('assignment_type')}
                                    row
                                >
                                    <FormControlLabel
                                        value="not_graded"
                                        control={<Radio color="primary" />}
                                        label="Not Graded"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="graded"
                                        control={<Radio color="primary" />}
                                        label="Graded"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                                <div className={classes.selectContainer} style={{ display: 'inline-block' }}>
                                    <ReactSelect
                                        placeholder="Cohort...*"
                                        // Do not allow editing of cohort for existing assignments
                                        isDisabled={!this.isNewAssignment()}
                                        // ReactSelect requires the whole object, not just value!
                                        value={assignment.cohort}
                                        onChange={this.handleCohortSelect}
                                        options={cohortOptions}
                                        className={classes.cohortSelect}
                                        classNamePrefix="react-select"
                                    />
                                </div>
                                <br />
                                <br />
                                <ReactSelect
                                    isMulti
                                    name="tags"
                                    placeholder="Tags..."
                                    onChange={this.handleTagSelect}
                                    options={tags}
                                    value={assignment.tags}
                                    style={{ zIndex: 999 }}
                                    className={classes.cohortSelect}
                                    classNamePrefix="react-select"
                                />
                                <TextField
                                    id="standard-repository"
                                    label="Git Repo / Dropbox URL"
                                    value={assignment.repository}
                                    onChange={this.handleChangeFor('repository')}
                                    className={classes.textField}
                                    margin="normal"
                                />
                                <br />
                                <br />
                                <br />
                                <InputLabel>Description:</InputLabel>
                                <br />
                                <br />
                                <ReactMde
                                    value={assignment.notes}
                                    onChange={this.handleMdeChange}
                                    selectedTab={selectedTab}
                                    onTabChange={this.setSelectedTab}
                                    generateMarkdownPreview={markdown => (
                                        Promise.resolve(
                                            <ReactMarkdown
                                                components={{ code: CodeBlock }}
                                                remarkPlugins={[remarkGfm]}
                                            >
                                                {markdown}
                                            </ReactMarkdown>,
                                        )
                                    )}
                                />
                            </form>
                        </div>
                        <br />
                        <br />
                        {errorText.length > 0 && (
                            <div className={classes.errorText}>{errorText}</div>
                        )}
                        <DialogActions>
                            <Button
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleSubmit}
                            >
                                { existing ? <span>Save</span> : <span>Create</span> }
                            </Button>
                        </DialogActions>
                    </Paper>
                </div>
            </>
        );
    }
}

CreateAssignmentPage.defaultProps = {
    newAssignmentCohort: undefined,
};

CreateAssignmentPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
    cohorts: PropTypes.instanceOf(Array).isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    newAssignmentCohort: PropTypes.instanceOf(Object),
    tags: PropTypes.instanceOf(Array).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        state: PropTypes.instanceOf(Object),
    }).isRequired,
};

const mapStateToProps = state => ({
    cohorts: state.cohort.cohortList,
    assignment: state.assignments.editingAssignment,
    createdAssignment: state.assignments.assignment,
    newAssignmentCohort: state.assignments.newAssignmentCohort,
    sendingModalOpen: state.assignments.sendingModal,
    completeModalOpen: state.assignments.completeModal,
    tags: state.assignments.tags,
});
const createAssignmentModal = withStyles(styles)(CreateAssignmentPage);
export default connect(mapStateToProps)(createAssignmentModal);
