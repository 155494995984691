import React from 'react';
import {
    HashRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
// import LoginPage from '../LoginPage/LoginPage';
import LandingPage from '../LoginPage/LandingPage';
import CohortListPage from '../Cohort/CohortListPage/CohortListPage';
import CohortStudentsPage from '../Cohort/CohortStudentsPage/CohortStudentsPage';
import CohortGradesPage from '../Cohort/CohortGradePage/CohortGradesPage';
import CohortAssignmentsPage from '../Cohort/CohortAssignmentsPage/CohortAssignmentsPage';
import CohortFeedbackPage from '../Cohort/CohortFeedbackPage/CohortFeedbackPage';
import CreateCohortPage from '../Cohort/CreateCohortPage/CreateCohortPage';
import GradeCourseWorkPage from '../CourseWork/GradeCourseWorkPage';
import CoursePage from '../Courses/CoursesPage/CoursesPage';
import CourseEditPage from '../Courses/CourseEditPage/CourseEditPage';
import EmployeeListPage from '../Settings/EmployeeListPage/EmployeeListPage';
import CreateEmployeePage from '../Settings/CreateEmployeePage/CreateEmployeePage';
import StudentListPage from '../Student/StudentListPage/StudentListPage';
import StudentDetailPage from '../Student/StudentDetailPage/StudentDetailPage';
import StudentFeedbackPage from '../Student/StudentFeedbackPage/StudentFeedbackPage';
import CreateStudentPage from '../Student/CreateStudentPage/CreateStudentPage';
import AssignmentDetailPage from '../Assignment/AssignmentDetailPage/AssignmentDetailPage';
import AssignmentListPage from '../Assignment/AssignmentListPage/AssignmentListPage';
import CreateAssignmentPage from '../Assignment/CreateAssignmentPage/CreateAssignmentPage';
import StudentPortalAssignmentsPage from '../StudentPortal/StudentAssignments/StudentAssignments';
import StudentPortalFeedbackPage from '../StudentPortal/StudentFeedback/StudentFeedback';
import StudentPortalAssignmentDetail from '../StudentPortal/StudentAssignments/StudentAssignmentDetail';
import SideNav from '../SideNav/SideNav';
import Theme from './Theme';
import ErrorMessageModal from '../Modal/ErrorMessageModal';
import SavingModal from '../Modal/SavingModal';
import AuthRoute from '../General/AuthRoute';
import './App.css';
import ResetPasswordPage from '../LoginPage/ResetPasswordPage';
import ProfilePage from '../Profile/ProfilePage';
import GlobalAlertModal from '../Modal/GlobalAlertModal';
import InstructorDashboardPage from '../Dashboard/InstructorDashboard/InstructorDashboardPage';
import CareerDashboardPage from '../Dashboard/CareerDashboard/CareerDashboardPage';
import CompanyListPage from '../Company/CompanyListPage/CompanyListPage';
import CompanyDetailPage from '../Company/CompanyDetailPage/CompanyDetailPage';
import CreateCompanyPage from '../Company/CreateCompanyPage/CreateCompanyPage';
import PlacementListPage from '../Placements/PlacementListPage/PlacementListPage';
import CreatePlacementPage from '../Placements/CreatePlacementPage/CreatePlacementPage';
import StudentFeedbackDetailsPage from '../Student/StudentFeedbackDetailsPage/StudentFeedbackDetailsPage';
import ApplicationsListPage from '../Applications/ApplicationsListPage/ApplicationsListPage';
import ApplicationDetailPage from '../Applications/ApplicationDetailPage/ApplicationDetailPage';
import EditApplicationPage from '../Applications/EditApplicationPage/EditApplicationPage';
import ApplicantAppListPage from '../ApplicantPortal/ApplicationListPage/ApplicantAppListPage';
import UXApplication from '../ApplicantPortal/Application/UXApplication';
import UXAcademyApplication from '../ApplicantPortal/Application/UXAcademyApplication';
import FullStackApplication from '../ApplicantPortal/Application/FullStackApplication';
import ApplicantPortalRegistrationPage from '../ApplicantPortal/RegistrationPage/RegistrationForm';
import ReportsPage from '../Reports/ReportsPage';

const { lightTheme, darkTheme } = Theme;
const {
    STUDENT,
    EMPLOYEE,
    ADMIN,
    ADJUNCT,
    GRADER,
    STUDENT_LIFE,
    DIRECTOR,
    CAREER,
    ALL_STAFF,
    APPLICANT,
} = require('../General/UserTypes');

const App = () => (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={lightTheme}>
            <ErrorMessageModal
                closeActionType="SET_ERROR_MODAL"
            />
            <GlobalAlertModal
                closeActionType="SET_GLOBAL_MODAL"
            />
            <SavingModal />
            <Router>
                <div className="app-root">
                    <ScrollToTop />
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={darkTheme}>
                            <SideNav />
                        </ThemeProvider>
                    </StyledEngineProvider>
                    <div className="app-content">
                        {/* <TopBar className={classes.appBar} /> */}
                        <Switch>
                            <Route
                                exact
                                path="/"
                                component={LandingPage}
                            />
                            <Route
                                exact
                                path="/login"
                                component={LandingPage}
                            />
                            <Route
                                exact
                                path="/apply"
                                component={ApplicantPortalRegistrationPage}
                            />
                            <Route
                                exact
                                path="/password/reset/:token"
                                component={ResetPasswordPage}
                            />
                            <AuthRoute
                                exact
                                path="/profile"
                                Component={ProfilePage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT, GRADER, STUDENT, APPLICANT]}
                            />
                            <AuthRoute
                                exact
                                path="/cohorts"
                                Component={CohortListPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/cohorts/new"
                                Component={CreateCohortPage}
                                requiredRoles={[...ALL_STAFF]}
                            />
                            <AuthRoute
                                exact
                                path="/cohorts/:id"
                                Component={CohortStudentsPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/cohorts/:id/grades"
                                Component={CohortGradesPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/cohorts/:id/edit"
                                Component={CreateCohortPage}
                                requiredRoles={[...ALL_STAFF]}
                            />
                            <AuthRoute
                                exact
                                path="/cohorts/:id/assignments"
                                Component={CohortAssignmentsPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/cohorts/:id/feedback"
                                Component={CohortFeedbackPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/employees"
                                Component={EmployeeListPage}
                                requiredRoles={[ADMIN, DIRECTOR, CAREER]}
                            />
                            <AuthRoute
                                exact
                                path="/employees/new"
                                Component={CreateEmployeePage}
                                requiredRoles={[ADMIN, DIRECTOR, CAREER]}
                            />
                            <AuthRoute
                                exact
                                path="/employees/:id/edit"
                                Component={CreateEmployeePage}
                                requiredRoles={[ADMIN, DIRECTOR, CAREER]}
                            />
                            <AuthRoute
                                exact
                                path="/students"
                                Component={StudentListPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/students/new"
                                Component={CreateStudentPage}
                                requiredRoles={[...ALL_STAFF]}
                            />
                            <AuthRoute
                                exact
                                path="/students/:id/edit"
                                Component={CreateStudentPage}
                                requiredRoles={[...ALL_STAFF]}
                            />
                            <AuthRoute
                                exact
                                path="/students/:id"
                                Component={StudentDetailPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/students/:id/feedback"
                                Component={StudentFeedbackPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/students/:id/feedback/:feedbackId"
                                Component={StudentFeedbackDetailsPage}
                                requiredRoles={[...ALL_STAFF]}
                            />
                            <AuthRoute
                                exact
                                path="/assignments"
                                Component={AssignmentListPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT, GRADER]}
                            />
                            <AuthRoute
                                exact
                                path="/assignments/new"
                                Component={CreateAssignmentPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/assignments/:id"
                                Component={AssignmentDetailPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT, GRADER]}
                            />
                            <AuthRoute
                                exact
                                path="/assignments/:id/edit"
                                Component={CreateAssignmentPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/student/assignments"
                                Component={StudentPortalAssignmentsPage}
                                requiredRoles={[STUDENT]}
                            />
                            <AuthRoute
                                exact
                                path="/student/assignments/:id"
                                Component={StudentPortalAssignmentDetail}
                                requiredRoles={[STUDENT]}
                            />
                            <AuthRoute
                                exact
                                path="/student/reflections"
                                Component={StudentPortalFeedbackPage}
                                requiredRoles={[STUDENT]}
                            />
                            <AuthRoute
                                exact
                                path="/dashboard"
                                Component={InstructorDashboardPage}
                                requiredRoles={[EMPLOYEE, ADMIN, DIRECTOR, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/career"
                                Component={CareerDashboardPage}
                                requiredRoles={[ADMIN, CAREER, STUDENT_LIFE, DIRECTOR]}
                            />
                            <AuthRoute
                                exact
                                path="/coursework/:id/grade"
                                Component={GradeCourseWorkPage}
                                requiredRoles={[...ALL_STAFF, ADJUNCT]}
                            />
                            <AuthRoute
                                exact
                                path="/companies"
                                Component={CompanyListPage}
                                requiredRoles={[ADMIN, CAREER]}
                            />
                            <AuthRoute
                                exact
                                path="/companies/new"
                                Component={CreateCompanyPage}
                                requiredRoles={[ADMIN, CAREER]}
                            />
                            <AuthRoute
                                exact
                                path="/companies/:id"
                                Component={CompanyDetailPage}
                                requiredRoles={[ADMIN, CAREER]}
                            />
                            <AuthRoute
                                exact
                                path="/placements"
                                Component={PlacementListPage}
                                requiredRoles={[...ALL_STAFF]}
                            />
                            <AuthRoute
                                exact
                                path="/companies/:id/edit"
                                Component={CreateCompanyPage}
                                requiredRoles={[ADMIN, CAREER]}
                            />
                            <AuthRoute
                                exact
                                path="/admissions_applications"
                                Component={ApplicationsListPage}
                                requiredRoles={[ADMIN, CAREER, STUDENT_LIFE, DIRECTOR]}
                            />
                            <AuthRoute
                                exact
                                path="/admissions_applications/:id"
                                Component={ApplicationDetailPage}
                                requiredRoles={[ADMIN, CAREER, STUDENT_LIFE, DIRECTOR]}
                            />
                            <AuthRoute
                                exact
                                path="/admissions_applications/:id/edit"
                                Component={EditApplicationPage}
                                requiredRoles={[ADMIN, CAREER, STUDENT_LIFE, DIRECTOR]}
                            />
                            <AuthRoute
                                exact
                                path="/admissions_applications/:id/tab/:tab"
                                Component={ApplicationDetailPage}
                                requiredRoles={[ADMIN, CAREER, STUDENT_LIFE, DIRECTOR]}
                            />
                            <AuthRoute
                                exact
                                path="/placements/new"
                                Component={CreatePlacementPage}
                                requiredRoles={[ADMIN, CAREER]}
                            />
                            <AuthRoute
                                exact
                                path="/placements/edit/:id"
                                Component={CreatePlacementPage}
                                requiredRoles={[ADMIN, CAREER]}
                            />
                            <AuthRoute
                                exact
                                path="/reports"
                                Component={ReportsPage}
                                requiredRoles={[ADMIN, DIRECTOR]}
                            />
                            <AuthRoute
                                exact
                                path="/courses"
                                Component={CoursePage}
                                requiredRoles={[ADMIN, DIRECTOR]}
                            />
                            <AuthRoute
                                exact
                                path="/courses/:id"
                                Component={CourseEditPage}
                                requiredRoles={[ADMIN, DIRECTOR]}
                            />
                            <AuthRoute
                                exact
                                path="/applications"
                                Component={ApplicantAppListPage}
                                requiredRoles={[APPLICANT]}
                            />
                            <AuthRoute
                                exact
                                path="/courses/ux"
                                Component={UXApplication}
                                requiredRoles={[APPLICANT]}
                            />
                            <AuthRoute
                                exact
                                path="/courses/ux/apply"
                                Component={UXApplication}
                                requiredRoles={[APPLICANT]}
                            />
                            <AuthRoute
                                exact
                                path="/courses/ux/apply/:step"
                                Component={UXApplication}
                                requiredRoles={[APPLICANT]}
                            />
                            <AuthRoute
                                exact
                                path="/courses/engineering"
                                Component={FullStackApplication}
                                requiredRoles={[APPLICANT]}
                            />
                            <AuthRoute
                                exact
                                path="/courses/engineering/apply"
                                Component={FullStackApplication}
                                requiredRoles={[APPLICANT]}
                            />
                            <AuthRoute
                                exact
                                path="/courses/engineering/apply/:step"
                                Component={FullStackApplication}
                                requiredRoles={[APPLICANT]}
                            />
                            <AuthRoute
                                exact
                                path="/courses/ux-academy"
                                Component={UXAcademyApplication}
                                requiredRoles={[APPLICANT]}
                            />
                            <AuthRoute
                                exact
                                path="/courses/ux-academy/apply"
                                Component={UXAcademyApplication}
                                requiredRoles={[APPLICANT]}
                            />
                            <AuthRoute
                                exact
                                path="/courses/ux-academy/apply/:step"
                                Component={UXAcademyApplication}
                                requiredRoles={[APPLICANT]}
                            />
                            {/* If none of the other routes matched, we will show a 404. */}
                            <Route render={() => <h1>404</h1>} />
                        </Switch>
                    </div>
                </div>
            </Router>
        </ThemeProvider>
    </StyledEngineProvider>
);

export default App;
