import axios from 'axios';
import { put, takeLatest, all } from 'redux-saga/effects';
import { getPlacementQueryParams } from '../../helpers/QueryParamsHelper';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* fetchPlacements(action) {
    try {
        const params = getPlacementQueryParams(action.payload);
        const [
            placementsRes,
            statsRes,
            summaryRes,
        ] = yield all([
            axios.get('/api/placements', { params }),
            axios.get('/api/placements/stats', { params }),
            axios.get('/api/placements/summary', {
                params: { ...params, groupBy: 'placement_type' },
            }),
        ]);
        yield put({ type: 'SET_PLACEMENTS', payload: placementsRes.data });
        yield put({ type: 'SET_PLACEMENT_STATS', payload: statsRes.data });
        yield put({ type: 'SET_PLACEMENTS_BY_TYPE', payload: summaryRes.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchCompanyPlacements(action) {
    try {
        const response = yield axios.get(`api/companies/placements/${action.payload.companyId}`, { params: action.payload });
        yield put({ type: 'SET_COMPANY_PLACEMENTS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* createPlacement(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const placement = action.payload;
        yield axios.post('/api/placements', placement);

        yield put({ type: 'FETCH_PLACEMENTS' });
        // // Move to page for company created
        yield delay(250);
        yield action.history.replace('/placements/');
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* fetchPlacementForEdit(action) {
    try {
        const response = yield axios.get(`api/placements/details/${action.payload.placementId}`);
        yield put({ type: 'SET_EDITING_PLACEMENT', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* updatePlacement(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put('api/placements/update', action.payload);

        // Move to page fostudent created
        yield delay(250);
        yield action.history.replace('/placements');
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* deletePlacement(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.delete(`api/placements/delete/${action.payload.studentId}/${action.payload.placementId}`);
        yield delay(250);
        yield action.history.replace('/placements');
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* placementSaga() {
    yield takeLatest('FETCH_COMPANY_PLACEMENTS', fetchCompanyPlacements);
    yield takeLatest('FETCH_PLACEMENTS', fetchPlacements);
    yield takeLatest('CREATE_PLACEMENT', createPlacement);
    yield takeLatest('FETCH_PLACEMENT_FOR_EDIT', fetchPlacementForEdit);
    yield takeLatest('PUT_PLACEMENT', updatePlacement);
    yield takeLatest('DELETE_STUDENT_PLACEMENT', deletePlacement);
}
export default placementSaga;
