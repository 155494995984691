// Currently logged in employee
const user = (state = { first_name: '', last_name: '', loading: true }, action) => {
    // let copy;
    switch (action.type) {
    case 'SET_USER':
        return action.payload;
    case 'UNSET_USER':
        return { first_name: '', last_name: '', loading: false };
    default:
        return state;
    }
};

export default user;
