import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { Prompt } from 'react-router-dom';
import { readAndCompressImage } from 'browser-image-resizer';

import {
    Paper,
    DialogActions,
    Button,
    TextField,
    RadioGroup,
    Checkbox,
    FormControlLabel,
    FormLabel,
    Radio,
    Grid,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PageTitle from '../../PageTitle/PageTitle';
import { cohortOptionSelect } from '../../../helpers/CohortTextHelper';
// TODO: Add this back when we're ready to transition away from Trello
// import { studentStatusTags, currentStatusSelect } from '../../../helpers/StudentStatusHelper';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import { getPhotoUrl } from '../../../helpers/PhotoHelper';
import ChangePasswordModal from '../../Modal/ChangePasswordModal';
import StateAutocomplete from '../../../helpers/StateAutocomplete';

function makePassword(length) {
    let result = '';
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const imageConfig = {
    quality: 1.0,
    maxHeight: 300,
};

const styles = theme => ({
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
    },
    /**
    * React doesn't provide an auto complete input field. Styling this third
    * party component takes a bit more work that standard Material UI components.
    * https://react-select.com/styles#using-classnames
    */
    cohortSelect: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '0px',
        },
        '& .react-select__control': {
            height: '39px',
            borderRadius: '3px',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
        '& .react-select__multi-value__label': {
            fontSize: '16px',
        },
        '& .react-select__multi-value__remove': {
            cursor: 'pointer',
        },
        '& .react-select__menu': {
            zIndex: '1000',
        },
    },
    passwordText: {
        marginTop: '36px',
        fontSize: '16px',
    },
    selectContainer: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
});

class CreateStudentPage extends Component {
    constructor(props) {
        super(props);
        const { match, dispatch } = this.props;
        let existing = false;
        if (match.params.id) {
            existing = true;
        }
        this.state = {
            changes: false,
            // editing is set to true if editing an existing student
            existing,
            errorText: '',
            confirmationModalOpen: false,
            previousCohort: '',
            password: makePassword(8),
        };

        if (existing) {
            dispatch({ type: 'FETCH_STUDENT_FOR_EDIT', payload: { studentId: match.params.id } });
        }
    }

    componentDidMount() {
        const { dispatch, history } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_COHORT_LIST' });
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        this.unlisten = history.listen(this.onRouteChange);
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_EDITING_STUDENT' });
        this.unlisten();
    }

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = (location) => {
        const { dispatch, match, history } = this.props;

        // If we're not actually changing routes,
        // do not refetch data. This happens if user hits "back", then
        // "cancel" at the unsaved changes prompt
        if (location.pathname === history.location.pathname) {
            return;
        }

        if (!this.isNewStudent()) {
            dispatch({ type: 'FETCH_STUDENT_FOR_EDIT', payload: { studentId: match.params.id } });
        }
    };

    handleSubmit = () => {
        const { student } = this.props;
        const { previousCohort } = this.state;
        const valid = this.validateForm();
        if (!valid) {
            return;
        }
        // The previousCohort value is only set when editing a student. If the value
        // doesn't match the cohort_name, that means the cohort was changed.
        if (previousCohort !== '' && previousCohort !== student.cohort_name) {
            // Show confirmation dialog
            this.setState({ confirmationModalOpen: true });
        } else {
            this.sendFormDataToServer();
        }
    };

    sendFormDataToServer = () => {
        const { dispatch, student, history } = this.props;
        const {
            password, selectedFile, resizedFile,
        } = this.state;
        const valid = this.validateForm();
        if (!valid) {
            return;
        }

        // hubspot_id is type integer, cannot accept empty string
        if (student.hubspot_id === '') {
            student.hubspot_id = null;
        }

        // TODO: Update changes after a successful save. Right now we're assuming
        // success.
        this.setState({
            changes: false,
        });
        let action;
        // The file name seems to be dropped on resize, send both the
        // original and resized files.
        if (this.isNewStudent()) {
            action = {
                type: 'CREATE_STUDENT',
                payload: {
                    ...student,
                    password,
                    selectedFile,
                    resizedFile,
                },
                history,
            };
        } else {
            action = {
                type: 'PUT_STUDENT',
                payload: { ...student, selectedFile, resizedFile },
                history,
            };
        }
        dispatch(action);
    };

    handleCancel = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleChangeFor = propertyName => (event) => {
        const { dispatch, student } = this.props;
        let updatedStudent = '';
        if (propertyName === 'skip_placement_reporting') {
            updatedStudent = {
                ...student,
                [propertyName]: !student.skip_placement_reporting,
            };
        } else {
            updatedStudent = {
                ...student,
                [propertyName]: event.target.value,
            };
        }
        this.setState({
            changes: true,
            errorText: '',
        });
        dispatch({ type: 'SET_EDITING_STUDENT', payload: updatedStudent });
    };

    handleCohortSelect = (selectedOption) => {
        const { dispatch, student } = this.props;
        const { previousCohort } = this.state;
        let previousCohortCopy = previousCohort;
        if (previousCohortCopy === '' && !this.isNewStudent()) {
            previousCohortCopy = student.cohort_name;
        }

        const updatedStudent = {
            ...student,
            cohort_id: selectedOption.value,
            cohort_name: selectedOption.label,
            cohort: selectedOption,
        };
        this.setState({
            changes: true,
            previousCohort: previousCohortCopy,
            errorText: '',
        });
        dispatch({ type: 'SET_EDITING_STUDENT', payload: updatedStudent });
    };

    // handleStatusSelect = (selectedOption) => {
    //     const { dispatch, student } = this.props;
    //     const updatedStudent = {
    //         ...student,
    //         academic_status: selectedOption.value,
    //     };
    //     this.setState({
    //         changes: true,
    //         errorText: '',
    //     });
    //     dispatch({ type: 'SET_EDITING_STUDENT', payload: updatedStudent });
    // }

    validateForm = () => {
        const { student } = this.props;
        let valid = true;
        // Probably check others?
        if (student.first_name === '' || student.last_name === '') {
            valid = false;
            this.setState({
                errorText: 'Student name is required.',
            });
        } else if (!student.email || student.email === '') {
            valid = false;
            this.setState({
                errorText: 'Student email is required.',
            });
        } else if (!student.address || student.address === '') {
            valid = false;
            this.setState({
                errorText: 'Student address is required.',
            });
        } else if (!student.state || student.state === '') {
            valid = false;
            this.setState({
                errorText: 'Student state is required.',
            });
        } else if (!student.city || student.city === '') {
            valid = false;
            this.setState({
                errorText: 'Student city is required.',
            });
        } else if (!student.zip_code || student.zip_code === '') {
            valid = false;
            this.setState({
                errorText: 'Student zip is required.',
            });
        } else if (!student.phone || student.phone === '') {
            valid = false;
            this.setState({
                errorText: 'Student phone is required.',
            });
        } else if (this.isNewStudent() && (!student.course_id || student.course_id === '')) {
            // Course selection is required but can be determined by the
            // cohort selection (if selected).
            valid = false;
            this.setState({
                errorText: 'Program is required.',
            });
        } else if (student.application_status !== 'dropped_out'
            && (!student.cohort_id || student.cohort_id === '')) {
            // Cohort selection is required
            valid = false;
            this.setState({
                errorText: 'Cohort is required.',
            });
        }
        return valid;
    };

    onFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (acceptedImageTypes.includes(selectedFile.type)) {
            const copyFile = new Blob([selectedFile], { type: selectedFile.type });
            const resizedFile = await readAndCompressImage(copyFile, imageConfig);
            this.setState({
                selectedFile,
                resizedFile,
                preview: URL.createObjectURL(resizedFile),
                changes: true,
            });
        } else {
            this.setState({
                errorText: 'Invalid image file type. Must be gif, jpeg or png.',
            });
        }
    };

    isNewStudent() {
        const { match } = this.props;
        if (match.params.id) {
            return false;
        }
        return true;
    }

    render() {
        const {
            user,
            classes,
            student,
            cohorts,
            changePasswordModal,
            match,
        } = this.props;
        const {
            errorText,
            changes,
            existing,
            confirmationModalOpen,
            previousCohort,
            password,
            preview,
        } = this.state;
        let photoSrc = getPhotoUrl(
            user.s3_bucket,
            student.id,
            student.photo_file_name,
        );
        if (preview) {
            photoSrc = preview;
        }
        const cohortOptions = cohortOptionSelect(cohorts);
        // TODO: Add this back when we're ready to transition away from Trello
        // const currentStatus = currentStatusSelect(student.academic_status);
        let pageTitle = 'Create New Student';
        if (existing) {
            pageTitle = `Edit Student: ${student.first_name} ${student.last_name}`;
        }
        return (
            <>
                {/*
                    For a custom rendered modal, we may want to switch to this:
                    https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
                */}
                <Prompt
                    // Use a standard alert dialog if the user leaves the page when
                    // changes are detected.
                    when={changes}
                    message="You have unsaved edits. Are you sure you want to leave?"
                />
                <div className="container">
                    <PageTitle
                        title={pageTitle}
                        actions={existing ? [
                            {
                                label: 'Change Password',
                                // Dispatch an action...
                                type: 'SET_CHANGE_PASSWORD_MODAL',
                            },
                        ] : undefined}
                    />
                    <Paper>
                        {errorText.length > 0 && (
                            <div className={classes.errorText}>{errorText}</div>
                        )}
                        <br />
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <img
                                        className="placeholder-photo-preview"
                                        src={photoSrc}
                                        alt={student.first_name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <strong>Photo</strong>
                                    <br />
                                    <input type="file" onChange={this.onFileChange} />
                                    <br />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>GENERAL</Typography>
                                </Grid>
                                {
                                    !existing
                                    && (
                                        <Grid item xs={12} sm={6}>
                                            <FormLabel component="legend">Program</FormLabel>
                                            <RadioGroup
                                                required
                                                aria-label="course_id"
                                                name="course_id"
                                                value={student.course_id}
                                                onChange={this.handleChangeFor('course_id')}
                                                row
                                            >
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio color="primary" />}
                                                    label="Full-Stack"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value="2"
                                                    control={<Radio color="primary" />}
                                                    label="User Experience"
                                                    labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    )
                                }
                                {
                                    (
                                        // Only display if has student applications is false.
                                        process.env.REACT_APP_HAS_STUDENT_APPLICATIONS === 'false'
                                        || process.env.REACT_APP_HAS_STUDENT_APPLICATIONS === false
                                    )
                                    // Changing status for placed students not supported.
                                    && student.application_status !== 'placed'
                                    // Only existing students can be set to 'dropped_out'
                                    && !this.isNewStudent()
                                    && (
                                        <Grid item xs={12} sm={6}>
                                            <FormLabel component="legend">Status</FormLabel>
                                            <RadioGroup
                                                required
                                                aria-label="application_status_id"
                                                name="application_status"
                                                value={student.application_status}
                                                onChange={this.handleChangeFor('application_status')}
                                                row
                                            >
                                                <FormControlLabel
                                                    value="confirmed"
                                                    control={<Radio color="primary" />}
                                                    label="Confirmed"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value="dropped_out"
                                                    control={<Radio color="primary" />}
                                                    label="Dropped Out"
                                                    labelPlacement="end"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    )
                                }
                                {
                                    // Disable cohort select for students with status of dropped_out
                                    student.application_status !== 'dropped_out'
                                    && (
                                        <Grid item xs={12} sm={6}>
                                            <FormLabel component="label">Cohort</FormLabel>
                                            <ReactSelect
                                                placeholder="Cohort..."
                                                value={student.cohort}
                                                onChange={this.handleCohortSelect}
                                                options={cohortOptions}
                                                className={classes.cohortSelect}
                                                classNamePrefix="react-select"
                                            />
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="first-name"
                                        label="Student First Name"
                                        className={classes.textField}
                                        value={student.first_name}
                                        onChange={this.handleChangeFor('first_name')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="middle-name"
                                        label="Student Middle Name"
                                        className={classes.textField}
                                        value={student.middle_name || ''}
                                        onChange={this.handleChangeFor('middle_name')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="last-name"
                                        label="Student Last Name"
                                        className={classes.textField}
                                        value={student.last_name}
                                        onChange={this.handleChangeFor('last_name')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="pronunciation"
                                        label="Pronunciation"
                                        className={classes.textField}
                                        value={student.pronunciation}
                                        onChange={this.handleChangeFor('pronunciation')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        helperText="Note: Email is the username this student will use to login to the portal."
                                        id="email"
                                        label="Student Email Address"
                                        className={classes.textField}
                                        value={student.email}
                                        onChange={this.handleChangeFor('email')}
                                        margin="normal"
                                    />
                                </Grid>
                                {
                                    this.isNewStudent() && (
                                        <Grid item xs={12} sm={6}>
                                            <div className={classes.passwordText}>{`Password: ${password}`}</div>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="street-address"
                                        label="Student Street Address"
                                        className={classes.textField}
                                        // Default to an empty string to avoid
                                        // overlapping text for optional fields.
                                        value={student.address || ''}
                                        onChange={this.handleChangeFor('address')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="city"
                                        label="Student City"
                                        className={classes.textField}
                                        // Default to an empty string to avoid
                                        // overlapping text for optional fields.
                                        value={student.city || ''}
                                        onChange={this.handleChangeFor('city')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <StateAutocomplete
                                        value={student.state || ''}
                                        onChange={value => this.handleChangeFor('state')({
                                            target: { value },
                                        })}
                                        label="Student State"
                                        variant="standard"
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="zip-code"
                                        label="Student Zip Code"
                                        className={classes.textField}
                                        // Default to an empty string to avoid
                                        // overlapping text for optional fields.
                                        value={student.zip_code || ''}
                                        onChange={this.handleChangeFor('zip_code')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="phone"
                                        label="Student Phone Number"
                                        className={classes.textField}
                                        // Default to an empty string to avoid
                                        // overlapping text for optional fields.
                                        value={student.phone || ''}
                                        onChange={this.handleChangeFor('phone')}
                                        margin="normal"
                                        type="phone"
                                    />
                                </Grid>
                                {user.hubspot_portal_id
                                    && (
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="hubspot"
                                                label="Student HubSpot Id"
                                                className={classes.textField}
                                                // Default to an empty string to avoid
                                                // overlapping text for optional fields.
                                                value={student.hubspot_id || null}
                                                onChange={this.handleChangeFor('hubspot_id')}
                                                margin="normal"
                                                type="phone"
                                            />
                                        </Grid>
                                    )}
                                {/*
                                    TODO: Add this back when we're ready to transition
                                    away from Trello
                                */}
                                {/* {
                                    existing && (
                                        <Grid item xs={12} sm={6}>
                                            <FormLabel component="label">Student Issue</FormLabel>
                                            <ReactSelect
                                                placeholder="Status..."
                                                value={currentStatus}
                                                onChange={this.handleStatusSelect}
                                                options={studentStatusTags}
                                                className={classes.cohortSelect}
                                                classNamePrefix="react-select"
                                            />
                                        </Grid>
                                    )
                                } */}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="github"
                                        label="GitHub User Name"
                                        className={classes.textField}
                                        // Default to an empty string to avoid
                                        // overlapping text for optional fields.
                                        value={student.github_account || ''}
                                        onChange={this.handleChangeFor('github_account')}
                                        margin="normal"
                                        type="phone"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={!!student.skip_placement_reporting}
                                                onChange={this.handleChangeFor('skip_placement_reporting')}
                                                name="skip_placement_reporting"
                                                color="primary"
                                            />
                                        )}
                                        label="Exclude from placement reporting?"
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <br />
                        <br />
                        {errorText.length > 0 && (
                            <div className={classes.errorText}>{errorText}</div>
                        )}
                        <DialogActions>
                            <Button
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleSubmit}
                            >
                                {existing ? <span>Save</span> : <span>Create</span>}
                            </Button>
                        </DialogActions>
                    </Paper>
                    <ConfirmationModal
                        open={confirmationModalOpen}
                        dialogTitle="Confirm Cohort Transfer"
                        dialogMessage={`Are you sure you want to transfer ${student.first_name} ${student.last_name} from ${previousCohort} cohort to ${student.cohort_name} cohort?`}
                        onCancel={() => this.setState({ confirmationModalOpen: false })}
                        onConfirm={() => this.sendFormDataToServer()}
                    />
                    <ChangePasswordModal
                        open={changePasswordModal}
                        // indicates that an employee is changing a student's password
                        type="employeeChange"
                        studentId={match.params.id}
                    />
                </div>
            </>
        );
    }
}

CreateStudentPage.defaultProps = {
};

CreateStudentPage.propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
    cohorts: PropTypes.instanceOf(Array).isRequired,
    student: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
    }).isRequired,
    changePasswordModal: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    cohorts: state.cohort.cohortList,
    student: state.student.editingStudent,
    tags: state.assignments.tags,
    changePasswordModal: state.modal.changePasswordModalOpen,
});
const createAssignmentModal = withStyles(styles)(CreateStudentPage);
export default connect(mapStateToProps)(createAssignmentModal);
