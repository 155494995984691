import { combineReducers } from 'redux';

// Modal that appears when the server returns an error code
const modalOpen = (state = false, action) => {
    switch (action.type) {
    case 'SET_ERROR_MODAL':
        return action.payload.modalOpen;
    default:
        return state;
    }
};

// Object that keeps track of the current server error
const currentError = (state = {}, action) => {
    switch (action.type) {
    case 'SET_ERROR':
        if (action.payload.response) {
            return action.payload.response;
        }
        return action.payload;
    default:
        return state;
    }
};

export default combineReducers({
    currentError,
    modalOpen,
});
