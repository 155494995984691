import { combineReducers } from 'redux';

const summary = (state = [], action) => {
    switch (action.type) {
    case 'SET_REPORT_SUMMARY':
        return action.payload;
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

export default combineReducers({
    summary,
});
