import { combineReducers } from 'redux';

const searchOffset = (state = 0, action) => {
    switch (action.type) {
    case 'SET_SEARCH_OFFSET':
        return action.payload.offset;
    case 'UNSET_SEARCH_OFFSET':
    case 'UNSET_USER':
        return 0;
    default:
        return state;
    }
};

const searchText = (state = '', action) => {
    switch (action.type) {
    case 'SET_SEARCH_TEXT':
        return action.payload.text;
    case 'UNSET_SEARCH_TEXT':
    case 'UNSET_USER':
        return '';
    default:
        return state;
    }
};

// This is an object because the list is paginated and the object contains
// the total number of results in addtion to the array of students.
const studentList = (state = {}, action) => {
    switch (action.type) {
    case 'SET_STUDENT_LIST':
        return action.payload;
    case 'UNSET_STUDENT_LIST':
        return {};
    default:
        return state;
    }
};

const studentStatList = (state = {}, action) => {
    switch (action.type) {
    case 'SET_STUDENT_STAT_LIST':
        return action.payload;
    case 'UNSET_STUDENT_STAT_LIST':
        return {};
    default:
        return state;
    }
};

const studentDropdownList = (state = [], action) => {
    switch (action.type) {
    case 'SET_STUDENT_DROPDOWN_LIST':
        return action.payload;
    case 'UNSET_STUDENT_DROPDOWN_LIST':
        return [];
    default:
        return state;
    }
};

const defaultStudent = {
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    phone: '',
    github_account: '',
    cohort_id: '',
    course_id: '1', // FSE
    hubspot_id: null,
    application_status: 'confirmed',
};

const student = (state = defaultStudent, action) => {
    switch (action.type) {
    case 'SET_STUDENT':
        return action.payload;
    case 'UNSET_STUDENT':
    case 'UNSET_USER':
        return defaultStudent;
    default:
        return state;
    }
};

const editingStudent = (state = defaultStudent, action) => {
    let copy;
    switch (action.type) {
    case 'SET_EDITING_STUDENT':
        copy = Object.assign({}, action.payload);
        // If a cohort exists, make sure the value and label are available
        // for the drop down select to pre-populate.
        if (copy.cohort_name && copy.cohort_id) {
            // Used to pre-select drop down value
            copy.cohort = { ...copy.cohort, value: copy.cohort_id, label: copy.cohort_name };
        }
        return copy;
    case 'UNSET_EDITING_STUDENT':
    case 'UNSET_USER':
        return defaultStudent;
    default:
        return state;
    }
};

const courseWork = (state = {}, action) => {
    switch (action.type) {
    case 'SET_STUDENT_COURSE_WORKS':
        return action.payload;
    case 'UNSET_STUDENT_COURSE_WORKS':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const notes = (state = [], action) => {
    switch (action.type) {
    case 'SET_STUDENT_NOTES':
        return action.payload;
    case 'UNSET_STUDENT_NOTES':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const tags = (state = {}, action) => {
    switch (action.type) {
    case 'SET_STUDENT_TAGS':
        return action.payload;
    case 'UNSET_STUDENT_TAGS':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const cohortHistory = (state = [], action) => {
    switch (action.type) {
    case 'SET_STUDENT_COHORT_HISTORY':
        return action.payload;
    case 'UNSET_STUDENT_COHORT_HISTORY':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const placements = (state = [], action) => {
    switch (action.type) {
    case 'SET_STUDENT_PLACEMENTS':
        return action.payload;
    case 'UNSET_STUDENT_PLACEMENTS':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

/**
 * Picks up the SAME action type as the student reducer and generates data for
 * chartjs.
 */
const feedbackDataset = (state = {}, action) => {
    switch (action.type) {
    case 'SET_STUDENT_FEEDBACK': {
        const labels = action.payload.map(feedback => (
            feedback.feedback_on
        ));
        const understandingData = action.payload.map((feedback) => {
            const dateOfFeedback = new Date(feedback.feedback_on).setHours(24);
            return {
                y: feedback.understanding_score,
                t: dateOfFeedback,
            };
        });
        const supportData = action.payload.map((feedback) => {
            const dateOfFeedback = new Date(feedback.feedback_on).setHours(24);
            return {
                y: feedback.support_score,
                t: dateOfFeedback,
            };
        });
        const dataset = {
            labels,
            datasets: [
                {
                    type: 'line',
                    data: understandingData,
                    label: 'Understanding',
                    borderColor: '#178DAA',
                    backgroundColor: '#178DAA',
                    fill: false,
                    lineTension: 0.05,
                    borderJoinStyle: 'miter',
                },
                {
                    type: 'line',
                    data: supportData,
                    label: 'Support',
                    borderColor: '#08AA9E',
                    backgroundColor: '#08AA9E',
                    fill: false,
                    lineTension: 0.05,
                    borderJoinStyle: 'miter',
                },
            ],
        };
        return dataset;
    }
    case 'UNSET_STUDENT':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const feedbackSummary = (state = [], action) => {
    switch (action.type) {
    case 'SET_STUDENT_FEEDBACK_SUMMARY':
        return action.payload;
    case 'UNSET_STUDENT':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

// Academic Events
const academicEvents = (state = { resolved: [], unresolved: [] }, action) => {
    switch (action.type) {
    case 'SET_STUDENT_ACADEMIC_EVENT_LIST':
        return action.payload;
    case 'UNSET_STUDENT':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

// Selected Academic Events
const selectedAcademicEvent = (state = {}, action) => {
    switch (action.type) {
    case 'SET_SELECTED_ACADEMIC_EVENT':
        return action.payload;
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const defaultNote = {
    display: false,
    content: '',
    user_id: '',
};

const editingNote = (state = defaultNote, action) => {
    let copy;
    switch (action.type) {
    case 'SET_EDITING_STUDENT_NOTE':
        copy = Object.assign({}, action.payload);
        return copy;
    case 'UNSET_EDITING_STUDENT_NOTE':
        return defaultNote;
    default:
        return state;
    }
};

export default combineReducers({
    searchOffset,
    searchText,
    studentList,
    studentStatList,
    studentDropdownList,
    student,
    editingStudent,
    feedbackDataset,
    courseWork,
    notes,
    placements,
    academicEvents,
    feedbackSummary,
    selectedAcademicEvent,
    editingNote,
    tags,
    cohortHistory,
});
