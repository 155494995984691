/* eslint-disable no-console */
import axios from 'axios';
import {
    put, takeLatest,
} from 'redux-saga/effects';

function* fetchReportSummary(action) {
    try {
        const response = yield axios.get('api/reports/summary');
        yield put({ type: 'SET_REPORT_SUMMARY', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student notes - get request failed', error);
    }
}

function* reportSaga() {
    yield takeLatest('FETCH_REPORT_SUMMARY', fetchReportSummary);
}

export default reportSaga;
