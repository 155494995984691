import {
    TableCell,
    Tooltip,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useStyles from './styles';

function AssignmentNameCell({
    assignment,
    isActive,
    direction,
}) {
    const classes = useStyles();

    return (
        <TableCell
            key={assignment.id}
            className={classes.th}
            style={{
                background: isActive ? '#d2eef3' : '',
            }}
        >
            <Link
                to={`/assignments/${assignment.id}`}
                style={{
                    display: 'block',
                    color: '#676a6c',
                }}
            >
                <Tooltip
                    title={(
                        <span
                            style={{
                                fontSize: 16,
                            }}
                        >
                            {assignment.name}
                        </span>
                    )}
                    placement="top"
                >
                    <span
                    // Rotate title vertically
                        style={direction === 'vertical' ? {
                            writingMode: 'vertical-rl',
                            transform: 'rotate(-180deg)',
                        } : {}}
                    >
                        {/* Truncate to 25 chars */}
                        {assignment.name.length > 25
                            ? `${assignment.name.slice(0, 25)}...`
                            : assignment.name
                        }
                    </span>

                </Tooltip>
            </Link>
        </TableCell>
    );
}

AssignmentNameCell.propTypes = {
    assignment: PropTypes.instanceOf(Object).isRequired,
    isActive: PropTypes.bool,
    direction: PropTypes.string,
};

AssignmentNameCell.defaultProps = {
    isActive: false,
    direction: 'vertical',
};

export default AssignmentNameCell;
