import React from 'react';
import PropTypes from 'prop-types';
import {
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    radioStart: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-11px',
            flexDirection: 'row',
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: '27px',
        },
    },
    feedbackRadioGroup: {
        padding: '24px 0px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'row',
        },
    },
});

const StudentFeedbackRadioGroup = ({
    classes,
    startLabel,
    endLabel,
    handleRadioChange,
    value,
}) => (
    <RadioGroup
        required
        className={classes.feedbackRadioGroup}
        aria-label="position"
        name="position"
        value={value}
        onChange={handleRadioChange}
    >
        <FormControlLabel
            value="1"
            className={classes.radioStart}
            control={<Radio color="primary" />}
            label={startLabel}
            labelPlacement="start"
        />
        <FormControlLabel
            value="2"
            control={<Radio color="primary" />}
        />
        <FormControlLabel
            value="3"
            control={<Radio color="primary" />}
        />
        <FormControlLabel
            value="4"
            control={<Radio color="primary" />}
        />
        <FormControlLabel
            value="5"
            control={<Radio color="primary" />}
        />
        <FormControlLabel
            value="6"
            control={<Radio color="primary" />}
            label={endLabel}
            labelPlacement="end"
        />
    </RadioGroup>
);

StudentFeedbackRadioGroup.defaultProps = {
    value: '',
};

StudentFeedbackRadioGroup.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    startLabel: PropTypes.string.isRequired,
    endLabel: PropTypes.string.isRequired,
    value: PropTypes.string,
    handleRadioChange: PropTypes.instanceOf(Function).isRequired,
};

export default withStyles(styles)(StudentFeedbackRadioGroup);
