import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageTitle from '../../PageTitle/PageTitle';
import ApplicationTabsNoLogic from './ApplicationTabsNoLogic';

const UXAcademyApplication = ({
    dispatch,
    startTabDetails,
    history,
    match,
    user,
}) => {
    const stableDispatch = useCallback(dispatch, []);
    const stableHistory = useCallback(history, []);

    useEffect(() => {
        stableDispatch({ type: 'SET_APPLICANT_COURSE_ID', payload: { course_id: 3 } });
        stableDispatch({ type: 'FETCH_APPLICANT_APPLICATION_DETAILS', payload: { course_id: 3, stableHistory } });
    }, [user, stableDispatch, stableHistory]);

    return (
        <>
            <PageTitle
                title="Application for UX Academy"
            />

            <ApplicationTabsNoLogic
                logicTitle="Reasoning"
                technicalTitle="Creative"
                startTabText={startTabDetails}
                programType="ux-academy"
                history={history}
                match={match}
            />

        </>
    );
};

UXAcademyApplication.propTypes = {
    dispatch: PropTypes.func.isRequired,
    startTabDetails: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    startTabDetails: state.applicant.startTabDetails,
    user: state.user,
});

export default connect(mapStateToProps)(UXAcademyApplication);
