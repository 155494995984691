import { combineReducers } from 'redux';

const displayBack = (state = false, action) => {
    switch (action.type) {
    case 'SET_DISPLAY_BACK':
        return action.payload.displayBack;
    default:
        return state;
    }
};

// Allows the page to set different text for the back button
const backText = (state = 'Back', action) => {
    switch (action.type) {
    case 'SET_BACK_TEXT':
        return action.payload.backText;
    default:
        return state;
    }
};

// user will be on the redux state at:
// state.user
export default combineReducers({
    // TODO: cohort and cohorts should probably be combined into one reducer
    displayBack,
    backText,
});
