import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    List,
    Paper,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CohortPageTitle from '../CohortPageTitle';
import CohortStudentRow from './CohortStudentRow';
import CohortSummary from './CohortSummary';
import CohortWaitlistList from './CohortWaitlistList';
import CohortStatsRow from './CohortStatsRow';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import CohortPreviousStudentList from './CohortPreviousStudentList';

const {
    ADMIN,
} = require('../../General/UserTypes');

const styles = {
    cohortList: {
        width: '100%',
    },
    spacer: {
        flex: 1,
    },
    content: {
        padding: '12px 0px 12px 0px',
        // margin: '0px 15px',
        display: 'flex',
        alignItems: 'center',
    },
    actionButton: {
        margin: '0px 15px 0px 0px',
    },
};

class CohortStudentsPage extends Component {
    state = {
        confirmationModalOpen: false,
    };

    componentDidMount() {
        const { dispatch, match, history } = this.props;
        dispatch({ type: 'FETCH_COHORT_DETAILS', payload: { id: match.params.id } });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        this.unlisten = history.listen((location) => {
            this.onRouteChange(location);
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = (location) => {
        const { dispatch, cohort, match } = this.props;
        const urlParts = location.pathname.split('/');
        const cohortId = urlParts.pop();
        if (cohortId && cohortId !== '' && urlParts.pop() === 'cohorts') {
            dispatch({ type: 'FETCH_COHORT_DETAILS', payload: { id: cohortId } });
        } else if (cohort && cohort.id !== match.params.id) {
            dispatch({ type: 'FETCH_COHORT_DETAILS', payload: { id: match.params.id } });
        }
    };

    getPlacementText = (student) => {
        let result = '';
        if (student.skip_placement_reporting) {
            result = <span className="status-text status-excluded">Excluded</span>;
        } else if (student.application_status === 'dropped_out') {
            result = <span className="status-text status-unplaced">Dropped Out</span>;
        } else if (student.placement_count > 0) {
            result = <span className="status-text status-placed">Placed</span>;
        } else {
            result = <span className="status-text status-unplaced">Unplaced</span>;
        }
        return result;
    };

    viewStudentDetails = student => () => {
        const { history } = this.props;
        history.push(`/students/${student.id}`);
    };

    confirmArchiveCohort = () => {
        this.setState({
            confirmationModalOpen: true,
        });
    };

    archiveCohort = (cohortId) => {
        const { dispatch, history } = this.props;
        dispatch({ type: 'ARCHIVE_COHORT', payload: { cohortId }, history });
        this.setState({ confirmationModalOpen: false });
    };

    render() {
        // const { students } = this.props;
        const { user, cohort, classes } = this.props;
        const { confirmationModalOpen } = this.state;
        const actionButtons = [
            {
                label: 'Edit',
                // Dispatch an action...
                type: 'SET_EDITING_COHORT',
                payload: cohort,
                // ...and navigate to this page.
                path: `/cohorts/${cohort.id}/edit`,
            },
        ];
        if (user.role === ADMIN) {
            actionButtons.unshift({
                label: 'Archive',
                // Dispatch an action...
                type: 'DELETE_ASSIGNMENT',
                onClick: this.confirmArchiveCohort,
                color: 'secondary',
            });
        }
        return (
            <div className="container">
                <CohortPageTitle
                    title="Details"
                    cohort={cohort}
                    // Action buttons are created by the PageTitle component
                    actions={actionButtons}
                />
                <CohortSummary cohort={cohort} />
                <CohortStatsRow cohort={cohort} />
                <Paper>
                    <h3>Current Students</h3>
                    <List className={classes.cohortList}>
                        {
                            cohort.students
                            && cohort.students.map(student => (
                                <CohortStudentRow
                                    key={student.id}
                                    user={user}
                                    student={student}
                                    getPlacementText={this.getPlacementText}
                                    viewStudentDetails={this.viewStudentDetails}
                                />
                            ))
                        }
                    </List>
                    {
                        cohort.students
                        && cohort.students.length === 0
                        && (
                            <div>No students currently assigned to this cohort.</div>
                        )
                    }
                </Paper>
                {
                    cohort.waitlist
                    && cohort.waitlist.length > 0
                    && (
                        <CohortWaitlistList
                            cohort={cohort}
                            viewStudentDetails={this.viewStudentDetails}
                            user={user}
                        />
                    )
                }
                {
                    cohort.previousStudents
                    && cohort.previousStudents.length > 0
                    && (
                        <CohortPreviousStudentList
                            cohort={cohort}
                            viewStudentDetails={this.viewStudentDetails}
                            user={user}
                        />
                    )
                }
                <ConfirmationModal
                    open={confirmationModalOpen}
                    dialogTitle="Archive Cohort"
                    dialogMessage={"You're about to archive a cohort. Would you like to continue?"}
                    onCancel={() => this.setState({ confirmationModalOpen: false })}
                    onConfirm={() => this.archiveCohort(cohort.id)}
                />

            </div>
        );
    }
}

CohortStudentsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
    }).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// Instead of taking everything from state, we just want the user info.
// if you wanted you could write this code like this:
// const mapStateToProps = ({user}) => ({ user });
const mapStateToProps = state => ({
    user: state.user,
    cohort: state.cohort.cohort,
    students: state.cohort.cohortStudentList,
});

// this allows us to use <App /> in index.js
export default connect(mapStateToProps)(withStyles(styles)(CohortStudentsPage));
