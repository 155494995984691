import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import {
    Paper,
    Typography,
    Table,
    TableBody,
} from '@mui/material';
import AcademicEventRow from './AcademicEventRow';
import { getPhotoUrl } from '../../helpers/PhotoHelper';

const styles = theme => ({
    progressFill: {
        backgroundColor: theme.palette.primary.main,
        height: '5px',
        borderRadius: '3px',
    },
    progressEmpty: {
        backgroundColor: '#ccc',
        height: '5px',
        width: '90px',
        borderRadius: '3px',
    },
});

const AcademicEventsTable = ({
    academicEvents,
    user,
    navigateToFeedback,
    title,
}) => (
    <>
        {
            academicEvents
            && academicEvents.length > 0
            && (
                <Paper>
                    <Typography>{title}</Typography>
                    <Table>
                        <TableBody>
                            {
                                academicEvents
                                && academicEvents.map(academicEvent => (
                                    <AcademicEventRow
                                        key={academicEvent.id}
                                        user={user}
                                        academicEvent={academicEvent}
                                        navigateToFeedback={
                                            navigateToFeedback
                                        }
                                        getPhotoUrl={getPhotoUrl}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
            )
        }
    </>
);

AcademicEventsTable.propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
    academicEvents: PropTypes.instanceOf(Array).isRequired,
    navigateToFeedback: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(AcademicEventsTable));
