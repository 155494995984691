import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    TextField,
    Button,
    Grid,
    Paper,
} from '@mui/material';
import PageTitle from '../PageTitle/PageTitle';

class LoginPage extends Component {
    state = {
        username: '', // 'tester@primeacademy.io',
        password: '',
        errorText: '',
        statusText: '',
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
    }

    login = (event) => {
        const { dispatch } = this.props;
        event.preventDefault();
        const { username, password } = this.state;
        if (username && username.length > 3 && password && password.length > 1) {
            dispatch({ type: 'LOGIN', payload: { username, password } });
        } else {
            this.setState({
                errorText: 'Please enter a username and password.',
            });
        }
    }; // end login

    resetPassword = () => {
        const { dispatch } = this.props;
        const { username } = this.state;
        if (username && username.length > 3) {
            dispatch({ type: 'REQUEST_PASSWORD_RESET', payload: { email: username } });
            this.setState({
                errorText: '',
            });
        } else {
            this.setState({
                errorText: 'Please enter a username.',
            });
        }
    };

    handleInputChangeFor = propertyName => (event) => {
        this.setState({
            [propertyName]: event.target.value,
            errorText: '',
        });
    };

    render() {
        const {
            username,
            password,
            errorText,
            statusText,
        } = this.state;
        return (
            <div className="container">
                <PageTitle title="Login" />
                <Paper>
                    <Grid container justifyContent="center">
                        <form onSubmit={this.login}>
                            <TextField
                                id="outlined-name"
                                label="Email"
                                style={{ width: '300px' }}
                                value={username}
                                helperText="This is the email you applied with."
                                onChange={this.handleInputChangeFor('username')}
                                margin="dense"
                                variant="outlined"
                            />
                            <br />
                            <br />
                            <TextField
                                id="outlined-uncontrolled"
                                label="Password"
                                type="password"
                                style={{ width: '300px' }}
                                value={password}
                                onChange={this.handleInputChangeFor('password')}
                                margin="dense"
                                variant="outlined"
                            />
                            <br />
                            <Grid
                                justifyContent="space-between"
                                container
                            >
                                <Button onClick={this.resetPassword}>Reset Password</Button>
                                <Button variant="contained" type="submit">Login</Button>
                            </Grid>
                            <br />
                            {
                                errorText && errorText !== '' && (
                                    <div style={{ color: 'red', width: '300px' }}>{errorText}</div>
                                )
                            }
                            {
                                statusText && statusText !== '' && (
                                    <div style={{ width: '300px' }}>{statusText}</div>
                                )
                            }
                        </form>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

LoginPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    user: state.user,
});

export default connect(mapStateToProps)(LoginPage);
