import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
    Paper,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import AlertModal from '../../Modal/AlertModal';
import PageTitle from '../../PageTitle/PageTitle';
import StudentFeedbackForm from './StudentFeedbackForm';
import StudentFeedbackRow from './StudentFeedbackRow';
import StudentReflectionModal from './StudentReflectionModal';

const styles = {
    feedbackContainer: {
        padding: '20px',
    },
    pastReflectionContainer: {
        padding: '20px',
    },
    textField: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
    content: {
        maxWidth: '1000px',
        margin: '15px auto',
    },
};

const endOfDay = dateIn => (
    // Set time to 12pm to avoid going back to yesterday when converting to central time
    moment.utc(dateIn).set({ hour: 12, minute: 0 }).tz('US/Central').endOf('day')
);

const inTier1orTier2orTier3 = (cohort) => {
    const preworkStart = moment(cohort.prework_start).tz('US/Central');
    // Add one day to allow feedback on graduation day
    const graduation = endOfDay(cohort.graduation).add(1, 'days');
    const now = moment().tz('US/Central');
    return preworkStart.isBefore(now) && graduation.isAfter(now);
};

const StudentFeedbackPage = ({
    classes,
    feedbackDateCompare,
    feedbackHistory,
    selectedFeedback,
    conversationModalOpen,
    dispatch,
    cohort,
}) => (
    <div className="container">
        <PageTitle
            title="Daily Reflection"
        />
        {
            inTier1orTier2orTier3(cohort)
            && feedbackDateCompare.current_feedback_date
            && (
                <Paper className={classes.content}>
                    {
                        feedbackDateCompare.completed_today === false ? (
                            <>
                                <h6>{`Completing reflection for ${feedbackDateCompare.current_feedback_date}`}</h6>
                                <StudentFeedbackForm />
                            </>
                        ) : (
                            <h6>{`Thank you for completing your daily reflection for ${feedbackDateCompare.current_feedback_date}!`}</h6>
                        )
                    }
                </Paper>
            )
        }
        <Paper className={classes.content}>
            <div className={classes.pastReflectionContainer}>
                <Typography>PAST REFLECTIONS</Typography>
                {
                    feedbackHistory.length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Date
                                    </TableCell>
                                    <TableCell>
                                        Understanding
                                    </TableCell>
                                    <TableCell>
                                        Support
                                    </TableCell>
                                    <TableCell>
                                        One Word
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    feedbackHistory.map(feedback => (
                                        <StudentFeedbackRow
                                            key={feedback.id}
                                            feedback={feedback}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    ) : (
                        <p>Submit your first reflection using the form above.</p>
                    )
                }
            </div>
        </Paper>
        <AlertModal
            open={conversationModalOpen}
            dialogTitle="Thank you for your request"
            dialogMessage="A staff member will reach out via direct message in Slack to schedule a 1:1."
            onClose={() => dispatch({ type: 'STUDENT_ADDITIONAL_CONVERSATION_MODAL', payload: { modalOpen: false } })}
        />
        <StudentReflectionModal
            open={selectedFeedback !== undefined && selectedFeedback.feedback_on !== undefined}
            feedback={selectedFeedback}
            closeActionType="UNSET_STUDENT_SELECTED_FEEDBACK"
        />
    </div>
);

StudentFeedbackPage.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    feedbackDateCompare: PropTypes.instanceOf(Object).isRequired,
    feedbackHistory: PropTypes.instanceOf(Array).isRequired,
    selectedFeedback: PropTypes.instanceOf(Object).isRequired,
    conversationModalOpen: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    cohort: state.studentPortal.cohort,
    studentFeedback: state.studentPortal.studentFeedback,
    feedbackDateCompare: state.studentPortal.feedbackDateCompare,
    feedbackHistory: state.studentPortal.feedbackHistory,
    selectedFeedback: state.studentPortal.selectedFeedback,
    conversationModalOpen: state.studentPortal.additionalConversationModalOpen,
});

const studentFeedbackPage = withStyles(styles)(StudentFeedbackPage);
export default connect(mapStateToProps)(studentFeedbackPage);
