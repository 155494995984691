import React, {
    useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    Button,
    Grid,
    // Icon,
} from '@mui/material';
import {
    useHistory,
} from 'react-router-dom';
import { withStyles } from '@mui/styles';
import PageTitle from '../../PageTitle/PageTitle';

const styles = theme => ({
    gridItem: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

});

function Courses(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const fetchCourseList = () => {
        dispatch({ type: 'FETCH_COURSE_LIST' });
    };

    useEffect(() => {
        fetchCourseList();
    }, []);

    const {
        classes,
    } = props;

    const courses = useSelector(store => store.course.courseList);

    const viewCourseDetails = course => async () => {
        dispatch({
            type: 'SET_EDITING_COURSE',
            payload: course,
        });
        history.push(`/courses/${course.id}`);
    };

    return (
        <div className={classes.container}>
            <PageTitle
                title="Courses"
            />
            {
                courses && (
                    courses.map(course => (
                        <Paper key={course.code}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <span>
                                        <strong>Name: </strong>
                                        {course.name}
                                    </span>
                                </Grid>
                                <Grid item xs={12}>
                                    <strong>Description:</strong>
                                    <p>{course.description}</p>
                                </Grid>
                                <Grid item xs={12}>
                                    <strong>Status:</strong>
                                    <br />
                                    {course.active ? <p>Active</p> : <p>Inactive</p>}
                                </Grid>
                            </Grid>
                            <br />
                            <div>
                                <Button variant="outlined" onClick={viewCourseDetails(course)} type="button">View</Button>
                            </div>
                            <br />
                        </Paper>
                    ))
                )
            }
        </div>
    );
}

Courses.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Courses);
