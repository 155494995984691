import React from 'react';
import { connect } from 'react-redux';
import {
    TableRow,
    TableCell,
} from '@mui/material';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FeedbackTooltip from '../../General/FeedbackTooltip';

const styles = {
    actionButtons: {
        marginLeft: '15px',
    },
    assignmentStatus: {
        padding: '6px 0px',
    },
    tableRow: {
        cursor: 'pointer',
    },
};

const setSelectedFeedback = (dispatch, feedback) => () => {
    dispatch({
        type: 'SET_STUDENT_SELECTED_FEEDBACK',
        payload: feedback,
    });
};

const StudentFeedbackRow = ({
    feedback,
    classes,
    dispatch,
}) => (
    <TableRow
        className={classes.tableRow}
        hover
        onClick={setSelectedFeedback(dispatch, feedback)}
    >
        <TableCell>
            {feedback.feedback_on}
        </TableCell>
        <TableCell>
            {feedback.understanding_score}
            {
                feedback.understanding_explanation
                && (
                    // Not sure if it makes sense to have a feedback bubble here
                    <FeedbackTooltip
                        title="Comment"
                        tooltip={
                            `${feedback.understanding_explanation}`
                        }
                    />
                )
            }
        </TableCell>
        <TableCell>
            {feedback.support_score}
            {
                feedback.support_explanation
                && (
                    // Not sure if it makes sense to have a feedback bubble here
                    <FeedbackTooltip
                        title="Comment"
                        tooltip={
                            `${feedback.support_explanation}`
                        }
                    />
                )
            }
        </TableCell>
        <TableCell>
            {feedback.feeling_explanation && feedback.feeling_explanation}
        </TableCell>
    </TableRow>
);

StudentFeedbackRow.propTypes = {
    feedback: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect()(withStyles(styles)(StudentFeedbackRow));
