import React from 'react';
import {
    TableRow,
    TableCell,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { shortDate } from '../../../helpers/DateFormatHelper';
import formatDollars from '../../../helpers/CurrencyHelper';

const CompanyPlacementRow = ({
    placementInfo,
    classes,
}) => (
    <>
        <TableRow>
            <TableCell className={classes.tableCell}>
                {placementInfo.company.name}
            </TableCell>
            <TableCell>
                <Link onClick={e => e.stopPropagation()} to={`/students/${placementInfo.user.id}`}>
                    {placementInfo.user.first_name}
                    {' '}
                    {placementInfo.user.last_name}
                </Link>
            </TableCell>
            <TableCell>
                {placementInfo.cohort
                && (
                    <Link onClick={e => e.stopPropagation()} to={`/cohorts/${placementInfo.cohort.id}`}>
                        {placementInfo.cohort.name}
                    </Link>
                ) }
            </TableCell>
            <TableCell>
                {placementInfo.placement_type}
            </TableCell>
            <TableCell>
                {placementInfo.job_title}
            </TableCell>
            <TableCell>
                {formatDollars(placementInfo.post_income)}
            </TableCell>
            <TableCell>
                { placementInfo.placed_at
                && shortDate(placementInfo.placed_at)}
            </TableCell>
        </TableRow>
    </>
);

CompanyPlacementRow.propTypes = {
    placementInfo: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};
export default CompanyPlacementRow;
