const styles = {
    textfield: {
        width: '100%',
    },
    incomplete: {
        fontSize: '14px',
        fontStyle: 'italic',
    },
    submit: {
        marginTop: '10px',
        marginBottom: '15px',
    },
    noteName: {
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '3px',
    },
    noteDate: {
        fontSize: '12px',
        marginTop: '8px',
    },
    profileQuestion: {
        marginTop: '15px',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    profileAnswer: {
        marginTop: '5px',
        marginBottom: '5px',
        fontSize: '14px',
    },
    profileAnswerIncomplete: {
        fontSize: '14px',
        fontStyle: 'italic',
        marginTop: '5px',
        marginBottom: '5px',
    },
    errorText: {
        fontSize: '14px',
        marginTop: '5px',
        color: 'red',
    },
};

export default styles;
