import React from 'react';
import {
    Typography,
    Grid,
    Paper,
} from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { getPhotoUrl } from '../../../helpers/PhotoHelper';

const styles = theme => ({
    icon: {
        margin: 0,
        padding: theme.spacing(),
        fontSize: '14px',
    },
    smallIcon: {
        margin: 0,
        padding: '0 4px',
        fontSize: '14px',
    },
    studentTitleText: {
        padding: '5px 10px',
    },
    feedbackScore: {
        fontSize: '16px',
        textAlign: 'center',
        minWidth: '80px',
    },
    actionButtons: {
        marginLeft: '15px',
    },
    userColumn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    wideColumn: {
        minWidth: '150px',
    },
    scoreContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    tableRow: {
        cursor: 'pointer',
    },
});

/**
 * Feedback is returned from the server can be null. This function
 * checks to see if an entry exists and format the entry accordingly.
 */
function formatFeedbackNumber(entry) {
    let result = '';
    if (entry) {
        result = `${Number(entry).toFixed(0)} / 6`;
    }
    return result;
}

function formatFeedbackText(entry) {
    let result = '';
    if (entry) {
        result = entry;
    }
    return result;
}

const CohortFeedbackModule = ({
    user,
    studentFeedback,
    viewFeedbackDetails,
    classes,
    index,
}) => (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
        <Paper style={{ height: '100%', width: '100%', margin: 0 }}>
            <div>
                <img
                    style={{ float: 'left' }}
                    className="placeholder-photo-xs"
                    src={getPhotoUrl(user.s3_bucket, studentFeedback.student_id, studentFeedback.photo_file_name, 'thumb')}
                    alt={studentFeedback.first_name}
                />

                <Typography variant="h5" style={{ marginLeft: 32, marginTop: -2 }}>
                    <Link onClick={e => e.stopPropagation()} to={`/students/${studentFeedback.student_id}`}>
                        {studentFeedback.first_name}
                        {' '}
                        {studentFeedback.last_name}
                        {studentFeedback.pronouns && ` (${studentFeedback.pronouns})`}
                    </Link>
                </Typography>
            </div>
            {
                studentFeedback.understanding_score ? (
                    <>
                        <Typography variant="h6">
                            {'How well do you feel you understood today\'s material?'}
                        </Typography>
                        <Typography>
                            {`${formatFeedbackNumber(
                                studentFeedback.understanding_score,
                            )}`}
                        </Typography>
                        <Typography>
                            {formatFeedbackText(
                                studentFeedback.understanding_explanation,
                            )}
                        </Typography>
                        <Typography variant="h6">
                            How well supported do you feel today?
                        </Typography>
                        <Typography>
                            {`${formatFeedbackNumber(
                                studentFeedback.support_score,
                            )}`}
                        </Typography>
                        <Typography>
                            {formatFeedbackText(
                                studentFeedback.support_explanation,
                            )}
                        </Typography>
                        {
                            studentFeedback.feeling_explanation && (
                                <>
                                    <Typography variant="h6">
                                        One word
                                    </Typography>
                                    <Typography>
                                        {formatFeedbackText(
                                            studentFeedback.feeling_explanation,
                                        )}
                                    </Typography>
                                </>
                            )
                        }
                        {
                            studentFeedback.thanks && (
                                <>
                                    <Typography variant="h6">
                                        Thanks
                                    </Typography>
                                    <Typography>
                                        {formatFeedbackText(
                                            studentFeedback.thanks,
                                        )}
                                    </Typography>
                                </>
                            )
                        }
                        {
                            studentFeedback.recommended_experiences && (
                                <>
                                    <Typography variant="h6">
                                        Suggest
                                    </Typography>
                                    <Typography>
                                        {formatFeedbackText(
                                            studentFeedback.recommended_experiences,
                                        )}
                                    </Typography>
                                </>
                            )
                        }
                        {
                            studentFeedback.accomplishment && (
                                <>
                                    <Typography variant="h6">
                                        Accomplishment
                                    </Typography>
                                    <Typography>
                                        {formatFeedbackText(
                                            studentFeedback.accomplishment,
                                        )}
                                    </Typography>
                                </>
                            )
                        }
                    </>
                ) : (
                    <Typography variant="body2">
                        No reflection
                    </Typography>
                )
            }
        </Paper>
    </Grid>
);

CohortFeedbackModule.propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
    studentFeedback: PropTypes.instanceOf(Object).isRequired,
    viewFeedbackDetails: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    index: PropTypes.number.isRequired,
};

export default withStyles(styles)(CohortFeedbackModule);
