import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import PageTitle from '../PageTitle/PageTitle';

function CohortPageTitle({
    cohort,
    title,
    actions,
}) {
    const history = useHistory();

    return (
        <PageTitle
            title={`${cohort.name || 'Cohort'} :: ${title}`}
            navigation={
                [
                    { name: 'details', path: `/cohorts/${cohort.id}` },
                    { name: 'grades', path: `/cohorts/${cohort.id}/grades` },
                    { name: 'assignments', path: `/cohorts/${cohort.id}/assignments` },
                    { name: 'feedback', path: `/cohorts/${cohort.id}/feedback` },
                ]
                    // mark the active path as selected
                    .map(nav => (
                        nav.path === history.location.pathname
                            ? { ...nav, selected: true }
                            : nav
                    ))
            }
            actions={actions}
        />
    );
}

CohortPageTitle.propTypes = {
    cohort: PropTypes.instanceOf(Object).isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.instanceOf(Array),
};

CohortPageTitle.defaultProps = {
    actions: [],
};

export default CohortPageTitle;
