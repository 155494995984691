import { combineReducers } from 'redux';

// Offset for the company list page
const searchOffset = (state = 0, action) => {
    switch (action.type) {
    case 'SET_COMPANY_OFFSET':
        return action.payload.offset;
    case 'UNSET_COMPANY_OFFSET':
        return 0;
    default:
        return state;
    }
};

// orderBy name, order is asc or desc
let defaultSortOrder = { orderBy: 'name', order: 'asc' };

try {
    if (localStorage.getItem('companySortOrder')) {
        defaultSortOrder = JSON.parse(localStorage.getItem('companySortOrder'));
    }
} catch {
    defaultSortOrder = { orderBy: 'name', order: 'asc' };
}

const sortOrder = (state = defaultSortOrder, action) => {
    switch (action.type) {
    case 'SET_COMPANY_SORT_ORDER':
        localStorage.setItem('companySortOrder', JSON.stringify(action.payload));
        return action.payload;
    case 'UNSET_COMPANY_SORT_ORDER':
        return defaultSortOrder;
    default:
        return state;
    }
};

const searchText = (state = '', action) => {
    switch (action.type) {
    case 'SET_COMPANY_SEARCH_TEXT':
        return action.payload.text;
    case 'UNSET_SEARCH_TEXT':
    case 'UNSET_USER':
        return '';
    default:
        return state;
    }
};

const company = (state = [], action) => {
    switch (action.type) {
    case 'SET_COMPANIES':
        return action.payload;
    default:
        return state;
    }
};

const companyDropdownList = (state = [], action) => {
    switch (action.type) {
    case 'SET_COMPANIES_DROP_DOWN_LIST':
        return action.payload;
    default:
        return state;
    }
};

const recruiterDropdownList = (state = [], action) => {
    switch (action.type) {
    case 'SET_RECRUITERS_DROP_DOWN_LIST':
        return action.payload;
    default:
        return state;
    }
};

const defaultNote = {
    display: false,
    content: '',
    company_id: '',
    user_id: '',
};

const editingNote = (state = defaultNote, action) => {
    let copy;
    switch (action.type) {
    case 'SET_EDITING_COMPANY_NOTE':
        copy = Object.assign({}, action.payload);
        return copy;
    case 'UNSET_EDITING_COMPANY_NOTE':
        return defaultNote;
    default:
        return state;
    }
};

const companyNotes = (state = [], action) => {
    switch (action.type) {
    case 'SET_COMPANY_NOTES':
        return action.payload;
    default:
        return state;
    }
};

const defaultCompany = {
    address1: '',
    address2: '',
    city: '',
    recruiter: false,
    id: '',
    last_placement: '',
    name: '',
    phone: '',
    placements: '',
    state: '',
    website_link: '',
    jobs_link: '',
    zip_code: '',
    active: true,
};

const selectedCompany = (state = defaultCompany, action) => {
    switch (action.type) {
    case 'SET_COMPANY':
        return action.payload;
    case 'UNSET_COMPANY':
        return defaultCompany;
    default:
        return state;
    }
};

const editingCompany = (state = defaultCompany, action) => {
    let copy;
    switch (action.type) {
    case 'SET_EDITING_COMPANY':
        copy = Object.assign({}, action.payload);
        return copy;
    case 'UNSET_EDITING_COMPANY':
        return defaultCompany;
    case 'UNSET_USER':
        return defaultCompany;
    default:
        return state;
    }
};

export default combineReducers({
    company,
    companyDropdownList,
    recruiterDropdownList,
    sortOrder,
    searchOffset,
    searchText,
    selectedCompany,
    companyNotes,
    editingCompany,
    editingNote,
});
