import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PageTitle from '../../PageTitle/PageTitle';
import PlacementsSummary from '../../Placements/PlacementListPage/PlacementsSummary';
import ApplicationsSummary from '../../Applications/ApplicationsListPage/ApplicationsSummary';
import CohortListPage from '../../Cohort/CohortListPage/CohortListPage';

const styles = {
    feedbackContainer: {
        padding: '20px',
    },
    textField: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
    summaryTitle: {
        position: 'relative',
    },
    cardContent: {
        padding: '15px 15px 0 15px',
    },
};

class CareerDashboardPage extends Component {
    componentDidMount() {
        const {
            dispatch,
            sortOrder,
        } = this.props;
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
        dispatch({ type: 'FETCH_APPLICATION_STATS' });
        this.fetchPlacements(
            {
                searchText: '',
                firstPlacementsOnly: true,
                startDate: null,
                endDate: null,
                dateRange: {
                    searchBy: 'graduation_date',
                },
                order: sortOrder.order,
                orderBy: sortOrder.orderBy,
            },
        );
    }

    fetchPlacements = (params) => {
        const {
            dispatch,
            sortOrder,
            searchOffset,
            rowsPerPage,
            searchText,
            dateRange,
            firstPlacementsOnly,
        } = {
            // Extend props with any provided params
            ...this.props,
            ...params,
        };

        dispatch({
            type: 'FETCH_PLACEMENTS',
            payload: {
                sortOrder,
                searchOffset,
                rowsPerPage,
                searchText,
                dateRange,
                firstPlacementsOnly,
            },
        });
    };

    render() {
        const {
            classes,
            history,
        } = this.props;

        return (
            <div className="container">
                <PageTitle title="Dashboard" />
                <Grid className={classes.cardContent} container>
                    <Grid item xs={12}>
                        {/* Placement summary */}
                        <PlacementsSummary
                            hideTitle={false}
                            hideNote
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* Application summary */}
                        <ApplicationsSummary
                            hideTitle
                        />
                    </Grid>
                </Grid>
                {/* Cohort List */}
                <CohortListPage
                    hideTitle
                    history={history}
                />
            </div>
        );
    }
}

CareerDashboardPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    sortOrder: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    sortOrder: state.assignments.dashboardAssignmentSortOrder,
});

export default connect(mapStateToProps)(withStyles(styles)(CareerDashboardPage));
