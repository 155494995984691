/**
 * This component is no longer being used. There were too many form
 * fields to make this work as a modal. Leaving here for now as an example.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { cohortOptionSelect } from '../../helpers/CohortTextHelper';

const styles = theme => ({
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
    },
    /**
    * React doesn't provide an auto complete input field. Styling this third
    * party component takes a bit more work that standard Material UI components.
    * https://react-select.com/styles#using-classnames
    */
    cohortSelect: {
        zIndex: '1000',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '0px',
        },
        '& .react-select__control': {
            height: '39px',
            borderRadius: '3px',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
    },
    selectContainer: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
});

class CreateAssignmentModal extends Component {
    state = {
        edits: false,
        exitPrompt: false,
        errorText: '',
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { edits } = this.state;
        const {
            dispatch,
            open,
            handleRefresh,
            assignment,
        } = this.props;
        if (edits && open && !nextProps.open) {
            dispatch({ type: 'UNSET_EDITING_ASSIGNMENT' });
            // Modal is closing, notify refresh handler
            handleRefresh();
        }
        // TODO: Also check new vs. editing. This should only be done for new
        // assignments.
        if (!assignment.cohortId
            && nextProps.selectedCohort
            && nextProps.selectedCohort.value > 0) {
            const updatedAssignment = {
                ...assignment,
                cohortId: nextProps.selectedCohort.value,
                cohort: nextProps.selectedCohort,
            };
            dispatch({ type: 'SET_EDITING_ASSIGNMENT', payload: updatedAssignment });
        }
    }

    componentWillUnmount() {
        const { edits } = this.state;
        const { dispatch, handleRefresh } = this.props;
        // Tell component who displayed the modal that contents have changed
        if (edits) {
            dispatch({ type: 'UNSET_EDITING_ASSIGNMENT' });
            handleRefresh();
        }
    }

    handleCancel = () => {
        const { edits } = this.state;
        const { dispatch, closeActionType } = this.props;

        if (edits) {
            this.setState({
                exitPrompt: true,
            });
        } else {
            dispatch({
                type: closeActionType,
                payload: {
                    modalOpen: false,
                },
            });
        }
    };

    clearFieldsAndExit = () => {
        const { dispatch, closeActionType } = this.props;
        dispatch({ type: 'UNSET_EDITING_ASSIGNMENT' });
        this.setState({
            exitPrompt: false,
            edits: false,
        });
        dispatch({
            type: closeActionType,
            payload: {
                modalOpen: false,
            },
        });
    };

    handleSubmit = () => {
        const { dispatch, closeActionType, assignment } = this.props;
        const valid = this.validateForm();
        if (valid) {
            const action = {
                type: 'POST_ASSIGNMENT',
                payload: assignment,
                // Tell the reducer how to close the modal
                closeActionType,
            };
            dispatch(action);
        }
    };

    handleChangeFor = propertyName => (event) => {
        const { dispatch, assignment } = this.props;
        const updatedAssignment = {
            ...assignment,
            [propertyName]: event.target.value,
        };
        this.setState({
            edits: true,
        });
        dispatch({ type: 'SET_EDITING_ASSIGNMENT', payload: updatedAssignment });
    };

    handleCohortSelect = (selectedOption) => {
        const { dispatch, assignment } = this.props;
        const updatedAssignment = {
            ...assignment,
            cohortId: selectedOption.value,
            cohort: selectedOption,
        };
        this.setState({
            edits: true,
        });
        dispatch({ type: 'SET_EDITING_ASSIGNMENT', payload: updatedAssignment });
    };

    validateForm = () => {
        const { assignment } = this.props;
        let valid = true;
        if (assignment.name === '') {
            valid = false;
            this.setState({
                errorText: 'Assignment name is required.',
            });
        } else if (!assignment.cohortId || assignment.cohortId === '') {
            valid = false;
            this.setState({
                errorText: 'Cohort is required.',
            });
        }
        return valid;
    };

    render() {
        const {
            classes,
            open,
            cohorts,
            assignment,
        } = this.props;
        const {
            exitPrompt,
            errorText,
        } = this.state;
        const cohortOptions = cohortOptionSelect(cohorts);
        return (
            <>
                <Dialog open={exitPrompt}>
                    <DialogTitle id="responsive-dialog-title">
                        Unsaved changes.
                    </DialogTitle>
                    <Button
                        onClick={
                            () => this.setState({
                                exitPrompt: false,
                            })
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.clearFieldsAndExit}
                    >
                        Discard Changes
                    </Button>
                </Dialog>
                {/* TODO: Move this dialog into a function component */}
                {/* <Dialog fullScreen={fullScreen} open={open}> */}
                <Dialog open={open}>
                    <DialogTitle id="responsive-dialog-title">
                        Create New Assignment
                    </DialogTitle>
                    {errorText.length > 0 && (
                        <div className={classes.errorText}>{errorText}</div>
                    )}
                    <DialogContent align="left" className={classes.feedbackContainer}>
                        <form>
                            <TextField
                                id="standard-name"
                                label="Assignment Name"
                                className={classes.textField}
                                value={assignment.name}
                                onChange={this.handleChangeFor('name')}
                                margin="normal"
                            />
                            <RadioGroup
                                aria-label="type"
                                name="type"
                                value={assignment.type}
                                onChange={this.handleChangeFor('type')}
                                row
                            >
                                <FormControlLabel
                                    value="not_graded"
                                    control={<Radio color="primary" />}
                                    label="Not Graded"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="graded"
                                    control={<Radio color="primary" />}
                                    label="Graded"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                            <div className={classes.selectContainer} style={{ display: 'inline-block' }}>
                                <ReactSelect
                                    placeholder="Cohort..."
                                    // ReactSelect requires the whole object, not just value!
                                    value={assignment.cohort}
                                    onChange={this.handleCohortSelect}
                                    options={cohortOptions}
                                    className={classes.cohortSelect}
                                    classNamePrefix="react-select"
                                />
                            </div>
                            {/* TODO: Chip selection for tags */}
                            <TextField
                                id="standard-tags"
                                label="Tags (comma separated)"
                                value={assignment.tagSearchText}
                                onChange={this.handleChangeFor('tagSearchText')}
                                className={classes.textField}
                                margin="normal"
                            />
                            <TextField
                                id="standard-repository"
                                label="Git Repo / Dropbox URL"
                                value={assignment.repository}
                                onChange={this.handleChangeFor('repository')}
                                className={classes.textField}
                                margin="normal"
                            />
                            <TextField
                                id="standard-description"
                                label="Description"
                                value={assignment.notes}
                                onChange={this.handleChangeFor('notes')}
                                className={classes.textField}
                                margin="normal"
                            />
                        </form>
                    </DialogContent>
                    <br />
                    <DialogActions>
                        <Button
                            onClick={this.handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                        >
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

CreateAssignmentModal.defaultProps = {
    selectedCohort: undefined,
};

CreateAssignmentModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    open: PropTypes.bool.isRequired,
    closeActionType: PropTypes.string.isRequired,
    cohorts: PropTypes.instanceOf(Array).isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    handleRefresh: PropTypes.func.isRequired,
    selectedCohort: PropTypes.instanceOf(Object),
};

const mapStateToProps = state => ({
    cohorts: state.cohort.cohortList,
    assignment: state.assignments.editingAssignment,
});
const createAssignmentModal = withStyles(styles)(CreateAssignmentModal);
export default connect(mapStateToProps)(createAssignmentModal);
