import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import {
    Box, Button, Divider, Typography,
} from '@mui/material';
import ReactSelect from 'react-select';
import { connect } from 'react-redux';
import InterviewSummaryItem from './InterviewSummaryItem';

const styles = theme => ({
    wrapper: {
        boxShadow: 2,
        borderRadius: '8px',
        padding: '16px',
    },
    heading: {
        fontWeight: 500,
        color: 'black',
    },
    bold: {
        fontWeight: 'bold',
        color: 'black',
        padding: '8px 0',
    },
    copy: {
        padding: '8px 0',
    },
    score: {
        maxWidth: '200px',
        marginBottom: '10px ',
    },
    headerWrapper: {
        display: 'flex',
        gap: '30px',
    },
});

const InterviewSummary = ({
    classes,
    sections,
    handleScoreChange,
    editingInterviewScore,
    setEditing,
    interviewScores,
    editingApplication,
}) => (
    <Box>
        <Box className={classes.headerWrapper}>
            <Typography className={classes.heading} variant="h2">
                Interview summary
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => { setEditing(true); }}
            >
                Edit Page
            </Button>
        </Box>

        <Typography className={classes.bold} variant="h4">
            Overall Score
        </Typography>

        <Box className={classes.copy}>
            {editingApplication?.interview_summary}
        </Box>

        <Typography variant="h6" className={classes.heading}> Score </Typography>
        <ReactSelect
            placeholder="Interview Score.."
            value={editingInterviewScore}
            isDisabled
            className={classes.score}
            options={interviewScores}
            styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999 }),
            }}
        />
        {sections.map((section) => (
            <InterviewSummaryItem
                key={section.id}
                handleScoreChange={handleScoreChange}
                section={section}
            />
        ))}
        <br />
        <br />
        <Divider />
        <br />
        <br />
    </Box>
);

InterviewSummary.propTypes = {

    classes: PropTypes.instanceOf(Object).isRequired,
    sections: PropTypes.array.isRequired,
    handleScoreChange: PropTypes.func.isRequired,
    interviewScores: PropTypes.instanceOf(Array).isRequired,
    editingInterviewScore: PropTypes.instanceOf(Object).isRequired,
    editingApplication: PropTypes.instanceOf(Object).isRequired,
    setEditing: PropTypes.func.isRequired,

};
const mapStateToProps = state => ({
    editingInterviewScore: state.application.editingInterviewScore,
    interviewScores: state.application.interviewScores,
    editingApplication: state.application.editingApplicationDetails,

});

export default connect(mapStateToProps)(withStyles(styles)(InterviewSummary));
