/* eslint-disable prefer-template */
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import './CohortGrades.css';
import FaIcon from '../../../helpers/FaIcon';

function LegendRow({
    items,
    renderClasses,
    renderContent,
    label,
}) {
    const classes = useStyles();

    return (
        <div className={classes.legendRow}>
            {items.map(i => (

                <div
                    key={i}
                    className={[
                        classes.gradeCell,
                        'MuiTableCell-root',
                        ...renderClasses(i),
                    ].join(' ')}
                >
                    {renderContent(i)}
                </div>
            ))}
            <div className={classes.legendLabel}>
                {label}
            </div>
        </div>
    );
}

LegendRow.propTypes = {
    items: PropTypes.instanceOf(Array),
    renderClasses: PropTypes.func.isRequired,
    renderContent: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

LegendRow.defaultProps = {
    items: [],
};

function GradeCellLegend() {
    return (
        <div className="cohort-grades">
            <h5 style={{ paddingBottom: 10 }}>Legend</h5>

            {/* Graded Course Work */}
            <LegendRow
                key="graded"
                items={[1, 3, 5]}
                renderClasses={n => [`grade-${n}`]}
                renderContent={n => n}
                label="Graded Work"
            />

            {/* Late Course Work */}
            <LegendRow
                key="late"
                items={[1, 3, 5]}
                renderClasses={n => [`grade-${n}`, 'grade-late']}
                renderContent={n => n}
                label="Graded Work (Submitted Late)"
            />

            {/* Drafted Work */}
            <LegendRow
                key="draft"
                items={[1, 3, 5]}
                renderClasses={n => [`grade-${n}`, 'grade-draft']}
                renderContent={n => (
                    <FaIcon
                        icon="file-alt"
                        iconType="fas"
                        style={{
                            display: 'inline',
                            fontSize: 16,
                        }}
                    />
                )}
                label="Draft Feedback (Graded)"
            />

            {/* Resubmission Required */}
            <LegendRow
                key="resub-required"
                items={[null]}
                renderClasses={n => ['grade-1', 'grade-resubmission-due']}
                renderContent={n => '1'}
                label="Resubmission Required"
            />

            {/* Resubmission Complete */}
            <LegendRow
                key="resub-required"
                items={[null]}
                renderClasses={n => ['grade-1', 'grade-resubmission-complete']}
                renderContent={n => '1'}
                label="Resubmission Complete"
            />

            {/* Resubmission Accepted */}
            <LegendRow
                key="resub-accepted"
                items={[null]}
                renderClasses={n => ['grade-1', 'grade-resubmission-accepted']}
                renderContent={n => '1'}
                label="Resubmission Accepted"
            />

            {/* Drafted Work (no grade) */}
            <LegendRow
                key="draft-no-grade"
                items={[null]}
                renderClasses={n => ['grade-null', 'grade-draft']}
                renderContent={n => (
                    <FaIcon
                        icon="file-alt"
                        iconType="fas"
                        style={{
                            display: 'inline',
                            fontSize: 16,
                        }}
                    />
                )}
                label="Draft Feedback (No Grade)"
            />

            {/* Ungraded Work */}
            <LegendRow
                key="ungraded"
                items={[null]}
                renderClasses={n => ['grade-null', 'grade-not-graded']}
                renderContent={n => (
                    <FaIcon
                        icon="file-alt"
                        iconType="far"
                        style={{
                            display: 'inline',
                            fontSize: 16,
                        }}
                    />
                )}
                label="Not yet graded"
            />

            {/* Uncompleted Work */}
            <LegendRow
                key="uncompleted"
                items={[null]}
                renderClasses={n => ['grade-null', 'grade-uncompleted']}
                renderContent={n => '-'}
                label="Uncompleted (not yet due)"
            />

            {/* Missing Work */}
            <LegendRow
                key="missing"
                items={[null]}
                renderClasses={n => ['grade-null', 'grade-missing']}
                renderContent={n => (
                    <FaIcon
                        icon="exclamation-triangle"
                        iconType="fas"
                        style={{
                            display: 'inline',
                            fontSize: 16,
                        }}
                    />
                )}
                label="Missing (overdue)"
            />

        </div>
    );
}

export default GradeCellLegend;
