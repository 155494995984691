import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    CardContent,
    Card,
} from '@mui/material';
import ExpandArrowIcon from '../General/ExpandArrowIcon';

const useStyles = makeStyles({
    placementSummary: {
        position: 'relative',
        margin: '30px 0 40px 0',
    },
    dashboardSummary: {
        position: 'relative',
        margin: '0 0 15px 0',
    },
    statCollapsed: {
        display: 'inline-block',
        padding: '0 20px 0 20px',
        borderLeft: '1px solid #d4d4d4',
        textAlign: 'left',
        '&:first-child': {
            border: 'none',
            paddingLeft: 0,
        },
        '& > span:first-child': {
            fontSize: 22,
            marginRight: 10,
            paddingLeft: 0,
        },
        '& > span:last-child': {
            fontSize: 15,
            fontWeight: 'bold',
            verticalAlign: 2,
            border: 'none',
        },
    },
    collapseBtn: {
        position: 'absolute',
        right: 0,
        textAlign: 'right',
        cursor: 'pointer',

        // Increase the "hit box" for this button
        padding: '15px 20px 20px 170px',
        marginTop: -50,

        '& span:first-child': {
            fontSize: 'inherit',
            textDecoration: 'underline',
            verticalAlign: 'bottom',
            marginRight: 5,
        },
        '& span:last-child': {
            fontSize: 12,
            padding: 2,
            verticalAlign: -11,
        },
    },
});

const ReportsPage = ({ title, data }) => {
    const classes = useStyles();
    const [collapsed, setCollapsed] = useState(false);

    const getProperty = (dataIn, key) => {
        const result = dataIn.filter(i => i.type === key);
        if (result && result.length > 0) {
            return `${result[0].count} `;
        }
        return '0 ';
    };

    return (
        <Card className={classes.dashboardSummary}>
            <div
                style={{ marginTop: 0 }}
                onClick={() => setCollapsed(!collapsed)}
                onKeyPress={() => setCollapsed(!collapsed)}
                role="button"
                tabIndex={0}
            >
                <Typography variant="h5">
                    {title}
                </Typography>
                {/* Expand / Collapse linkg */}
                <div className={classes.collapseBtn}>
                    <span>{collapsed ? 'Expand' : 'Collapse'}</span>
                    <ExpandArrowIcon expanded={collapsed} collapsedIcon="fa-chevron-up" />
                </div>
            </div>

            {!collapsed && (
                <CardContent>
                    {/* {JSON.stringify(data)} */}
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h6">
                                Tier 1
                            </Typography>
                            <Typography variant="body1">
                                {getProperty(data, 'T1')}
                                students
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h6">
                                Tier 2 and 3
                            </Typography>
                            <Typography variant="body1">
                                {getProperty(data, 'T2_T3')}
                                students
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h6">
                                Active Cohorts
                            </Typography>
                            <Typography variant="body1">
                                {getProperty(data, 'ACTIVE_COHORTS')}
                                cohorts
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h6">
                                Job Seekers
                            </Typography>
                            <Typography variant="body1">
                                {getProperty(data, 'JOB_SEEKERS')}
                                students
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            )
            }
        </Card>
    );
};

ReportsPage.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    sortOrder: state.assignments.dashboardAssignmentSortOrder,
});

export default connect(mapStateToProps)(ReportsPage);
