import React from 'react';
import {
    List,
} from '@mui/material';
import { ReactComponent as Logo } from './logo-prime-horizontal.svg';
import SideNavItem from './SideNavItem';
import makeSideNavStyles from './makeSideNavStyles';
import UserInfo from './UserInfo';

const useStyles = makeSideNavStyles();

const GraderNav = () => {
    const classes = useStyles();
    return (
        <div className={classes.sideNav}>
            <Logo className={classes.logo} />
            <List>

                {/* Assignments */}
                <SideNavItem
                    to="/assignments"
                    label="Assignments"
                    iconClass="fa fa-code"
                />

                {/* Profile */}
                <SideNavItem
                    to="/profile"
                    label="Profile"
                    iconClass="fa fa-user"
                />
                <UserInfo />
            </List>
        </div>
    );
};

export default GraderNav;
