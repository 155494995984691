import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    Button,
    Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Prompt } from 'react-router-dom';
import PageTitle from '../../PageTitle/PageTitle';
import { relativeDateTime } from '../../../helpers/DateFormatHelper';
import CompanyNotes from './CompanyNotes';
import CompanyPlacements from './CompanyPlacements';
import CreateNote from '../../../helpers/CreateNote';
import ConfirmationModal from '../../Modal/ConfirmationModal';

const styles = {
    actionButton: {
        float: 'right',
    },
    tableCell: {
        padding: '10px',
    },
    notes: {
        paddingTop: '10px',
    },
};

class CompanyDetailPage extends Component {
    state = {
        changes: false,
        confirmationModalOpen: false,
        noteId: '',
    };

    componentDidMount() {
        const { dispatch, match, sortOrder } = this.props;
        dispatch({ type: 'UNSET_COMPANY' });
        dispatch({ type: 'FETCH_COMPANY_DETAILS', payload: { companyId: match.params.id, sortOrder } });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'SET_EDITING_COMPANY_NOTE', payload: { display: false } });
    }

    addNote = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'SET_EDITING_COMPANY_NOTE', payload: { companyId: match.params.id, display: true } });
    };

    confirmDeleteNote = (noteId) => {
        this.setState({
            confirmationModalOpen: true,
            noteId,
        });
    };

    deleteNote = (noteId) => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'DELETE_COMPANY_NOTE', payload: { noteId, companyId: match.params.id } });
        this.setState({ confirmationModalOpen: false });
    };

    handleCancel = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'SET_EDITING_COMPANY_NOTE', payload: { companyId: match.params.id, display: false } });
    };

    handleChangeFor = (value) => {
        const { dispatch, match } = this.props;
        this.setState({
            changes: true,
        });
        dispatch({
            type: 'SET_EDITING_COMPANY_NOTE',
            payload: {
                content: value,
                company_id: parseInt(match.params.id, 10),
                display: true,
            },
        });
    };

    handleSubmit = () => {
        const { dispatch, editingNote } = this.props;
        this.setState({
            changes: false,
        });
        dispatch({ type: 'CREATE_COMPANY_NOTE', payload: editingNote });
    };

    sortBy = orderBy => () => {
        const {
            dispatch,
            sortOrder,
            match,
        } = this.props;
        let { order } = sortOrder;

        // Existing column selected, reverse the sort
        if (orderBy === sortOrder.orderBy) {
            if (order === 'asc') {
                order = 'desc';
            } else {
                order = 'asc';
            }
        }
        const payload = {
            companyId: match.params.id,
            orderBy,
            order,
            offset: 0,
        };
        dispatch({ type: 'SET_COMPANY_PLACEMENT_SORT_ORDER', payload });
        dispatch({ type: 'FETCH_COMPANY_PLACEMENTS', payload });
    };

    render() {
        const {
            classes,
            selectedCompany,
            companyNotes,
            placements,
            sortOrder,
            editingNote,
        } = this.props;
        const {
            changes,
            confirmationModalOpen,
            noteId,
        } = this.state;
        const pageTitle = `Company :: ${selectedCompany.name}`;
        const actions = [{
            label: 'Edit',
            // Dispatch an action...
            type: 'SET_EDITING_COMPANY',
            payload: selectedCompany,
            // ...and navigate to this page.
            path: `/companies/${selectedCompany.id}/edit`,
        }];
        let address = '';
        if (selectedCompany.address1 && selectedCompany.address1.length > 0) {
            address += `${selectedCompany.address1}, `;
        }
        if (selectedCompany.address2 && selectedCompany.address2.length > 0) {
            address += `${selectedCompany.address2}, `;
        }
        address += `${selectedCompany.city}, ${selectedCompany.state} ${selectedCompany.zip_code}`;
        return (
            <>
                <Prompt
                    // Use a standard alert dialog if the user leaves the page when
                    // changes are detected.
                    when={changes}
                    message="You have unsaved edits. Are you sure you want to leave?"
                />
                <div className="container">
                    <PageTitle
                        title={pageTitle}
                        actions={actions}
                    />
                    <Paper>
                        <h5>
                            {selectedCompany.name}
                        </h5>
                        <br />
                        <p>
                            <b>Recruiter?</b>
                            <br />
                            {
                                selectedCompany.recruiter
                                    ? 'Yes'
                                    : 'No'
                            }
                            <br />
                        </p>
                        <p>
                            <b>Active?</b>
                            <br />
                            {
                                selectedCompany.active
                                    ? 'Yes'
                                    : 'No'
                            }
                            <br />
                        </p>
                        <p>
                            <b>Phone:</b>
                            <br />
                            {selectedCompany.phone ? `${selectedCompany.phone}` : ''}
                            <br />
                        </p>
                        <p>
                            <b>Address:</b>
                            <br />
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://maps.google.com/maps?q=${address}`}
                            >
                                {address}
                            </a>
                            <br />
                        </p>
                        {
                            selectedCompany.website_link
                                && selectedCompany.website_link.length > 0
                                    && (
                                        <p>
                                            <b>Website:</b>
                                            <br />
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={selectedCompany.website_link}
                                            >
                                                {selectedCompany.website_link}
                                            </a>
                                            <br />
                                        </p>
                                    )

                        }
                        {
                            selectedCompany.jobs_link
                                && selectedCompany.jobs_link.length > 0
                                    && (
                                        <p>
                                            <b>Jobs:</b>
                                            <br />
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={selectedCompany.jobs_link}
                                            >
                                                {selectedCompany.jobs_link}
                                            </a>
                                            <br />
                                        </p>
                                    )

                        }
                        {
                            (
                                placements.last_placement
                            )
                            && (
                                <p>
                                    Last hire was
                                    {' '}
                                    {relativeDateTime(placements.last_placement)}

                                </p>
                            )
                        }
                    </Paper>
                    {
                        editingNote.display
                            && (
                                <CreateNote
                                    handleChangeFor={this.handleChangeFor}
                                    handleCancel={this.handleCancel}
                                    handleSubmit={this.handleSubmit}
                                    editingNote={editingNote}
                                />
                            )}

                    <Paper>
                        <div style={{ marginBottom: '20px' }}>
                            <h5 style={{ display: 'inline' }}>
                                Notes
                            </h5>
                            <Button
                                className={classes.actionButton}
                                variant="outlined"
                                type="button"
                                onClick={this.addNote}
                            >
                                Add Note
                            </Button>
                        </div>

                        <Divider />

                        {
                            (
                                companyNotes
                            && companyNotes.length > 0
                            ) ? (
                                    companyNotes.map(notes => (
                                        <CompanyNotes
                                            key={notes.id}
                                            companyNotes={notes}
                                            confirmDeleteNote={this.confirmDeleteNote}
                                        />
                                    ))
                                ) : (
                                    <p className={classes.notes}>No notes for this company</p>
                                )
                        }
                    </Paper>
                    <Paper>
                        <h5>
                            Placements
                        </h5>
                        {
                            (
                                placements.rows
                            && placements.rows.length > 0
                            )
                                ? (
                                    <CompanyPlacements
                                        sortBy={this.sortBy}
                                        sortOrder={sortOrder}
                                        placements={placements.rows}
                                        classes={classes}
                                    />
                                ) : (
                                    <p> No students have been placed at this company </p>
                                )
                        }
                    </Paper>
                    <ConfirmationModal
                        open={confirmationModalOpen}
                        dialogTitle="Delete Note"
                        dialogMessage={"You're about to delete a company note. Would you like to continue?"}
                        onCancel={() => this.setState({ confirmationModalOpen: false })}
                        onConfirm={() => this.deleteNote(noteId)}
                    />
                </div>
            </>
        );
    }
}

CompanyDetailPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    selectedCompany: PropTypes.instanceOf(Object).isRequired,
    companyNotes: PropTypes.instanceOf(Object).isRequired,
    editingNote: PropTypes.instanceOf(Object).isRequired,
    placements: PropTypes.instanceOf(Object).isRequired,
    sortOrder: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    selectedCompany: state.company.selectedCompany,
    companyNotes: state.company.companyNotes,
    placements: state.placement.companyPlacements,
    sortOrder: state.placement.companyPlacementSortOrder,
    editingNote: state.company.editingNote,
});

export default connect(mapStateToProps)(withStyles(styles)(CompanyDetailPage));
