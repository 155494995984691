import React from 'react';
import {
    Grid, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const MissingFields = ({
    classes,
    errors,
}) => (
    <Grid container spacing={2} className={classes.yellowBackground}>
        <Grid item xs={12}>
            { errors && errors.length
                ? (
                    <Typography className={classes.missingFieldsTitle}>
                        Please address the items below before submitting:
                    </Typography>
                )
                : (
                    <Typography className={classes.missingFieldsTitle}>
                        Congratulations, it looks like you&apos;re ready to submit!
                    </Typography>
                )
            }
            {
                errors && errors.map(error => (
                    <Typography key={error.message}>
                        {error.message}
                        <Link to={`./${error.stepLink}`} className={classes.missingFieldLink}>
                            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                            Go back to the <strong>{error.stepName} tab</strong> to complete
                        </Link>
                    </Typography>
                ))
            }
        </Grid>
    </Grid>
);

MissingFields.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    errors: PropTypes.instanceOf(Array).isRequired,
};

export default MissingFields;
