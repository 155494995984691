import React, { useEffect, useState } from 'react';
import {
    Paper,
    Grid,
    Typography,
    Button,
    Box, Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ReactSelect from 'react-select';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { grey } from '@mui/material/colors';
import CodeBlock from '../../../General/CodeBlock';

import InterviewHeader from '../../../General/InterviewSection/InterviewHeader';
import {
    collaborationInfo,
    endInfo, environmentInfo, growthInfo,
    learningInfo,
    strengthInfo,
} from '../../../General/InterviewSection/InterviewSectionCopy';
import InterviewChecklistItem from '../../../General/InterviewSection/InterviewChecklistItem';
import InterviewSummary from '../../../General/InterviewSection/InterviewSummary';
import InterviewSection from '../../../General/InterviewSection/InterviewSection';

const styles = theme => ({
    tipsList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        position: 'relative',
        right: '18px',
    },
    heading: {
        fontWeight: 500,
        color: 'black',
    },
    question: {
        paddingTop: '20px',
        paddingBottom: '10px',
        fontWeight: 300,
        color: 'black',
        '& div': {
            paddingTop: '5px',
            paddingBottom: '5px',
        },
    },
    score: {
        maxWidth: '200px',
        margin: '10px 0',
        paddingBottom: '10px',
    },
    summaryWrapper: {
        padding: '0 10px',
    },
    responseBox: {
        backgroundColor: '#EEE',
        padding: '16px',
        marginTop: '30px',
    },
    container: {
        paddingTop: '40px',
        paddingBottom: '50px',
        maxWidth: '720px',
        minWidth: '70%',
        margin: 'auto',
        borderRadius: 0,
        position: 'relative',
        top: 2,
    },
    selectWrapper: {
        maxWidth: '256px',
    },
    profileAnswer: {
        padding: '10px 0',
    },
    secondaryText: {
        fontWeight: 500,
    },
    notesContainer: {
        margin: '10px 0',
    },
    saveButton: {
        maxWidth: '920px',
        margin: 'auto',
    },
    headingContainer: {
        background: '#FDF2D0',
        padding: '15px',
        margin: '15px 0',
        borderRadius: '8px',
    },
    caption: {
        margin: '10px 0',
    },
    actionButtons: {
        display: 'flex',
        gap: '10px',
    },
    secondaryButton: {
        border: grey['800'],
    },
});
const interviewSections = [
    learningInfo,
    collaborationInfo,
    strengthInfo,
    growthInfo,
    environmentInfo,
];
const nextStepOptions = [
    { value: 0, label: 'Admit' },
    { value: 1, label: 'Discuss' },
    { value: 2, label: 'Decline' },
];
const InterviewTab = ({
    value,
    index,
    handleScoreChange,
    interviewScores,
    applicationInterviewReview,
    editingInterviewScore,
    submitInterviewReview,
    user,
    editingApplication,
    handleScoreSubmission,
    classes,
}) => {
    const [selectedTab, setSelectedTab] = useState('write');
    const [editing, setEditing] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [nextSteps, setNextSteps] = useState(0);
    const dispatch = useDispatch();

    const handleSummaryChange = (content) => {
        dispatch({
            type: 'SET_EDITING_APPLICATION_DETAILS',
            payload: {
                ...editingApplication,
                interview_summary: content,
            },
        });
    };

    const handleNextStepsChange = (event) => {
        if (!event) return;

        setNextSteps(event);
        dispatch({
            type: 'SET_EDITING_APPLICATION_DETAILS',
            payload: {
                ...editingApplication,
                interview_next_steps: event.value,
            },
        });
    };
    const validate = () => {
        // checking section scores
        let valid = true;

        // we have defined questions with ids ranging from 1-5 on the interview copy file
        for (let i = 1; i < 5; i += 1) {
            const review = applicationInterviewReview.get(i);
            if (!review || !review.score) {
                valid = false;
            }
        }
        // checking overall score and note
        if (
            editingApplication.interview_summary === ''
            && editingInterviewScore.value === ''
        ) {
            valid = false;
        }
        return valid;
    };

    const handleSave = () => {
        submitInterviewReview();
        if (editingInterviewScore.value) {
            handleScoreSubmission('interview', editingInterviewScore.value);
        }
        dispatch({
            type: 'PUT_APPLICATION_DETAILS',
            payload: editingApplication,
        });
    };

    const handleSubmit = () => {
        submitInterviewReview();
        handleScoreSubmission('interview', editingInterviewScore.value);
        dispatch({
            type: 'PUT_APPLICATION_DETAILS',
            payload: {
                ...editingApplication,
                interview_completed: true,
                interview_submitted_by: user.id,
                interview_submitted_at: new Date(),
            },
        });
        setEditing(false);
    };

    useEffect(() => {
        if (editingApplication?.interview_completed && firstLoad) {
            setFirstLoad(false);
            setEditing(false);
        }
        if (!editingApplication.interview_completed) {
            setEditing(true);
        }
    }, [editingApplication.interview_completed]);

    useEffect(() => {
        if (editingApplication.interview_next_steps !== null) {
            const foundOption = nextStepOptions.find(
                (option) => editingApplication.interview_next_steps === option.value);
            setNextSteps(foundOption);
        }
    }, [editingApplication]);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Grid container direction="row">
                    <Paper className={classes.container}>
                        {!editing
                            && (
                                <InterviewSummary
                                    handleScoreChange={handleScoreChange}
                                    setEditing={setEditing}
                                    sections={interviewSections}
                                />
                            )

                        }
                        {
                            editing
                            && (
                                <>
                                    <Typography className={classes.heading} variant="h2">
                                        Interview Questions
                                    </Typography>
                                    <Typography>
                                        Use the checkboxes to keep track
                                        of what questions have been asked.
                                    </Typography>
                                    <Typography>

                                        Use the category’s text field and dropdown to
                                        keep track of notes and scores for each category.
                                    </Typography>
                                    <Typography>
                                        Use notes to provide rationales
                                        to scores. Specific examples,
                                        such as patterns of behavior
                                        or thought, make for great notes.
                                    </Typography>
                                    {interviewSections.map((section) => (
                                        <InterviewSection
                                            key={section.questionId}
                                            section={section}
                                            submitInterviewReview={submitInterviewReview}
                                        />
                                    ))}
                                    <InterviewHeader
                                        header={endInfo.header}
                                        bullets={endInfo.bullets}
                                        secondary={endInfo.secondary}
                                        color={endInfo.color}
                                    />
                                    {endInfo.checkList.map((item) => (
                                        <InterviewChecklistItem
                                            key={item.title}
                                            followUp={item.followUp}
                                            checked={item.checked}
                                            questionId={6}
                                            title={item.title}
                                            boxes={item.boxes}
                                        />
                                    ))}
                                    <br />
                                    <br />

                                    <Divider />
                                    <br />
                                    <br />
                                    <Box className={classes.summaryWrapper}>
                                        <Typography variant="h4" className={classes.heading}> Interview summary </Typography>
                                        <Box className={classes.caption}>

                                            <Typography variant="caption">  Describe your thoughts about this interview in a few sentences. </Typography>
                                        </Box>

                                        <ReactMde
                                            value={editingApplication.interview_summary}
                                            onChange={handleSummaryChange}
                                            selectedTab={selectedTab}
                                            onTabChange={setSelectedTab}
                                            minEditorHeight={120}
                                            minPreviewHeight={120}
                                            textAreaProps={{ placeholder: 'Add a note...' }}
                                            generateMarkdownPreview={markdown => (
                                                Promise.resolve(
                                                    <ReactMarkdown
                                                        components={{ code: CodeBlock }}
                                                        remarkPlugins={[remarkGfm]}
                                                    >
                                                        {markdown}
                                                    </ReactMarkdown>,
                                                )
                                            )}
                                        />

                                        <br />
                                        <Typography variant="h6" className={classes.heading}> Overall score </Typography>
                                        <ReactSelect
                                            placeholder="Interview Score.."
                                            value={editingInterviewScore}
                                            onChange={event => handleScoreChange(event, 'interview')}
                                            className={classes.score}
                                            options={interviewScores}
                                            styles={{
                                            // Fixes the overlapping problem of the component
                                                menu: provided => ({ ...provided, zIndex: 9999 }),
                                            }}
                                        />
                                        <Typography variant="h6" className={classes.heading}> Recommended Next Steps </Typography>
                                        <ReactSelect
                                            placeholder="Next Steps... "
                                            onChange={handleNextStepsChange}
                                            value={nextSteps}
                                            className={classes.score}
                                            options={nextStepOptions}
                                            styles={{
                                            // Fixes the overlapping problem of the component
                                                menu: provided => ({ ...provided, zIndex: 9999 }),
                                            }}
                                        />
                                    </Box>

                                    <br />
                                    <br />
                                    <Box className={classes.actionButtons}>
                                        <Button
                                            variant="outlined"
                                            sx={{ border: '1px solid grey' }}
                                            onClick={() => {
                                                handleSave();
                                            }}
                                        >
                                            Save
                                        </Button>
                                        {!editing
                                            ? (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        handleSave();
                                                    }}
                                                >
                                                    Update
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        if (validate()) {
                                                            handleSubmit();
                                                        } else {
                                                            dispatch({
                                                                type: 'SET_GLOBAL_MODAL',
                                                                payload: {
                                                                    modalOpen: true,
                                                                    title: 'Missing Fields',
                                                                    body: 'Interview score for each section, overall score and overall note are required.',
                                                                },
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            )
                                        }

                                    </Box>

                                </>
                            )

                        }

                    </Paper>
                </Grid>
            )}
        </div>
    );
};

InterviewTab.propTypes = {
    editingInterviewScore: PropTypes.instanceOf(Object).isRequired,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    handleScoreChange: PropTypes.func.isRequired,
    interviewScores: PropTypes.instanceOf(Array).isRequired,
    applicationInterviewReview: PropTypes.instanceOf(Object).isRequired,
    submitInterviewReview: PropTypes.func.isRequired,
    handleScoreSubmission: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    editingApplication: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    interviewScores: state.application.interviewScores,
    editingInterviewScore: state.application.editingInterviewScore,
    applicationInterviewReview: state.application.applicationInterviewReview,
    editingApplication: state.application.editingApplicationDetails,

});

export default connect(mapStateToProps)(withStyles(styles)(InterviewTab));
