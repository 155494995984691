import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Divider, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import remarkGfm from 'remark-gfm';
import CodeBlock from '../../General/CodeBlock';
import { shortDateTime } from '../../../helpers/DateFormatHelper';

const styles = {};

const CompanyNotes = ({
    companyNotes,
    confirmDeleteNote,
}) => (
    <>
        <div>
            <br />
            <Button style={{ float: 'right' }} variant="outlined" onClick={() => confirmDeleteNote(companyNotes.id)}> Delete </Button>
            <p>
                <strong>
                    {`By: ${companyNotes.employee.first_name} ${companyNotes.employee.last_name}`}
                </strong>
            </p>
            <ReactMarkdown
                align="left"
                remarkPlugins={[remarkGfm]}
                components={{ code: CodeBlock }}
                linkTarget="_blank"
            >
                {companyNotes.content}
            </ReactMarkdown>
            <br />
            <p>{` ${shortDateTime(companyNotes.created_at)}`}</p>
            <br />
            <Divider />
        </div>

    </>
);

CompanyNotes.propTypes = {
    companyNotes: PropTypes.instanceOf(Object).isRequired,
    confirmDeleteNote: PropTypes.func.isRequired,
};
export default withStyles(styles)(CompanyNotes);
