import React from 'react';
import {
    List,
    Paper,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CohortWaitlistRow from './CohortWaitlistRow';

const styles = {
    card: {
        margin: '15px',
    },
    cardContent: {
        marginLeft: '10px',
        marginRight: '10px',

    },
    cardHeading: {
        display: 'flex',
        marginBottom: '5px',
    },
    cardHeadingText: {
        width: '100%',
    },
};
const CohortWaitlistList = ({ cohort, viewStudentDetails, user }) => (

    <Paper>
        <h3>Waitlisted Students</h3>
        <List>
            {
                cohort.waitlist
                            && cohort.waitlist.map(row => (
                                <CohortWaitlistRow
                                    key={row.id}
                                    student={row.user}
                                    waitlist={row}
                                    viewStudentDetails={viewStudentDetails}
                                    user={user}
                                />
                            ))
            }
        </List>
    </Paper>
);

CohortWaitlistList.propTypes = {
    cohort: PropTypes.instanceOf(Object).isRequired,
    viewStudentDetails: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default withStyles(styles)(CohortWaitlistList);
