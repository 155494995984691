import { Container, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    headingContainer: {
        padding: '15px',
        margin: '15px 0',
        borderRadius: '8px',
    },
    heading: {
        fontWeight: 500,
        color: 'black',
    },
});
const InterviewHeader = ({
    header,
    secondary,
    bullets,
    classes,
    color,
}) => (
    <Container sx={{ background: color }} className={classes.headingContainer}>
        <Typography className={classes.heading} variant="h4">
            {header}
        </Typography>
        {bullets
        && (
            <ul>
                {bullets.map((bullet) => (
                    <li key={bullet}>{bullet}</li>
                ))}
            </ul>
        )
        }
        {secondary && <Typography>{secondary}</Typography>}

    </Container>

);
InterviewHeader.defaultProps = {
    secondary: null,
    bullets: [],
};

InterviewHeader.propTypes = {

    header: PropTypes.string.isRequired,
    secondary: PropTypes.string,
    color: PropTypes.string.isRequired,
    bullets: PropTypes.array,
    classes: PropTypes.instanceOf(Object).isRequired,

};

export default withStyles(styles)(InterviewHeader);
