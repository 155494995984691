import React from 'react';
import {
    Card,
    CardContent,
    Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';

const styles = {
    statCardTitle: {
        fontSize: '16px',
        fontWeight: '600',
    },
};
const CohortEmpStats = ({ cohort, classes }) => {
    const chartOptions = {
        title: {
            display: false,
            text: 'Education level',
            align: 'start',
        },
        indexAxis: 'y',
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        suggestedMax: 5,
                    },
                },
            ],
        },
        tooltips: {
            callbacks: {
                label(tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = cohort.students ? cohort.students.length : 0;
                    const currentValue = dataset.data[tooltipItem.index];
                    const percentage = Math.floor(((currentValue / total) * 100) + 0.5);

                    return `${currentValue} (${percentage}%)`;
                },
            },
        },
    };

    const chartData = {};

    const studentList = cohort.students;
    if (studentList) {
        const counts = [
            { label: 'Full-Time', count: studentList.filter(student => student.employment_status === 'Employed Full-time').length },
            { label: 'Part-Time', count: studentList.filter(student => student.employment_status === 'Employed Part-time').length },
            { label: 'Self-Employed', count: studentList.filter(student => (student.employment_status === 'Self Employed' || student.employment_status === 'Self-Employed')).length },
            { label: 'Unemployed', count: studentList.filter(student => student.employment_status === 'Unemployed').length },
        ];
        counts.sort((a, b) => ((a.count < b.count) ? 1 : -1));
        const sortedData = counts.map(count => count.count);
        const sortedLabels = counts.map(count => count.label);
        chartData.labels = sortedLabels;
        const data = {
            data: sortedData,
            axis: 'y',
            backgroundColor: '#5569D0',
        };
        chartData.datasets = [data];
    }
    return (
        <Grid item style={{ display: 'flex' }} xs={12} sm={6} lg={4}>
            <Card elevation={1} style={{ width: '100%' }}>
                <CardContent>
                    <span className={classes.statCardTitle}>Employment Status</span>
                    <br />
                    <br />
                    <HorizontalBar data={chartData} options={chartOptions} />
                </CardContent>
            </Card>
        </Grid>
    );
};

CohortEmpStats.propTypes = {
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default withStyles(styles)(CohortEmpStats);
