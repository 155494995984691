import { useEffect, useRef } from 'react';
import 'prismjs/themes/prism.css';

import Prism from 'prismjs';

// Languages must be imported individually,
// to support syntax highlighting
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-csv';
import 'prismjs/components/prism-docker';
import 'prismjs/components/prism-git';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-mongodb';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-regex';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-yaml';

const React = require('react');
const PropTypes = require('prop-types');

function CodeBlock({
    children,
    className,
    inline,
}) {
    const codeRef = useRef();

    useEffect(() => {
        if (codeRef.current) {
            Prism.highlightElement(codeRef.current);
        }
    }, [className, children]);
    const match = /language-(\w+)/.exec(className || 'language-js');
    return (
        <>
            {
                inline ? (
                    <code ref={codeRef} className={`language-${match[1]}`}>
                        {children}
                    </code>
                ) : (
                    <pre>
                        <code ref={codeRef} className={`language-${match[1]}`}>
                            {children}
                        </code>
                    </pre>
                )
            }
        </>

    );
}

CodeBlock.defaultProps = {
    children: '',
    className: 'language-js',
};

CodeBlock.propTypes = {
    children: PropTypes.string,
    className: PropTypes.string,
    inline: PropTypes.bool.isRequired,
};

export default CodeBlock;
