import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import {
    Paper,
    Button,
    Icon,
    Tabs,
    Tab,
} from '@mui/material';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';

const {
    GRADER,
} = require('../General/UserTypes');

const styles = theme => ({
    secondaryNavigation: {
        padding: '0px 15px 0px 40px',
    },
    navigationLink: {
        color: '#222 !important',
        textTransform: 'capitalize',
    },
    navigationLinkSelected: {
        textTransform: 'capitalize',
    },
    spacer: {
        flex: 1,
    },
    content: {
        padding: '24px 24px 24px 24px',
        display: 'flex',
        alignItems: 'center',
    },
    actionButton: {
        margin: '0px 0px 0px 15px',
    },
    backButton: {
        margin: '40px 0px 0px 40px',
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        marginLeft: -30,
    },
});

const PageTitle = ({
    title,
    navigation,
    classes,
    actions,
    dispatch,
    drawerOpen,
    history,
    user,
}) => (
    <Paper className="page-title" square elevation={1}>
        {/* This takes up too much screen space and the browser back works for this */}
        {/* <Button className={classes.backButton} variant="outlined">Back</Button> */}
        <div className={classes.content}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Button className={classes.menuButton} onClick={() => dispatch({ type: 'SET_DRAWER_STATE', payload: { drawerOpen: !drawerOpen } })} type="button">
                <Icon
                    className={classNames(classes.icon, 'fa fa-bars')}
                />
            </Button>
            <h3 className={classes.pageTitle}>{title}</h3>
            <div className={classes.spacer} />
            {
                actions
                && actions.length > 0
                && (user.role !== GRADER || (user.role === GRADER && actions[0].label === 'Change Password'))
                && (
                    <>
                        {actions.map(action => (
                            <Button
                                key={action.label}
                                className={classes.actionButton}
                                variant={action.variant || 'outlined'}
                                color={action.color || 'inherit'}
                                type="button"
                                onClick={() => {
                                    if (action.onClick) {
                                        action.onClick();
                                        return;
                                    }
                                    dispatch(action);
                                    if (action.path) {
                                        history.push(action.path, action.state);
                                    }
                                }}
                            >
                                {action.label}
                            </Button>
                        ))}
                    </>
                )
            }
        </div>
        {
            navigation
            && navigation.length > 0
            && (
                <>
                    <div className={classes.secondaryNavigation}>
                        {/*
                          * We're using tabs with links so we have to define a variable
                          * for the selected tab.
                          */}
                        <Tabs value="selected" indicatorColor="primary">
                            {navigation.map(item => (
                                item.selected ? (
                                    <Tab
                                        label={item.name}
                                        className={classes.navigationLinkSelected}
                                        value="selected"
                                    />
                                ) : (
                                    <Tab
                                        label={item.name}
                                        to={item.path}
                                        className={classes.navigationLink}
                                        component={Link}
                                    />
                                )
                            ))}
                        </Tabs>
                    </div>
                </>
            )
        }
    </Paper>
);

PageTitle.defaultProps = {
    navigation: [],
    actions: [],
};

PageTitle.propTypes = {
    dispatch: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    navigation: PropTypes.instanceOf(Array),
    user: PropTypes.instanceOf(Object).isRequired,
    actions: PropTypes.instanceOf(Array),
    classes: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    drawerOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    drawerOpen: state.drawer.drawerOpen,
});

export default connect(mapStateToProps)(withStyles(styles)(withRouter(PageTitle)));
