import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    TableRow,
    TableCell,
    Button,
    Hidden,
    Checkbox,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import {
    completedText,
    gradedText,
    draftedText,
    dueAtText,
} from '../../../helpers/CourseWorkTextHelper';
import { shortDateTime } from '../../../helpers/DateFormatHelper';
import TableCellLink from '../../General/TableCellLink';

const styles = {
    tableRow: {
        cursor: 'pointer',
        '& td': {
            position: 'relative',
        },
    },
    smallText: {
        fontSize: '11px',
        color: '#999',
    },
};

const AssignmentListRow = ({
    assignment,
    assignmentsToCopy,
    viewAssignmentDetails,
    classes,
    handleRowCheckbox,
    displayCheckboxes,
}) => (
    <TableRow
        className={classes.tableRow}
        hover
    >
        {
            displayCheckboxes
            && (
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={assignmentsToCopy.indexOf(assignment.id) >= 0}
                        onClick={event => handleRowCheckbox(event, assignment)}
                    />
                </TableCell>
            )
        }
        <TableCellLink to={`/assignments/${assignment.id}`} first>
            {assignment.name}
            <br />
            <Hidden mdDown>
                {
                    assignment.tags
                && assignment.tags.map((tag, i, arr) => {
                    // The last tag won't have a comma
                    let result = <span key={tag.id} className={classes.smallText}>{`${tag.name}`}</span>;
                    // All other tags are followed by a comma
                    if (i < arr.length - 1) {
                        result = <span key={tag.id} className={classes.smallText}>{`${tag.name}, `}</span>;
                    }
                    return result;
                })
                }
            </Hidden>
            <Hidden mdUp>
                <Link onClick={e => e.stopPropagation()} to={`/cohorts/${assignment.cohort.id}`}>
                    {assignment.cohort.name}
                </Link>
            </Hidden>
        </TableCellLink>
        <Hidden mdDown>
            <TableCell>
                <Link onClick={e => e.stopPropagation()} to={`/cohorts/${assignment.cohort.id}`}>
                    {assignment.cohort.name}
                </Link>
            </TableCell>
        </Hidden>
        <TableCellLink to={`/assignments/${assignment.id}`}>
            {
                completedText(assignment)
            }
        </TableCellLink>
        <Hidden mdDown>
            <TableCellLink to={`/assignments/${assignment.id}`}>
                {
                    gradedText(assignment)
                }
            </TableCellLink>
            <TableCellLink to={`/assignments/${assignment.id}`}>
                {
                    draftedText(assignment)
                }
            </TableCellLink>
        </Hidden>
        <TableCellLink to={`/assignments/${assignment.id}`}>
            {
                dueAtText(assignment)
            }
        </TableCellLink>
        <Hidden mdDown>
            <TableCellLink to={`/assignments/${assignment.id}`}>
                {
                    assignment.assigned_at ? (
                        shortDateTime(assignment.assigned_at)
                    ) : (
                        'Not assigned'
                    )
                }
            </TableCellLink>
        </Hidden>
        <Hidden mdDown>
            <TableCell align="right">
                <Button variant="outlined" onClick={viewAssignmentDetails(assignment)} type="button">View</Button>
            </TableCell>
        </Hidden>
    </TableRow>
);

AssignmentListRow.propTypes = {
    assignmentsToCopy: PropTypes.instanceOf(Array).isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    viewAssignmentDetails: PropTypes.func.isRequired,
    handleRowCheckbox: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    displayCheckboxes: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    assignmentsToCopy: state.assignments.assignmentsToCopy,
});

export default connect(mapStateToProps)(withStyles(styles)(AssignmentListRow));
