import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    progressFill: {
        backgroundColor: theme.palette.primary.main,
        height: '5px',
        borderRadius: '3px',
    },
    progressEmpty: {
        backgroundColor: '#ccc',
        height: '5px',
        width: '90px',
        borderRadius: '3px',
    },
});

const ProgressBar = ({ fillPercent, classes }) => (
    <div className={classes.progressEmpty}>
        {
            !Number.isNaN(fillPercent)
            && <div className={classes.progressFill} style={{ width: `${fillPercent}%` }} />
        }
    </div>
);

ProgressBar.propTypes = {
    fillPercent: PropTypes.number.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ProgressBar);
