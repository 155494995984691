import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import {
    Paper,
    Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import AlertModal from '../Modal/AlertModal';

const styles = {
};

class LoadingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertModalOpen: false,
            alertTitle: 'Session Timeout',
            alertMessage: 'We were unable to load your session. Please try to login again.',
        };
    }

    componentDidMount() {
        // const { dispatch } = this.props;
        // dispatch({ type: 'FETCH_USER' });
        this.searchTimeout = setTimeout(() => {
            this.setState({ alertModalOpen: true });
        }, 10000);
    }

    componentWillUnmount() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = null;
    }

    displayLoginScreen = () => {
        const { dispatch } = this.props;
        this.setState({ alertModalOpen: false });
        dispatch({
            type: 'UNSET_USER',
        });
    };

    render() {
        const { alertModalOpen, alertTitle, alertMessage } = this.state;
        return (
            <div className="container">
                <Paper>
                    <Grid container justifyContent="center">
                        Loading...
                    </Grid>
                </Paper>
                <AlertModal
                    open={alertModalOpen}
                    dialogTitle={alertTitle}
                    dialogMessage={alertMessage}
                    onClose={() => {
                        this.displayLoginScreen();
                    }}
                />
            </div>
        );
    }
}

LoadingPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
};

// Instead of taking everything from state, we just want the user info.
// if you wanted you could write this code like this:
// const mapStateToProps = ({user}) => ({ user });
const mapStateToProps = state => ({
    user: state.user,
});

// this allows us to use <App /> in index.js
export default connect(mapStateToProps)(withStyles(styles)(LoadingPage));
