export const STUDENT = 'student';
export const APPLICANT = 'applicant';
export const ADJUNCT = 'adjunct';
export const GRADER = 'grader';
export const CAREER = 'career';
export const STUDENT_LIFE = 'student_life';
export const EMPLOYEE = 'faculty';
export const DIRECTOR = 'director';
export const ADMIN = 'admin';
export const ALL_STAFF = [CAREER, STUDENT_LIFE, EMPLOYEE, DIRECTOR, ADMIN];

export default {
    STUDENT,
    ADJUNCT,
    GRADER,
    CAREER,
    STUDENT_LIFE,
    EMPLOYEE,
    DIRECTOR,
    ADMIN,
    ALL_STAFF,
};
