import { combineReducers } from 'redux';

// Modal that appears on the assignment page
const modalOpen = (state = false, action) => {
    switch (action.type) {
    case 'SET_DAILY_FEEDBACK_MODAL':
        return action.payload.modalOpen;
    default:
        return state;
    }
};

const selectedFeedback = (state = {}, action) => {
    switch (action.type) {
    case 'SET_SELECTED_DAILY_FEEDBACK':
        return action.payload;
    case 'UNSET_SELECTED_DAILY_FEEDBACK':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const selectedFeedbackIndex = (state = 0, action) => {
    switch (action.type) {
    case 'SET_SELECTED_DAILY_FEEDBACK_INDEX':
        return action.payload.index;
    case 'UNSET_SELECTED_DAILY_FEEDBACK_INDEX':
        return 0;
    default:
        return state;
    }
};

export default combineReducers({
    selectedFeedback,
    selectedFeedbackIndex,
    modalOpen,
});
