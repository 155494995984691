/* eslint-disable no-console */
import axios from 'axios';
import {
    put, takeLatest,
} from 'redux-saga/effects';

function* fetchStudentNotes(action) {
    try {
        const response = yield axios.get(`api/hubspot/student/notes/${action.payload}`);
        yield put({ type: 'SET_HUBSPOT_STUDENT_NOTES', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student notes - get request failed', error);
    }
}

function* fetchCommunicationPreferences(action) {
    try {
        const response = yield axios.get(`api/hubspot/communication/${action.payload}`);
        yield put({ type: 'SET_HUBSPOT_USER_COMMUNICATION', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Hubspot communication preferences - get request failed', error);
    }
}

function* hubspotSaga() {
    yield takeLatest('FETCH_HUBSPOT_STUDENT_NOTES', fetchStudentNotes);
    yield takeLatest('FETCH_HUBSPOT_USER_COMMUNICATION', fetchCommunicationPreferences);
}

export default hubspotSaga;
