import React from 'react';
import {
    Card,
    CardContent,
    Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { safariFormat } from '../../../helpers/DateFormatHelper';

const styles = {
    statCardTitle: {
        fontSize: '16px',
        fontWeight: '600',
    },
};
const getAge = (completeDate, birthday) => {
    if (!birthday) {
        return {};
    }
    return (new Date(safariFormat(completeDate)).getTime() - new Date(birthday).getTime())
        / (365 * 24 * 60 * 60 * 1000);
};
const CohortAgeStats = ({ cohort, classes }) => {
    const chartOptions = {
        title: {
            display: false,
            text: 'Age',
            align: 'start',
        },
        scales: {
            y: {
                beginAtZero: true,
            },
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        suggestedMax: 10,
                    },
                },
            ],
        },
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label(tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = cohort.students.filter(student => student.birthdate).length;
                    const currentValue = dataset.data[tooltipItem.index];
                    const percentage = Math.floor(((currentValue / total) * 100) + 0.5);

                    return `${currentValue} (${percentage}%)`;
                },
            },
        },
    };
    const chartData = {

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: [
            '15-19',
            '20-24',
            '25-29',
            '30-34',
            '35-39',
            '40-44',
            '45-49',
            '50-54',
            '55+',
        ],

    };
    const studentList2 = cohort.students;

    if (studentList2) {
        const studentList = studentList2.map(student => ({
            ...student,
            age: getAge(student.completed_at, student.birthdate),
        }));
        const count15 = studentList.filter(student => student.age >= 15
        && student.age < 20).length;
        const count20 = studentList.filter(student => student.age >= 20
            && student.age < 25).length;
        const count25 = studentList.filter(student => student.age >= 25
            && student.age < 30).length;
        const count30 = studentList.filter(student => student.age >= 30
            && student.age < 35).length;
        const count35 = studentList.filter(student => student.age >= 35
            && student.age < 40).length;
        const count40 = studentList.filter(student => student.age >= 40
            && student.age < 45).length;
        const count45 = studentList.filter(student => student.age >= 45
            && student.age < 50).length;
        const count50 = studentList.filter(student => student.age >= 50
            && student.age < 55).length;
        const count55 = studentList.filter(student => student.age >= 55).length;
        const data = {
            data: [count15, count20, count25, count30, count35, count40, count45, count50, count55],
            backgroundColor: '#5569D0',
        };
        chartData.datasets = [data];
    }
    return (
        <Grid item style={{ display: 'flex' }} xs={12} sm={6} lg={4}>
            <Card elevation={1} style={{ width: '100%' }}>
                <CardContent>
                    <span className={classes.statCardTitle}>Age</span>
                    <br />
                    <br />
                    <br />
                    <Bar data={chartData} options={chartOptions} />
                </CardContent>
            </Card>
        </Grid>
    );
};

CohortAgeStats.propTypes = {
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default withStyles(styles)(CohortAgeStats);
