import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControlLabel,
    Checkbox,
    Hidden,
    TableSortLabel,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { shortStatusText } from '../../../../helpers/CourseWorkTextHelper';
import FeedbackTooltip from '../../../General/FeedbackTooltip';
import { getCourseWorkUrl } from '../../../../helpers/PhotoHelper';
import FaIcon from '../../../../helpers/FaIcon';
import { shortDateTimeTimestamp } from '../../../../helpers/DateFormatHelper';

const styles = theme => ({
    icon: {
        margin: 0,
        padding: theme.spacing(),
        fontSize: '14px',
    },
    feedbackContainer: {
        backgroundColor: '#eee',
        padding: '20px',
    },
    feedback: {
        backgroundColor: '#fff',
        padding: '20px',
    },
    tableRow: {
        cursor: 'pointer',
    },
});

class StudentCourseWork extends Component {
    state = {
        gradedOnly: false,
        sortOrder: {
            orderBy: 'due_at',
            order: 'desc',
        },
    };

    // setState is async, and we were losing the value of event
    // by the time setState actually ran.
    // Saving in a variable allows us to remember for the callback
    // https://www.ludofischer.com/blog/reactjs-null-event-target/
    handleChange = name => (event) => {
        const { checked } = event.target;
        this.setState(previousState => (
            { ...previousState, [name]: checked }
        ));
    };

    gradedText = (work) => {
        let result = 'Not Yet Graded';
        if (work.workflow_state === 'draft' && work.drafted_by) {
            result = `${work.drafted_by} (Draft)`;
        } else if (work.employee) {
            result = `${work.employee.first_name} ${work.employee.last_name}`;
        } else if (work.assignment.assignment_type === 'not_graded') {
            result = '';
        }
        return result;
    };

    sortBy = orderBy => () => {
        const { sortOrder } = this.state;
        if (sortOrder.order === 'asc') {
            this.setState({
                sortOrder: {
                    order: 'desc',
                    orderBy,
                },
            });
        } else {
            this.setState({
                sortOrder: {
                    order: 'asc',
                    orderBy,
                },
            });
        }
    };

    isNullOrUndefined = check => (
        check === null || typeof check === 'undefined'
    );

    render() {
        const {
            courseWork,
            classes,
            viewCourseWork,
            user,
        } = this.props;
        const { gradedOnly, sortOrder } = this.state;

        let courseWorkToDisplay = [];
        if (courseWork && courseWork.course_works) {
            if (gradedOnly) {
                courseWorkToDisplay = courseWork.course_works.filter(work => work.assignment.assignment_type === 'graded');
            } else {
                courseWorkToDisplay = courseWork.course_works;
            }
            courseWorkToDisplay.sort((a, b) => {
                let aStr = !this.isNullOrUndefined(a[sortOrder.orderBy]) ? String(a[sortOrder.orderBy]) : '';
                let bStr = !this.isNullOrUndefined(b[sortOrder.orderBy]) ? String(b[sortOrder.orderBy]) : '';
                if (sortOrder.orderBy === 'due_at' || sortOrder.orderBy === 'assigned_at') {
                    aStr = !this.isNullOrUndefined(a.assignment[sortOrder.orderBy]) ? String(a.assignment[sortOrder.orderBy]) : '';
                    bStr = !this.isNullOrUndefined(b.assignment[sortOrder.orderBy]) ? String(b.assignment[sortOrder.orderBy]) : '';
                }
                if (aStr === bStr && !!b['completed_at'] && !!a['completed_at']) {
                    return b['completed_at'].localeCompare(a['completed_at']);
                }
                if (sortOrder.order === 'asc') {
                    return aStr.localeCompare(bStr);
                }
                return bStr.localeCompare(aStr);
            });
        }

        return (
            <Paper>
                <h5>Assignments</h5>
                <FormControlLabel
                    control={
                        (
                            <Checkbox
                                checked={gradedOnly}
                                onChange={this.handleChange('gradedOnly')}
                                value="gradedOnly"
                                color="primary"
                            />
                        )
                    }
                    label="Show only graded assignments"
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sortDirection={sortOrder.orderBy === 'assignmentName' && sortOrder.order}
                            >
                                <TableSortLabel
                                    active={sortOrder.orderBy === 'assignmentName'}
                                    direction={sortOrder.order}
                                    onClick={this.sortBy('assignmentName')}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sortDirection={sortOrder.orderBy === 'cohortName' && sortOrder.order}
                            >
                                <TableSortLabel
                                    active={sortOrder.orderBy === 'cohortName'}
                                    direction={sortOrder.order}
                                    onClick={this.sortBy('cohortName')}
                                >
                                    Cohort
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sortDirection={sortOrder.orderBy === 'grade' && sortOrder.order}
                            >
                                <TableSortLabel
                                    active={sortOrder.orderBy === 'grade'}
                                    direction={sortOrder.order}
                                    onClick={this.sortBy('grade')}
                                >
                                    Grade
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Graded By</TableCell>

                            <Hidden mdDown>
                                <TableCell
                                    sortDirection={sortOrder.orderBy === 'due_at' && sortOrder.order}
                                >
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'due_at'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('due_at')}
                                    >
                                        Due At
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={sortOrder.orderBy === 'completed_at' && sortOrder.order}
                                >
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'completed_at'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('completed_at')}
                                    >
                                        Completed At
                                    </TableSortLabel>
                                </TableCell>
                            </Hidden>
                            <TableCell>Feedback</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell>Completed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            courseWorkToDisplay.map(work => (
                                <TableRow
                                    key={work.id}
                                    className={classes.tableRow}
                                    hover
                                    onClick={viewCourseWork(work, work.assignment)}
                                >
                                    <TableCell>{work.assignment.name}</TableCell>
                                    <TableCell>
                                        <Link onClick={e => e.stopPropagation()} to={`/cohorts/${work.assignment.cohort_id}`}>{work.cohortName}</Link>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            !Number.isNaN(work.grade)
                                            && work.grade !== null
                                            && `${work.grade}`
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            this.gradedText(work)
                                        }
                                    </TableCell>

                                    <Hidden mdDown>
                                        <TableCell>
                                            {
                                                work.assignment.due_at
                                                && (
                                                    shortDateTimeTimestamp(
                                                        work.assignment.due_at,
                                                    )
                                                )
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                work.completed_at
                                                && (
                                                    shortDateTimeTimestamp(
                                                        work.completed_at,
                                                    )
                                                )
                                            }
                                        </TableCell>
                                    </Hidden>
                                    <TableCell>
                                        {
                                            work.instructor_comment
                                            && (
                                                // Not sure if it makes sense to have a
                                                // feedback bubble here
                                                <FeedbackTooltip
                                                    title="Instructor Comments"
                                                    tooltip={
                                                        `${work.instructor_comment.substring(0, 120)}... (click to view more)`
                                                    }
                                                />
                                            )
                                        }
                                    </TableCell>

                                    <TableCell>
                                        {
                                            work.file_name
                && work.file_content_type
                && (
                    <span>
                        {work.file_content_type.slice(-3)}
                        <a
                            onClick={e => e.stopPropagation()}
                            download
                            href={getCourseWorkUrl(
                                user.s3_bucket,
                                work.id,
                                work.file_name,
                            )}
                            target="_blank"
                            rel="noreferrer"
                        >

                            <FaIcon
                                icon="link"
                                iconType="fas"
                                style={{
                                    color: '#555555',
                                    fontSize: '18px',
                                    marginBottom: '-3px',
                                    marginLeft: '12px',
                                }}
                            />
                        </a>
                    </span>
                )
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            shortStatusText(
                                                work,
                                                work.assignment,
                                            )
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

StudentCourseWork.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    viewCourseWork: PropTypes.func.isRequired,
    courseWork: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(StudentCourseWork);
