/* eslint-disable max-len */
import React, {
    useEffect,
    useState,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    DialogActions,
    Button,
    Checkbox,
    Grid,
    Select,
    MenuItem,
    FormControlLabel,
    TextField,
} from '@mui/material';
import {
    Prompt,
    useParams,
} from 'react-router-dom';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { withStyles } from '@mui/styles';
import PageTitle from '../../PageTitle/PageTitle';
import CodeBlock from '../../General/CodeBlock';

const styles = theme => ({
    feedback: {
        backgroundColor: '#fff',
        maxWidth: '80ch',
    },
});

const CourseEdit = (props) => {
    const dispatch = useDispatch();
    const courseId = useParams().id;
    const { course } = props;

    const [state] = useState({
        changes: false,
    });

    const [selectedTab1, setSelectedTab1] = useState('write');
    const [selectedTab2, setSelectedTab2] = useState('write');
    const [selectedTab3, setSelectedTab3] = useState('write');
    const [selectedTab4, setSelectedTab4] = useState('write');

    const {
        classes,
        history,
    } = props;

    useEffect(() => {
        const fetchCourseDetail = async () => {
            await dispatch({ type: 'FETCH_COURSE_DETAILS', payload: { id: courseId } });
        };

        fetchCourseDetail();
    }, []);

    const handleMdeChange = propertyName => (value) => {
        const updatedCourse = {
            ...course,
            [propertyName]: value,
        };
        dispatch({ type: 'SET_EDITING_COURSE', payload: updatedCourse });
    };

    const handleSubmit = () => {
        const action = {
            type: 'PUT_COURSE',
            payload: course,
            path: '/courses',
            history,
        };
        dispatch(action);
    };

    const handleChangeFor = propertyName => (event, data) => {
        const value = event?.target?.value || data?.value;
        if (value === undefined || value === null) {
            const updatedCourse = {
                ...course,
                [propertyName]: '',
            };
            dispatch({ type: 'SET_EDITING_COURSE', payload: updatedCourse });
        } else {
            const updatedCourse = {
                ...course,
                [propertyName]: value,
            };
            dispatch({ type: 'SET_EDITING_COURSE', payload: updatedCourse });
        }
    };

    const handleActive = () => {
        const updatedCourse = {
            ...course,
            active: !course.active,
        };
        dispatch({ type: 'SET_EDITING_COURSE', payload: updatedCourse });
    };

    const handleShowTechExplanation = () => {
        const updatedCourse = {
            ...course,
            technical_challenge_explanation: !course.technical_challenge_explanation,
        };
        dispatch({ type: 'SET_EDITING_COURSE', payload: updatedCourse });
    };

    const handleAskForResume = () => {
        const updatedCourse = {
            ...course,
            ask_for_resume: !course.ask_for_resume,
        };
        dispatch({ type: 'SET_EDITING_COURSE', payload: updatedCourse });
    };

    return (
        <div className="container">
            <PageTitle
                title={course.name || ''}
                // Action buttons are created by the PageTitle component
                actions={[
                    {
                        label: 'Save Course',
                        onClick: handleSubmit,
                    },
                ]}
            />
            <Prompt
            // Use a standard alert dialog if the user leaves the page when
            // changes are detected.
                when={state && state.changes}
                message="You have unsaved edits. Are you sure you want to leave?"
            />
            <Paper>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="course-name"
                        label="Course Name"
                        className={classes.textField}
                        value={course.name}
                        onChange={handleChangeFor('name')}
                        margin="normal"
                    />
                </Grid>
                <br />
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="course-description"
                        label="Course Description"
                        className={classes.textField}
                        value={course.description || ''}
                        onChange={handleChangeFor('description')}
                        margin="normal"
                        multiline
                        rows={4}
                    />
                </Grid>
                <br />
                <FormControlLabel
                    control={
                        (
                            <Checkbox
                                checked={course.active}
                                onClick={handleActive}
                            />
                        )
                    }
                    label="Course Active"
                />
                <hr />
                <br />
                <h6>Start Description</h6>
                <br />
                <ReactMde
                    className={classes.feedback}
                    value={course.start_description || ''}
                    onChange={handleMdeChange('start_description')}
                    selectedTab={selectedTab4}
                    onTabChange={setSelectedTab4}
                    generateMarkdownPreview={markdown => (
                        Promise.resolve(
                            <ReactMarkdown
                                components={{ code: CodeBlock }}
                                remarkPlugins={[remarkGfm]}
                            >
                                {markdown}
                            </ReactMarkdown>,
                        )
                    )}
                />
                <br />
                <hr />
                <br />
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={10} sm={6}>
                        <FormControlLabel
                            control={
                                (
                                    <Checkbox
                                        checked={Boolean(course.technical_challenge_explanation)}
                                        onClick={handleShowTechExplanation}
                                    />
                                )
                            }
                            label="Show Technical Challenge Explanation"
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <h6>Technical Challenge Label</h6>
                        <Select
                            required
                            value={course.technical_challenge_label || ''}
                            onChange={handleChangeFor('technical_challenge_label')}
                            classNamePrefix="react-select"
                            className={classes.reactSelect}
                        >
                            <MenuItem value="Technical">Technical</MenuItem>
                            <MenuItem value="Creative">Creative</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <br />
                <h6>Technical Challenge Description</h6>
                <ReactMde
                    className={classes.feedback}
                    value={course.technical_challenge_description || ''}
                    onChange={handleMdeChange('technical_challenge_description')}
                    selectedTab={selectedTab1}
                    onTabChange={setSelectedTab1}
                    generateMarkdownPreview={markdown => (
                        Promise.resolve(
                            <ReactMarkdown
                                components={{ code: CodeBlock }}
                                remarkPlugins={[remarkGfm]}
                            >
                                {markdown}
                            </ReactMarkdown>,
                        )
                    )}
                />
                <br />
                <hr />
                <br />
                <h6>Profile Question Label</h6>
                <Grid item xs={12} sm={6}>
                    <Select
                        required
                        value={course.profile_question_label || ''}
                        onChange={handleChangeFor('profile_question_label')}
                        classNamePrefix="react-select"
                        className={classes.reactSelect}
                    >
                        <MenuItem value="Personal">Personal</MenuItem>
                    </Select>
                </Grid>
                <br />
                <br />
                <h6>Profile Question Description</h6>
                <ReactMde
                    className={classes.feedback}
                    value={course.profile_question_description || ''}
                    onChange={handleMdeChange('profile_question_description')}
                    selectedTab={selectedTab2}
                    onTabChange={setSelectedTab2}
                    generateMarkdownPreview={markdown => (
                        Promise.resolve(
                            <ReactMarkdown
                                components={{ code: CodeBlock }}
                                remarkPlugins={[remarkGfm]}
                            >
                                {markdown}
                            </ReactMarkdown>,
                        )
                    )}
                />
                <br />
                <hr />
                <br />
                <h6>Logic Question Label</h6>
                <br />
                <Grid item xs={12} sm={6}>
                    <Select
                        required
                        value={course.logic_question_label || ''}
                        onChange={handleChangeFor('logic_question_label')}
                        classNamePrefix="react-select"
                        className={classes.reactSelect}
                    >
                        <MenuItem value="Logic">Logic</MenuItem>
                        <MenuItem value="Reasoning">Reasoning</MenuItem>
                    </Select>
                </Grid>
                <br />
                <br />
                <h6>Logic Question Description</h6>
                <ReactMde
                    className={classes.feedback}
                    value={course.logic_question_description || ''}
                    onChange={handleMdeChange('logic_question_description')}
                    selectedTab={selectedTab3}
                    onTabChange={setSelectedTab3}
                    generateMarkdownPreview={markdown => (
                        Promise.resolve(
                            <ReactMarkdown
                                components={{ code: CodeBlock }}
                                remarkPlugins={[remarkGfm]}
                            >
                                {markdown}
                            </ReactMarkdown>,
                        )
                    )}
                />
                <br />
                <hr />
                <br />
                <FormControlLabel
                    control={
                        (
                            <Checkbox
                                checked={Boolean(course?.ask_for_resume)}
                                onClick={handleAskForResume}
                            />
                        )
                    }
                    label="Ask For Resume"
                />
                <br />
                <Grid item xs={6} sm={3}>
                    <TextField
                        required
                        id="course-slack_channel_id"
                        label="Slack Channel Id"
                        className={classes.textField}
                        value={course?.slack_channel_id || ''}
                        onChange={handleChangeFor('slack_channel_id')}
                        margin="normal"
                    />
                </Grid>
                <DialogActions>
                    <div style={{ flex: '1 0 0' }} />
                    <Button
                        onClick={handleSubmit}
                        variant="outlined"
                        type="button"
                    >
                        Save Course
                    </Button>
                </DialogActions>
            </Paper>
        </div>
    );
};

CourseEdit.defaultProps = {
    history: null,
};

CourseEdit.propTypes = {
    history: PropTypes.instanceOf(Object),
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    // TODO: Add this back when we're ready to transition away from Trello
    classes: PropTypes.instanceOf(Object).isRequired,
};

CourseEdit.propTypes = {
    course: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        active: PropTypes.bool,
        ask_for_resume: PropTypes.bool,
        start_description: PropTypes.string,
        technical_challenge_label: PropTypes.string,
        technical_challenge_description: PropTypes.string,
        technical_challenge_explanation: PropTypes.bool,
        profile_question_label: PropTypes.string,
        profile_question_description: PropTypes.string,
        logic_question_label: PropTypes.string,
        logic_question_description: PropTypes.string,
        slack_channel_id: PropTypes.string,
    }).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    course: state.course.editingCourse,
});

export default connect(mapStateToProps)(withStyles(styles)(CourseEdit));
