import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import {
    TextField,
    Button,
    Grid,
    Paper,
    Typography,
    DialogActions,
    FormLabel,
} from '@mui/material';
import { readAndCompressImage } from 'browser-image-resizer';
import ReactSelect from 'react-select';
import { withStyles } from '@mui/styles';
import PageTitle from '../PageTitle/PageTitle';
import ChangePasswordModal from '../Modal/ChangePasswordModal';
import StateAutocomplete from '../../helpers/StateAutocomplete';
import { getPhotoUrl } from '../../helpers/PhotoHelper';

const { STUDENT, APPLICANT } = require('../General/UserTypes');

const imageConfig = {
    quality: 1.0,
    maxHeight: 300,
};

const styles = {
    textField: {
        width: '100%',
    },
    formLabel: {
        padding: '10px',
        fontSize: '10pt',
    },
    reactSelect: {
        paddingTop: '2px',
        zIndex: '1000',
        width: '100%',
        padding: '0px',
        '& .react-select__control': {
            height: '39px',
            borderRadius: '3px',
            width: '100%',
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
    },
    content: {
        maxWidth: '1000px',
        margin: '15px auto',
    },
};

class ProfilePage extends Component {
    state = {
        changes: false,
        errorText: '',
    };

    componentDidMount() {
        const {
            dispatch,
        } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_CHANGE_PASSWORD_MODAL' });
    }

    handleChangeFor = propertyName => (event) => {
        const { dispatch, editingUser } = this.props;

        const updatedUser = {
            ...editingUser,
            [propertyName]: event.target.value,
        };

        this.setState({
            changes: true,
        });

        dispatch({ type: 'SET_EDITING_USER', payload: updatedUser });
    };

    handleReactSelect = propertyName => (event) => {
        const { dispatch, editingUser } = this.props;
        const updatedUser = {
            ...editingUser,
            [propertyName]: event,
        };

        this.setState({
            changes: true,
        });

        dispatch({ type: 'SET_EDITING_USER', payload: updatedUser });
    };

    handleSubmit = () => {
        const {
            selectedFile,
            resizedFile,
        } = this.state;
        const {
            editingUser,
            dispatch,
            history,
            user,
        } = this.props;
        this.setState({
            changes: false,
        });
        const updatedUser = Object.assign({}, editingUser);
        if (updatedUser.pronouns && updatedUser.pronouns.value) {
            updatedUser.pronouns = updatedUser.pronouns.value;
        }
        dispatch({
            type: 'PUT_STUDENT_PORTAL_STUDENT',
            payload: { ...updatedUser, role: user.role },
            profilePhoto: {
                selectedFile,
                resizedFile,
            },
            history,
        });
    };

    handleCancel = () => {
        const { history } = this.props;
        history.goBack();
    };

    onFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (acceptedImageTypes.includes(selectedFile.type)) {
            const copyFile = new Blob([selectedFile], { type: selectedFile.type });
            const resizedFile = await readAndCompressImage(copyFile, imageConfig);
            this.setState({
                selectedFile,
                resizedFile,
                preview: URL.createObjectURL(resizedFile),
                changes: true,
            });
        } else {
            this.setState({
                errorText: 'Invalid image file type. Must be gif, jpeg or png.',
            });
        }
    };

    render() {
        const {
            changes,
            preview,
            errorText,
        } = this.state;
        const {
            classes, user, editingUser, changePasswordModal, pronounOptions,
        } = this.props;
        let photoSrc = getPhotoUrl(
            user.s3_bucket,
            user.id,
            user.photo_file_name,
        );
        if (preview) {
            photoSrc = preview;
        }
        return (
            <>
                {(user.role === STUDENT || user.role === APPLICANT)
                    ? (
                        <>
                            <Prompt
                                // Use a standard alert dialog if the user leaves the page when
                                // changes are detected.
                                when={changes}
                                message="You have unsaved edits. Are you sure you want to leave?"
                            />
                            <PageTitle
                                title="Profile"
                                actions={[
                                    {
                                        label: 'Change Password',
                                        // Dispatch an action...
                                        type: 'SET_CHANGE_PASSWORD_MODAL',
                                    },
                                ]}
                            />
                            <Paper className={classes.content}>
                                {errorText.length > 0 && (
                                    <>
                                        <div className={classes.errorText}>{errorText}</div>
                                        <br />
                                    </>
                                )}
                                {
                                    user.role === STUDENT
                                    && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <img
                                                    className="placeholder-photo-preview"
                                                    src={photoSrc}
                                                    alt={user.first_name}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <strong>Photo</strong>
                                                <br />
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={this.onFileChange}
                                                />
                                                <br />
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                <br />
                                <br />
                                <Typography> Name and Address </Typography>
                                <form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="first-name"
                                                label="First name"
                                                className={classes.textField}
                                                value={editingUser.first_name || ''}
                                                onChange={this.handleChangeFor('first_name')}
                                                margin="dense"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="middle-name"
                                                label="Middle name"
                                                className={classes.textField}
                                                value={editingUser.middle_name || ''}
                                                onChange={this.handleChangeFor('middle_name')}
                                                margin="dense"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="last-name"
                                                label="Last name"
                                                className={classes.textField}
                                                value={editingUser.last_name || ''}
                                                onChange={this.handleChangeFor('last_name')}
                                                margin="dense"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="address"
                                                label="Address"
                                                className={classes.textField}
                                                value={editingUser.address || ''}
                                                onChange={this.handleChangeFor('address')}
                                                margin="dense"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="city"
                                                label="City"
                                                className={classes.textField}
                                                value={editingUser.city || ''}
                                                onChange={this.handleChangeFor('city')}
                                                margin="dense"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <StateAutocomplete
                                                value={editingUser.state || ''}
                                                onChange={value => this.handleChangeFor('state')({
                                                    target: { value },
                                                })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="zipcode"
                                                label="Zip code"
                                                className={classes.textField}
                                                value={editingUser.zip_code || ''}
                                                onChange={this.handleChangeFor('zip_code')}
                                                margin="dense"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="phone"
                                                label="Phone"
                                                className={classes.textField}
                                                value={editingUser.phone || ''}
                                                onChange={this.handleChangeFor('phone')}
                                                margin="dense"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormLabel className={classes.formLabel} component="label">Pronouns</FormLabel>
                                            <br />
                                            <ReactSelect
                                                value={editingUser.pronouns}
                                                onChange={this.handleReactSelect('pronouns')}
                                                options={pronounOptions}
                                                className={classes.reactSelect}
                                                classNamePrefix="react-select"
                                            />
                                        </Grid>
                                        { user.role === STUDENT && (
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Links and Social Accounts
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="linkedin"
                                                        helperText="Example: http://www.linkedin.com/in/myprofile"
                                                        label="Link to LinkedIn"
                                                        className={classes.textField}
                                                        value={editingUser.linkedin_account || ''}
                                                        onChange={this.handleChangeFor('linkedin_account')}
                                                        margin="dense"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="personal"
                                                        helperText="Example: http://myawesomewebsite.com"
                                                        label="Link to personal website"
                                                        className={classes.textField}
                                                        value={editingUser.website_link || ''}
                                                        onChange={this.handleChangeFor('website_link')}
                                                        margin="dense"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="github"
                                                        helperText="Example: primeacademy"
                                                        label="Github username"
                                                        className={classes.textField}
                                                        value={editingUser.github_account || ''}
                                                        onChange={this.handleChangeFor('github_account')}
                                                        margin="dense"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        id="Slack"
                                                        label="Slack username"
                                                        className={classes.textField}
                                                        value={editingUser.slack_username || ''}
                                                        onChange={this.handleChangeFor('slack_username')}
                                                        margin="dense"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                                <DialogActions>
                                    <Button
                                        onClick={this.handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleSubmit}
                                    >
                                        Save
                                    </Button>
                                </DialogActions>
                                <ChangePasswordModal
                                    open={changePasswordModal}
                                    // indicates that a user is changing their own password
                                    type="userChange"
                                />
                            </Paper>
                        </>
                    )
                    : (
                        <>
                            <PageTitle
                                title="Profile"
                                actions={[
                                    {
                                        label: 'Change Password',
                                        // Dispatch an action...
                                        type: 'SET_CHANGE_PASSWORD_MODAL',
                                    },
                                ]}
                            />
                            <Paper>
                                <Typography>
                                    Name:
                                    {' '}
                                    {user.first_name}
                                    {' '}
                                    {user.last_name}
                                </Typography>
                            </Paper>
                            <ChangePasswordModal
                                open={changePasswordModal}
                                // indicates that a user is changing their own password
                                type="userChange"
                            />

                        </>
                    ) }
            </>
        );
    }
}

ProfilePage.propTypes = {
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    editingUser: PropTypes.instanceOf(Object).isRequired,
    changePasswordModal: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    pronounOptions: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    editingUser: state.studentPortal.editingUser,
    changePasswordModal: state.modal.changePasswordModalOpen,
    pronounOptions: state.applicant.pronounOptions,
});

export default connect(mapStateToProps)(withStyles(styles)(ProfilePage));
