import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Table,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    TableHead,
    Paper,
    Typography,
    Icon,
    Tooltip,
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import ExpandArrowIcon from '../../General/ExpandArrowIcon';
import { safariFormat } from '../../../helpers/DateFormatHelper';

const useStyles = makeStyles({
    dashboardSummary: {
        position: 'relative',
        margin: '0 0 0 0',
        '&:hover': {
            backgroundColor: '#FAFAFA',
        },
        '&:active': {
            backgroundColor: '#F5F5F5',
        },
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',

    },
    hoverTitle: {
        marginBottom: '0px',
        '&:hover': {
            backgroundColor: '#FAFAFA',
        },
        '&:active': {
            backgroundColor: '#F5F5F5',
        },
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
    },
    searchInput: {
        width: '100%',
        marginTop: '20px',
    },
    selectLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    applicationSelect: {
        marginTop: '5px',
        width: '100%',
    },
    updateButton: {
        marginTop: '25px',
    },
    filterContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    },
    tableRow: {
        cursor: 'pointer',
    },
    emailColumn: {
        width: '280px',
    },
    nameColumn: {
        width: '180px',
    },
    statusColumn: {
        width: '180px',
    },
    graphTitle: {
        fontSize: '18px',
        textAlign: 'center',
        weight: '400',
    },
    chartDetails: {
        fontSize: '14px',
        color: '#5E5E5E',
        textAlign: 'left',
    },
    applicationCount: {
        textAlign: 'right',
    },
    chartCohort: {
        fontSize: '16px',
        color: '#222222',
    },
    applicationTableCount: {
        textAlign: 'center',
    },
    collapseBtn: {
        position: 'absolute',
        right: 0,
        textAlign: 'right',
        cursor: 'pointer',

        // Increase the "hit box" for this button
        padding: '15px 20px 20px 170px',
        marginTop: -50,

        '& span:first-child': {
            fontSize: 'inherit',
            textDecoration: 'underline',
            verticalAlign: 'bottom',
            marginRight: 5,
        },
        '& span:last-child': {
            fontSize: 12,
            padding: 2,
            verticalAlign: -11,
        },
    },
    statsCards: {
        marginTop: '0px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
    },
    dashboardStatsCards: {
        position: 'relative',
        margin: '0 0 0 0',
    },
});

function ApplicationsSummary({ dispatch, hideTitle }) {
    const applicationStats = useSelector(store => store.application.applicationStatsList);
    const graphExpanded = useSelector(store => store.application.graphExpanded);
    const classes = useStyles();
    const { cohortList } = applicationStats;
    const { stats } = applicationStats;
    const [hoverText, setHoverText] = useState('');
    let graphStats = [];
    let data = { datasets: [] };
    const appsByWeek = (weeks, weekApplications, closeDate) => {
        let weeklyApps = [];
        const checkDate = new Date(closeDate);
        const endDate = new Date();
        endDate.setUTCDate(endDate.getUTCDate() + 7);
        for (let week = weeks; week >= 0; week -= 1) {
            weeklyApps[week] = weekApplications.filter(application => application['admission_application.completed_at'] && (checkDate.getTime()) >= new Date(safariFormat(application['admission_application.completed_at'])).getTime()).length;
            if (checkDate.getTime() > endDate.getTime()) {
                weeklyApps[week] = null;
            }
            checkDate.setDate(checkDate.getDate() - 7);
        }
        if (weeklyApps.length > 13) {
            weeklyApps = weeklyApps.splice(weeklyApps.length - 13);
        }
        return weeklyApps;
    };
    const updateLine = (line, weeksOpen) => {
        const newObj = { ...line };
        while (newObj.data.length < weeksOpen + 1) {
            newObj.data.unshift(null);
        }
        return newObj;
    };
    const countApplicants = (applicants) => {
        const applicantIds = applicants.map(app => app.admission_application_id);
        return [...new Set(applicantIds)].length;
    };
    const addStatsToCohort = (cohort, statsList) => {
        const newObj = { ...cohort };
        const assignedApps = statsList.filter(stat => (
            stat['admission_application.application_status'] === 'placed'
            || stat['admission_application.application_status'] === 'confirmed'
            || stat['admission_application.application_status'] === 'declined_by_applicant'
            || stat['admission_application.application_status'] === 'dropped_out'));
        newObj.assignedApps = assignedApps.filter(stat => (stat['admission_application.assigned_cohort_id'] === cohort.id));
        newObj.initiated = statsList.filter(stat => (stat.cohort_id === cohort.id));
        newObj.total_weeks = Math.round((new Date(cohort.applications_close).getTime()
        - (new Date(cohort.applications_open).getTime())) / (1000 * 3600 * 24 * 7));
        newObj.weeklyApps = appsByWeek(newObj.total_weeks, statsList.filter(
            stat => (stat.cohort_id === cohort.id),
        ), cohort.applications_close);
        return newObj;
    };
    const colors = ['#946300', '#337277', '#0F67B4', '#C73D5B', '#57A916', '#872F9D',
        '#EB6200', '#009D9A', '#0F62FE', '#740937', '#022D0D', '#8A3FFC'];
    let lineStats = [];

    if (stats && cohortList) {
        const newCoList = cohortList.map(cohort => addStatsToCohort(cohort, stats));
        data = {
            labels: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
            datasets: [
            ],
        };
        let weeksOpen = Math.max(...newCoList.map(cohort => cohort.total_weeks));
        if (weeksOpen > 12) {
            weeksOpen = 12;
        } else if (weeksOpen < 12) {
            data.labels = data.labels.splice(data.labels.length - weeksOpen - 1);
        }
        graphStats = newCoList.map(cohort => (
            {
                cohort_id: cohort.id,
                cohort_name: cohort.name,
                initiated: cohort.initiated.length,
                received: cohort.initiated.filter(application => application['admission_application.completed_at']).length,
                offered: countApplicants(cohort.assignedApps),
                confirmed: countApplicants(cohort.assignedApps.filter(application => application['admission_application.application_status'] === 'confirmed')),
                lost: cohort.initiated.filter(application => application['admission_application.application_status'] === 'declined_reapplicant').length
                + cohort.initiated.filter(application => application['admission_application.application_status'] === 'declined').length
                + cohort.initiated.filter(application => application['admission_application.application_status'] === 'on_hold').length,
                applications_open: `${new Date(cohort.applications_close).getUTCMonth() + 1}/${new Date(cohort.applications_open).getUTCDate()}`,
                applications_close: `${new Date(cohort.applications_close).getUTCMonth() + 1}/${new Date(cohort.applications_close).getUTCDate()}`,
                days_left: Math.round((new Date(cohort.applications_close).getTime()
                - new Date().getTime()) / (1000 * 3600 * 24)),
            }
        ));
        lineStats = newCoList.map(cohort => (
            {
                label: cohort.name,
                data: cohort.weeklyApps,
                fill: false,
                // backgroundColor: 'rgb(255, 99, 132)',
                // borderColor: '#0F67B4'
            }
        ));
        for (let i = 0; i < colors.length; i += 1) {
            if (lineStats[i]) {
                lineStats[i].borderColor = colors[i];
                lineStats[i].backgroundColor = colors[i];
            }
        }
        lineStats = lineStats.map(line => updateLine(line, weeksOpen));
    }
    data.datasets.push(...lineStats);
    const chartOptions = {
        plugins: {
            title: {
                font: {
                    size: 16,
                },
            },
        },
        elements: {
            point: {
                radius: 2,
            },
        },
        legend: {
            display: true,
            position: 'bottom',
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        suggestedMax: 20,
                    },
                    position: 'right',
                },
            ],
        },
    };
    if (graphStats.length > 1) {
        graphStats.sort((a, b) => (
            (
                (
                    (a.days_left > -1)
            && ((b.days_left > -1 && a.days_left < b.days_left)
                 || (b.days_left < 0 && a.days_left > b.days_left)))
                    ? -1 : 1)));
    }

    return (
        <>
            <Paper className={hideTitle ? classes.dashboardSummary : classes.hoverTitle}>
                {/* Expand / Collapse linkg */}
                <div
                    onClick={() => dispatch({ type: 'SET_APPLICATION_GRAPH_EXPANDED', payload: !graphExpanded })}
                    onKeyPress={() => dispatch({ type: 'SET_APPLICATION_GRAPH_EXPANDED', payload: !graphExpanded })}
                    role="button"
                    tabIndex={0}
                >
                    <Typography variant="h5">
                        {
                            hideTitle
                                ? (
                                    <Link to="/admissions_applications">Applications</Link>
                                ) : (
                                    'Applications'
                                )
                        }
                    </Typography>
                    <div className={classes.collapseBtn}>
                        <span>{graphExpanded ? 'Collapse' : 'Expand'}</span>
                        <ExpandArrowIcon expanded={!graphExpanded} collapsedIcon="fa-chevron-up" />
                    </div>
                </div>
            </Paper>
            {graphExpanded && (
                <Paper className={hideTitle ? classes.dashboardStatsCards : classes.statsCards}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        {/* empty */}
                                        <TableCell>
                                            <Tooltip title={hoverText}>
                                                <Icon
                                                    onMouseOver={() => setHoverText('All Statuses')}
                                                    onFocus={() => setHoverText('All Statuses')}
                                                    className="fas fa-info-circle"
                                                    style={{
                                                        width: '100%',
                                                        color: '#555555',
                                                        fontSize: '14px',
                                                        marginBottom: '-3px',
                                                        textAlign: 'center',
                                                    }}
                                                />
                                            </Tooltip>
                                            <h6>Initiated</h6>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={hoverText}>
                                                <Icon
                                                    onMouseOver={() => setHoverText(
                                                        `Completed, Scheduled, Second Review, 
                                Needs Scheduling, Placed, Confirmed, 
                                Declined, On Hold, Dropped Out`,
                                                    )}
                                                    onFocus={() => setHoverText(
                                                        `Completed, Scheduled, Second Review, 
                                                    Needs Scheduling, Placed, Confirmed, 
                                                    Declined, On Hold, Dropped Out`,
                                                    )}
                                                    className="fas fa-info-circle"
                                                    style={{
                                                        width: '100%',
                                                        color: '#555555',
                                                        fontSize: '14px',
                                                        marginBottom: '-3px',
                                                        textAlign: 'center',
                                                    }}
                                                />
                                            </Tooltip>
                                            <h6>Received</h6>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={hoverText}>
                                                <Icon
                                                    onMouseOver={() => setHoverText(
                                                        `Placed, Confirmed, 
                                Declined By Applicant, On Hold, Dropped Out`,
                                                    )}
                                                    onFocus={() => setHoverText(
                                                        `Placed, Confirmed, 
                                                    Declined By Applicant, On Hold, Dropped Out`,
                                                    )}
                                                    className="fas fa-info-circle"
                                                    style={{
                                                        width: '100%',
                                                        color: '#555555',
                                                        fontSize: '14px',
                                                        marginBottom: '-3px',
                                                        textAlign: 'center',
                                                    }}
                                                />
                                            </Tooltip>
                                            <h6>Offered</h6>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={hoverText}>
                                                <Icon
                                                    onMouseOver={() => setHoverText(
                                                        'Confirmed',
                                                    )}
                                                    onFocus={() => setHoverText(
                                                        'Confirmed',
                                                    )}
                                                    className="fas fa-info-circle"
                                                    style={{
                                                        width: '100%',
                                                        color: '#555555',
                                                        fontSize: '14px',
                                                        marginBottom: '-3px',
                                                        textAlign: 'center',
                                                    }}
                                                />
                                            </Tooltip>
                                            <h6>Confirmed</h6>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={hoverText}>
                                                <Icon
                                                    onMouseOver={() => setHoverText(
                                                        'Declined, Declined Re-Applicant, On Hold',
                                                    )}
                                                    onFocus={() => setHoverText(
                                                        'Declined, Declined Re-Applicant, On Hold',
                                                    )}
                                                    className="fas fa-info-circle"
                                                    style={{
                                                        width: '100%',
                                                        color: '#555555',
                                                        fontSize: '14px',
                                                        marginBottom: '-3px',
                                                        textAlign: 'center',
                                                    }}
                                                />
                                            </Tooltip>
                                            <h6>Lost</h6>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {graphStats.map(row => (
                                        <TableRow key={row.cohort_name}>
                                            <TableCell>
                                                <Link className={classes.chartCohort} onClick={e => e.stopPropagation()} to={`/cohorts/${row.cohort_id}`}>{row.cohort_name}</Link>
                                                <br />
                                                <span className={classes.chartDetails}>
                                                    Deadline
                                                    {' '}
                                                    {row.applications_close}
                                                    <br />
                                                    {
                                                        row.days_left < 0
                                                    && (
                                                        <span>
                                                            Closed
                                                            {' '}
                                                            {row.days_left.toString().substr(1)}
                                                            {' '}
                                                            days ago
                                                        </span>
                                                    )
                                                    }
                                                    {
                                                        row.days_left >= 0
                                                    && (
                                                        <span>
                                                            Open for
                                                            {' '}
                                                            {row.days_left}
                                                            {' '}
                                                            more days
                                                        </span>
                                                    )
                                                    }
                                                </span>
                                            </TableCell>
                                            <TableCell className={classes.applicationTableCount}>
                                                <Link onClick={e => e.stopPropagation()} to={`/admissions_applications?order=asc&orderBy=first_name&limit=25&cohortAppliedId=${row.cohort_id}`}>{row.initiated}</Link>
                                            </TableCell>
                                            <TableCell className={classes.applicationTableCount}>
                                                <Link onClick={e => e.stopPropagation()} to={`/admissions_applications?order=asc&orderBy=first_name&limit=25&cohortAppliedId=${row.cohort_id}&selectedStatus=received`}>{row.received}</Link>
                                            </TableCell>
                                            <TableCell className={classes.applicationTableCount}>
                                                <Link onClick={e => e.stopPropagation()} to={`/admissions_applications?order=asc&orderBy=first_name&limit=25&cohortId=${row.cohort_id}&selectedStatus=offered`}>{row.offered}</Link>
                                            </TableCell>
                                            <TableCell className={classes.applicationTableCount}>
                                                <Link onClick={e => e.stopPropagation()} to={`/admissions_applications?order=asc&orderBy=first_name&limit=25&cohortId=${row.cohort_id}&selectedStatus=confirmed`}>{row.confirmed}</Link>
                                            </TableCell>
                                            <TableCell className={classes.applicationTableCount}>
                                                <Link onClick={e => e.stopPropagation()} to={`/admissions_applications?order=asc&orderBy=first_name&limit=25&cohortAppliedId=${row.cohort_id}&selectedStatus=lost`}>{row.lost}</Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <div className={classes.graphTitle}>
                                Completed applications for cohorts in recruitment
                                <div className="responsive-chart">
                                    <Line
                                        data={data}
                                        options={chartOptions}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            )
            }
        </>
    );
}

ApplicationsSummary.propTypes = {
    dispatch: PropTypes.func.isRequired,
    hideTitle: PropTypes.bool,
};
ApplicationsSummary.defaultProps = {
    hideTitle: false,
};

export default connect()(ApplicationsSummary);
