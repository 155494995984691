import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import {
    Table,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    Card,
    CardContent,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ExpandArrowIcon from '../../General/ExpandArrowIcon';
import PlacementsByType from './PlacementsByType';
import { DateRangeSearchBy } from '../../../redux/reducers/placementsReducer';

const useStyles = makeStyles({
    placementSummary: {
        position: 'relative',
        margin: '30px 0 40px 0',
    },
    dashboardSummary: {
        position: 'relative',
        margin: '0 0 15px 0',
    },
    statCollapsed: {
        display: 'inline-block',
        padding: '0 20px 0 20px',
        borderLeft: '1px solid #d4d4d4',
        textAlign: 'left',
        '&:first-child': {
            border: 'none',
            paddingLeft: 0,
        },
        '& > span:first-child': {
            fontSize: 22,
            marginRight: 10,
            paddingLeft: 0,
        },
        '& > span:last-child': {
            fontSize: 15,
            fontWeight: 'bold',
            verticalAlign: 2,
            border: 'none',
        },
    },
    collapseBtn: {
        position: 'absolute',
        right: 0,
        textAlign: 'right',
        cursor: 'pointer',

        // Increase the "hit box" for this button
        padding: '15px 20px 20px 170px',
        marginTop: -15,

        '& span:first-child': {
            fontSize: 'inherit',
            textDecoration: 'underline',
            verticalAlign: 'bottom',
            marginRight: 5,
        },
        '& span:last-child': {
            fontSize: 12,
            padding: 2,
            verticalAlign: -11,
        },
    },
    placementStats: {
        '& .MuiTable-root': {
            margin: '15px 0',
        },
        '& tr.MuiTableRow-root': {
            background: 'none',
        },
        '& .MuiTableCell-root': {
            border: 'none',
        },
        // stat value
        '& tr > td:first-child': {
            fontSize: 22,
            textAlign: 'right',
        },
        // stat label
        '& tr > td:last-child': {
            fontSize: 15,
            fontWeight: 'bold',
        },
        // subtitles
        '& td > .MuiTypography-root': {
            display: 'block',
            fontWeight: 'normal',
            fontSize: 13,
        },
    },
});

const renderStat = (value, digits = 0, placeholder = '--') => (
    typeof value === 'number'
        ? value.toFixed(digits)
        : placeholder
);

const renderPercentage = (value, digits = 2, placeholder = '--') => {
    // Some stats may return >100%, because search terms match placement data,
    // but not student data.
    if (value > 1) {
        return placeholder;
    }

    if (value === 0) {
        return '0%';
    }

    return typeof value === 'number'
        ? `${(value * 100).toFixed(digits)}%`
        : placeholder;
};

function PlacementsSummary({ hideTitle, hideNote }) {
    const classes = useStyles();
    const [collapsed, setCollapsed] = useState(false);

    const stats = useSelector(store => store.placement.stats);
    const dateRange = useSelector(store => store.placement.dateRange);

    // We'll not render placement rates, if the data is invalid / unavailable
    const isPlacementRateInvalid = (
        // Some stats may return >100%, because search terms match placement data,
        // but not student data (eg. if we search by company name, we find matching
        // placements, but no matching students)
        stats.placementCount > stats.studentCount
        // or we're searching by placement date
        // (search applies to placements, but not total student counts, so percentages are off)
        || (
            dateRange.searchBy === DateRangeSearchBy.PlacementDate
            && (dateRange.startDate || dateRange.endDate)
        )
    );

    return (
        <Card className={hideTitle ? classes.placementSummary : classes.dashboardSummary}>
            <div
                style={hideTitle ? {} : { marginTop: -50 }}
                onClick={() => setCollapsed(!collapsed)}
                onKeyPress={() => setCollapsed(!collapsed)}
                role="button"
                tabIndex={0}
            >
                {
                    !hideTitle && (
                        <Typography variant="h5">
                            <Link to="/placements">Placements</Link>
                        </Typography>
                    )
                }
                {/* Expand / Collapse linkg */}
                <div className={classes.collapseBtn}>
                    <span>{collapsed ? 'Expand' : 'Collapse'}</span>
                    <ExpandArrowIcon expanded={collapsed} collapsedIcon="fa-chevron-up" />
                </div>
            </div>

            {/* Collapsed view (stats only) */}
            { collapsed && (
                <>
                    <div
                        onClick={() => setCollapsed(!collapsed)}
                        onKeyPress={() => setCollapsed(!collapsed)}
                        role="button"
                        tabIndex={0}
                    >
                        {/* Percent placed at 6 months */}
                        <div className={classes.statCollapsed}>
                            <span>
                                {renderPercentage(stats.placementRateAtSixMonths)}
                            </span>
                            <span>Placed</span>
                        </div>

                        {/* Average days to placement */}
                        <div className={classes.statCollapsed}>
                            <span>
                                {renderStat(stats.averageDaysToPlacement, 0)}
                            </span>
                            <span>Days to Placement</span>
                        </div>

                        {/* Total number placed */}
                        <div className={classes.statCollapsed}>
                            <span>
                                {renderStat(stats.placementCount, 0)}
                                <span style={{ fontSize: 16, color: '#676a6c' }}>

                                    {
                                        isPlacementRateInvalid
                                            ? ' / --'
                                            : ` / ${renderStat(stats.studentCount, 0)}`
                                    }
                                </span>

                            </span>
                            <span>Alumni placed</span>
                        </div>
                    </div>

                    {/* Note about inaccurate numbers for some searches */}
                    <div style={{
                        fontSize: 13,
                        color: 'rgba(0, 0, 0, 0.54)',
                        paddingTop: 6,
                    }}
                    >
                        {/*
                        Placement rates are calculated by comparing placements
                        which match the search query to students who match the search query.
                        If we search by company, for example,
                        we'll match placements but no students.
                        */}
                        {!hideNote
                        && (
                            <span>
                                * Placement statistics may be inaccurate if searching by
                                company name, type of work, job title, or placement date.
                            </span>
                        )}
                    </div>
                </>
            )
            }

            {!collapsed && (
                <CardContent>
                    <Grid container spacing={4}>
                        {/* Salary by job type */}

                        <Grid
                            item
                            xs={6}
                            className={classes.salaryByJobType}
                            onClick={() => setCollapsed(!collapsed)}
                            onKeyPress={() => setCollapsed(!collapsed)}
                            role="button"
                            tabIndex={0}
                        >
                            <Typography variant="h3" style={{ fontWeight: 'normal' }}>
                                Placement Breakdown
                            </Typography>
                            <PlacementsByType />
                        </Grid>

                        {/* for spacing / layout */}
                        <Grid item xs={1} />
                        {/* Placement Stats */}
                        <Grid item xs={4} className={classes.placementStats}>

                            <Typography
                                variant="h3"
                                style={{ fontWeight: 'normal' }}
                                onClick={() => setCollapsed(!collapsed)}
                                onKeyPress={() => setCollapsed(!collapsed)}
                                role="button"
                                tabIndex={0}
                            >
                                Placement Stats
                            </Typography>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        {/* Placement rate at 6 months */}
                                        <TableCell>
                                            {renderPercentage(stats.placementRateAtSixMonths)}
                                        </TableCell>
                                        <TableCell>
                                            Placed
                                            <Typography variant="subtitle2">
                                                Six months after graduation
                                                <div>
                                                    {/* Total placement rate, for all time */}
                                                    <span style={{
                                                        fontWeight: 'bold',
                                                        paddingRight: 3,
                                                        color: 'rgba(0, 0, 0, 0.75)',
                                                    }}
                                                    >
                                                        {renderPercentage(stats.totalPlacementRate)}
                                                    </span>
                                                    placed in all
                                                </div>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    {/* Total placement count */}
                                    <TableRow>
                                        <TableCell>
                                            {renderStat(stats.placementCount)}
                                        </TableCell>
                                        <TableCell>
                                            Alumni placed
                                        </TableCell>
                                    </TableRow>

                                    {/* Total matching student count */}
                                    <TableRow>
                                        <TableCell>
                                            {
                                                isPlacementRateInvalid
                                                    ? '--'
                                                    : renderStat(stats.studentCount, 0)
                                            }
                                        </TableCell>
                                        <TableCell>
                                            Total Alumni
                                        </TableCell>
                                    </TableRow>

                                    {/* Average days to placement */}
                                    <TableRow>
                                        <TableCell>
                                            {renderStat(stats.averageDaysToPlacement)}
                                        </TableCell>
                                        <TableCell>
                                            Avg days to placement
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>

                        {/* Note */}
                        <div style={{
                            fontSize: 13,
                            color: 'rgba(0, 0, 0, 0.54)',
                            paddingTop: 6,
                        }}
                        >
                            {/*
                            Placement rates are calculated by comparing placements
                            which match the search query to students who match the search query.
                            If we search by company, for example,
                            we'll match placements but no students.
                            */}
                            {!hideNote
                            && (
                                <span>
                                    * Placement statistics may be inaccurate if searching by
                                    company name, type of work, job title, or placement date.
                                </span>
                            )}
                        </div>
                    </Grid>
                </CardContent>
            )
            }
        </Card>

    );
}
PlacementsSummary.propTypes = {
    hideTitle: PropTypes.bool,
    hideNote: PropTypes.bool,
};
PlacementsSummary.defaultProps = {
    hideTitle: true,
    hideNote: false,
};
export default PlacementsSummary;
