/* eslint-disable prefer-template */
import {
    Paper,
    Table,
    TableCell,
    TableHead,
    TableBody,
    TableRow,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import Alert from '@mui/lab/Alert';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import CohortPageTitle from '../CohortPageTitle';
import useStyles from './styles';
import './CohortGrades.css';
import AssignmentNameCell from './AssignmentNameCell';
import GradeCell from './GradeCell';
import { CourseWorkStatus } from '../../../helpers/CourseWorkTextHelper';
import GradeCellLegend from './GradeCellLegend';
import CourseWorkModal from '../../Modal/CourseWorkModal';

/**
 * A summary view of grades for all assignments in a cohort.
 */
function CohortGradesPage() {
    const classes = useStyles();
    const { id: cohortId } = useParams();
    const dispatch = useDispatch();
    const cohort = useSelector(store => store.cohort.cohort);
    const courseWork = useSelector(store => store.cohort.courseWorkList);
    const [direction, setDirection] = useState('vertical');

    /**
     * Load all the data from the server, required for this page
     */
    const loadPageData = () => {
        // Fetch cohort to display
        dispatch({
            type: 'FETCH_COHORT_DETAILS',
            payload: { id: cohortId },
        });
        // Fetch all the course work (grades)
        // for this cohort
        dispatch({
            type: 'FETCH_COHORT_COURSE_WORK',
            payload: { cohortId },
        });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
    };
    const stableLoadPageData = useCallback(loadPageData, []);
    // Load page data on init
    useEffect(() => {
        stableLoadPageData();
    }, [cohortId, stableLoadPageData]);

    // Get a list of assignments for this cohort
    // We can re-use our cohort data
    // to figure out a list
    const assignments = Object.values(
        courseWork.reduce(
            (assgns, cw) => ({
                ...assgns,
                [cw.assignment.id]: cw.assignment,
            }),
            {},
        ),
    )
        // Sort by date assigned
        .sort((a, b) => (a.assigned_at > b.assigned_at ? 1 : -1));

    // Calculate average grades for each student
    const gradeSums = courseWork
        .reduce((sums, cw) => {
            /* eslint-disable no-param-reassign */
            const status = CourseWorkStatus.from(cw, cw.assignment);

            // Initialize an object for each student / assignment
            // to track sum and count
            if (!sums.byStudent[cw.user.id]) {
                sums.byStudent[cw.user.id] = { sum: 0, count: 0 };
            }
            if (!sums.byAssignment[cw.assignment.id]) {
                sums.byAssignment[cw.assignment.id] = { sum: 0, count: 0 };
            }

            // Save the grade for this course work
            // Ignore missing assignments
            if (status !== CourseWorkStatus.Missing && cw.grade !== null) {
                sums.byStudent[cw.user.id].sum += cw.grade;
                sums.byStudent[cw.user.id].count += 1;

                sums.byAssignment[cw.assignment.id].sum += cw.grade;
                sums.byAssignment[cw.assignment.id].count += 1;
            }
            // Don't count un-graded course work

            return sums;
            /* eslint-enable no-param-reassign */
        }, { byStudent: {}, byAssignment: {} });

    const avgByStudent = Object.entries(gradeSums.byStudent)
        .reduce((byId, [id, { sum, count }]) => {
            // ignore students with no coursework
            if (!count && !sum) {
                return byId;
            }
            return ({
                ...byId,
                [id]: sum / count,
            });
        }, {});

    const avgByAssignment = Object.entries(gradeSums.byAssignment)
        .reduce((byId, [id, { sum, count }]) => {
            // ignore assignments with no coursework
            if (!count && !sum) {
                return byId;
            }
            return ({
                ...byId,
                [id]: sum / count,
            });
        }, {});

    const totalAverage = Object.values(avgByStudent)
        .reduce((sum, val) => sum + val, 0)
        / Object.values(avgByStudent).length;

    if (!courseWork.length) {
        return (
            <div className="cohort-grades">
                <CohortPageTitle
                    title="Grades Summary"
                    cohort={cohort}
                />
                <Paper>
                    <Alert severity="info">
                        No course work has been assigned, yet.
                    </Alert>
                </Paper>
            </div>
        );
    }

    return (
        <div className="cohort-grades">
            <CohortPageTitle
                title="Grades Summary"
                cohort={cohort}
            />

            <Paper style={{
                position: 'relative',
                whiteSpace: 'nowrap',
            }}
            >
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={direction === 'horizontal'}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setDirection('horizontal');
                                } else {
                                    setDirection('vertical');
                                }
                            }}
                        />
                    )}
                    label="Display titles horizontally."
                />
                <div style={{
                    width: '100%',
                    overflow: 'auto',
                }}
                >
                    <div style={{
                        maxWidth: 1,
                        display: 'block',
                    }}
                    >
                        <Table>
                            {/* Header row: Assignment names */}
                            <TableHead>
                                <TableRow>
                                    {/* Name Column Header */}
                                    <TableCell
                                        key="name"
                                        className={`${classes.th} ${classes.stickyColumn}`}
                                    >
                                        Name
                                        <div
                                            className={`${classes.gradeCell} ${classes.stickyColumn}`}
                                            style={{
                                                float: 'right',
                                                margin: -6,
                                                padding: '5px 8px',
                                                border: 'none',
                                            }}
                                        >
                                            AVG
                                        </div>
                                    </TableCell>

                                    {/* Assignment names */}
                                    {assignments.map(assignment => (
                                        <AssignmentNameCell
                                            key={assignment.id}
                                            assignment={assignment}
                                            direction={direction}
                                        />
                                    ))}
                                </TableRow>
                            </TableHead>

                            {/* Grid of grades per student/assignment */}
                            <TableBody>
                                {/* Map through students */}
                                {cohort.students?.map((student) => {
                                    const avg = avgByStudent[student.id];
                                    let studentFullName = '';
                                    let studentUrl = '';
                                    if (student.first_name && student.last_name && student.id) {
                                        studentFullName = student.first_name + ' ' + student.last_name;
                                        studentUrl = '/students/' + student.id;
                                    }
                                    return (
                                        <TableRow
                                            key={student.id}
                                        >
                                            {student && student.id && (
                                                <TableCell
                                                    key="name"
                                                    className={classes.stickyColumn}
                                                    style={{
                                                        paddingRight: 60,
                                                    }}
                                                >
                                                    <>
                                                        {/* Student name links to student page */}
                                                        <Link
                                                            to={studentUrl}
                                                            style={{
                                                                color: 'inherit',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            {studentFullName}
                                                        </Link>
                                                        {/* Average Score Column */}
                                                        <div
                                                            className={[
                                                                classes.gradeCell,
                                                                classes.stickyColumn,
                                                                'grade-avg',
                                                                // Style, to highlight
                                                                // low average grades
                                                                (
                                                                    avg >= 2
                                                                    && avg <= 2.8
                                                                    && 'grade-avg-warning'
                                                                ),
                                                                (avg < 2 && 'grade-avg-critical'),
                                                            ].filter(Boolean).join(' ')}
                                                        >
                                                            {avg
                                                                ? avg.toFixed(1)
                                                                : '-'
                                                            }
                                                        </div>
                                                    </>
                                                </TableCell>
                                            )}
                                            {/* Student grade columns */}
                                            {assignments.map((assignment, index) => {
                                                // Find the courseWork for this student
                                                // and this assignment
                                                // Note that we do not map through
                                                // coursework directly, because we
                                                // need these grades to be in the
                                                // same order as our assignments
                                                const scw = courseWork.find(
                                                    cw => (
                                                        cw.assignment.id === assignment.id
                                                        && cw.user.id === student.id
                                                    ),
                                                );

                                                return (
                                                    <GradeCell
                                                        key={scw ? scw.id : index}
                                                        courseWork={scw}
                                                    />
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                                <TableRow key="assignment-avgs">
                                    <TableCell
                                        key="name"
                                        className={classes.stickyColumn}
                                        style={{
                                            paddingRight: 60,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {/* Student name links to student page */}
                                        AVG

                                        {/* Total averageAverage Score Column */}
                                        <div
                                            className={[
                                                classes.gradeCell,
                                                classes.stickyColumn,
                                                'grade-avg',
                                                'grade-avg-neutral',
                                            ].filter(Boolean).join(' ')}
                                        >
                                            {totalAverage.toFixed(1)}
                                        </div>
                                    </TableCell>

                                    {assignments.map(assignment => (
                                        <TableCell
                                            key={assignment.id}
                                            className={[
                                                classes.gradeCell,
                                                'grade-avg-neutral',
                                            ].join(' ')}
                                        >
                                            {avgByAssignment[assignment.id]
                                                && avgByAssignment[assignment.id].toFixed(1)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Paper>
            <Paper>
                <GradeCellLegend />
            </Paper>
            {/* CourseWork modal, to see/modify feedback */}
            <CourseWorkModal
                // Refresh the page data when the grade is updated
                handleRefresh={loadPageData}
            />
        </div>
    );
}

export default CohortGradesPage;
