import React, { useState } from 'react';
import {
    Paper,
    Grid,
    Divider,
    Typography,
    TextField,
    Button,
    IconButton,
    Input,
    Link,
    Select,
    MenuItem,
    FormControl,
    OutlinedInput,
    Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NoteContainer from '../../../General/Notes/NoteContainer';
import ConditionalTypography from '../ConditionalTypography';
import { getResumeUrl } from '../../../../helpers/PhotoHelper';
import { shortDateSlash, getTimestamp } from '../../../../helpers/DateFormatHelper';
import { races } from '../../../../config/labels';

const styles = {};

const acceptedContractFileTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const GeneralTab = ({
    value,
    index,
    applicant,
    applicantNotes,
    hubSpotNotes,
    addNote,
    handleNoteChange,
    editingNote,
    deleteNote,
    user,
    application,
    editingApplication,
    dispatch,
}) => {
    const [showDemographicInfo, setShowDemographicInfo] = useState(false);
    // File inputs cannot be controlled in React
    // To clear a file input, we need to trick
    // React into re-rendering (ie. with a new `key` attr)
    // https://github.com/redux-form/redux-form/issues/769#issuecomment-215411743
    const [contractFileKey, setContractFileKey] = useState(0);

    const handleChangeFor = propertyName => (event) => {
        const updatedApplication = {
            ...editingApplication,
            [propertyName]: event.target.value,
        };
        dispatch({ type: 'SET_EDITING_APPLICATION_DETAILS', payload: updatedApplication });
    };

    const showErrorModal = (title, body) => {
        dispatch({
            type: 'SET_GLOBAL_MODAL',
            payload: {
                modalOpen: true,
                title,
                body,
            },
        });
    };

    const validateContractFile = (file) => {
        // Validate file type
        if (!acceptedContractFileTypes.includes(file.type)) {
            showErrorModal(
                'Invalid File Type',
                'File must be a pdf, doc, or docx file type',
            );
            return false;
        }

        // Validate file size (10 MB)
        if (file.size > 1024 * 1024 * 10) {
            showErrorModal(
                'File is Too Big',
                'File size limit is 10MB.',
            );
            return false;
        }

        return true;
    };

    const handleFileChange = (event) => {
        // If no files are selected, cancel
        if (!event.target.files.length) {
            return;
        }

        const file = event.target.files[0];

        if (!validateContractFile(file)) {
            return;
        }

        // Save contract file to redux state
        // It will be uploaded by our PUT_APPLICATION_DETAILS saga
        dispatch({
            type: 'SET_EDITING_APPLICATION_DETAILS',
            payload: {
                ...editingApplication,
                contractFile: file,
            },
        });
    };

    const clearContractFileInput = () => {
        setContractFileKey(contractFileKey + 1);

        dispatch({
            type: 'SET_EDITING_APPLICATION_DETAILS',
            payload: {
                ...editingApplication,
                contractFile: null,
            },
        });
    };

    const submitApplicationDetails = () => {
        dispatch({
            type: 'PUT_APPLICATION_DETAILS',
            payload: editingApplication,
        });

        // Clear the file input
        clearContractFileInput();
    };

    const formatBool = (boolValue) => {
        let result;
        if (boolValue === true || boolValue === 'true') {
            result = 'Yes';
        } else if (boolValue === false || boolValue === 'false') {
            result = 'No';
        }
        return result;
    };

    const renderRace = () => {
        const isRaceDefined = Object.keys(races)
            .filter(race => applicant[race])
            .length;

        if (!isRaceDefined) {
            return (
                <Typography variant="body2">
                    Not specified
                </Typography>
            );
        }

        const selectedRaceLabels = Object.entries(races)
            .filter(([raceKey, raceLabel]) => applicant[raceKey]);

        return selectedRaceLabels.map(([raceKey, raceLabel]) => (
            <Typography variant="body1">
                {raceLabel}
            </Typography>
        ));
    };

    const notesToDisply = () => {
        let allNotes = [];
        if (applicantNotes && applicantNotes.length > 0) {
            allNotes = [...allNotes, ...applicantNotes];
        }

        if (hubSpotNotes.comments && hubSpotNotes.comments.length > 0) {
            allNotes = [...allNotes, ...hubSpotNotes.comments];
        }

        allNotes.sort((a, b) => {
            const aDate = getTimestamp(a.created_at);
            const bDate = getTimestamp(b.created_at);
            return bDate - aDate;
        });
        return allNotes;
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Grid container direction="row">
                    <Grid item xs={12} sm={6}>
                        <Paper>
                            <Typography variant="h5">Education and Employment</Typography>
                            <Divider />
                            <ConditionalTypography
                                title="Legal Status"
                                displayValue={applicant.legal_status}
                                defaultValue="Incomplete"
                            />
                            <ConditionalTypography
                                title="Education"
                                displayValue={applicant.education}
                                defaultValue="Incomplete"
                            />
                            <ConditionalTypography
                                title="Employment"
                                displayValue={applicant.employment_status}
                                defaultValue="Incomplete"
                            />
                            <ConditionalTypography
                                title="Goal"
                                displayValue={applicant.goal}
                                defaultValue="Incomplete"
                            />
                            {application.course_id === 2
                                && (
                                    <ConditionalTypography
                                        title="Resume"
                                        displayValue={
                                            applicant.resume_file_name
                                                ? (
                                                    <Link href={getResumeUrl(
                                                        user.s3_bucket,
                                                        applicant.id,
                                                        applicant.resume_file_name,
                                                    )}
                                                    >
                                                        {applicant.resume_file_name}
                                                    </Link>
                                                )
                                                : null
                                        }
                                        defaultValue="Incomplete"
                                    />
                                )
                            }
                            <br />
                            <Typography variant="h5"> Income </Typography>
                            <Divider />
                            <ConditionalTypography
                                title="Annual Household Income"
                                displayValue={applicant.income && applicant.income.toLocaleString()}
                                defaultValue="Not Provided"
                            />
                            <ConditionalTypography
                                title="Personal Income"
                                displayValue={
                                    applicant.personal_income
                                    && applicant.personal_income.toLocaleString()
                                }
                                defaultValue="Not Provided"
                            />
                            <br />
                            <Typography variant="h5"> Links and Social Accounts </Typography>
                            <Divider />
                            <ConditionalTypography
                                title="LinkedIn Account"
                                displayValue={
                                    applicant.linkedin_account
                                        ? (
                                            <Link href={applicant.linkedin_account}>
                                                {applicant.linkedin_account}
                                            </Link>
                                        )
                                        : null
                                }
                                defaultValue="Not Provided"
                            />
                            {/* <ConditionalTypography
                                title="Twitter Account"
                                displayValue={
                                    applicant.twitter_account
                                        ? (
                                            <Link href={applicant.twitter_account}>
                                                {applicant.twitter_account}
                                            </Link>
                                        )
                                        : null
                                }
                                defaultValue="Not Provided"
                            /> */}
                            <ConditionalTypography
                                title="GitHub Account"
                                displayValue={
                                    applicant.github_account
                                        ? (
                                            <Link href={applicant.github_account}>
                                                {applicant.github_account}
                                            </Link>
                                        )
                                        : null
                                }
                                defaultValue="Not Provided"
                            />
                            <ConditionalTypography
                                title="Website Link"
                                displayValue={
                                    applicant.website_link
                                        ? (
                                            <Link href={applicant.website_link}>
                                                {applicant.website_link}
                                            </Link>
                                        )
                                        : null
                                }
                                defaultValue="Not Provided"
                            />
                            <br />
                            <Typography variant="h5">Demographic Information</Typography>
                            <Divider />
                            {
                                showDemographicInfo ? (
                                    <>
                                        <ConditionalTypography
                                            title="Date of Birth"
                                            displayValue={
                                                applicant.birthdate
                                                && shortDateSlash(applicant.birthdate)
                                            }
                                            defaultValue="Not provided"
                                        />
                                        <ConditionalTypography
                                            title="Gender"
                                            displayValue={applicant.gender}
                                            defaultValue="Not Provided"
                                        />
                                        {
                                            applicant.gender === 'Self-Describe'
                                            && (
                                                <ConditionalTypography
                                                    title="Gender Description"
                                                    displayValue={applicant.gender_description}
                                                    defaultValue="Not Provided"
                                                />
                                            )
                                        }
                                        <ConditionalTypography
                                            title="Pronouns"
                                            displayValue={applicant.pronouns}
                                            defaultValue="Not Provided"
                                        />
                                        <ConditionalTypography
                                            title="Number of Dependents"
                                            displayValue={applicant.dependents}
                                            defaultValue="Not Provided"
                                        />
                                        <ConditionalTypography
                                            title="Geography"
                                            displayValue={applicant.geography}
                                            defaultValue="Not Provided"
                                        />
                                        <ConditionalTypography
                                            title="Veteran"
                                            displayValue={formatBool(applicant.veteran)}
                                            defaultValue="Not Provided"
                                        />

                                        <Typography variant="h6">
                                            Race
                                        </Typography>
                                        {renderRace()}

                                        <Button onClick={() => setShowDemographicInfo(false)}>
                                            Hide Demographic Information
                                        </Button>
                                    </>
                                ) : (
                                    <Button onClick={() => setShowDemographicInfo(true)}>
                                        Show Demographic Information
                                    </Button>
                                )
                            }
                            <br />
                            <br />
                            <Typography variant="h5">Details</Typography>
                            <Divider />
                            <br />
                            <Typography variant="h6">Preferred Cohorts</Typography>
                            {
                                application.cohorts
                                && application.cohorts.map(cohort => (
                                    <div key={`cohort_${cohort.cohort_id}`}>
                                        <Typography>
                                            {`${cohort.cohort_name} - Prework: ${shortDateSlash(cohort.prework_start)}`}
                                        </Typography>
                                    </div>
                                ))
                            }
                            <br />
                            <Typography variant="h6">Application Status</Typography>
                            <FormControl>
                                <Select
                                    labelid="application_status_label"
                                    id="application_status"
                                    input={<OutlinedInput margin="dense" />}
                                    value={editingApplication.application_status ? editingApplication.application_status : ''}
                                    onChange={handleChangeFor('application_status')}
                                >
                                    <MenuItem value="not_started">Not Started</MenuItem>
                                    <MenuItem value="started">Started</MenuItem>
                                    <MenuItem value="completed">Completed</MenuItem>
                                    <MenuItem value="on_hold">Deferred</MenuItem>
                                    <MenuItem value="scheduled">Scheduled</MenuItem>
                                    <MenuItem value="second_review">Second Review</MenuItem>
                                    <MenuItem value="placed">Accepted</MenuItem>
                                    <MenuItem value="confirmed">Confirmed</MenuItem>
                                    <MenuItem value="declined">Declined</MenuItem>
                                    <MenuItem value="declined_by_applicant">Withdrew Application</MenuItem>
                                    <MenuItem value="dropped_out">Withdrew from Cohort</MenuItem>
                                    <MenuItem value="declined_reapplicant">Declined - Reapplicant</MenuItem>
                                </Select>
                            </FormControl>
                            <br />
                            <br />
                            <Typography variant="h6">Aid Status</Typography>
                            <FormControl>
                                <Select
                                    labelid="aid_status_label"
                                    id="aid_status"
                                    input={<OutlinedInput margin="dense" />}
                                    value={editingApplication.aid_status ? editingApplication.aid_status : ''}
                                    onChange={handleChangeFor('aid_status')}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="unknown">Unknown</MenuItem>
                                    <MenuItem value="interested">Interested</MenuItem>
                                    <MenuItem value="not_eligable">Not Eligable</MenuItem>
                                    <MenuItem value="referral_needed">Referral Needed</MenuItem>
                                    <MenuItem value="referred">Referred</MenuItem>
                                    <MenuItem value="aid_awarded">Awarded</MenuItem>
                                </Select>
                            </FormControl>
                            <br />
                            <br />
                            <Typography variant="h6">Aid Amount Awarded</Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                type="number"
                                value={editingApplication.aid_award_amount ? editingApplication.aid_award_amount : ''}
                                margin="dense"
                                onChange={handleChangeFor('aid_award_amount')}
                            />
                            <br />
                            <br />
                            <Typography variant="h6">Aid Source</Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={editingApplication.aid_source ? editingApplication.aid_source : ''}
                                margin="dense"
                                onChange={handleChangeFor('aid_source')}
                            />
                            <br />
                            <br />
                            <Typography variant="h6">Aid Detail</Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={editingApplication.aid_details ? editingApplication.aid_details : ''}
                                margin="dense"
                                onChange={handleChangeFor('aid_details')}
                            />
                            <br />
                            <br />

                            <Typography variant="h6">Executed Contract</Typography>

                            {/* Contract download link */}
                            {editingApplication.executed_contract_url
                                && (
                                    <Typography
                                        variant="body1"
                                        /* Strikeout file name, if another file is being uploaded */
                                        style={{ textDecoration: editingApplication.contractFile && 'line-through' }}
                                    >
                                        <Link href={editingApplication.executed_contract_url}>
                                            <Icon
                                                className="fas fa-cloud-download-alt"
                                                style={{
                                                    width: 'auto',
                                                    marginRight: 4,
                                                    verticalAlign: -5,
                                                    fontSize: '1.3em',
                                                }}
                                            />
                                            {editingApplication.executed_contract_file_name}
                                        </Link>
                                    </Typography>
                                )
                            }

                            {/* Contract file upload */}
                            <Input
                                id="contract-upload-button"
                                inputProps={{
                                    accept: acceptedContractFileTypes.join(','),
                                }}
                                onChange={handleFileChange}
                                type="file"
                                disableUnderline
                                key={contractFileKey}
                            />

                            {/* Cancel file upload button */}
                            {editingApplication.contractFile
                                && (
                                    <IconButton onClick={clearContractFileInput} size="large">
                                        <Icon
                                            className="fas fa-minus-circle"
                                            style={{
                                                marginTop: -3,
                                                fontSize: '0.6em',
                                                color: '#AB3E07',
                                            }}
                                        />
                                    </IconButton>
                                )
                            }

                            <br />
                            <br />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submitApplicationDetails}
                            >
                                Update
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NoteContainer
                            buttonText="Add Call Note"
                            textfieldText="Add a note..."
                            addNote={addNote}
                            onChange={handleNoteChange}
                            editAction="SET_EDITING_GENERAL_NOTE"
                            submitAction="CREATE_GENERAL_NOTE"
                            editingNote={editingNote}
                            notesToDisplay={notesToDisply()}
                            deleteNote={deleteNote}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

GeneralTab.propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    applicantNotes: PropTypes.instanceOf(Object).isRequired,
    hubSpotNotes: PropTypes.instanceOf(Object).isRequired,
    applicant: PropTypes.instanceOf(Object).isRequired,
    application: PropTypes.instanceOf(Object).isRequired,
    editingApplication: PropTypes.instanceOf(Object).isRequired,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    addNote: PropTypes.func.isRequired,
    handleNoteChange: PropTypes.func.isRequired,
    editingNote: PropTypes.instanceOf(Object).isRequired,
    deleteNote: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    editingApplication: state.application.editingApplicationDetails,
    applicantNotes: state.application.applicationCallNotes,
    hubSpotNotes: state.hubspot.studentNotes,
    user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(GeneralTab));
