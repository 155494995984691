import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Container,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ReactSelect from 'react-select';
import CodeBlock from '../CodeBlock';

const styles = theme => ({
    secondaryText: {
        fontWeight: 500,
        padding: '5px 0',
    },
    container: {
        margin: '5px 0',
    },
    score: {
        maxWidth: '200px',
        margin: '10px 0',
    },
    save: {
        margin: '10px 0',
    },

});
const InterviewNoteEditor = ({
    classes,
    interviewScores,
    applicationInterviewReview,
    dispatch,
    questionId,
    submitInterviewReview,

}) => {
    const [selectedTab, setSelectedTab] = useState('write');
    const [content, setContent] = useState('');
    const [score, setScore] = useState(0);

    const handleChange = (value) => {
        const review = applicationInterviewReview.get(questionId);
        applicationInterviewReview.set(questionId,
            { ...review, interview_note: value, questionId });

        dispatch({ type: 'UPDATE_APPLICATION_INTERVIEW_REVIEW', payload: applicationInterviewReview });
        setContent(value);
    };

    const handleScoreChange = (value) => {
        const review = applicationInterviewReview.get(questionId);
        applicationInterviewReview.set(questionId,
            { ...review, score: value.value, questionId });

        dispatch({ type: 'UPDATE_APPLICATION_INTERVIEW_REVIEW', payload: applicationInterviewReview });
        setScore(value);
    };

    useEffect(() => {
        const review = applicationInterviewReview.get(questionId);
        if (review?.interview_note) {
            setContent(review.interview_note);
        }
        if (review?.score) {
            const scoreToSet = interviewScores.find((item) => item.value === review.score);
            setScore(scoreToSet);
        }
    }, []);

    return (
        <Container className={classes.container}>
            <Typography variant="h6">
                Notes for category

            </Typography>
            <ReactMde
                value={content}
                onChange={handleChange}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                minEditorHeight={120}
                minPreviewHeight={120}
                generateMarkdownPreview={markdown => (
                    Promise.resolve(
                        <ReactMarkdown
                            components={{ code: CodeBlock }}
                            remarkPlugins={[remarkGfm]}
                        >
                            {markdown}
                        </ReactMarkdown>,
                    )
                )}
            />
            <div className={classes.score}>
                <Typography>Score</Typography>
                <ReactSelect
                    onChange={event => handleScoreChange(event)}
                    value={score}
                    placeholder=""
                    options={interviewScores}
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                    }}
                />
                <Button
                    className={classes.save}
                    variant="outlined"
                    sx={{ border: '1px solid grey' }}
                    onClick={submitInterviewReview}
                >
                    Save
                </Button>
            </div>

        </Container>
    );
};

InterviewNoteEditor.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    dispatch: PropTypes.instanceOf(Object).isRequired,
    interviewScores: PropTypes.instanceOf(Array).isRequired,
    applicationInterviewReview: PropTypes.instanceOf(Map).isRequired,
    questionId: PropTypes.number.isRequired,
    submitInterviewReview: PropTypes.func.isRequired,

};
const mapStateToProps = state => ({
    dispatch: state.dispatch,
    interviewScores: state.application.interviewScores,
    applicationInterviewReview: state.application.applicationInterviewReview,
});
export default connect(mapStateToProps)(withStyles(styles)(InterviewNoteEditor));
