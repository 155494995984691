import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import BaseGeneralTab from './BaseGeneralTab';

function GeneralTab({
    value,
    index,
    nextTab,
}) {
    const dispatch = useDispatch();

    const applicant = useSelector(store => store.applicant.editingGeneralInformation);
    const courseId = useSelector(store => store.applicant.editingCourseId);
    const activeCohorts = useSelector(store => store.applicant.activeCohorts);

    const dispatchChange = (action) => {
        if (action) {
            dispatch(action);
        }
        dispatch({ type: 'SET_UNSAVED_GENERAL_CHANGE', payload: true });
    };

    return (
        <>
            {value === index
            && (
                <BaseGeneralTab
                    applicant={applicant}
                    courseId={courseId}
                    activeCohorts={activeCohorts}
                    onApplicantChange={(property, val) => {
                        dispatchChange({
                            type: 'SET_EDITING_GENERAL_APPLICANT',
                            payload: { ...applicant, [property]: val },
                        });
                    }}
                    onActiveCohortChange={(cohorts) => {
                        dispatchChange({
                            type: 'SET_APPLICANT_ACTIVE_COHORTS',
                            payload: cohorts,
                        });
                    }}
                    onErrorMessage={(title, body) => {
                        dispatch({
                            type: 'SET_GLOBAL_MODAL',
                            payload: {
                                modalOpen: true,
                                title,
                                body,
                            },
                        });
                    }}
                    onFileSelect={() => dispatchChange()}
                    onSubmit={({
                        // eslint-disable-next-line no-shadow
                        applicant, courseId, selectedFile, activeCohorts,
                    }) => {
                        dispatch({
                            type: 'PUT_APPLICANT_GENERAL_INFORMATION',
                            payload: {
                                applicant,
                                courseId,
                                activeCohorts,
                                selectedFile,
                                nextTab,
                            },
                        });
                    }}
                />
            )
            }
        </>
    );
}

GeneralTab.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    nextTab: PropTypes.func.isRequired,
};

export default GeneralTab;
