/* eslint-disable no-console */
import axios from 'axios';
import {
    put, all, takeLatest,
} from 'redux-saga/effects';
import { APPLICANT, STUDENT } from '../../components/General/UserTypes';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* fetchStudentPortalFeedback() {
    try {
        const response = yield axios.get('api/students/feedback');
        yield put({ type: 'SET_STUDENT_PORTAL_FEEDBACK_HISTORY', payload: response.data });
        yield put({ type: 'FETCH_STUDENT_PORTAL_FEEDBACK_LATEST' });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

// Get the most recent feedback for the user. Used to determine whether to show
// the feedback (reflection) form. We may eventually add pagination to the feedback
// history so we can't count on that having the last feedback entry.
function* fetchStudentPortalLastFeedback() {
    try {
        const response = yield axios.get('api/students/feedback/latest');
        yield put({ type: 'SET_STUDENT_PORTAL_FEEDBACK_LATEST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchStudentPortalAssignments(action) {
    try {
        const response = yield axios.get('api/students/assignments', { params: action.payload });
        yield put({ type: 'SET_STUDENT_PORTAL_ASSIGNMENTS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchStudentPortalCohort() {
    try {
        const response = yield axios.get('api/students/cohort');
        yield put({ type: 'SET_STUDENT_PORTAL_COHORT', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchStudentPortalCourseWorkDetail(action) {
    try {
        const response = yield axios.get(`api/students/coursework/${action.payload.id}`);
        yield put({ type: 'SET_STUDENT_PORTAL_COURSE_WORK', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* putStudentPortalCourseWork(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put('api/students/coursework', action.payload);
        if (action.payload.selectedFile && action.payload.selectedFile.type) {
            yield put({
                type: 'PUT_UPLOAD_COURSE_WORK',
                payload: {
                    selectedFile: action.payload.selectedFile,
                    assignmentId: action.payload.assignmentId,
                    id: action.payload.id,
                    fileName: action.payload.standardName,
                },
            });
        }
        yield put({ type: 'FETCH_STUDENT_PORTAL_COURSE_WORK_DETAIL', payload: { id: action.payload.assignmentId } });
        yield put({
            type: 'SET_STUDENT_COURSE_WORK_MODAL',
            payload: {
                modalOpen: false,
            },
        });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* putStudentPortalFeedback(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put('api/students/feedback', action.payload);
        yield put({ type: 'UNSET_STUDENT_DAILY_FEEDBACK' });
        yield put({ type: 'FETCH_STUDENT_PORTAL_FEEDBACK' });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* fetchStudentPortalInfo() {
    yield all([
        put({ type: 'FETCH_STUDENT_PORTAL_COHORT' }),
        put({ type: 'FETCH_STUDENT_PORTAL_FEEDBACK' }),
        put({ type: 'FETCH_USER_PROFILE_INFORMATION' }),
    ]);
}

function* uploadStudentPhoto(action) {
    try {
        const { selectedFile, resizedFile } = action.payload;
        // The name seems to be dropped on resize, send the name from the
        // original selected file instead.
        const fileName = encodeURIComponent(selectedFile.name);
        const fileType = encodeURIComponent(resizedFile.type);
        const fileSize = encodeURIComponent(resizedFile.size);
        const formData = new FormData();
        formData.append('image', resizedFile);
        yield axios.post(`api/students/profile/photo?name=${fileName}&type=${fileType}&size=${fileSize}`,
            formData);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student Photo Upload - post request failed', error);
    }
}

// For non-prime admission process students
function* updateUser(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put('/api/students/student/update', action.payload);
        if (action.profilePhoto) {
            yield* uploadStudentPhoto({ payload: action.profilePhoto });
        }
        yield put({ type: 'FETCH_USER' });

        // Move to reflection page
        yield delay(250);
        if (action.payload.role === APPLICANT) {
            yield action.history.replace('/applications');
        } else if (action.payload.role === STUDENT) {
            yield action.history.replace('/students/reflections');
        }
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Update Student - put request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

// Saga for obtaining relevant information for student profile
function* fetchUserProfile(action) {
    try {
        const response = yield axios.get('api/students/student/profile');
        yield put({ type: 'SET_EDITING_USER', payload: response.data });
    } catch (error) {
        if (action.payload && action.payload.location === 'login') {
            // do not redirect if we came from the login page
        } else if (error.response.status === 403) {
            localStorage.removeItem('hasUser');
            yield put({ type: 'LOGOUT' });
            yield put({ type: 'UNSET_USER' });
            // With protected routes, this should be unncessary
            // window.location.href = '/#/login';
        }
    }
}
function* uploadCourseWork(action) {
    try {
        const { selectedFile } = action.payload;
        const fileName = encodeURIComponent(action.payload.fileName);
        const fileType = encodeURIComponent(selectedFile.type);
        const fileSize = encodeURIComponent(selectedFile.size);
        const courseWorkId = action.payload.id;
        const formData = new FormData();
        formData.append('courseWork', selectedFile);
        yield axios.post(`api/students/coursework/upload?name=${fileName}&type=${fileType}&size=${fileSize}&id=${courseWorkId}`, formData);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Course Work Upload - post request failed', error);
    }
}

function* userSaga() {
    yield takeLatest('FETCH_ALL_STUDENT_PORTAL_INFO', fetchStudentPortalInfo);
    yield takeLatest('FETCH_STUDENT_PORTAL_FEEDBACK', fetchStudentPortalFeedback);
    yield takeLatest('FETCH_STUDENT_PORTAL_ASSIGNMENTS', fetchStudentPortalAssignments);
    yield takeLatest('FETCH_STUDENT_PORTAL_COURSE_WORK_DETAIL', fetchStudentPortalCourseWorkDetail);
    yield takeLatest('PUT_STUDENT_PORTAL_COURSE_WORK', putStudentPortalCourseWork);
    yield takeLatest('PUT_STUDENT_PORTAL_FEEDBACK', putStudentPortalFeedback);
    yield takeLatest('FETCH_STUDENT_PORTAL_COHORT', fetchStudentPortalCohort);
    yield takeLatest('FETCH_STUDENT_PORTAL_FEEDBACK_LATEST', fetchStudentPortalLastFeedback);
    // yield takeLatest('FETCH_STUDENT_ACADEMIC_EVENTS', fetchStudentAcademicEvents);
    yield takeLatest('PUT_STUDENT_PORTAL_STUDENT', updateUser);
    yield takeLatest('FETCH_USER_PROFILE_INFORMATION', fetchUserProfile);
    yield takeLatest('PUT_UPLOAD_COURSE_WORK', uploadCourseWork);
}

export default userSaga;
