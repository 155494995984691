export const getPhotoUrl = (bucket, studentId, photoName, size = 'medium') => {
    let url = `student_${size}_missing.svg`;

    if (photoName && bucket && bucket !== '') {
        // Paperclip, the ruby plugin previously used for S3 uploads, stores
        // photos using a folder structure with the user id. For example,
        // an id of 3012 would turn into 000/003/012
        let idText = `${studentId}`;
        while (idText.length < 9) {
            // Prepend 0's until we get to 9 digits
            idText = `0${idText}`;
        }
        // Split the string into a folder path
        const folders = `${idText.substring(0, 3)}/${idText.substring(3, 6)}/${idText.substring(6, idText.length)}`;
        url = `https://${bucket}.s3.amazonaws.com/students/photos/${folders}/${size}/${photoName}`;
    }
    return url;
};

export const getLogicQuestionUrl = (bucket, questionId, photoName, size = 'original') => {
    let url = `student_${size}_missing.svg`;

    if (photoName && bucket && bucket !== '') {
        // Paperclip, the ruby plugin previously used for S3 uploads, stores
        // photos using a folder structure with the user id. For example,
        // an id of 3012 would turn into 000/003/012
        let idText = `${questionId}`;
        while (idText.length < 9) {
            // Prepend 0's until we get to 9 digits
            idText = `0${idText}`;
        }
        // Split the string into a folder path
        const folders = `${idText.substring(0, 3)}/${idText.substring(3, 6)}/${idText.substring(6, idText.length)}`;
        url = `https://${bucket}.s3.amazonaws.com/logic_questions/question_images/${folders}/${size}/${photoName}`;
    }
    return url;
};

export const getResumeUrl = (bucket, userId, fileName) => {
    let url = '';

    if (fileName && bucket && bucket !== '') {
        // Paperclip, the ruby plugin previously used for S3 uploads, stores
        // photos using a folder structure with the user id. For example,
        // an id of 3012 would turn into 000/003/012
        let idText = `${userId}`;
        while (idText.length < 9) {
            // Prepend 0's until we get to 9 digits
            idText = `0${idText}`;
        }
        // Split the string into a folder path
        const folders = `${idText.substring(0, 3)}/${idText.substring(3, 6)}/${idText.substring(6, idText.length)}`;
        url = `https://${bucket}.s3.amazonaws.com/users/resumes/${folders}/original/${fileName}`;
    }
    return url;
};

export const getCourseWorkUrl = (bucket, cwId, fileName) => {
    let url = '';

    if (fileName && bucket && bucket !== '') {
        // Paperclip, the ruby plugin previously used for S3 uploads, stores
        // photos using a folder structure with the user id. For example,
        // an id of 3012 would turn into 000/003/012
        let idText = `${cwId}`;
        while (idText.length < 9) {
            // Prepend 0's until we get to 9 digits
            idText = `0${idText}`;
        }
        // Split the string into a folder path
        const folders = `${idText.substring(0, 3)}/${idText.substring(3, 6)}/${idText.substring(6, idText.length)}`;
        url = `https://${bucket}.s3.amazonaws.com/assignments/course_works/${folders}/original/${fileName}`;
    }
    return url;
};

export default {
    getPhotoUrl,
    getLogicQuestionUrl,
    getResumeUrl,
    getCourseWorkUrl,
};
