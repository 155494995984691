import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const ConditionalTypography = ({
    title,
    displayValue,
    defaultValue,
}) => (
    <>
        <Typography variant="h6">
            {title}
        </Typography>
        {
            (displayValue === undefined || displayValue === null || displayValue === '') ? (
                <>
                    <Typography variant="body2">
                        {defaultValue}
                    </Typography>
                </>
            ) : (
                <>
                    <Typography variant="body1">
                        {displayValue}
                    </Typography>
                </>
            )}
    </>

);

ConditionalTypography.propTypes = {
    title: PropTypes.string.isRequired,
    displayValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
    ]),
    defaultValue: PropTypes.string.isRequired,
};

ConditionalTypography.defaultProps = {
    displayValue: null,
};
export default ConditionalTypography;
