import React from 'react';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { extendedDateTime, shortDateTime } from './DateFormatHelper';

export const CourseWorkStatus = {
    // Uncompleted: Work is not completed, but not yet due
    Uncompleted: 'Uncompleted',

    // Missing: Assignment is past due, and incomplete
    Missing: 'Missing',

    // Complete: Assignment is complete, and has no due date
    Complete: 'Complete',
    CompleteGraded: 'CompleteGraded',

    // Early: Assignment is complete before much before due date
    // (15min for daily or 6h for weekend assignment)
    Early: 'Early',
    EarlyGraded: 'EarlyGraded',

    // OnTime: Assignment is complete before due date
    OnTime: 'OnTime',
    OnTimeGraded: 'OnTimeGraded',

    // Late: Assignment is complete after the due date
    Late: 'Late',
    LateGraded: 'LateGraded',

    ResubmissionDue: 'ResubmissionDue',
    ResubmissionComplete: 'ResubmissionComplete',
    ResubmissionAccepted: 'ResubmissionAccepted',
};

CourseWorkStatus.from = (courseWork, assignment) => {
    const completedAt = moment(courseWork.completed_at);
    // Give them a 5 minute buffer
    let dueAt = moment(assignment.due_at).add({ minutes: 5 });
    const assignedAt = moment(assignment.assigned_at);
    const duration = moment.duration(dueAt.diff(assignedAt));

    // Daily assignments are early if turned in 15 minutes in advance
    let earlyAt = moment(assignment.due_at).subtract({ minutes: 15 });

    const isGraded = assignment.assignment_type === 'graded'
        && !Number.isNaN(courseWork.grade)
        && courseWork.grade !== null
        && courseWork.workflow_state !== 'draft';

    if (Math.abs(duration.asHours()) > 24) {
        // Weekend assignments have a larger early threshold
        earlyAt = moment(assignment.due_at).subtract({ hours: 6 });
    } else {
        // Daily assignments have a larger late buffer
        dueAt = moment(assignment.due_at).add({ minutes: 60 });
    }

    // Handle Resubmissions
    if (courseWork.resubmission_due_at && isGraded) {
        if (courseWork.resubmission_accepted_at !== null) {
            return CourseWorkStatus.ResubmissionAccepted;
        }
        if (courseWork.resubmission_completed_at !== null) {
            return CourseWorkStatus.ResubmissionComplete;
        }
        return CourseWorkStatus.ResubmissionDue;
    }

    // Missing: Assignment is past due, and incomplete
    if (!courseWork.completed_at && moment() > dueAt) {
        return CourseWorkStatus.Missing;
    }

    // Uncompleted: Work is not completed, but not yet due
    if (!courseWork.completed_at) {
        return CourseWorkStatus.Uncompleted;
    }

    // Complete: Assignment is complete, and has no due date
    if (!assignment.due_at) {
        return isGraded ? CourseWorkStatus.CompleteGraded : CourseWorkStatus.Complete;
    }

    // Early: Assignment is complete before much before due date
    // (15min for daily or 6h for weekend assignment)
    if (completedAt < earlyAt) {
        return isGraded ? CourseWorkStatus.EarlyGraded : CourseWorkStatus.Early;
    }

    // OnTime: Assignment is complete before due date
    if (completedAt < dueAt) {
        return isGraded ? CourseWorkStatus.OnTimeGraded : CourseWorkStatus.OnTime;
    }

    // Late: Assignment is complete after the due date
    return isGraded ? CourseWorkStatus.LateGraded : CourseWorkStatus.Late;
};

/**
 * Student-facing display of course work status.
 * Note that students will not see early or on time status, just "Complete"
 */
export const studentStatusText = (courseWork, assignment) => {
    const status = CourseWorkStatus.from(courseWork, assignment);

    switch (status) {
    case CourseWorkStatus.Uncompleted:
        return <span className="status-text status-uncompleted">Uncompleted</span>;
    case CourseWorkStatus.Missing:
        return <span className="status-text status-missing">Missing</span>;
    case CourseWorkStatus.CompleteGraded:
    case CourseWorkStatus.EarlyGraded:
    case CourseWorkStatus.OnTimeGraded:
    case CourseWorkStatus.LateGraded:
        return <span className="status-text status-graded">Graded</span>;
    case CourseWorkStatus.Complete:
    case CourseWorkStatus.Early:
    case CourseWorkStatus.OnTime:
        return <span className="status-text status-early">Complete</span>;
    case CourseWorkStatus.Late:
        return <span className="status-text status-late">Late</span>;
    case CourseWorkStatus.ResubmissionDue:
        return (
            <Tooltip
                title={`Due ${extendedDateTime(courseWork.resubmission_due_at)}`}
            >
                <span className="status-text status-missing">Resubmission Required</span>
            </Tooltip>
        );
    case CourseWorkStatus.ResubmissionComplete:
        return <span className="status-text status-early">Resubmission Complete</span>;
    case CourseWorkStatus.ResubmissionAccepted:
        return <span className="status-text status-graded">Resubmission Accepted</span>;
    default:
        return <span className="status-text status-uncompleted">Uncompleted</span>;
    }
};

/**
 * Instructor facing status text
 * Includes early/on time status text
 */
export const shortStatusText = (courseWork, assignment) => {
    const status = CourseWorkStatus.from(courseWork, assignment);

    switch (status) {
    case CourseWorkStatus.Uncompleted:
        return <span className="status-text status-uncompleted">Uncompleted</span>;
    case CourseWorkStatus.Missing:
        return <span className="status-text status-missing">Missing</span>;
    case CourseWorkStatus.Complete:
    case CourseWorkStatus.CompleteGraded:
        return <span className="status-text status-early">Complete</span>;
    case CourseWorkStatus.Early:
    case CourseWorkStatus.EarlyGraded:
        return <span className="status-text status-early">Early</span>;
    case CourseWorkStatus.OnTime:
    case CourseWorkStatus.OnTimeGraded:
        return <span className="status-text status-on-time">On Time</span>;
    case CourseWorkStatus.Late:
    case CourseWorkStatus.LateGraded:
        return <span className="status-text status-late">Late</span>;
    case CourseWorkStatus.ResubmissionDue:
        return (
            <Tooltip
                title={`Due ${extendedDateTime(courseWork.resubmission_due_at)}`}
            >
                <span className="status-text status-missing">Resubmission Required</span>
            </Tooltip>
        );
    case CourseWorkStatus.ResubmissionComplete:
        return <span className="status-text status-early">Resubmission Complete</span>;
    case CourseWorkStatus.ResubmissionAccepted:
        return <span className="status-text status-graded">Resubmission Accepted</span>;
    default:
        return <span className="status-text status-uncompleted">Uncompleted</span>;
    }
};

// Sets green background if 15/15 (100%), otherwise yellow
export const numberOfText = (first, second) => {
    let result = <span className="status-text status-complete">{`${first} / ${second}`}</span>;
    if (first < second) {
        result = <span className="status-text status-incomplete">{`${first} / ${second}`}</span>;
    }
    return result;
};

export const completedText = (assignment) => {
    let result = 'n/a';
    if (assignment.workflow_state !== 'assigned') {
        result = 'Not assigned';
    } else if (assignment.workflow_state === 'assigned' && assignment.assignment_type === 'graded') {
        result = numberOfText(assignment.summary.completed, assignment.summary.total);
    } else if (assignment.workflow_state === 'assigned' && assignment.assignment_type !== 'graded') {
        result = <span>{`${assignment.summary.completed} / ${assignment.summary.total}`}</span>;
    }
    return result;
};

export const gradedText = (assignment) => {
    let result = 'n/a';
    if (assignment.workflow_state !== 'assigned') {
        result = 'Not assigned';
    } else if (assignment.workflow_state === 'assigned' && assignment.assignment_type === 'graded') {
        result = numberOfText(assignment.summary.graded, assignment.summary.total);
    } else if (assignment.workflow_state === 'assigned' && assignment.assignment_type !== 'graded') {
        result = 'Ungraded';
    }
    return result;
};

export const draftedText = (assignment) => {
    let result = 'n/a';
    if (assignment.workflow_state !== 'assigned') {
        result = 'Not assigned';
    } else if (assignment.workflow_state === 'assigned' && assignment.assignment_type === 'graded') {
        result = `${assignment.summary.drafted} Drafted`;
    } else if (assignment.workflow_state === 'assigned' && assignment.assignment_type !== 'graded') {
        result = 'Ungraded';
    }
    return result;
};

export const dueAtText = (assignment) => {
    let result = 'n/a';
    if (assignment.workflow_state !== 'assigned') {
        result = 'Not assigned';
    } else if (!assignment.due_at) {
        result = 'No due date';
    } else {
        result = shortDateTime(assignment.due_at);
    }
    return result;
};

export default {
    shortStatusText,
    numberOfText,
    completedText,
    gradedText,
    draftedText,
    dueAtText,
};
