/**
 * Browser-specific app settings
 *
 * These are generally sync'd up to local storage
 */

import { combineReducers } from 'redux';

const localStorageRowsPerPage = localStorage.getItem('rowsPerPage');
const defaultRowsPerPage = (
    Number(localStorageRowsPerPage) && !Number.isNaN(Number(localStorageRowsPerPage))
        ? Number(localStorageRowsPerPage) : 25
);

/**
 * How many list items to render by default on paginated views
 * Sync'd to local storage
 */
const rowsPerPage = (state = defaultRowsPerPage, action) => {
    switch (action.type) {
    case 'SET_ROWS_PER_PAGE':
        localStorage.setItem('rowsPerPage', action.payload);
        return Number(action.payload);
    default:
        return state;
    }
};

export default combineReducers({
    rowsPerPage,
});
