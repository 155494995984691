import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Paper, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { getPhotoUrl } from '../../../../helpers/PhotoHelper';
import { checkIfUrl } from '../../../../helpers/UrlHelper';
import HubspotIcon from '../../../General/Icons/HubpostIcon';

const styles = {
    container: {
        display: 'flex',
        width: '100%',
    },
    details: {
        flex: 1,
        minHeight: '200px',
    },
};

/**
 * Stats returned from the server can be null. This function
 * checks for a valid numner and formats the entry accordingly.
 */
function formatStatNumber(entry) {
    let result = '0.00';
    // We have an entry and it's a valid number
    if (entry && !Number.isNaN(Number(entry))) {
        result = `${Number(entry).toFixed(2)}`;
    }
    return result;
}

class StudentProfile extends Component {
    getGithubLink = (githubAccount) => {
        let githubUrl = '';
        if (!githubAccount) {
            return githubUrl;
        }
        const result = checkIfUrl(githubAccount);
        if (result) {
            githubUrl = <a href={githubAccount}> GitHub Profile </a>;
            return githubUrl;
        }
        githubUrl = <a href={`https://github.com/${githubAccount}`}> GitHub Profile </a>;
        return githubUrl;
    };

    getPlacementText = (student) => {
        let result = '';
        if (student.skip_placement_reporting) {
            result = <span className="status-text status-excluded">Excluded</span>;
        } else if (student.application_status === 'dropped_out') {
            result = <span className="status-text status-unplaced">Dropped Out</span>;
        } else if (student.placement_count > 0) {
            result = <span className="status-text status-placed">Placed</span>;
        } else {
            result = <span className="status-text status-unplaced">Unplaced</span>;
        }
        return result;
    };

    render() {
        const {
            user,
            student,
            cohort,
            classes,
        } = this.props;
        let pronounValue = '';
        if (student && student.pronouns && student.pronouns !== 'Prefer-not-to-say') {
            pronounValue = `${student.pronouns}`;
        }
        return (
            <div className={classes.container}>
                <img
                    className="placeholder-photo-lg"
                    src={getPhotoUrl(user.s3_bucket, student.id, student.photo_file_name)}
                    alt={student.first_name}
                />
                <Paper className={classes.details}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {`${student.first_name} ${student.last_name}`}
                                { student.pronouns && ` (${pronounValue})`}
                                {
                                    (
                                        student.hubspot_url
                                        && student.hubspot_url
                                    )
                                    && (
                                        <a href={`${student.hubspot_url}`} target="_blank" rel="noopener noreferrer">
                                            <HubspotIcon />
                                        </a>
                                    )
                                }
                            </Typography>
                            {
                                student.pronunciation && (
                                    <p>{`Pronunciation: ${student.pronunciation}`}</p>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p>{this.getPlacementText(student)}</p>
                            <br />
                            <p>{student.email}</p>
                            <p>{student.phone}</p>
                            <br />
                            {
                                this.getGithubLink(student.github_account) !== ''
                                && (
                                    <>
                                        <p>
                                            {this.getGithubLink(student.github_account)}
                                        </p>

                                        <br />
                                        <br />
                                    </>
                                )
                            }

                            {
                                student.cohort_name && (
                                    <>
                                        <Typography variant="h6">
                                            Cohort
                                        </Typography>
                                        <Link to={`/cohorts/${student.cohort_id}`}>{student.cohort_name}</Link>
                                    </>
                                )
                            }
                            <br />
                            <br />
                            <Typography variant="h6">
                                Application
                            </Typography>
                            {
                                student.applications && student.applications.map(application => (
                                    <>
                                        <Link to={`/admissions_applications/${application.id}`}>
                                            {application.course_name}
                                        </Link>
                                        <br />
                                    </>
                                ))
                            }
                            <br />
                            {
                                (
                                    student.hubspot_url
                                    && student.hubspot_url
                                )
                                && (
                                    <>
                                        <Typography variant="h6">
                                            Hubspot Id
                                        </Typography>
                                        <a href={`${student.hubspot_url}`} target="_blank" rel="noopener noreferrer">
                                            {student.hubspot_id}
                                        </a>
                                    </>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            {formatStatNumber(student.avg_feeling)}
                                            <br />
                                            <p>Feeling</p>
                                        </td>
                                        <td>
                                            {formatStatNumber(cohort.avg_feeling)}
                                            <br />
                                            <p>Cohort</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {formatStatNumber(student.avg_understanding)}
                                            <br />
                                            <p>Understanding</p>
                                        </td>
                                        <td>
                                            {formatStatNumber(cohort.avg_understanding)}
                                            <br />
                                            <p>Cohort</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {formatStatNumber(student.avg_support)}
                                            <br />
                                            <p>Support</p>
                                        </td>
                                        <td>
                                            {formatStatNumber(cohort.avg_support)}
                                            <br />
                                            <p>Cohort</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

StudentProfile.propTypes = {
    user: PropTypes.instanceOf(Object).isRequired,
    student: PropTypes.instanceOf(Object).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(StudentProfile);
