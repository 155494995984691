import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import {
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    Hidden,
    TableSortLabel,
    TableBody,
    TextField,
    FormHelperText,
    FormLabel,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CohortAssignmentRow from './CohortAssignmentRow';
import CohortPageTitle from '../CohortPageTitle';
import TablePagination from '../../../helpers/TablePagination';

const styles = theme => ({
    searchInput: {
        width: '250px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    /**
* React doesn't provide an auto complete input field. Styling this third
* party component takes a bit more work that standard Material UI components.
* https://react-select.com/styles#using-classnames
*/
    cohortSelect: {
        paddingTop: '9px',
        zIndex: '1000',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '0px',
        },
        '& .react-select__control': {
            height: '39px',
            borderRadius: '3px',
            width: '250px',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
    },
    pagination: {
        textAlign: 'right',
        width: '100%',
    },
    searchBar: {
        margin: 5,
        paddingRight: 15,
        display: 'flex',
        flexFlow: 'row wrap',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    helperText: {
        margin: 0,
        padding: 0,
    },
    selectLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
});

class CohortAssignmentsPage extends Component {
    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_USER' });
        // dispatch({ type: 'SET_STUDENT_MODAL', payload: { modalOpen: false } });
        dispatch({ type: 'FETCH_COHORT_DETAILS', payload: { id: match.params.id } });
        dispatch({ type: 'FETCH_COHORT_ASSIGNMENTS', payload: this.getPayload() });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
    }

    viewAssignmentDetails = assignment => () => {
        const { history } = this.props;
        history.push(`/assignments/${assignment.id}`);
    };

    handleSearchChange = (event) => {
        const { dispatch } = this.props;
        const searchText = event.target.value;
        dispatch({ type: 'SET_COHORT_ASSIGNMENT_TEXT', payload: { text: searchText } });
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => { this.runSearch(searchText); }, 250);
    };

    handleStatusSelect = (selectedOption) => {
        const { dispatch } = this.props;
        dispatch({ type: 'SET_COHORT_ASSIGNMENT_STATUS_TEXT', payload: selectedOption });
        const payload = {
            ...this.getPayload(),
            statusText: selectedOption.value,
            offset: 0,
        };
        dispatch({ type: 'FETCH_COHORT_ASSIGNMENTS', payload });
    };

    runSearch = (searchText) => {
        const { dispatch } = this.props;
        // When the user changes search text, reset the offset to 0
        dispatch({ type: 'SET_COHORT_ASSIGNMENT_OFFSET', payload: { offset: 0 } });
        const payload = {
            ...this.getPayload(),
            offset: 0,
            searchText,
        };
        dispatch({ type: 'FETCH_COHORT_ASSIGNMENTS', payload });
        this.searchTimeout = null;
    };

    handleChangePage = (event, page) => {
        const {
            dispatch,
            rowsPerPage,
        } = this.props;
        const offset = Math.max(page * rowsPerPage, 0);
        dispatch({ type: 'SET_COHORT_ASSIGNMENT_OFFSET', payload: { offset } });
        const payload = {
            ...this.getPayload(),
            offset,
        };
        dispatch({ type: 'FETCH_COHORT_ASSIGNMENTS', payload });
    };

    handleChangeRowsPerPage = (nextRowsPerPage) => {
        const { dispatch } = this.props;

        dispatch({
            type: 'FETCH_COHORT_ASSIGNMENTS',
            payload: {
                ...this.getPayload(),
                limit: nextRowsPerPage,
            },

        });
    };

    sortBy = orderBy => () => {
        const {
            sortOrder,
        } = this.props;
        const {
            dispatch,
        } = this.props;
        let { order } = sortOrder;
        // Existing column selected, reverse the sort
        if (orderBy === sortOrder.orderBy) {
            if (order === 'asc') {
                order = 'desc';
            } else {
                order = 'asc';
            }
        }
        const updatedSort = {
            orderBy,
            order,
        };
        dispatch({ type: 'SET_COHORT_ASSIGNMENT_SORT_ORDER', payload: updatedSort });
        const payload = {
            ...this.getPayload(),
            orderBy: updatedSort.orderBy,
            order: updatedSort.order,
        };
        dispatch({ type: 'FETCH_COHORT_ASSIGNMENTS', payload });
    };

    getPayload = () => {
        const {
            sortOrder,
        } = this.props;
        const {
            searchText,
            searchOffset,
            selectedStatus,
            match,
            rowsPerPage,
        } = this.props;
        const payload = {
            order: sortOrder.order,
            orderBy: sortOrder.orderBy,
            statusText: selectedStatus.value,
            cohortId: match.params.id,
            searchText,
            offset: searchOffset,
            limit: rowsPerPage,
        };
        return payload;
    };

    render() {
        const {
            sortOrder,
        } = this.props;
        const {
            cohort,
            assignments,
            searchOffset,
            rowsPerPage,
            classes,
            searchText,
            selectedStatus,
        } = this.props;
        const page = Math.floor(searchOffset / rowsPerPage);
        return (
            <div className="container">
                <CohortPageTitle
                    title="Assignments"
                    cohort={cohort}
                    // Action buttons are created by the PageTitle component
                    actions={[
                        {
                            label: 'Add New Assignment',
                            // Dispatch an action...
                            type: 'UNSET_EDITING_ASSIGNMENT',
                            // ...and navigate to this page.
                            path: '/assignments/new',
                            // history.state to prepopulate the selected cohort
                            state: {
                                value: cohort.id,
                                label: cohort.name,
                            },
                        },
                    ]}
                />
                <Paper>
                    <div className={classes.searchBar}>
                        <div className={classes.searchInputContainer} style={{ display: 'inline-block', paddingRight: '30px' }}>
                            <FormLabel component="label" className={classes.selectLabel}>Search</FormLabel>
                            <FormHelperText className={classes.helperText}>
                                Search by assignment name or tag.
                            </FormHelperText>
                            <TextField
                                className={classes.searchInput}
                                margin="dense"
                                variant="outlined"
                                value={searchText}
                                onChange={this.handleSearchChange}
                            />
                        </div>
                        <div className={classes.selectContainer} style={{ display: 'inline-block', paddingRight: '30px', paddingTop: '18px' }}>
                            <FormLabel component="label" className={classes.selectLabel}>Assignment Type</FormLabel>
                            <ReactSelect
                                value={selectedStatus}
                                onChange={this.handleStatusSelect}
                                options={[
                                    { value: '', label: 'All' },
                                    { value: 'graded', label: 'Graded' },
                                    { value: 'not_graded', label: 'Ungraded' },
                                ]}
                                className={classes.cohortSelect}
                                classNamePrefix="react-select"
                            />
                        </div>
                    </div>
                    <br />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sortDirection={sortOrder.orderBy === 'name' && sortOrder.order}
                                >
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'name'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('name')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    # Completed
                                </TableCell>
                                {/* Only three rows can fit on most mobile devices */}
                                <Hidden mdDown>
                                    <TableCell>
                                        # Graded
                                    </TableCell>
                                    <TableCell>
                                        # Drafted
                                    </TableCell>
                                </Hidden>
                                <TableCell
                                    sortDirection={sortOrder.orderBy === 'due_at' && sortOrder.order}
                                >
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'due_at'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('due_at')}
                                    >
                                        Due At
                                    </TableSortLabel>
                                </TableCell>
                                <Hidden mdDown>
                                    <TableCell
                                        sortDirection={sortOrder.orderBy === 'assigned_at' && sortOrder.order}
                                    >
                                        <TableSortLabel
                                            active={sortOrder.orderBy === 'assigned_at'}
                                            direction={sortOrder.order}
                                            onClick={this.sortBy('assigned_at')}
                                        >
                                            Assigned At
                                        </TableSortLabel>
                                    </TableCell>
                                </Hidden>

                                {/* Only three rows can fit on most mobile devices */}
                                <Hidden mdDown>
                                    <TableCell align="right">Action</TableCell>
                                </Hidden>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                assignments
                                && assignments.rows
                                && assignments.rows.length > 0
                                && assignments.rows.map(assignment => (
                                    <CohortAssignmentRow
                                        divider
                                        key={assignment.id}
                                        assignment={assignment}
                                        viewAssignmentDetails={this.viewAssignmentDetails}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                    {
                        assignments.count > 0
                        && (
                            <TablePagination
                                count={assignments.count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        )
                    }
                </Paper>
            </div>
        );
    }
}

CohortAssignmentsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
    }).isRequired,
    cohort: PropTypes.instanceOf(Object).isRequired,
    assignments: PropTypes.instanceOf(Object).isRequired,
    searchOffset: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    searchText: PropTypes.string.isRequired,
    sortOrder: PropTypes.shape({
        order: PropTypes.string.isRequired,
        orderBy: PropTypes.string.isRequired,
    }).isRequired,
    selectedStatus: PropTypes.instanceOf(Object).isRequired,
};

// Instead of taking everything from state, we just want the user info.
// if you wanted you could write this code like this:
// const mapStateToProps = ({user}) => ({ user });
const mapStateToProps = state => ({
    user: state.user,
    cohort: state.cohort.cohort,
    assignments: state.cohort.assignments,
    searchOffset: state.cohort.assignmentSearchOffset,
    searchText: state.cohort.assignmentSearchText,
    rowsPerPage: state.settings.rowsPerPage,
    sortOrder: state.cohort.cohortAssignmentSortOrder,
    selectedStatus: state.cohort.assignmentSelectedStatus,
});

// this allows us to use <App /> in index.js
export default connect(mapStateToProps)(withStyles(styles)(CohortAssignmentsPage));
