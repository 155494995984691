import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import {
    MenuItem,
    FormControl,
    Select,
    InputLabel,
} from '@mui/material';

const styles = {
    feedbackContainer: {
        padding: '0px 20px',
    },
    formControl: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
};

const EmployeeSelect = ({
    index,
    employee,
    employeeList,
    employeeChangeFor,
    classes,
}) => (
    <FormControl className={classes.formControl}>
        <InputLabel id={`intructor_id_${index}_label`}>
            {`Instructor ${index} (Optional)`}
        </InputLabel>
        <Select
            labelid={`intructor_id_${index}_label`}
            id={`intructor_id_${index}`}
            value={`${employee.id}`}
            onChange={employeeChangeFor(index)}
        >
            {
                employeeList
                && employeeList.map(employeeItem => (
                    <MenuItem value={employeeItem.id} key={employeeItem.id}>
                        {
                            employeeItem.email
                                ? (`${employeeItem.first_name} ${employeeItem.last_name} (${employeeItem.email})`)
                                : ('None')
                        }
                    </MenuItem>
                ))
            }
        </Select>
    </FormControl>
);

EmployeeSelect.propTypes = {
    index: PropTypes.number.isRequired,
    employeeChangeFor: PropTypes.func.isRequired,
    employee: PropTypes.instanceOf(Object).isRequired,
    employeeList: PropTypes.instanceOf(Array).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(EmployeeSelect);
