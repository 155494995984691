import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { states } from '../config/labels';

function StateAutocomplete({
    value,
    onChange,
    label,
    variant,
    margin,
}) {
    return (
        <Autocomplete
            style={{ width: '300px', display: 'inline-block' }}
            options={Object.keys(states)}
            getOptionLabel={option => states[option] || option || ''}
            value={value}
            onChange={(evt, val) => {
                // MUI sometimes sets the value to the label here (full state name),
                // not the actual value (state abbreviation).
                // Need to reverse lookup.
                const [stateAbbrev] = Object.entries(states)
                    .find(([abbrev, stateName]) => stateName === val) || [val];

                return onChange(stateAbbrev);
            }}
            autoSelect
            freeSolo
            renderInput={params => (
                <TextField
                    {...params}
                    required
                    label={label}
                    variant={variant}
                    style={{ width: '300px', display: 'inline-block' }}
                    margin={margin}
                    inputProps={{
                        ...params.inputProps,
                        // Disable browser-native auto-complete
                        autoComplete: 'new-password',
                    }}
                />
            )}
        />
    );
}

StateAutocomplete.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    variant: PropTypes.string,
    margin: PropTypes.string,
};

StateAutocomplete.defaultProps = {
    label: 'State',
    variant: 'outlined',
    margin: 'dense',
};

export default StateAutocomplete;
