import React from 'react';
import { makeStyles } from '@mui/styles';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import FaIcon from '../../../helpers/FaIcon';

// https://www.opm.gov/policy-data-oversight/pay-leave/pay-administration/fact-sheets/computing-hourly-rates-of-pay-using-the-2087-hour-divisor/
const HOURS_PER_YEAR = 2087;

// const currencyFormatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
// });

function formatter(amount) {
    if (amount && typeof amount === 'number') {
        return `$${amount.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1,')}`;
    }
    return 'n/a';
}

const formatCurrency = val => formatter(val);

const formatIncome = val => (
    <>
        {formatCurrency(val)}
        <span className="hourly">
            {`${formatCurrency(val / HOURS_PER_YEAR)}/hr`}
        </span>
    </>
);

const useStyles = makeStyles({
    salaryByJobType: {
        '&.MuiTable-root': {
            marginTop: 30,
        },
        '& .MuiTableCell-head': {
            color: '#676a6c',
            borderBottomWidth: 2,
        },
        '& .MuiTableCell-root': {
            textAlign: 'right',
            fontSize: 15,
            color: 'rgba(0, 0, 0, 0.87)',
            // hourly
            '& > .hourly': {
                fontSize: 13,
                marginLeft: 5,
                color: '#676a6c',
            },
        },
        '& .MuiTableCell-body:first-child': {
            fontWeight: 'bold',
            textAlign: 'left',
        },
    },

    changeIcon: {
        fontSize: 17,
        verticalAlign: -3,
        marginLeft: 4,
    },
    changeIconUp: {
        color: '#4caf50',
    },
    changeIconDown: {
        color: '#ff9800',
    },
});

function PlacementsByType() {
    const classes = useStyles();

    const byType = useSelector(store => store.placement.byType);

    return (
        <Table className={classes.salaryByJobType}>
            <TableHead>
                <TableRow>
                    <TableCell />
                    {/* empty */}
                    <TableCell>
                        # Roles
                    </TableCell>
                    <TableCell>
                        Avg. Annual
                    </TableCell>
                    <TableCell>
                        Median Annual
                    </TableCell>
                    <TableCell>
                        Avg. Change
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {byType.map(row => (
                    <TableRow key={row.placement_type}>
                        {/* Job Type */}
                        <TableCell>{row.placement_type}</TableCell>

                        {/* Count */}
                        <TableCell>{row.count}</TableCell>

                        {/* Average Income */}
                        <TableCell>
                            {formatIncome(row.average_post_income)}
                        </TableCell>

                        {/* Median Income */}
                        <TableCell>
                            {formatIncome(row.median_post_income)}
                        </TableCell>
                        {/* Average change amount */}
                        {/* <TableCell>
                            {formatIncome((row.average_income_change).toFixed(0))}
                            <FaIcon
                                icon={row.average_income_change <= 0
                                    ? 'arrow-alt-circle-down'
                                    : 'arrow-alt-circle-up'
                                }
                                className={classNames(
                                    classes.changeIcon,
                                    row.average_income_change <= 0
                                        ? classes.changeIconDown
                                        : classes.changeIconUp,
                                )}
                            />
                        </TableCell> */}
                        {/* Average change percent */}
                        <TableCell>
                            {`${(row.average_income_change_percentage * 100).toFixed(0)}%`}
                            <FaIcon
                                icon={row.average_income_change_percentage <= 0
                                    ? 'arrow-alt-circle-down'
                                    : 'arrow-alt-circle-up'
                                }
                                className={classNames(
                                    classes.changeIcon,
                                    row.average_income_change_percentage <= 0
                                        ? classes.changeIconDown
                                        : classes.changeIconUp,
                                )}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default PlacementsByType;
