import React, { useState } from 'react';
import {
    Grid,
    Paper,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CohortGenderStats from './CohortGenderStats';
import CohortAgeStats from './CohortAgeStats';
import CohortBIPOCStats from './CohortBIPOCStats';
import CohortEduStats from './CohortEduStats';
import CohortEmpStats from './CohortEmpStats';
import CohortVeteranStats from './CohortVeteranStats';
import ExpandArrowIcon from '../../General/ExpandArrowIcon';

const styles = {
    card: {
        margin: '15px',
    },
    cardContent: {
        marginLeft: '10px',
        marginRight: '10px',

    },
    cardHeading: {
        display: 'flex',
        marginBottom: '15px',
        width: '100%',
    },
    cardHeadingText: {
        width: '100%',
    },
    hoverTitle: {
        marginBottom: '0px',
        '&:hover': {
            backgroundColor: '#FAFAFA',
        },
        '&:active': {
            backgroundColor: '#F5F5F5',
        },
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
    },
    statsCards: {
        marginTop: '0px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
    },
    collapseBtn: {
        position: 'absolute',
        right: 0,
        textAlign: 'right',
        cursor: 'pointer',

        // Increase the "hit box" for this button
        padding: '15px 20px 20px 170px',
        marginTop: -15,

        '& span:first-child': {
            fontSize: 'inherit',
            textDecoration: 'underline',
            verticalAlign: 'bottom',
            marginRight: 5,
        },
        '& span:last-child': {
            fontSize: 12,
            padding: 2,
            verticalAlign: -11,
        },
    },
};
const CohortStatsRow = ({ cohort, classes }) => {
    const [collapsed, setCollapsed] = useState(true);
    return (
        <>
            <Paper
                className={classes.hoverTitle}
                onClick={() => setCollapsed(!collapsed)}
                onKeyPress={() => setCollapsed(!collapsed)}
                role="button"
                tabIndex={0}
            >

                <Grid container>
                    <div className={classes.cardHeading}>
                        <h5>Student Demographics</h5>

                        <div className={classes.collapseBtn}>
                            <span>{collapsed ? 'Expand' : 'Collapse'}</span>
                            <ExpandArrowIcon expanded={collapsed} collapsedIcon="fa-chevron-up" />
                        </div>
                    </div>

                </Grid>
            </Paper>
            {!collapsed && (
                <Paper className={classes.statsCards}>
                    <Grid container>
                        <CohortAgeStats cohort={cohort} />
                        <CohortBIPOCStats cohort={cohort} />
                        <CohortGenderStats cohort={cohort} />
                        <CohortEduStats cohort={cohort} />
                        <CohortEmpStats cohort={cohort} />
                        <CohortVeteranStats cohort={cohort} />
                    </Grid>
                </Paper>
            ) }
        </>

    );
};

CohortStatsRow.propTypes = {
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default withStyles(styles)(CohortStatsRow);
