/* eslint-disable no-console */
import { put, takeLatest, all } from 'redux-saga/effects';
import axios from 'axios';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* createApplicant(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const applicantToCreate = Object.assign({}, action.payload);
        delete applicantToCreate.retypePassword;
        yield axios.post('/api/applicants', applicantToCreate);
        yield put({ type: 'UNSET_EDITING_APPLICANT' });

        // Move to page for student created
        yield delay(100);
        yield put({
            type: 'LOGIN',
            payload: {
                username: applicantToCreate.email,
                password: applicantToCreate.password,
            },
        });
    } catch (error) {
        let errorText = 'Unknown error occurred. Please reach out to us at hello@primeacademy.io and we will help resolve the issue.';
        if (error && error.response && error.response.data && typeof error.response.data === 'string') {
            errorText = error.response.data;
        }
        yield put({
            type: 'SET_GLOBAL_MODAL',
            payload: {
                modalOpen: true,
                title: 'An Error Occurred',
                body: errorText,
            },
        });
        // console.log('Create Applicant - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* createApplication(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.post('/api/applicants/application', action.payload);
        // Move to page for application created
        yield delay(100);
        yield action.history.push(action.path);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Create Applicant - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* uploadResume(action) {
    try {
        const selectedFile = action.payload;
        const fileName = encodeURIComponent(selectedFile.name);
        const fileType = encodeURIComponent(selectedFile.type);
        const fileSize = encodeURIComponent(selectedFile.size);
        const formData = new FormData();
        formData.append('resume', selectedFile);
        yield axios.post(`api/applicants/resume?name=${fileName}&type=${fileType}&size=${fileSize}`, formData);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student Photo Upload - post request failed', error);
    }
}

function* fetchActiveApplications() {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const response = yield axios.get('api/applicants/applications');
        yield put({ type: 'SET_ACTIVE_APPLICATIONS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Fetch Applications - get request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* fetchActiveCourses() {
    try {
        const response = yield axios.get('api/applicants/courses');
        yield put({ type: 'SET_ACTIVE_APPLICATION_COURSES', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Fetch Applications - get request failed', error);
    }
}

function* fetchApplicationDetails(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield all([
            put({ type: 'FETCH_APPLICANT_ACTIVE_COHORTS', payload: action.payload }),
            put({ type: 'FETCH_APPLICANT_APPLICATION_GENERAL_DETAILS', payload: action.payload }),
            put({ type: 'FETCH_APPLICANT_APPLICATION_TECHNICAL_DETAILS', payload: action.payload }),
            put({ type: 'FETCH_APPLICANT_APPLICATION_START_DETAILS', payload: action.payload }),
            put({ type: 'FETCH_APPLICANT_APPLICATION_PROFILE_DETAILS', payload: action.payload }),
            put({ type: 'FETCH_APPLICANT_APPLICATION_LOGIC_DETAILS', payload: action.payload }),
        ]);
        yield delay(100);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Fetch Application Details - get request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* fetchApplicationStartDetails(action) {
    try {
        const response = yield axios.get(`api/applicants/application/detail/start/${action.payload.course_id}`);
        yield put({ type: 'SET_START_TAB_DETAILS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Fetch Application Start Details - get request failed', error);
    }
}

function* fetchApplicationActiveCohorts(action) {
    try {
        const response = yield axios.get(`api/applicants/cohorts/${action.payload.course_id}`);
        yield put({ type: 'SET_APPLICANT_ACTIVE_COHORTS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Fetch Application Start Details - get request failed', error);
    }
}

function* fetchApplicationTechnicalDetails(action) {
    try {
        const response = yield axios.get(`api/applicants/application/detail/technical/${action.payload.course_id}`);
        yield put({ type: 'SET_EDITING_TECHNICAL_APPLICANT', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Fetch Application Technical Details - get request failed', error);
    }
}

function* fetchApplicationProfileDetails(action) {
    try {
        const response = yield axios.get(`api/applicants/application/detail/profile/${action.payload.course_id}`);
        yield put({ type: 'SET_EDITING_PROFILE_APPLICANT', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Fetch Application Profile Details - get request failed', error);
    }
}

function* fetchApplicationLogicDetails(action) {
    try {
        const response = yield axios.get(`api/applicants/application/detail/logic/${action.payload.course_id}`);
        yield put({ type: 'SET_EDITING_LOGIC_APPLICANT', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Fetch Application Profile Details - get request failed', error);
    }
}

// TODO: Chris will revisit to potentially revert to one object
function* fetchApplicationGeneralDetails(action) {
    try {
        const response = yield axios.get(`api/applicants/application/detail/general/${action.payload.course_id}`);
        const selectValues = Object.assign({}, response.data);
        const inputValues = Object.assign({}, response.data);

        // null is an invalid data type for material ui textinput, needs to be empty strings
        Object.keys(inputValues).forEach((key) => {
            if (inputValues[key] === null) {
                inputValues[key] = '';
            }
        });
        yield put({ type: 'SET_APPLICANT_STEP', payload: response.data.application_step });
        yield put({ type: 'SET_EDITING_GENERAL_APPLICANT', payload: inputValues });
        yield put({ type: 'SET_EDITING_GENERAL_SELECT_INFORMATION', payload: selectValues });
    } catch (error) {
        console.log('Fetch Application General Details - get request failed', error);
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* startApplication(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put(`/api/applicants/application/start/${action.payload.courseId}`);
        yield delay(100);
        action.payload.nextTab();
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* submitApplication(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put(`/api/applicants/application/submit/${action.payload.courseId}`, action.payload.changes);
        yield put({ type: 'UNSET_UNSAVED_GENERAL_CHANGE', payload: false });
        yield put({ type: 'UNSET_UNSAVED_PROFILE_CHANGE', payload: false });
        yield put({ type: 'UNSET_UNSAVED_LOGIC_CHANGE', payload: false });
        yield put({ type: 'UNSET_UNSAVED_TECHNICAL_CHANGE', payload: false });
        yield put({ type: 'FETCH_APPLICANT_APPLICATION_GENERAL_DETAILS', payload: { course_id: action.payload.courseId } });
        yield delay(100);
        action.payload.nextTab();
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* updateGeneralApplicantInformation(action) {
    try {
        const updatedApplicant = { ...action.payload.applicant };
        const selectOptions = { ...action.payload.applicant }; // TODO?

        // for in loop converts react select values to values compatible with database
        // eslint-disable-next-line no-restricted-syntax
        for (const property in selectOptions) {
            if (Object.prototype.hasOwnProperty.call(selectOptions, property)) {
                if (selectOptions[property] && selectOptions[property].value) {
                    updatedApplicant[property] = selectOptions[property].value;
                }
            }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const property in updatedApplicant) {
            if (Object.prototype.hasOwnProperty.call(updatedApplicant, property)) {
                if (updatedApplicant[property] === '') {
                    updatedApplicant[property] = null;
                }
            }
        }

        if (action.payload.activeCohorts) {
            updatedApplicant.cohorts = action.payload.activeCohorts;
        }

        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put(`/api/applicants/application/general/${action.payload.courseId}`, updatedApplicant);
        yield put({ type: 'UNSET_UNSAVED_GENERAL_CHANGE', payload: false });
        if (action.payload.selectedFile && action.payload.selectedFile.type) {
            yield put({ type: 'PUT_UPLOAD_RESUME', payload: action.payload.selectedFile });
        }
        yield delay(100);
        action.payload.nextTab();
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* updateTechnicalApplicantInformation(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put(`/api/applicants/application/technical/${action.payload.editingTechnical.course_id}`, action.payload.editingTechnical);
        yield put({
            type: 'FETCH_APPLICANT_APPLICATION_DETAILS',
            payload: {
                course_id: action.payload.editingTechnical.course_id,
            },
        });
        yield delay(100);
        action.payload.nextTab();
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* updateProfileApplicantInformation(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put(`/api/applicants/application/profile/${action.payload.courseId}`, action.payload.profileQuestions);
        yield put({ type: 'UNSET_UNSAVED_PROFILE_CHANGE', payload: false });
        yield delay(100);
        action.payload.nextTab();
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* updateLogicApplicantInformation(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put(`/api/applicants/application/logic/${action.payload.courseId}`, action.payload.logicQuestions);
        yield put({ type: 'UNSET_UNSAVED_LOGIC_CHANGE', payload: false });
        yield delay(100);
        action.payload.nextTab();
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* applicantSaga() {
    yield takeLatest('CREATE_APPLICANT', createApplicant);
    yield takeLatest('FETCH_ACTIVE_APPLICATIONS', fetchActiveApplications);
    yield takeLatest('FETCH_ACTIVE_COURSES', fetchActiveCourses);
    yield takeLatest('CREATE_APPLICATION', createApplication);
    yield takeLatest('START_APPLICATION', startApplication);
    yield takeLatest('FETCH_APPLICANT_APPLICATION_DETAILS', fetchApplicationDetails);
    yield takeLatest('FETCH_APPLICANT_ACTIVE_COHORTS', fetchApplicationActiveCohorts);
    yield takeLatest('FETCH_APPLICANT_APPLICATION_GENERAL_DETAILS', fetchApplicationGeneralDetails);
    yield takeLatest('FETCH_APPLICANT_APPLICATION_TECHNICAL_DETAILS', fetchApplicationTechnicalDetails);
    yield takeLatest('FETCH_APPLICANT_APPLICATION_PROFILE_DETAILS', fetchApplicationProfileDetails);
    yield takeLatest('FETCH_APPLICANT_APPLICATION_LOGIC_DETAILS', fetchApplicationLogicDetails);
    yield takeLatest('FETCH_APPLICANT_APPLICATION_START_DETAILS', fetchApplicationStartDetails);
    yield takeLatest('PUT_APPLICANT_GENERAL_INFORMATION', updateGeneralApplicantInformation);
    yield takeLatest('PUT_APPLICANT_TECHNICAL_INFORMATION', updateTechnicalApplicantInformation);
    yield takeLatest('PUT_APPLICANT_PROFILE_INFORMATION', updateProfileApplicantInformation);
    yield takeLatest('PUT_APPLICANT_LOGIC_INFORMATION', updateLogicApplicantInformation);
    yield takeLatest('PUT_SUBMIT_APPLICATION', submitApplication);
    yield takeLatest('PUT_UPLOAD_RESUME', uploadResume);
}

export default applicantSaga;
