import React from 'react';
import {
    List,
    Paper,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CohortPreviousStudentRow from './CohortPreviousStudentRow';

const styles = {
    card: {
        margin: '15px',
    },
    cardContent: {
        marginLeft: '10px',
        marginRight: '10px',

    },
    cardHeading: {
        display: 'flex',
        marginBottom: '5px',
    },
    cardHeadingText: {
        width: '100%',
    },
};
const CohortPreviousStudentList = ({ cohort, viewStudentDetails, user }) => (

    <Paper>
        <h3>Previous Students</h3>
        <List>
            {
                cohort.previousStudents
                && cohort.previousStudents.map(row => (
                    <CohortPreviousStudentRow
                        key={row.id}
                        student={row.user}
                        movement={row}
                        viewStudentDetails={viewStudentDetails}
                        user={user}
                    />
                ))
            }
        </List>
    </Paper>
);

CohortPreviousStudentList.propTypes = {
    cohort: PropTypes.instanceOf(Object).isRequired,
    viewStudentDetails: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default withStyles(styles)(CohortPreviousStudentList);
