import { makeStyles } from '@mui/styles';

function makeSideNavStyles() {
    return makeStyles(theme => ({
        sideNav: {
            padding: '0',
            height: '100%', /* 100% Full-height */
            backgroundColor: '#222',
            overflowX: 'hidden', /* Disable horizontal scroll */
        },
        logo: {
            width: '155px',
            height: 'auto',
            padding: '30px 23px 10px 23px',
        },
        icon: {
            margin: 0,
            padding: theme.spacing(),
            fontSize: '14px',
            height: 'auto',
            width: 'auto',
        },
        navText: {
            margin: 0,
            padding: 0,
            color: 'white',
        },
        userName: {
            margin: 24,
            color: 'white',
        },
        logoutButton: {
            marginLeft: 24,
        },
    }));
}

export default makeSideNavStyles;
