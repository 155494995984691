/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
    Dialog,
    DialogTitle,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CourseWork from '../CourseWork/CourseWork';

const styles = theme => ({
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
        minWidth: '768px',
        [theme.breakpoints.down('md')]: {
            minWidth: '100px',
        },
        padding: '0px',
    },
    modalContainer: {
        // overflow: 'scroll',
        minWidth: '1000px',
    },
    feedbackContainer: {
        backgroundColor: '#f8fafb',
        margin: '0px',
        borderTop: '1px solid #bbb',
        borderBottom: '1px solid #bbb',

        '& img': {
            maxWidth: '100%',
        },
    },
    feedback: {
        backgroundColor: '#fff',
    },
    gridItem: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    repoAction: {
        marginTop: 7,
        '& > span': {
            marginLeft: 10,
            '&:first-child': {
                margin: 0,
            },
        },
        '& .fab, & .far': {
            fontSize: '1.2rem',
            verticalAlign: -3,
            marginRight: 3,
        },
        '& a': {
            color: 'inherit',
            '&:hover': {
                color: '#337377',
            },
        },

    },
});

class CourseWorkModal extends Component {
    componentDidMount() {
        // set-up global page listener for Escape Key events
        document.addEventListener('keydown', this.handleEscKeyDown, false);
    }

    componentWillUnmount() {
        // remove global page listener for Escape Key events
        document.removeEventListener('keydown', this.handleEscKeyDown, false);
    }

    handleEscKeyDown = (event) => {
        if (event.keyCode === 27) {
            // escape key pressed, close the modal cleanly
            this.handleClose();
        }
    };

    handleClose = () => {
        const { dispatch, closeActionType, handleRefresh } = this.props;

        dispatch({
            type: 'UNSET_ASSIGNMENT_COURSE_WORK',
        });

        dispatch({
            type: closeActionType,
            payload: {
                modalOpen: false,
            },
        });

        handleRefresh();
    };

    render() {
        const {
            classes,
            open,
            assignment,
        } = this.props;

        return (
            <>
                {/* TODO: Move this dialog into a function component */}
                {/* <Dialog classes={{ paper: classes.dialogPaper }} fullScreen={fullScreen} open={open} className="modalContainer"> */}
                <Dialog classes={{ paper: classes.dialogPaper }} open={open} className="modalContainer">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Helmet>
                            <title>Grading Assignment</title>
                        </Helmet>
                        <DialogTitle id="responsive-dialog-title" style={{ flex: 1 }}>
                            <Link
                                to={`/assignments/${assignment.id}`}
                                style={{ color: 'inherit', textDecoration: 'none', fontSize: 'inherit' }}
                                onClick={this.handleClose}
                            >
                                {assignment.name}
                            </Link>
                        </DialogTitle>
                        <Button
                            style={{ height: '44px', margin: '20px' }}
                            onClick={this.handleClose}
                        >
                            Close
                        </Button>
                    </div>

                    <div className={classes.feedbackContainer}>
                        <CourseWork
                            handleClose={this.handleClose}
                        />
                    </div>
                </Dialog>
            </>
        );
    }
}

CourseWorkModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    open: PropTypes.bool.isRequired,
    handleRefresh: PropTypes.func.isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    closeActionType: PropTypes.string,
};

CourseWorkModal.defaultProps = {
    closeActionType: 'SET_ASSIGNMENT_MODAL',
};

const mapStoreToProps = store => ({
    assignment: store.assignments.selectedAssignment,
    open: store.assignments.modalOpen,
});

export default connect(mapStoreToProps)(withStyles(styles)(CourseWorkModal));
