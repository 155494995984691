import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Divider } from '@mui/material';
import ApplicantNote from './UserNote';
import AddNote from './AddNote';
import InterviewNote from './InterviewNote';

const NoteContainer = ({
    buttonText,
    textfieldText,
    onChange,
    addNote,
    editAction,
    submitAction,
    editingNote,
    notesToDisplay,
    deleteNote,
    displayInput,
}) => (
    <>
        <Paper>
            <Typography variant="h5"> Notes </Typography>
            <Divider />
            {
                displayInput && (
                    <>
                        <AddNote
                            buttonText={buttonText}
                            textfieldText={textfieldText}
                            addNote={addNote}
                            onChange={onChange}
                            editAction={editAction}
                            submitAction={submitAction}
                            editingNote={editingNote}
                        />
                        <Divider />
                    </>
                )
            }
            {notesToDisplay && Array.isArray(notesToDisplay)
            && (
                notesToDisplay.map(note => (
                    <ApplicantNote
                        key={note.id}
                        applicantNote={note}
                        deleteNote={deleteNote}
                    />
                ))
            )
            }
            <InterviewNote />
        </Paper>
    </>
);

NoteContainer.defaultProps = {
    displayInput: true,
};

NoteContainer.propTypes = {
    buttonText: PropTypes.string.isRequired,
    textfieldText: PropTypes.string.isRequired,
    addNote: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    editAction: PropTypes.string.isRequired,
    submitAction: PropTypes.string.isRequired,
    editingNote: PropTypes.instanceOf(Object).isRequired,
    notesToDisplay: PropTypes.instanceOf(Object).isRequired,
    deleteNote: PropTypes.func.isRequired,
    displayInput: PropTypes.bool,
};

export default NoteContainer;
