/* eslint-disable no-console */
import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

const delay = ms => new Promise(res => setTimeout(res, ms));

// worker Saga: will be fired on "FETCH_EMPLOYEE_LIST" actions
function* fetchEmployeeList() {
    try {
        const response = yield axios.get('api/employees/list');
        yield put({ type: 'SET_EMPLOYEE_LIST', payload: response.data });
    } catch (error) {
        console.log('Campus get request failed', error);
    }
}

function* createEmployee(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.post('/api/employees/register', action.payload);
        yield put({ type: 'FETCH_EMPLOYEE_LIST' });
        // Move to page for student created
        yield delay(250);
        yield action.history.replace('/employees');
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Create employee - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* updateEmployee(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        // TODO put route for changing employee details
        yield axios.put('/api/employees', action.payload);
        yield put({ type: 'FETCH_EMPLOYEE_LIST' });
        // Move to page for student created
        yield delay(250);
        yield action.history.replace('/employees');
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Update employee - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* fetchEmployeeDetails(action) {
    try {
        const response = yield axios.get(`api/employees/details/${action.payload}`);
        yield put({ type: 'SET_EDITING_EMPLOYEE', payload: response.data });
        yield put({ type: 'SET_EMPLOYEE_STATUS', payload: response.data.status });
        yield put({ type: 'SET_EMPLOYEE_ROLE', payload: response.data.role });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchEmployeeAcademicEvents(action) {
    try {
        const response = yield axios.get('api/employees/academic/events/list', { params: { resolved: action.payload.resolved } });
        yield put({ type: 'SET_EMPOLOYEE_DASHBOARD_ACADEMIC_EVENT_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* employeeSaga() {
    yield takeLatest('FETCH_EMPLOYEE_LIST', fetchEmployeeList);
    yield takeLatest('CREATE_EMPLOYEE', createEmployee);
    yield takeLatest('PUT_EMPLOYEE', updateEmployee);
    yield takeLatest('FETCH_EMPLOYEE_ACADEMIC_EVENTS', fetchEmployeeAcademicEvents);
    yield takeLatest('FETCH_EMPLOYEE_DETAILS', fetchEmployeeDetails);
}

export default employeeSaga;
