import React from 'react';
import PropTypes from 'prop-types';
import {
    TableRow,
    TableCell,
    Hidden,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { shortDate } from '../../helpers/DateFormatHelper';

const styles = {
    tableRowCursor: {
        cursor: 'pointer',
    },
    tableRow: {
    },
};

const AcademicEventRow = ({
    user,
    academicEvent,
    navigateToFeedback,
    getPhotoUrl,
    classes,
}) => (
    <TableRow
        className={academicEvent.resolved === false ? classes.tableRowCursor : classes.tableRow}
        hover={academicEvent.resolved === false}
        onClick={navigateToFeedback(academicEvent)}
    >
        <TableCell>
            <img
                className="placeholder-photo-sm"
                src={getPhotoUrl(user.s3_bucket, academicEvent.user.id, academicEvent.user.photo_file_name, 'thumb')}
                alt={academicEvent.user.first_name}
            />
        </TableCell>
        <TableCell>{`${shortDate(academicEvent.created_at)}`}</TableCell>
        <TableCell>
            {`${academicEvent.user.first_name} ${academicEvent.user.last_name}'s ${academicEvent.title}`}
            {
                academicEvent.resolved === true
                && academicEvent.employee
                && (
                    <>
                        <br />
                        <br />
                        <p>
                            <span>
                                <strong>
                                    {`Resolved on ${shortDate(academicEvent.resolved_at)} by ${academicEvent.employee.first_name} ${academicEvent.employee.last_name}`}
                                </strong>
                            </span>
                        </p>
                        {`${academicEvent.employee_comment}`}
                        <br />
                        <br />
                    </>
                )
            }
        </TableCell>
        <Hidden mdDown>
            <TableCell align="right">
                {
                    academicEvent.resolved === false
                    && (
                        <Button variant="outlined">
                            View
                        </Button>
                    )
                }
            </TableCell>
        </Hidden>
    </TableRow>
);

AcademicEventRow.propTypes = {
    navigateToFeedback: PropTypes.func.isRequired,
    getPhotoUrl: PropTypes.func.isRequired,
    academicEvent: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(AcademicEventRow);
