import React from 'react';
import {
    Card,
    CardContent,
    Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

const styles = {

    statCardTitle: {
        fontSize: '16px',
        fontWeight: '600',
    },
};
const CohortGenderStats = ({ cohort, classes }) => {
    const chartData = {

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: [],

    };
    const chartOptions = {
        title: {
            display: false,
            text: 'Gender',
            align: 'start',
        },
        tooltips: {
            callbacks: {
                label(tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = cohort.students ? cohort.students.length : 0;
                    const currentLabel = chartData.labels[tooltipItem.index];
                    const currentValue = dataset.data[tooltipItem.index];
                    const percentage = Math.floor(((currentValue / total) * 100) + 0.5);

                    return `${currentLabel}: ${currentValue} (${percentage}%)`;
                },
            },
        },
    };

    const studentList = cohort.students;
    if (studentList) {
        const maleCount = studentList.filter(student => student.gender === 'Male').length;
        const femaleCount = studentList.filter(student => student.gender === 'Female').length;
        const nonBinaryCount = studentList.filter(student => student.gender === 'Non-Binary').length;
        const genderFluidCount = studentList.filter(student => student.gender === 'Gender-Fluid').length;
        const selfDescribeCount = studentList.filter(student => student.gender === 'Self-Describe').length;
        const otherCount = studentList.filter(student => student.gender === 'Other').length;
        const noAnswerCount = studentList.filter(student => !student.gender).length;
        const data = {
            data: [],
            backgroundColor: [
                '#1F6B77',
                '#009AA1',
                '#39E4EC',
                '#CFEBE9',
                '#A6E7D2',
                '#204A4B',
            ],
        };
        if (maleCount) {
            data.data.push(maleCount);
            chartData.labels.push('Male');
        }
        if (femaleCount) {
            data.data.push(femaleCount);
            chartData.labels.push('Female');
        }
        if (nonBinaryCount) {
            data.data.push(nonBinaryCount);
            chartData.labels.push('Non-Binary');
        }
        if (genderFluidCount) {
            data.data.push(genderFluidCount);
            chartData.labels.push('Gender-Fluid');
        }
        if (selfDescribeCount) {
            data.data.push(selfDescribeCount);
            chartData.labels.push('Self-Describe');
        }
        if (otherCount) {
            data.data.push(otherCount);
            chartData.labels.push('Other');
        }
        if (noAnswerCount) {
            data.data.push(noAnswerCount);
            chartData.labels.push('Did not respond');
        }
        chartData.datasets = [data];
    }
    return (
        <Grid item style={{ display: 'flex' }} xs={12} sm={6} lg={4}>
            <Card elevation={1} style={{ width: '100%' }}>
                <CardContent>
                    <span className={classes.statCardTitle}>Gender</span>
                    <br />
                    <br />
                    <Pie data={chartData} options={chartOptions} />
                </CardContent>
            </Card>
        </Grid>
    );
};

CohortGenderStats.propTypes = {
    cohort: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

// this allows us to use <App /> in index.js
export default withStyles(styles)(CohortGenderStats);
