import { combineReducers } from 'redux';

const cohortList = (state = [], action) => {
    switch (action.type) {
    case 'SET_COHORT_LIST':
        return action.payload;
    case 'UNSET_COHORT_LIST':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const feedback = (state = [], action) => {
    switch (action.type) {
    case 'SET_COHORT_FEEDBACK':
        return action.payload;
    case 'UNSET_COHORT_FEEDBACK':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const defaultCohort = {
    name: '',
    campus_id: '1',
    prework_start: '',
    classroom_start: '',
    tier3_start: '',
    graduation: '',
    applications_open: '',
    applications_close: '',
    feedback_nag: false,
    target_size: '20',
    course_id: '1', // FSE
    cohort_type: '',
    number_of_weeks: 20,
    archived: false,
    // We currently have three select fields on the create cohort
    // page. For now, ensure we have three objects when editing.
    // TODO: We may eventually want to make this dynamic.
    employees: [
        { id: '', first_name: '' },
        { id: '', first_name: '' },
        { id: '', first_name: '' },
    ],
};

const editingCohort = (state = defaultCohort, action) => {
    let copy;
    switch (action.type) {
    case 'SET_EDITING_COHORT':
        copy = Object.assign({}, action.payload);
        // If a cohort exists, make sure the value and label are available
        // for the drop down select to pre-populate.
        while (copy.employees.length < 3) {
            // We currently have three select fields on the create cohort
            // page. For now, ensure we have three objects when editing.
            // TODO: We may eventually want to make this dynamic.
            copy.employees.push({ id: '', first_name: '' });
        }
        return copy;
    case 'UNSET_EDITING_COHORT':
    case 'UNSET_USER':
        return defaultCohort;
    default:
        return state;
    }
};

const cohort = (state = {}, action) => {
    switch (action.type) {
    case 'SET_COHORT':
        return action.payload;
    case 'UNSET_COHORT':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const assignments = (state = { count: 0 }, action) => {
    switch (action.type) {
    case 'SET_COHORT_ASSIGNMENTS':
        return action.payload;
    case 'UNSET_COHORT':
    case 'UNSET_USER':
        return { count: 0 };
    default:
        return state;
    }
};

// Offset for the assignment list page
const assignmentSearchOffset = (state = 0, action) => {
    switch (action.type) {
    case 'SET_COHORT_ASSIGNMENT_OFFSET':
        return action.payload.offset;
    case 'UNSET_COHORT_ASSIGNMENT_OFFSET':
        return 0;
    default:
        return state;
    }
};

// Search text for the assignment list page
const assignmentSearchText = (state = '', action) => {
    switch (action.type) {
    case 'SET_COHORT_ASSIGNMENT_TEXT':
        return action.payload.text;
    case 'UNSET_COHORT_ASSIGNMENT_TEXT':
        return '';
    default:
        return state;
    }
};
const defaultAssignmentStatus = { value: '', label: 'All' };
// Search text for the assignment list page
const assignmentSelectedStatus = (state = defaultAssignmentStatus, action) => {
    switch (action.type) {
    case 'SET_COHORT_ASSIGNMENT_STATUS_TEXT':
        return action.payload;
    case 'UNSET_COHORT_ASSIGNMENT_STATUS_TEXT':
        return defaultAssignmentStatus;
    default:
        return state;
    }
};

// orderBy db column name, order is asc or desc
let defaultSortOrder = { orderBy: 'name', order: 'desc' };

try {
    if (localStorage.getItem('cohortAssignmentSortOrder')) {
        defaultSortOrder = JSON.parse(localStorage.getItem('cohortAssignmentSortOrder'));
    }
} catch {
    defaultSortOrder = { orderBy: 'name', order: 'desc' };
}

// Sort order for the assignment list page
const cohortAssignmentSortOrder = (state = defaultSortOrder, action) => {
    switch (action.type) {
    case 'SET_COHORT_ASSIGNMENT_SORT_ORDER':
        localStorage.setItem('cohortAssignmentSortOrder', JSON.stringify(action.payload));
        return action.payload;
    case 'UNSET_COHORT_ASSIGNMENT_SORT_ORDER':
        return defaultSortOrder;
    default:
        return state;
    }
};

// List of all course work for a cohort
const courseWorkList = (state = [], action) => {
    switch (action.type) {
    case 'SET_COHORT_COURSE_WORK_LIST':
        return action.payload;
    case 'UNSET_COHORT_COURSE_WORK_LIST':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const cohortStudentList = (state = [], action) => {
    switch (action.type) {
    case 'SET_COHORT_STUDENT_LIST':
        return action.payload;
    case 'UNSET_COHORT_STUDENT_LIST':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const feedbackDataset = (state = {}, action) => {
    switch (action.type) {
    case 'SET_COHORT_FEEDBACK_SUMMARY': {
        const labels = action.payload.map(summary => (
            summary.feedback_on
        ));
        const understandingData = action.payload.map((summary) => {
            const dateOfFeedback = new Date(summary.feedback_on).setHours(0);
            return {
                y: summary.understanding_score,
                t: dateOfFeedback,
            };
        });
        const supportData = action.payload.map((summary) => {
            const dateOfFeedback = new Date(summary.feedback_on).setHours(0);
            return {
                y: summary.support_score,
                t: dateOfFeedback,
            };
        });
        const dataset = {
            labels,
            datasets: [
                {
                    type: 'line',
                    data: understandingData,
                    label: 'Understanding',
                    borderColor: '#178DAA',
                    backgroundColor: '#178DAA',
                    fill: false,
                    lineTension: 0.05,
                    borderJoinStyle: 'miter',
                },
                {
                    type: 'line',
                    data: supportData,
                    label: 'Support',
                    borderColor: '#08AA9E',
                    backgroundColor: '#08AA9E',
                    fill: false,
                    lineTension: 0.05,
                    borderJoinStyle: 'miter',
                },
            ],
        };
        return dataset;
    }
    case 'UNSET_COHORT':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

export default combineReducers({
    // TODO: cohort and cohorts should probably be combined into one reducer
    cohort,
    assignments,
    courseWorkList,
    cohortList,
    feedback,
    assignmentSearchOffset,
    assignmentSearchText,
    assignmentSelectedStatus,
    editingCohort,
    cohortStudentList,
    feedbackDataset,
    cohortAssignmentSortOrder,
});
