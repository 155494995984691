/* eslint-disable max-len */
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    DialogActions,
    Button,
    Grid,
    Select,
    MenuItem,
    OutlinedInput,
    Tooltip,
    Link,
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material';
import { Link as RouterLink, Prompt } from 'react-router-dom';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import { extendedDateTime } from '../../helpers/DateFormatHelper';
import { getCourseWorkUrl } from '../../helpers/PhotoHelper';

import CodeBlock from '../General/CodeBlock';
import FaIcon from '../../helpers/FaIcon';

import {
    GRADER,
} from '../General/UserTypes';

const styles = theme => ({
    actionButton: {
        float: 'right',
    },
    dialogPaper: {
        minHeight: '100%',
        maxHeight: '100%',
        minWidth: '768px',
        [theme.breakpoints.down('md')]: {
            minWidth: '100px',
        },
        padding: '0px',
    },
    modalContainer: {
        // overflow: 'scroll',
        minWidth: '1000px',
    },
    feedbackContainer: {
        backgroundColor: '#f8fafb',
        margin: '0px',
        padding: '20px',
        borderTop: '1px solid #bbb',
        borderBottom: '1px solid #bbb',

        '& img': {
            maxWidth: '100%',
        },
    },
    feedback: {
        backgroundColor: '#fff',
        maxWidth: '80ch',
    },
    gridItem: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    repoAction: {
        marginTop: 7,
        '& > span': {
            marginLeft: 10,
            '&:first-child': {
                margin: 0,
            },
        },
        '& .fab, & .far': {
            fontSize: '1.2rem',
            verticalAlign: -3,
            marginRight: 3,
        },
        '& a': {
            color: 'inherit',
            '&:hover': {
                color: '#337377',
            },
        },

    },
});

const CopyConfirmationTooltip = withStyles({
    tooltip: {
        fontSize: 14,
        backgroundColor: 'rgba(97, 97, 97, 0.95)',
    },
})(Tooltip);

class CourseWork extends Component {
    constructor(props) {
        super(props);
        // const { selectedCourseWork } = props;

        this.state = {
            changes: false,
            showCopyRepoUrlConfirmation: false,
            // confirmationModalOpen: false,
        };

        // Date time pickers are hard difficult to convert
        // into Date objects. Using refs allows use to
        // construct Date objects from both fields together at once.
        this.resubmissionDueDateRef = createRef();
        this.resubmissionDueTimeRef = createRef();

        // Switch to 'write' to trigger a render cycle and avoid stale data.
        this.setState({
            selectedTab: 'write',
        });
        this.fetchCourseWorkDetail();
    }

    componentDidMount() {
        const { dispatch, history } = this.props;
        // set-up global page listener for Escape Key events
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        if (history) {
            this.unlisten = history.listen(() => {
                this.onRouteChange();
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            selectedCourseWork,
        } = this.props;

        // Current courseWork is empty {} which means we're recieving
        // this prop for the first time. If the incomming props has course
        // work that includes feedback, then we should default to 'preview'.
        if (selectedCourseWork
            && nextProps.selectedCourseWork
            && Object.keys(nextProps.selectedCourseWork).length > 0) {
            if (selectedCourseWork.id !== nextProps.selectedCourseWork.id
                && nextProps.selectedCourseWork.instructor_comment
                && nextProps.selectedCourseWork.instructor_comment.length > 0) {
                this.setState({
                    selectedTab: 'preview',
                });
            }
            if (selectedCourseWork.id !== nextProps.selectedCourseWork.id
                && nextProps.selectedCourseWork.workflow_state) {
                const nextDraft = nextProps.selectedCourseWork.workflow_state !== 'graded'
                    && nextProps.selectedCourseWork.drafted_by;
                this.setState({
                    isDraft: nextDraft,
                });
            }
        }
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    handleGradeChange = (event) => {
        const { dispatch, selectedCourseWork } = this.props;
        let updatedCoursework = {
            ...selectedCourseWork,
            grade: event.target.value,
        };

        // Unset resubmission requirements, if grade is not a Needs
        if (event.target.value !== 1 && event.target.value !== 0) {
            updatedCoursework = {
                ...updatedCoursework,
                resubmission_due_at: null,
                resubmission_accepted_at: null,
            };
        }

        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_ASSIGNMENT_COURSE_WORK', payload: updatedCoursework });
    };

    handleResubmissionRequired = (evt) => {
        const { dispatch, selectedCourseWork } = this.props;

        // Prevent unsaved changes
        this.setState({
            changes: true,
        });

        const resubmissionRequired = evt.target.checked;

        // If resubmission required,
        // set due date to next friday.
        // Otherwise, unset due date
        const resubmissionDueAt = resubmissionRequired
            ? moment().day(5).hour(9)
                .startOf('hour')
                .toISOString()
            : null;

        // Unset completion date, if resubmission not required
        const resubmissionCompletedAt = resubmissionRequired
            ? selectedCourseWork.resubmission_completed_at
            : null;

        dispatch({
            type: 'SET_ASSIGNMENT_COURSE_WORK',
            payload: {
                ...selectedCourseWork,
                resubmission_due_at: resubmissionDueAt,
                resubmission_completed_at: resubmissionCompletedAt,
            },
        });
    };

    handleResubmissionAccepted = (evt) => {
        const { dispatch, selectedCourseWork } = this.props;

        // Prevent unsaved changes
        this.setState({
            changes: true,
        });

        const isAccepted = evt.target.checked;

        dispatch({
            type: 'SET_ASSIGNMENT_COURSE_WORK',
            payload: {
                ...selectedCourseWork,
                // Timestamp the acceptance
                resubmission_accepted_at: isAccepted ? new Date().toISOString() : null,
            },
        });
    };

    handleResubmissionDueDateChange = (evt) => {
        const { dispatch, selectedCourseWork } = this.props;

        // Prevent unsaved changes
        this.setState({
            changes: true,
        });

        const dateString = this.resubmissionDueDateRef.current.value;
        const timeString = this.resubmissionDueTimeRef.current.value;

        const dueAt = moment(`${dateString} ${timeString}`);

        dispatch({
            type: 'SET_ASSIGNMENT_COURSE_WORK',
            payload: {
                ...selectedCourseWork,
                resubmission_due_at: dueAt.toISOString(),
                // If the due date changes, clear the completed at date
                resubmission_completed_at: null,
            },
        });
    };

    handleMdeChange = (value) => {
        const { dispatch, selectedCourseWork } = this.props;
        const updatedCoursework = {
            ...selectedCourseWork,
            instructor_comment: value,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_ASSIGNMENT_COURSE_WORK', payload: updatedCoursework });
    };

    handleDraftChange = () => {
        const { dispatch, selectedCourseWork } = this.props;
        this.setState({
            isDraft: true,
        });
        dispatch({
            type: 'DRAFT_COURSE_WORK',
            payload: {
                courseWork: selectedCourseWork,
                onComplete: this.handleSaveDraftComplete,
            },
        });
    };

    setSelectedTab = (value) => {
        this.setState({
            selectedTab: value,
        });
    };

    handleSave = () => {
        const { dispatch, selectedCourseWork } = this.props;
        dispatch({
            type: 'GRADE_COURSE_WORK',
            payload: {
                courseWork: selectedCourseWork,
                onComplete: this.handleSaveComplete,
            },
        });
    };

    handleSaveDraftComplete = () => {
        const { handleClose } = this.props;
        this.setState({
            changes: false,
            selectedTab: 'preview',
        });
        handleClose();
    };

    handleSaveComplete = () => {
        const { handleClose } = this.props;
        this.setState({
            changes: false,
            selectedTab: 'preview',
            isDraft: false,
        });
        handleClose();
    };

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = () => {
        const {
            match,
            courseWorkId,
            selectedCourseWork,
        } = this.props;
        const itemId = courseWorkId || selectedCourseWork.id;
        if (Number(match.params.id) !== itemId) {
            // Switch to 'write' to trigger a render cycle and avoid stale data.
            this.setState({
                selectedTab: 'write',
            });
            this.fetchCourseWorkDetail();
        }
    };

    // handleRefresh = () => {
    //     // Switch to 'write' to trigger a render cycle and avoid stale data.
    //     this.setState({
    //         selectedTab: 'write',
    //     });
    //     this.fetchCourseWorkDetail();
    // }

    fetchCourseWorkDetail() {
        const { dispatch, courseWorkId, selectedCourseWork } = this.props;
        if (courseWorkId) {
            dispatch({ type: 'FETCH_COURSE_WORK_DETAIL', payload: { id: courseWorkId } });
        } else {
            dispatch({ type: 'FETCH_COURSE_WORK_DETAIL', payload: { id: selectedCourseWork.id } });
        }
    }

    parseRepositoryUrl(url) {
        // Get rid of any query params --
        // The regEx below -- repoName would include all the query params
        // if a student includes them which breaks ssh.
        // Prob a way to remove via regEx, this was faster
        let [cleanedUrl] = url.split('?');
        if (cleanedUrl.endsWith('.git')) {
            cleanedUrl = cleanedUrl.substring(0, cleanedUrl.indexOf('.git'));
        }

        // Find the owner and repo name in the URL
        const matches = cleanedUrl.match(/.*github\.com[/:]([^/]+)\/([^/]+)/);
        if (!matches || matches.length < 2) {
            return null;
        }
        const [, owner, repoName] = matches;

        return {
            http: `https://github.com/${owner}/${repoName}`,
            gitSsh: `git@github.com:${owner}/${repoName}.git`,
        };
    }

    render() {
        const {
            user,
            classes,
            selectedStudent,
            selectedCourseWork,
            selectedAssignment,
        } = this.props;
        const {
            // confirmationModalOpen,
            changes,
            selectedTab,
            showCopyRepoUrlConfirmation,
            isDraft,
        } = this.state;

        const student = selectedStudent;
        const assignment = selectedAssignment;
        const courseWork = selectedCourseWork;

        let studentComment = 'No student comment.';
        if (courseWork && courseWork.student_comment) {
            studentComment = courseWork.student_comment;
        }
        let feedback = '';
        if (courseWork && courseWork.instructor_comment) {
            feedback = courseWork.instructor_comment;
        }
        let existingGrade = '';
        if (!Number.isNaN(courseWork.grade)) {
            existingGrade = courseWork.grade;
        }
        const completedAt = extendedDateTime(courseWork.completed_at);
        const dueAt = extendedDateTime(assignment.due_at);
        const repoUrl = courseWork.repository
            && this.parseRepositoryUrl(courseWork.repository);

        let gradeStatus = 'Completed';

        if (courseWork.workflow_state === 'draft') {
            gradeStatus = (
                <div style={{
                    fontSize: '1.5em',
                    color: 'darkred',
                }}
                >
                    Draft
                </div>
            );
        } else if (courseWork.graded_by_id && feedback !== '') {
            gradeStatus = 'Graded';
        }

        return (
            <>
                <Prompt
                // Use a standard alert dialog if the user leaves the page when
                // changes are detected.
                    when={changes}
                    message="You have unsaved edits. Are you sure you want to leave?"
                />
                <Paper>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4} className={classes.gridItem}>
                            <Grid container spacing={1} direction="column">
                                <Grid item xs={12}>
                                    <strong>Student:</strong>
                                    <br />
                                    <RouterLink to={`/students/${student.id}`} target="_blank" rel="noopener noreferrer">
                                        {`${student.first_name} ${student.last_name}`}
                                    </RouterLink>
                                </Grid>
                                {courseWork.repository
                                    && (
                                        <Grid item xs={12} className={classes.gridItem}>
                                            <strong>Repository:</strong>
                                            <br />
                                            <a
                                                href={courseWork.repository}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {courseWork.repository}
                                            </a>

                                            {/* Repo actions (open link, copy url) */}
                                            {repoUrl && (
                                                <div className={classes.repoAction}>
                                                    <span>
                                                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                        <a href={repoUrl.http} target="_blank" rel="noreferrer">
                                                            <FaIcon
                                                                icon="github"
                                                                iconType="fab"
                                                            />
                                                            Open on Github
                                                        </a>
                                                    </span>
                                                    <span className={classes.repoAction}>
                                                        <CopyConfirmationTooltip
                                                            open={showCopyRepoUrlConfirmation}
                                                            arrow
                                                            placement="top"
                                                            onClose={() => this.setState({
                                                                showCopyRepoUrlConfirmation: false,
                                                            })}
                                                            title="Copied!"
                                                        >
                                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                            <a
                                                                href="#"
                                                                onClick={(e) => {
                                                                    navigator.clipboard.writeText(repoUrl.gitSsh);
                                                                    this.setState({
                                                                        showCopyRepoUrlConfirmation: true,
                                                                    });
                                                                    return e.preventDefault();
                                                                }}
                                                            >
                                                                <FaIcon
                                                                    icon="copy"
                                                                    iconType="far"
                                                                    style={{ color: '#555555' }}
                                                                />
                                                                Copy Git URL
                                                            </a>
                                                        </CopyConfirmationTooltip>
                                                    </span>
                                                </div>
                                            )}
                                        </Grid>
                                    )
                                }
                                {
                                    courseWork.site
                                        && (
                                            <>
                                                <Grid item xs={12} className={classes.gridItem}>
                                                    <strong>Website:</strong>
                                                    <br />
                                                    <a
                                                        href={courseWork.site}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {courseWork.site}
                                                    </a>
                                                </Grid>
                                            </>
                                        )
                                }
                                {
                                    courseWork.file_name
                                        && (
                                            <>
                                                <Grid item xs={12} className={classes.gridItem}>
                                                    <strong>File:</strong>
                                                    <br />
                                                    <Link href={getCourseWorkUrl(
                                                        user.s3_bucket,
                                                        courseWork.id,
                                                        courseWork.file_name,
                                                    )}
                                                    >
                                                        {courseWork.file_name}
                                                    </Link>
                                                </Grid>
                                            </>
                                        )
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Grid container spacing={1} direction="column">
                                <Grid item xs={12}>
                                    <strong>Due at:</strong>
                                    <br />
                                    {dueAt}
                                </Grid>
                                <Grid item xs={12}>
                                    <strong>Completed at:</strong>
                                    <br />
                                    {completedAt}
                                </Grid>
                                <Grid item xs={12}>
                                    <strong>Status:</strong>
                                    <br />
                                    {gradeStatus}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Grid container spacing={1} direction="column">

                                {
                                    courseWork.drafted_by && (
                                        <Grid item xs={12}>
                                            <strong>Drafted by:</strong>
                                            <br />
                                            {courseWork.drafted_by}
                                            <br />
                                            <i>{extendedDateTime(courseWork.drafted_at)}</i>
                                        </Grid>
                                    )
                                }
                                {
                                    courseWork.graded_by_id
                                        && courseWork.employee
                                        && (
                                            <Grid item xs={12}>
                                                <strong>Graded by:</strong>
                                                <br />
                                                {` ${courseWork.employee.first_name} ${courseWork.employee.last_name}`}
                                                <br />
                                                <i>{extendedDateTime(courseWork.graded_at)}</i>
                                            </Grid>
                                        )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <h6>Student Comment:</h6>
                    <div>
                        {studentComment}
                    </div>
                    <br />
                    {
                        assignment.assignment_type === 'graded'
                            && (
                                <>
                                    <h6>Grade:</h6>
                                    <div>
                                        <Select
                                            value={existingGrade}
                                            onChange={this.handleGradeChange}
                                            input={<OutlinedInput labelWidth={300} name="grade" id="outlined-age-simple" />}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={0}>0 - Incomplete</MenuItem>
                                            <MenuItem value={1}>1 - Reinforcement Needed</MenuItem>
                                            <MenuItem value={3}>3 - Meets Expectations</MenuItem>
                                            <MenuItem value={5}>5 - Exceeds Expectations</MenuItem>
                                        </Select>
                                    </div>

                                    {/* Show Resubmission fields, if (0, 1) grades */}
                                    {(existingGrade === 1 || existingGrade === 0)
                                    && (
                                        <>
                                            <Grid container spacing={4}>

                                                {/* Resubmission Req. Toggle */}
                                                <Grid item>
                                                    <FormControlLabel
                                                        style={{
                                                            marginLeft: 3,
                                                            verticalAlign: -20,
                                                        }}
                                                        label="Requires Resubmission"
                                                        labelPlacement="start"
                                                        control={(
                                                            <Switch
                                                                checked={courseWork.resubmission_due_at !== null}
                                                                onChange={this.handleResubmissionRequired}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                                {/* Resubmission Due Date */}
                                                {courseWork.resubmission_due_at && (
                                                    <Grid item>
                                                        {/* Date */}
                                                        <TextField
                                                            inputRef={this.resubmissionDueDateRef}
                                                            type="date"
                                                            label="Resubmission Due Date:"
                                                            value={courseWork.resubmission_due_at
                                                                && moment(courseWork.resubmission_due_at).format('YYYY-MM-DD')
                                                            }
                                                            onChange={this.handleResubmissionDueDateChange}
                                                            style={{
                                                                width: 200,
                                                                marginLeft: 3,
                                                                display: 'inline-block',
                                                                marginTop: 5,
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                        {/* Time */}
                                                        <TextField
                                                            inputRef={this.resubmissionDueTimeRef}
                                                            type="time"
                                                            label="Time:"
                                                            value={courseWork.resubmission_due_at
                                                            && moment(courseWork.resubmission_due_at).format('kk:mm')
                                                            }
                                                            onChange={this.handleResubmissionDueDateChange}
                                                            style={{
                                                                width: 200,
                                                                marginLeft: 3,
                                                                display: 'inline-block',
                                                                marginTop: 5,
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>

                                            {/* Resubmission accepted toggle */}
                                            {courseWork.resubmission_due_at
                                            && (
                                                <div>
                                                    <FormControlLabel
                                                        style={{ marginLeft: 3 }}
                                                        label="Resubmission Accepted"
                                                        labelPlacement="start"
                                                        control={(
                                                            <Switch
                                                                checked={!!courseWork.resubmission_accepted_at}
                                                                color="primary"
                                                                onChange={this.handleResubmissionAccepted}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            )
                                            }
                                        </>
                                    )}
                                </>
                            )
                    }

                </Paper>
                <Paper>
                    <br />
                    <h6>Instructor Feedback</h6>
                    <hr />
                    <ReactMde
                        className={classes.feedback}
                        value={feedback}
                        onChange={this.handleMdeChange}
                        selectedTab={selectedTab}
                        onTabChange={this.setSelectedTab}
                        generateMarkdownPreview={markdown => (
                            Promise.resolve(
                                <ReactMarkdown
                                    components={{ code: CodeBlock }}
                                    remarkPlugins={[remarkGfm]}
                                >
                                    {markdown}
                                </ReactMarkdown>,
                            )
                        )}
                    />
                    <DialogActions>
                        <div style={{ flex: '1 0 0' }} />
                        <Button
                            onClick={this.handleDraftChange}
                            variant="outlined"
                            type="button"
                        >
                            Save Draft
                        </Button>
                        {
                            user && user.role !== GRADER
                            && (
                                <Button
                                    onClick={this.handleSave}
                                    disabled={!changes && !isDraft}
                                    variant="contained"
                                    color="primary"
                                >
                                    Send to Student
                                </Button>
                            )
                        }
                    </DialogActions>
                </Paper>
            </>
        );
    }
}

CourseWork.defaultProps = {
    history: null,
};

CourseWork.propTypes = {
    history: PropTypes.instanceOf(Object),
    courseWorkId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        s3_bucket: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    // TODO: Add this back when we're ready to transition away from Trello
    // tags: PropTypes.instanceOf(Array).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    selectedStudent: PropTypes.instanceOf(Object).isRequired,
    selectedAssignment: PropTypes.instanceOf(Object).isRequired,
    selectedCourseWork: PropTypes.instanceOf(Object).isRequired,
    // Called after "Save Draft" or "Send to student" buttons are clicked
    handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    cohort: state.cohort.cohort,
    // TODO: Add this back when we're ready to transition away from Trello
    // tags: state.student.tags,
    selectedAssignment: state.assignments.selectedAssignment,
    selectedCourseWork: state.assignments.selectedCourseWork,
    selectedStudent: state.assignments.selectedStudent,
});

export default connect(mapStateToProps)(withStyles(styles)(CourseWork));
