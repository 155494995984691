import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
    List,
} from '@mui/material';
import { ReactComponent as Logo } from './logo-prime-horizontal.svg';
import SideNavItem from './SideNavItem';
import makeSideNavStyles from './makeSideNavStyles';
import UserInfo from './UserInfo';
import { getAssignmentOrDeliverable } from '../../helpers/CourseTypeHelper';

const useStyles = makeSideNavStyles();

const reflectionHasStarted = (cohort) => {
    const reflectionStarted = moment(cohort.prework_start);
    return moment() > reflectionStarted;
};

const StudentNav = () => {
    const cohort = useSelector(store => store.studentPortal.cohort);
    const classes = useStyles();
    const assignmentOrDeliverable = getAssignmentOrDeliverable(cohort.course_id);

    return (
        <div className={classes.sideNav}>
            <Logo className={classes.logo} />
            <List>
                {/* Reflections */}
                {
                    reflectionHasStarted(cohort)
                    && (
                        <SideNavItem
                            to="/student/reflections"
                            iconClass="far fa-comment-dots"
                            label="Reflections"
                        />
                    )
                }

                {/* Assignments */}
                <SideNavItem
                    to="/student/assignments"
                    iconClass="fa fa-desktop"
                    label={`${assignmentOrDeliverable}s`}
                />

                {/* Edit Profile */}
                <SideNavItem
                    to="/profile"
                    iconClass="fa fa-user"
                    label="Edit Profile"
                />
                <UserInfo />
            </List>
        </div>
    );
};

export default StudentNav;
