import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
    Paper,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import CodeBlock from '../../General/CodeBlock';
import PageTitle from '../../PageTitle/PageTitle';
import SubmitCourseWorkModal from '../../Modal/SubmitCourseWorkModal';
import { extendedDateTime } from '../../../helpers/DateFormatHelper';
import { getAssignmentOrDeliverable } from '../../../helpers/CourseTypeHelper';
import { studentStatusText } from '../../../helpers/CourseWorkTextHelper';

const styles = {
    textField: {
        fontSize: '12px',
    },
    feedback: {
        maxWidth: '80ch',
    },
    assignmentNotes: {
        maxWidth: '80ch',
    },
};

class StudentAssignmentDetail extends Component {
    componentDidMount() {
        const { dispatch, match, history } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_STUDENT_PORTAL_COURSE_WORK_DETAIL', payload: { id: match.params.id } });
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        this.unlisten = history.listen(() => {
            this.onRouteChange();
        });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_STUDENT_PORTAL_COURSE_WORK' });
        this.unlisten();
    }

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_STUDENT_PORTAL_COURSE_WORK_DETAIL', payload: { id: match.params.id } });
    };

    getGradeForCourseWork = (courseWork) => {
        let result = 'Not Graded';
        if (courseWork.workflow_state === 'graded') {
            switch (courseWork.grade) {
            case 0:
                result = 'Incomplete';
                break;
            case 1:
                result = 'Reinforcement Needed';
                break;
            case 3:
                result = 'Meets Expectations';
                break;
            case 5:
                result = 'Exceeds Expectations';
                break;
            default:
                result = 'Graded';
            }
        }
        return result;
    };

    onClose = () => {
        const { dispatch, match } = this.props;
        dispatch({
            type: 'SET_STUDENT_COURSE_WORK_MODAL',
            payload: {
                modalOpen: false,
            },
        });
        dispatch({ type: 'FETCH_STUDENT_PORTAL_COURSE_WORK_DETAIL', payload: { id: match.params.id } });
    };

    buildFileName = (file, firstName, end) => (
        firstName + file.replace(/\s/g, '-')
            .replace(/\./g, '_')
            .replace(/[^\w\s\-_]/g, '') + end
    );

    onSubmit = () => {
        const { dispatch, selectedCourseWork, user } = this.props;
        const firstName = user.first_name;
        const copy = { ...selectedCourseWork };
        if (selectedCourseWork.selectedFile) {
            const newName = this.buildFileName(
                selectedCourseWork.selectedFile.name.slice(0, -4),
                firstName,
                selectedCourseWork.selectedFile.name.slice(-4),
            );
            copy.standardName = newName;
        }

        dispatch({
            type: 'PUT_STUDENT_PORTAL_COURSE_WORK',
            payload: copy,
        });
    };

    render() {
        const {
            assignmentModalOpen,
            selectedCourseWork,
            assignmentErrorText,
            courseId,
            classes,
        } = this.props;
        const assignmentOrDeliverable = getAssignmentOrDeliverable(courseId);

        const actionButtons = [];
        let buttonLabel = 'Submit Your Work';
        if (selectedCourseWork) {
            if (selectedCourseWork.completed_at) {
                buttonLabel = 'Update Your Submission';
            }
            const isGraded = selectedCourseWork.workflow_state === 'graded';
            const requiredResubmission = selectedCourseWork.resubmission_due_at
                && !selectedCourseWork.resubmission_accepted_at;
            // If the assignment is not graded or requires resubmission, display the button
            if (!isGraded || requiredResubmission || Number(selectedCourseWork.grade) === 0) {
                actionButtons.push(
                    {
                        label: buttonLabel,
                        type: 'SET_STUDENT_COURSE_WORK_MODAL',
                        payload: { modalOpen: true },
                        color: 'primary',
                        variant: 'contained',
                    },
                );
            }
        }

        return (
            <>
                {
                    selectedCourseWork
                    && selectedCourseWork.assignment
                    && (
                        <div className="container">
                            <PageTitle
                                title={`${assignmentOrDeliverable} :: ${selectedCourseWork.assignment.name}`}
                                // Action buttons are created by the PageTitle component
                                actions={actionButtons}
                            />
                            <Paper>
                                <p>
                                    {
                                        studentStatusText(
                                            selectedCourseWork,
                                            selectedCourseWork.assignment,
                                        )
                                    }
                                </p>
                                {
                                    !Number.isNaN(selectedCourseWork.grade)
                                    && selectedCourseWork.grade !== null
                                    && (
                                        <>
                                            <br />
                                            <p><strong>Assessment:</strong></p>
                                            <p>
                                                {this.getGradeForCourseWork(selectedCourseWork)}
                                            </p>
                                        </>
                                    )
                                }
                                <br />
                                <p><strong>Date Assigned:</strong></p>
                                <p>{`${extendedDateTime(selectedCourseWork.assignment.assigned_at)}`}</p>
                                <br />

                                {
                                    selectedCourseWork.assignment.due_at
                                    && (
                                        <>
                                            <p>
                                                <strong>
                                                    {selectedCourseWork.resubmission_due_at && 'Resubmission '}
                                                    Due Date:
                                                </strong>
                                            </p>
                                            <p>
                                                {extendedDateTime(
                                                    selectedCourseWork.resubmission_due_at
                                                    || selectedCourseWork.assignment.due_at,
                                                )}
                                            </p>
                                            <br />
                                        </>
                                    )
                                }
                                {
                                    selectedCourseWork.assignment.repository
                                    && (
                                        <>
                                            <p><strong>Project Resources:</strong></p>
                                            <p>
                                                <a href={selectedCourseWork.assignment.repository}>
                                                    {selectedCourseWork.assignment.repository}
                                                </a>
                                            </p>
                                            <br />
                                        </>
                                    )
                                }
                            </Paper>
                            {
                                selectedCourseWork.instructor_comment
                                && (
                                    <Paper>
                                        <h6>Instructor Feedback</h6>
                                        <br />
                                        {
                                            selectedCourseWork.employee
                                            && selectedCourseWork.employee.first_name
                                            && (
                                                <p>{`Feedback provided by: ${selectedCourseWork.employee.first_name} ${selectedCourseWork.employee.last_name}`}</p>
                                            )
                                        }
                                        <hr />
                                        <ReactMarkdown
                                            className={classes.feedback}
                                            align="left"
                                            components={{ code: CodeBlock }}
                                            remarkPlugins={[remarkGfm]}
                                        >
                                            {selectedCourseWork.instructor_comment}
                                        </ReactMarkdown>
                                    </Paper>
                                )
                            }
                            {
                                selectedCourseWork.assignment
                                && selectedCourseWork.assignment.notes
                                && (
                                    <Paper>
                                        <h6>
                                            {assignmentOrDeliverable}
                                            {' '}
                                            Details
                                        </h6>
                                        <br />
                                        <ReactMarkdown
                                            className={classes.assignmentNotes}
                                            align="left"
                                            components={{ code: CodeBlock }}
                                            remarkPlugins={[remarkGfm]}
                                        >
                                            {selectedCourseWork.assignment.notes}
                                        </ReactMarkdown>
                                    </Paper>
                                )
                            }
                            {
                                selectedCourseWork.student_comment
                                && selectedCourseWork.student_comment !== ''
                                && (
                                    <Paper>
                                        <h6>Student Comment</h6>
                                        <hr />
                                        <ReactMarkdown
                                            className={classes.assignmentNotes}
                                            align="left"
                                            components={{ code: CodeBlock }}
                                            remarkPlugins={[remarkGfm]}
                                        >
                                            {selectedCourseWork.student_comment}
                                        </ReactMarkdown>
                                    </Paper>
                                )
                            }
                            {
                                selectedCourseWork
                                && (
                                    <SubmitCourseWorkModal
                                        courseWork={selectedCourseWork}
                                        open={assignmentModalOpen}
                                        onClose={this.onClose}
                                        onSubmit={this.onSubmit}
                                        errorText={assignmentErrorText}
                                    />
                                )
                            }
                        </div>
                    )
                }
            </>
        );
    }
}

StudentAssignmentDetail.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    selectedCourseWork: PropTypes.instanceOf(Object).isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    assignmentModalOpen: PropTypes.bool.isRequired,
    assignmentErrorText: PropTypes.string.isRequired,
    courseId: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
    selectedCourseWork: state.studentPortal.selectedCourseWork,
    assignmentModalOpen: state.studentPortal.assignmentModalOpen,
    assignmentErrorText: state.studentPortal.assignmentErrorText,
    user: state.user,
    courseId: state.studentPortal.cohort.course_id,
});

export default withStyles(styles)(connect(mapStateToProps)(StudentAssignmentDetail));
