import React, { useState, useEffect, useCallback } from 'react';
import ReactGA from 'react-ga';
import {
    Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PageTitle from '../../PageTitle/PageTitle';
import ApplicationCard from './ApplicationCard';
import ReactPixel from '../../../helpers/FacebookPixel';

if (process.env.REACT_APP_FACEBOOK_PIXEL) {
    const initialized = (typeof window !== 'undefined') && !!window.fbq;
    if (!initialized) {
        ReactPixel.init(
            process.env.REACT_APP_FACEBOOK_PIXEL,
            {},
            { debug: false, autoConfig: true },
        );
    }
    ReactPixel.pageView();
}

if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

const styles = {
    content: {
        marginBottom: '250px',
    },
    card: {
        height: '100%',
    },
};

const ApplicantAppListPage = ({
    history,
    classes,
}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);

    const user = useSelector(store => store.user);
    const applications = useSelector(store => store.applicant.activeApplications);
    const courses = useSelector(store => store.applicant.activeCourses);

    const [displayUxa, setDisplayUxa] = useState(false);
    const [uxButtonText, setUxButtonText] = useState('Begin Application for User Experience Design');
    const [fullStackButtonText, setFullStackButtonText] = useState('Begin Application for Full Stack Engineering');
    const [uxAcademyButtonText, setUxAcademyButtonText] = useState('Begin Application for UX Academy');

    const [uxAction, setUxAction] = useState({
        path: '/courses/ux/apply/start',
        type: 'CREATE_APPLICATION',
        course: 'ux',
        payload: { course_id: 2 },
        history,
    });
    const [fullStackAction, setFullStackAction] = useState({
        path: '/courses/engineering/apply/start',
        type: 'CREATE_APPLICATION',
        course: 'fse',
        payload: { course_id: 1 },
        history,
    });
    const [uxAcademyAction, setUxAcademyAction] = useState({
        path: '/courses/ux-academy/apply/start',
        type: 'CREATE_APPLICATION',
        course: 'ux-academy',
        payload: { course_id: 3 },
        history,
    });

    useEffect(() => {
        const _hsq = window._hsq || [];
        _hsq.push(['identify', {
            email: user.email,
        }]);
    }, [user]);

    useEffect(() => {
        courses.map((course) => {
            if (course.code === 'ux-academy') {
                setDisplayUxa(true);
            }
            return course;
        });
    }, [courses]);

    useEffect(() => {
        const currentPath = location.pathname + location.search;
        // console.log('PAGEVIEW', currentPath);
        if (process.env.REACT_APP_GA_TRACKING_ID) {
            ReactGA.pageview(currentPath);
        }
        if (process.env.REACT_APP_HUBSPOT_TRACKING) {
            // Track a new page using setPath:
            // Update the path stored in the tracker:
            const _hsq = window._hsq || [];
            _hsq.push(['setPath', currentPath]);
            // Track the page view for the new page
            _hsq.push(['trackPageView']);
        }
        if (process.env.REACT_APP_HUBSPOT_TRACKING
            && (typeof window !== 'undefined')
            && !window.HubSpotConversations) {
            const hubspotScript = document.createElement('script');
            hubspotScript.src = `https://js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_TRACKING}.js`;
            hubspotScript.async = true;
            document.body.appendChild(hubspotScript);
        }
    }, [location]);

    useEffect(() => {
        stableDispatch({ type: 'FETCH_ACTIVE_COURSES' });
        stableDispatch({ type: 'FETCH_ACTIVE_APPLICATIONS' });
        stableDispatch({ type: 'UNSET_ALL_UNSAVED_CHANGES' });
    }, [stableDispatch]);

    useEffect(() => {
        for (let i = 0; i < applications.length; i += 1) {
            let step = applications[i].application_step;
            if (!step) {
                step = 'start';
            }
            if (applications[i].course_id === 1) {
                if (
                    applications[i].application_status === 'not_started'
                    || applications[i].application_status === 'started'
                ) {
                    setFullStackButtonText('Continue Application for Full Stack Engineering');
                    setFullStackAction({
                        path: `/courses/engineering/apply/${step}`,
                        payload: { course_id: 1 },
                        history,
                        status: 'In Progress',
                    });
                } else if (
                    applications[i].application_status === 'completed'
                    || applications[i].application_status === 'on_hold'
                    || applications[i].application_status === 'scheduled'
                    || applications[i].application_status === 'second_review'
                ) {
                    setFullStackButtonText('Make an Inquiry');
                    setFullStackAction({
                        type: 'ENGINEERING_INQUIRY',
                        status: 'In Review',
                    });
                } else if (
                    applications[i].application_status === 'placed'
                ) {
                    setFullStackButtonText('Make an Inquiry');
                    setFullStackAction({
                        type: 'ENGINEERING_INQUIRY',
                        status: 'Awaiting Contract',
                    });
                }
            } else if (applications[i].course_id === 2) {
                if (
                    applications[i].application_status === 'not_started'
                    || applications[i].application_status === 'started'
                ) {
                    setUxButtonText('Continue Application for User Experience Design');
                    setUxAction({
                        path: `/courses/ux/apply/${step}`,
                        payload: { course_id: 2 },
                        history,
                        status: 'In Progress',
                    });
                } else if (
                    applications[i].application_status === 'completed'
                    || applications[i].application_status === 'on_hold'
                    || applications[i].application_status === 'sheduled'
                    || applications[i].application_status === 'second_review'
                ) {
                    setUxButtonText('Make an Inquiry');
                    setUxAction({
                        type: 'UX_INQUIRY',
                        status: 'In Review',
                    });
                } else if (
                    applications[i].application_status === 'placed'
                ) {
                    setUxButtonText('Make an Inquiry');
                    setUxAction({
                        type: 'UX_INQUIRY',
                        status: 'Awaiting Contract',
                    });
                }
            } else if (applications[i].course_id === 3) {
                if (
                    applications[i].application_status === 'not_started'
                    || applications[i].application_status === 'started'
                ) {
                    setUxAcademyButtonText('Continue Application for UX Academy');
                    setUxAcademyAction({
                        path: `/courses/ux-academy/apply/${step}`,
                        payload: { course_id: 3 },
                        history,
                        status: 'In Progress',
                    });
                } else if (
                    applications[i].application_status === 'completed'
                    || applications[i].application_status === 'on_hold'
                    || applications[i].application_status === 'sheduled'
                    || applications[i].application_status === 'second_review'
                ) {
                    setUxAcademyButtonText('Make an Inquiry');
                    setUxAcademyAction({
                        type: 'UX_ACADEMY_INQUIRY',
                        status: 'In Review',
                    });
                } else if (
                    applications[i].application_status === 'placed'
                ) {
                    setUxAcademyButtonText('Make an Inquiry');
                    setUxAcademyAction({
                        type: 'UX_ACADEMY_INQUIRY',
                        status: 'Awaiting Contract',
                    });
                }
            }
        }
    }, [applications, dispatch, history]);

    return (
        <>
            <PageTitle
                title="Your Applications"
            />
            <Grid container direction="row" className={classes.content}>
                <Grid item xs={12} sm={6} lg={4}>
                    <ApplicationCard
                        image="./bg-ux.jpg"
                        imageTitle="User Experience Photo"
                        programDetails="Campus in Minneapolis"
                        applicationType="User Experience Design"
                        programBlurb="Prime’s immersive user experience design curriculum provides
                        students with the practice in research, design and prototype software
                        that is usable and meaningful."
                        buttonText={uxButtonText}
                        history={history}
                        action={uxAction}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <ApplicationCard
                        image="./bg-engineering.jpg"
                        imageTitle="Full Stack Engineering Photo"
                        programDetails="Campus in Minneapolis and online"
                        applicationType="Full Stack Engineering"
                        programBlurb="Prime’s immersive full stack engineering curriculum provides students with the skills to develop complex software to solve the real world businesses problems."
                        buttonText={fullStackButtonText}
                        history={history}
                        action={fullStackAction}
                    />
                </Grid>
                {
                    displayUxa && (
                        <Grid item xs={12} sm={6} lg={4}>
                            <ApplicationCard
                                className={classes.card}
                                image="./bg-uxa.jpg"
                                imageTitle="User Experience Photo"
                                programDetails="Campus in Minneapolis"
                                applicationType="UX Academy"
                                programBlurb="In partnership with MSP Tech Hire, Right Track, and BrookLynk, UX Academy is a 12-week immersive design program for young adults (18-24 years old) who meet eligibility requirements."
                                buttonText={uxAcademyButtonText}
                                history={history}
                                action={uxAcademyAction}
                            />
                        </Grid>
                    )
                }

            </Grid>

        </>
    );
};

ApplicantAppListPage.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ApplicantAppListPage);
