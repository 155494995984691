import React from 'react';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import styles from '../../../../helpers/TabStylesHelper';
import { shortDateTime } from '../../../../helpers/DateFormatHelper';

const jsUcfirst = string => string.charAt(0).toUpperCase() + string.slice(1);

const insertSpaces = s => s.replace(/([A-Z])/g, ' $1').trim();

const ChangeLogTab = ({
    value, index, changeLog, applicant,
}) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
    >
        {value === index && (
            <Paper>
                <Typography variant="h5">Activity</Typography>
                <Divider />
                <Typography variant="h6"> First Login </Typography>
                <Typography>
                    {shortDateTime(applicant.created_at)}
                </Typography>
                <Typography variant="h6">Last Login</Typography>
                <Typography>
                    {shortDateTime(applicant.current_sign_in_at)}
                </Typography>
                {changeLog
                    ? (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> Action </TableCell>
                                        <TableCell> Who </TableCell>
                                        <TableCell> When </TableCell>
                                        <TableCell> Changes </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        changeLog
                                        && changeLog.map(log => (
                                            <TableRow
                                                hover
                                                key={log.id}
                                            >
                                                <TableCell>
                                                    {jsUcfirst(log.action)}
                                                    {' '}
                                                    {insertSpaces(log.auditable_type)}
                                                </TableCell>
                                                <TableCell>
                                                    {log.author && `${log.author.first_name} ${log.author.last_name}`}
                                                </TableCell>
                                                <TableCell>
                                                    {shortDateTime(log.created_at)}
                                                </TableCell>
                                                <TableCell>
                                                    {log.audited_changes}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </>
                    )
                    : (
                        <Typography> No changes for this applicant </Typography>
                    )
                }

            </Paper>
        )}
    </div>
);

ChangeLogTab.propTypes = {
    applicant: PropTypes.instanceOf(Object).isRequired,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    changeLog: PropTypes.instanceOf(Array).isRequired,
};

export default withStyles(styles)(ChangeLogTab);
