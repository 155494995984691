import { combineReducers } from 'redux';

const searchOffset = (state = 0, action) => {
    switch (action.type) {
    case 'SET_PLACEMENT_OFFSET':
        return action.payload.offset;
    case 'UNSET_PLACEMENT_OFFSET':
        return 0;
    default:
        return state;
    }
};

let defaultSortOrder = { orderBy: 'job_title', order: 'desc' };

try {
    if (localStorage.getItem('placementSortOrder')) {
        defaultSortOrder = JSON.parse(localStorage.getItem('placementSortOrder'));
    }
} catch {
    defaultSortOrder = { orderBy: 'job_title', order: 'asc' };
}

const searchText = (state = '', action) => {
    switch (action.type) {
    case 'SET_PLACEMENT_SEARCH_TEXT':
        return action.payload.text;
    case 'UNSET_PLACEMENT_SEARCH_TEXT':
    case 'UNSET_USER':
        return '';
    default:
        return state;
    }
};

const firstPlacementsOnly = (state = true, action) => {
    switch (action.type) {
    case 'SET_PLACEMENT_FIRST_PLACEMENTS_ONLY':
        return action.payload;
    default:
        return state;
    }
};

export const DateRangeSearchBy = {
    GraduationDate: 'graduation_date',
    PlacementDate: 'placement_date',
};

const dateRange = (state = {
    startDate: null,
    endDate: null,
    searchBy: DateRangeSearchBy.GraduationDate,
}, action) => {
    switch (action.type) {
    case 'SET_PLACEMENT_DATE_RANGE':
        return { ...state, ...action.payload };
    default:
        return state;
    }
};

const sortOrder = (state = defaultSortOrder, action) => {
    switch (action.type) {
    case 'SET_PLACEMENT_SORT_ORDER':
        localStorage.setItem('placementSortOrder', JSON.stringify(action.payload));
        return action.payload;
    case 'UNSET_PLACEMENT_SORT_ORDER':
        return defaultSortOrder;
    default:
        return state;
    }
};

const companyPlacementsDefaultSortOrder = { orderBy: 'job_title', order: 'asc' };

const companyPlacementSortOrder = (state = companyPlacementsDefaultSortOrder, action) => {
    switch (action.type) {
    case 'SET_COMPANY_PLACEMENT_SORT_ORDER':
        localStorage.setItem('placementSortOrder', JSON.stringify(action.payload));
        return action.payload;
    case 'UNSET_COMPANY_PLACEMENT_SORT_ORDER':
        return companyPlacementsDefaultSortOrder;
    default:
        return state;
    }
};

const companyPlacements = (state = { last_placement: '', rows: [], count: 0 }, action) => {
    switch (action.type) {
    case 'SET_COMPANY_PLACEMENTS':
        return action.payload;
    default:
        return state;
    }
};

const placements = (state = { rows: [], count: 0 }, action) => {
    switch (action.type) {
    case 'SET_PLACEMENTS':
        return action.payload;
    default:
        return state;
    }
};

const stats = (state = {
    // Total first placements
    placementCount: null,

    // Total students
    studentCount: null,

    // Percentage of students with a placement
    totalPlacementRate: null,

    // Percentage of students who had a placement by
    // six months after graduation. Students who have graduated
    // less than six months ago are excluded from this number,
    // whether or not they have been placed.
    placementRateAtSixMonths: null,

    // Average number of days from graduation until a student is placed,
    // among all matching students who have been placed.
    averageDaysToPlacement: null,
}, action) => {
    switch (action.type) {
    case 'SET_PLACEMENT_STATS':
        return action.payload;
    default:
        return state;
    }
};

const byType = (state = [
    /*
    eg.
    {
        placement_type: 'Salary',
        count: 341,
        average_post_income: 82497.548387096774,
        median_post_income: 79369,
        average_income_change: 43290
        average_income_change_percentage: 0.731923812
    }
     */
], action) => {
    switch (action.type) {
    case 'SET_PLACEMENTS_BY_TYPE':
        return action.payload;
    default:
        return state;
    }
};

const defaultPlacement = {
    companyId: '',
    recruiterId: null,
    studentId: '',
    placement_type: '',
    job_title: '',
    how_found_notes: '',
    placement_duration: '',
    placement_rate: null,
    post_income: '',
    pre_income: '',
    placed_at: '',
    started_at: '',
    days_to_hire: null,
    sal_increase_amount: null,
    sal_increase_pct: null,
    notes: '',
    place_of_work: '',
};

const editingPlacement = (state = defaultPlacement, action) => {
    let copy;
    switch (action.type) {
    case 'SET_STUDENT_PRE_INCOME':
        return {
            ...state,
            pre_income: action.payload.income,
        };
    case 'SET_EDITING_PLACEMENT':
        copy = Object.assign({}, action.payload);
        // Dropdown selects require data in a specific format, the following
        // code ensures everything is correct.
        if (copy.company && !copy.selectedCompany) {
            copy.companyId = copy.company.id;
            copy.selectedCompany = {
                value: copy.company.id,
                label: copy.company.name,
            };
        }
        if (copy.recruiter && !copy.selectedRecruiter) {
            copy.recruiterId = copy.recruiter.id;
            copy.selectedRecruiter = {
                value: copy.recruiter.id,
                label: copy.recruiter.name,
            };
        }
        if (copy.user && !copy.selectedUser) {
            copy.studentId = copy.user.id;
            copy.selectedUser = {
                value: copy.user.id,
                label: `${copy.user.first_name} ${copy.user.last_name} (${copy.user.email})`,
            };
        }
        if (!copy.selectedType && copy.placement_type) {
            copy.selectedType = {
                value: copy.placement_type,
                label: copy.placement_type,
            };
        }
        if (!copy.selectedPlace && copy.place_of_work) {
            copy.selectedPlace = {
                value: copy.place_of_work,
                label: copy.place_of_work,
            };
        }
        return copy;
    case 'UNSET_EDITING_PLACEMENT':
        return defaultPlacement;
    case 'UNSET_USER':
        return defaultPlacement;
    default:
        return state;
    }
};

export default combineReducers({
    companyPlacements,
    placements,
    stats,
    byType,
    searchOffset,
    sortOrder,
    companyPlacementSortOrder,
    searchText,
    firstPlacementsOnly,
    dateRange,
    editingPlacement,
});
