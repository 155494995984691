import React from 'react';
import {
    List,
} from '@mui/material';
import { ReactComponent as Logo } from './logo-prime-horizontal.svg';
import SideNavItem from './SideNavItem';
import makeSideNavStyles from './makeSideNavStyles';
import UserInfo from './UserInfo';

const useStyles = makeSideNavStyles();

const ApplicantNav = () => {
    const classes = useStyles();

    return (
        <div className={classes.sideNav}>
            <Logo className={classes.logo} />
            <List>

                {/* Applications */}
                <SideNavItem
                    to="/applications"
                    label="Applications"
                    iconClass="fa fa-desktop"
                />

                {/* Edit Profile */}
                <SideNavItem
                    to="/profile"
                    label="Edit Profile"
                    iconClass="fa fa-user"
                />
                <UserInfo />
            </List>
        </div>
    );
};

export default ApplicantNav;
