import {
    TableCell,
    Tooltip,
    Badge,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useStyles from './styles';
import './CohortGrades.css';
import FaIcon from '../../../helpers/FaIcon';
import { CourseWorkStatus } from '../../../helpers/CourseWorkTextHelper';

function gradeCellStyles(courseWork) {
    const status = CourseWorkStatus.from(courseWork, courseWork.assignment);
    const style = {
        cellClasses: [`grade-${courseWork.grade}`],
        badgeContent: null,
        gradePhrase: '',
        cellContent: courseWork.grade,
    };

    // Figure out CSS classes for the grade cell
    switch (status) {
    case CourseWorkStatus.Uncompleted:
        style.cellClasses.push('grade-uncompleted');
        style.badgeContent = 'Uncompleted';
        style.cellContent = '-';
        break;
    case CourseWorkStatus.Missing:
        style.cellClasses.push('grade-missing');
        style.badgeContent = 'Missing';
        style.cellContent = (
            <FaIcon
                icon="exclamation-triangle"
                iconType="fas"
                style={{
                    display: 'inline',
                    fontSize: 16,
                }}
            />
        );
        break;
        // Complete but not graded
    case CourseWorkStatus.Early:
    case CourseWorkStatus.Complete:
    case CourseWorkStatus.OnTime:
    case CourseWorkStatus.Late:
        if (courseWork.workflow_state === 'draft') {
            style.cellClasses.push('grade-draft');
        } else {
            style.cellClasses.push('grade-not-graded');
            style.badgeContent = 'Not yet graded';

            // Ungraded cells shows file icon
            style.cellContent = (
                <FaIcon
                    icon="file"
                    iconType="far"
                    style={{
                        display: 'inline',
                        fontSize: 16,
                    }}
                />
            );
        }
        break;
    case CourseWorkStatus.ResubmissionDue:
        if (courseWork.workflow_state === 'draft') {
            style.cellClasses.push('grade-draft');
        }
        style.cellClasses.push('grade-resubmission-due');
        style.badgeContent = 'Requires Resubmission';
        style.cellContent = courseWork.grade;
        break;
    case CourseWorkStatus.ResubmissionAccepted:
        if (courseWork.workflow_state === 'draft') {
            style.cellClasses.push('grade-draft');
        }
        style.cellClasses.push('grade-resubmission-accepted');
        style.badgeContent = 'Resubmission Accepted';
        style.cellContent = courseWork.grade;
        break;
    case CourseWorkStatus.ResubmissionComplete:
        if (courseWork.workflow_state === 'draft') {
            style.cellClasses.push('grade-draft');
        }
        style.cellClasses.push('grade-resubmission-complete');
        style.badgeContent = 'Resubmission Complete';
        style.cellContent = courseWork.grade;
        break;
    case CourseWorkStatus.LateGraded:
        style.cellClasses.push('grade-late');
        style.badgeContent = 'Late';
        style.cellContent = courseWork.grade;
        break;
    default:
    }

    // Draft styles
    if (courseWork.workflow_state === 'draft') {
        style.cellClasses.push('grade-draft');

        // Badge content for drafts
        style.badgeContent = `Draft${courseWork.grade ? ` (${courseWork.grade})` : ''
        }`;

        // Draft cells show a draft icon
        style.cellContent = (
            <FaIcon
                icon="file-alt"
                iconType="fas"
                style={{
                    display: 'inline',
                    fontSize: 16,
                }}
            />
        );
    }

    // Grade text (for tooltip badge)
    if (courseWork.grade >= 0) {
        style.gradePhrase = {
            0: 'Missing',
            1: 'Needs Improvement',
            3: 'Meets Expectations',
            5: 'Exceeds Expectations',
        }[courseWork.grade];
    }

    return style;
}

function GradeCell({
    courseWork,
    onMouseEnter,
    onMouseLeave,
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const gradeStyle = courseWork ? gradeCellStyles(courseWork) : {};

    return (
        <TableCell
            className={`${classes.gradeCell} ${gradeStyle.cellClasses?.join(' ')}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {
                courseWork && courseWork.id && courseWork.user ? (
                    <GradeTooltip
                        user={courseWork.user}
                        gradePhrase={gradeStyle.gradePhrase}
                        courseWork={courseWork}
                        assignment={courseWork.assignment}
                        onClick={() => {
                            if (!courseWork.completed_at) {
                                // Don't open course work model
                                // if it's not yet submitted
                                return;
                            }

                            // Trigger opening course work model
                            // and fetching the data from the server
                            // (we need additional data about this course work object)
                            dispatch({
                                type: 'FETCH_COURSE_WORK_DETAIL',
                                payload: { id: courseWork.id },
                                showModal: true,
                            });
                        }}
                    />
                ) : '-'
            }
        </TableCell>
    );
}

GradeCell.propTypes = {
    courseWork: PropTypes.instanceOf(Object),
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

GradeCell.defaultProps = {
    // Student may not have coursework for this assignment
    // eg, transfer student
    courseWork: null,
    onMouseEnter: () => { },
    onMouseLeave: () => { },
};

const StyledTooltip = withStyles(theme => ({
    tooltip: {
        maxWidth: 600,
    },
}))(Tooltip);

function GradeTooltip({
    gradePhrase,
    user,
    assignment,
    courseWork,
    onClick,
}) {
    const gradeStyle = gradeCellStyles(courseWork);

    // Render a badge in the tooltip
    // if the coursework is "flagged" (eg. it's "late")
    const tooltipContent = (
        <Badge
            color="secondary"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            invisible={!gradeStyle.badgeContent}
            badgeContent={gradeStyle.badgeContent}
            style={{
                display: 'block',
                fontSize: 16,
                padding: '7px 3px',
                lineHeight: 1.3,
            }}
        >
            <div style={{ fontWeight: 'bold' }}>
                {assignment.name}
            </div>
            <div>
                {`${user.first_name} ${user.last_name} - `}
                {' '}
                <span style={{ fontStyle: 'italic' }}>
                    {gradePhrase}
                </span>
            </div>
        </Badge>
    );
    return (
        <StyledTooltip
            title={tooltipContent}
            enterDelay={100}
            enterNextDelay={100}
        >
            {/* Cell content (grade number, or icon) */}
            <div
                style={{
                    color: 'inherit',
                    display: 'block',
                    cursor: courseWork.completed_at && 'pointer',
                }}
                onClick={onClick}
                onKeyDown={onClick}
                role="button"
                tabIndex="0"
            >
                {gradeStyle.cellContent}
            </div>

        </StyledTooltip>
    );
}

GradeTooltip.propTypes = {
    gradePhrase: PropTypes.string.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
    assignment: PropTypes.instanceOf(Object).isRequired,
    courseWork: PropTypes.instanceOf(Object).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default GradeCell;
