import React, { Component } from 'react';
import {
    Container,

    Hidden, Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import ApplicationListRow from './ApplicationListRow';

const styles = ({
    searchInput: {
        width: '100%',
        marginTop: '20px',
    },
    selectLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    applicationSelect: {
        marginTop: '5px',
        width: '100%',
    },
    updateButton: {
        marginTop: '25px',
    },
    filterContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    },
    tableRow: {
        cursor: 'pointer',
        color: 'black',
        backgroundColor: '#FFFDE7 !important',
    },
    emailColumn: {
        width: '280px',
        color: '#DB5652 !important',
    },
    nameColumn: {
        width: '180px',
        color: '#DB5652 !important',
    },
    statusColumn: {
        width: '180px',
    },
    graphTitle: {
        fontSize: '18px',
        textAlign: 'center',
        weight: '400',
    },
    chartDetails: {
        fontSize: '14px',
        color: '#5E5E5E',
        textAlign: 'left',
    },
    applicationCount: {
        textAlign: 'right',
    },
    chartCohort: {
        fontSize: '16px',
        color: '#222222',
    },
    applicationTableCount: {
        textAlign: 'center',
    },
    tableCell: {
        color: '#DB5652 !important',
    },
    title: {
        backgroundColor: '#525252',
        margin: 0,
        padding: '15px',
        color: 'white',
    },
    table: {
        maxWidth: '100%',
        padding: '15px !important',
    },
    paper: {
        border: '1px solid black',
        padding: '0px !important',
    },
});
class OverdueApplications extends Component {
    render() {
        const {
            classes,
            overdueApplications,
        } = this.props;

        return (
            <Paper className={classes.paper}>
                <Typography variant="h5" className={classes.title}>
                    Overdue
                </Typography>
                <Container className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel>
                                        Applicant
                                    </TableSortLabel>
                                </TableCell>
                                <Hidden mdDown>
                                    <TableCell>
                                        <TableSortLabel>
                                            Email
                                        </TableSortLabel>
                                    </TableCell>
                                </Hidden>
                                <Hidden lgDown>
                                    <TableCell>
                                        Logic
                                    </TableCell>
                                    <TableCell>
                                        Profile
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel>
                                            Resume
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel>
                                            Interview
                                        </TableSortLabel>
                                    </TableCell>
                                </Hidden>
                                <TableCell>
                                    Applied to
                                </TableCell>
                                <Hidden lgDown>
                                    <TableCell>
                                        <TableSortLabel>
                                            Completed on
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel>
                                            Resubmitted
                                        </TableSortLabel>
                                    </TableCell>
                                </Hidden>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                overdueApplications
                                && overdueApplications.rows.map(application => (
                                    <ApplicationListRow
                                        overdueApplications
                                        key={application.application_id}
                                        applicationInfo={application}
                                        classes={classes}
                                        viewApplicationDetails={this.viewApplicationDetails}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>

                </Container>

            </Paper>
        );
    }
}

OverdueApplications.propTypes = {
    overdueApplications: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = state => ({
    applications: state.application.applicationList,
    applicationStats: state.application.applicationStatsList,
    sortOrder: state.application.sortOrder,
    searchOffset: state.application.searchOffset,
    rowsPerPage: state.settings.rowsPerPage,
    searchText: state.application.searchText,
    statuses: state.application.statuses,
    cohorts: state.cohort.cohortList,
    cohortId: state.application.selectedCohort,
    selectedStatus: state.application.selectedStatus,
    cohortAppliedId: state.application.selectedCohortApplied,
});
export default connect(mapStateToProps)(withStyles(styles)(OverdueApplications));
