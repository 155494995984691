import React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import CompanyPlacementsRow from './CompanyPlacementsRow';

const CompanyPlacements = ({
    placements,
    classes,
}) => (
    <>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Company
                    </TableCell>
                    <TableCell>
                        Student
                    </TableCell>
                    <TableCell>
                        Cohort
                    </TableCell>
                    <TableCell>
                        Type
                    </TableCell>
                    <TableCell>
                        Title
                    </TableCell>
                    <TableCell>
                        Salary
                    </TableCell>
                    <TableCell>
                        Offer Date
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    placements
                    && placements.map(placementInfo => (
                        <CompanyPlacementsRow
                            key={placementInfo.id}
                            placementInfo={placementInfo}
                            classes={classes}
                        />
                    ))
                }
            </TableBody>
        </Table>

    </>

);

CompanyPlacements.propTypes = {
    placements: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};
export default (CompanyPlacements);
