/* eslint-disable no-console */
import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* createCohort(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const { data: newCohort } = yield axios.post('/api/cohorts', action.payload);
        yield put({ type: 'FETCH_COHORT_LIST' });
        // Move to page for student created
        yield delay(250);
        yield action.history.replace(`/cohorts/${newCohort.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Create Student - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* fetchCohortList() {
    try {
        const response = yield axios.get('api/cohorts/list');
        yield put({ type: 'SET_COHORT_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Cohort list - get request failed', error);
    }
}

function* fetchCohortFeedback(action) {
    try {
        const response = yield axios.get(
            `api/cohorts/feedback/${action.payload.id}`,
            {
                params: {
                    feedbackOn: action.payload.feedbackOn,
                },
            },
        );
        yield put({ type: 'SET_COHORT_FEEDBACK', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Cohort feedback - get request failed', error);
    }
}

function* fetchCohortDetails(action) {
    try {
        const response = yield axios.get(`api/cohorts/${action.payload.id}`);
        yield put({ type: 'SET_COHORT', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Cohort details - get request failed', error);
    }
}

function* fetchCohortAssignments(action) {
    try {
        const response = yield axios.get('api/assignments/list', { params: action.payload });
        yield put({ type: 'SET_COHORT_ASSIGNMENTS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Cohort assignments - get request failed', error);
    }
}

function* fetchCohortForEdit(action) {
    try {
        const response = yield axios.get(`api/cohorts/${action.payload.cohortId}`);
        yield put({ type: 'SET_EDITING_COHORT', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Fetch Cohort For Edit - get request failed', error);
    }
}

// For non-prime admission process students
function* updateCohort(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const { data: updatedCohort } = yield axios.put('/api/cohorts/update', action.payload);
        // Move to page for student created
        yield delay(250);
        yield action.history.replace(`/cohorts/${updatedCohort.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Update Cohort - put request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* fetchCohortFeedbackSummary(action) {
    try {
        const response = yield axios.get(`api/cohorts/feedback/summary/${action.payload.id}`);
        yield put({ type: 'SET_COHORT_FEEDBACK_SUMMARY', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Fetch Cohort Feedback Summary - get request failed', error);
    }
}

function* deleteCohort(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put(`api/cohorts/archive/${action.payload.cohortId}`);
        // Add delay to allow loading indicator to appear
        yield delay(250);
        yield action.history.replace('/cohorts');
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* userSaga() {
    yield takeLatest('CREATE_COHORT', createCohort);
    yield takeLatest('PUT_COHORT', updateCohort);
    yield takeLatest('FETCH_COHORT_FEEDBACK', fetchCohortFeedback);
    yield takeLatest('FETCH_COHORT_LIST', fetchCohortList);
    yield takeLatest('FETCH_COHORT_DETAILS', fetchCohortDetails);
    yield takeLatest('FETCH_COHORT_ASSIGNMENTS', fetchCohortAssignments);
    yield takeLatest('FETCH_COHORT_FOR_EDIT', fetchCohortForEdit);
    yield takeLatest('FETCH_COHORT_FEEDBACK_SUMMARY', fetchCohortFeedbackSummary);
    yield takeLatest('ARCHIVE_COHORT', deleteCohort);
}

export default userSaga;
