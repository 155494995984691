import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import {
    TableCell,
} from '@mui/material';

const styles = {
    tableCell: {
        position: 'relative',

    },
    tableCellLink: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
};

const TableCellLink = ({
    children,
    classes,
    color,
    to,
    align,
    first,
}) => (
    <TableCell style={{ color }} className={classes.tableCell} align={align}>
        {children}
        <Link
            className={classes.tableCellLink}
            to={to}
            tabIndex={first ? 0 : -1}
        />
    </TableCell>
);

TableCellLink.defaultProps = {
    align: 'left',
    first: false,
    children: null,
    color: 'unset',
};

TableCellLink.propTypes = {
    to: PropTypes.string.isRequired,
    align: PropTypes.string,
    first: PropTypes.bool,
    color: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Object),
        PropTypes.number,
        PropTypes.bool,
    ]),
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(TableCellLink);
