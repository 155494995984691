import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import {
    Paper,
    DialogActions,
    Grid,
    Button,
    TextField,
    FormLabel,
} from '@mui/material';
import ReactSelect from 'react-select';
import PageTitle from '../../PageTitle/PageTitle';

function makePassword(length) {
    let result = '';
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const styles = theme => ({
    roleImg: {
        width: '900px',
        objectFit: 'contain',
    },
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
    },
    /**
    * React doesn't provide an auto complete input field. Styling this third
    * party component takes a bit more work that standard Material UI components.
    * https://react-select.com/styles#using-classnames
    */
    roleSelect: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '0px',
        },
        '& .react-select__control': {
            height: '39px',
            borderRadius: '3px',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
        '& .react-select__multi-value__label': {
            fontSize: '16px',
        },
        '& .react-select__multi-value__remove': {
            cursor: 'pointer',
        },
        '& .react-select__menu': {
            zIndex: '1000',
        },
        marginTop: '26px',
    },
    passwordText: {
        marginTop: '36px',
        fontSize: '16px',
    },
    selectContainer: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
});

class CreateEmployeePage extends Component {
    constructor(props) {
        super(props);
        // const { match, dispatch } = this.props;

        this.state = {
            changes: false,
            // editing is set to true if editing an existing employee
            existing: false,
            errorText: '',
            // confirmationModalOpen: false,
            password: makePassword(8),
        };
    }

    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        if (match.params.id) {
            this.setState({
                existing: true,
            });
            dispatch({
                type: 'FETCH_EMPLOYEE_DETAILS',
                payload: match.params.id,
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_EMPLOYEE_ROLE' });
        dispatch({ type: 'UNSET_EMPLOYEE_STATUS' });
        dispatch({ type: 'UNSET_EDITING_EMPLOYEE' });
    }

    handleCancel = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleChangeFor = propertyName => (event) => {
        const { dispatch, employee } = this.props;
        const updatedEmployee = {
            ...employee,
            [propertyName]: event.target.value,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_EDITING_EMPLOYEE', payload: updatedEmployee });
    };

    handleStatusSelect = (selectedOption) => {
        const { dispatch, employee } = this.props;
        const updatedEmployee = {
            ...employee,
            status: selectedOption.value,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_EDITING_EMPLOYEE', payload: updatedEmployee });
        dispatch({ type: 'UPDATE_EMPLOYEE_STATUS', payload: selectedOption });
    };

    handleRoleSelect = (selectedOption) => {
        const { dispatch, employee } = this.props;
        const updatedEmployee = {
            ...employee,
            role: selectedOption.value,
        };
        this.setState({
            changes: true,
        });
        dispatch({ type: 'SET_EDITING_EMPLOYEE', payload: updatedEmployee });
        dispatch({ type: 'UPDATE_EMPLOYEE_ROLE', payload: selectedOption });
    };

    handleSubmit = () => {
        const { employee, history, dispatch } = this.props;
        const { password, existing } = this.state;
        const valid = this.validateForm();
        if (!valid) {
            return;
        }
        // TODO: Update changes after a successful save. Right now we're assuming
        // success.
        this.setState({
            changes: false,
        });
        let action;
        if (!existing) {
            action = {
                type: 'CREATE_EMPLOYEE',
                payload: { ...employee, password },
                history,
            };
        } if (existing) {
            action = {
                type: 'PUT_EMPLOYEE',
                payload: { ...employee },
                history,
            };
        }
        dispatch(action);
    };

    validateForm = () => {
        const { employee } = this.props;
        let valid = true;
        // Probably check others?
        if (employee.first_name === '' || employee.last_name === '') {
            valid = false;
            this.setState({
                errorText: 'Employee name is required.',
            });
        } else if (!employee.email || employee.email === '') {
            valid = false;
            this.setState({
                errorText: 'Employee email is required.',
            });
        } else if (!employee.role || employee.role === '') {
            valid = false;
            this.setState({
                errorText: 'Employee role is required.',
            });
        } else if (!employee.status || employee.status === '') {
            valid = false;
            this.setState({
                errorText: 'Employee status is required.',
            });
        }
        return valid;
    };

    render() {
        const {
            classes,
            employee,
            roles,
            statuses,
            employeeStatus,
            employeeRole,
        } = this.props;
        const {
            changes,
            existing,
            errorText,
            password,
        } = this.state;
        let pageTitle = '';
        if (existing) {
            pageTitle = `Edit Employee: ${employee.first_name} ${employee.last_name}`;
        } else {
            pageTitle = 'Create New Employee';
        }
        return (
            <>
                <Prompt
                    // Use a standard alert dialog if the user leaves the page when
                    // changes are detected.
                    when={changes}
                    message="You have unsaved edits. Are you sure you want to leave?"
                />
                <div className="container">
                    <PageTitle
                        title={pageTitle}
                    />
                    <Paper>
                        {errorText.length > 0 && (
                            <div className={classes.errorText}>{errorText}</div>
                        )}
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="first-name"
                                        label="Employee First Name"
                                        className={classes.textField}
                                        value={employee.first_name}
                                        onChange={this.handleChangeFor('first_name')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="last-name"
                                        label="Employee Last Name"
                                        className={classes.textField}
                                        value={employee.last_name}
                                        onChange={this.handleChangeFor('last_name')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        helperText="Note: Email is the username this employee will use to login to the portal."
                                        id="email"
                                        label="Employee Email Address"
                                        className={classes.textField}
                                        value={employee.email}
                                        onChange={this.handleChangeFor('email')}
                                        margin="normal"
                                    />
                                </Grid>
                                {
                                    !existing && (
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="password"
                                                label="Temporary Password"
                                                helperText="Employees can change this password after logging in."
                                                className={classes.textField}
                                                value={password}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                margin="normal"
                                            />
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} sm={6}>
                                    <FormLabel component="label">Status</FormLabel>
                                    <ReactSelect
                                        required
                                        placeholder="Status"
                                        value={employeeStatus}
                                        onChange={this.handleStatusSelect}
                                        options={statuses}
                                        className={classes.roleSelect}
                                        classNamePrefix="react-select"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormLabel component="label">Role</FormLabel>
                                    <ReactSelect
                                        required
                                        placeholder="Role"
                                        value={employeeRole}
                                        onChange={this.handleRoleSelect}
                                        options={roles}
                                        className={classes.roleSelect}
                                        classNamePrefix="react-select"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <img className={classes.roleImg} src="./roles.png" alt="Role privileges" />
                                </Grid>
                            </Grid>
                        </form>
                        <br />
                        <br />
                        {errorText.length > 0 && (
                            <div className={classes.errorText}>{errorText}</div>
                        )}
                        <DialogActions>
                            <Button
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleSubmit}
                            >
                                {existing ? <span>Save</span> : <span>Create</span>}
                            </Button>
                        </DialogActions>
                    </Paper>
                </div>
            </>
        );
    }
}

CreateEmployeePage.propTypes = {
    match: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    employee: PropTypes.instanceOf(Object).isRequired,
    employeeStatus: PropTypes.instanceOf(Object).isRequired,
    employeeRole: PropTypes.instanceOf(Object).isRequired,
    roles: PropTypes.instanceOf(Array).isRequired,
    statuses: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    employee: state.employee.editingEmployee,
    roles: state.employee.roles,
    statuses: state.employee.statuses,
    employeeStatus: state.employee.employeeStatus,
    employeeRole: state.employee.employeeRole,
});

const createEmployeePage = withStyles(styles)(CreateEmployeePage);
export default connect(mapStateToProps)(createEmployeePage);
