import React from 'react';
import {
    TableRow,
    TableCell,
    Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import formatDollars from '../../../helpers/CurrencyHelper';

const {
    ADMIN,
    CAREER,
} = require('../../General/UserTypes');

const PlacementListRow = ({
    editPlacement,
    placementInfo,
    classes,
    user,
}) => (
    <TableRow className={classes.tableRow} hover onClick={() => editPlacement(placementInfo)}>
        <TableCell className={classes.tableCell}>
            <Link onClick={e => e.stopPropagation()} to={`/companies/${placementInfo['company.id']}`}>
                {placementInfo['company.name']}
            </Link>
        </TableCell>
        <TableCell>
            <Link onClick={e => e.stopPropagation()} to={`/students/${placementInfo['user.id']}`}>
                {placementInfo['user.first_name']}
                {' '}
                {placementInfo['user.last_name']}
                {placementInfo['user.pronouns'] && ` (${placementInfo['user.pronouns']})`}
            </Link>
        </TableCell>
        <TableCell>
            <Link onClick={e => e.stopPropagation()} to={`/cohorts/${placementInfo['cohort.id']}`}>
                {placementInfo['cohort.name']}
            </Link>
        </TableCell>
        <TableCell>
            {placementInfo.placement_type}
        </TableCell>
        <TableCell>
            {placementInfo.job_title}
        </TableCell>
        {(user.role === ADMIN || user.role === CAREER) && (
            <TableCell>
                {formatDollars(placementInfo.post_income)}
            </TableCell>
        )}
        <TableCell>
            {placementInfo.placed_at}
        </TableCell>
        <TableCell>
            {placementInfo.cohort_graduation_date}
        </TableCell>
        <TableCell>
            {`${placementInfo.days_to_placement} days`}
        </TableCell>
        {(user.role === ADMIN || user.role === CAREER) && (
            <TableCell align="right">
                <Button variant="outlined" type="button" onClick={() => editPlacement(placementInfo)}>
                    Edit Placement
                </Button>
            </TableCell>
        )}
    </TableRow>
);

PlacementListRow.propTypes = {
    placementInfo: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    editPlacement: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
};

export default (PlacementListRow);
