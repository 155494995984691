import { combineReducers } from 'redux';

const courseList = (state = [], action) => {
    switch (action.type) {
    case 'SET_COURSE_LIST':
        return action.payload;
    case 'UNSET_COURSE_LIST':
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const defaultCourse = {
    active: null,
    ask_for_resume: null,
    code: '',
    description: '',
    id: null,
    logic_question_description: '',
    logic_question_label: '',
    name: '',
    profile_question_description: '',
    profile_question_label: '',
    slack_channel_id: null,
    slug: '',
    start_description: '',
    technical_challenge_description: '',
    technical_challenge_explanation: null,
    technical_challenge_label: '',
};

const editingCourse = (state = defaultCourse || {}, action) => {
    // let copy;
    switch (action.type) {
    case 'SET_EDITING_COURSE':
        return action.payload;
    case 'UNSET_EDITING_COURSE':
        return {};
    case 'UNSET_USER':
        return defaultCourse;
    default:
        return state;
    }
};

const course = (state = {}, action) => {
    switch (action.type) {
    case 'SET_COURSE':
        return action.payload;
    case 'UNSET_COURSE':
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

export default combineReducers({
    // TODO: course and courses should probably be combined into one reducer
    course,
    courseList,
    editingCourse,
});
