import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    Grid,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from '@mui/material';
import PageTitle from '../../PageTitle/PageTitle';
import AcademicEventsTable from '../../General/AcademicEventsTable';
import StudentFeedbackRow from './StudentFeedbackRow';
import StudentFeedbackGraph from '../StudentDetailPage/StudentFeedbackGraph/StudentFeedbackGraph';

class StudentFeedbackPage extends Component {
    componentDidMount() {
        const { dispatch, match, history } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'UNSET_STUDENT' });
        dispatch({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: match.params.id } });
        dispatch({ type: 'FETCH_STUDENT_ACADEMIC_EVENTS', payload: { studentId: match.params.id } });
        dispatch({ type: 'FETCH_STUDENT_FEEDBACK_SUMMARY', payload: { studentId: match.params.id } });
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        this.unlisten = history.listen(() => {
            this.onRouteChange();
        });
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'UNSET_STUDENT' });
        this.unlisten();
    }

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = () => {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: match.params.id } });
        dispatch({ type: 'FETCH_STUDENT_ACADEMIC_EVENTS', payload: { studentId: match.params.id } });
        dispatch({ type: 'FETCH_STUDENT_FEEDBACK_SUMMARY', payload: { studentId: match.params.id } });
    };

    navigateToFeedback = academicEvent => () => {
        const { history } = this.props;
        const feedbackId = academicEvent.academic_eventable_id;
        history.push(`/students/${academicEvent.user.id}/feedback/${feedbackId}`);
    };

    viewFeedbackDetails = selectedFeedback => () => {
        const {
            student, history,
        } = this.props;
        history.push(`/students/${student.id}/feedback/${selectedFeedback.id}`);
    };

    render() {
        const {
            student,
            classes = {},
            academicEvents,
            feedback,
            feedbackDataset,
        } = this.props;

        const pageTitle = `Student :: ${student.first_name} ${student.last_name}`;

        const actions = [{
            label: 'Edit',
            // Dispatch an action...
            type: 'SET_EDITING_STUDENT',
            payload: student,
            // ...and navigate to this page.
            path: `/students/${student.id}/edit`,
        }];

        return (
            <div className="container">
                <PageTitle
                    title={pageTitle}
                    // Action buttons are created by the PageTitle component
                    actions={actions}
                    navigation={
                        [
                            { name: 'profile', path: `/students/${student.id}` },
                            { name: 'feedback', path: `/students/${student.id}/feedback`, selected: true },
                        ]
                    }
                />
                {
                    academicEvents
                    && academicEvents.unresolved
                    && academicEvents.unresolved.length > 0
                    && (
                        <Grid className={classes.cardContent} container>
                            <Grid item xs={12}>
                                <AcademicEventsTable
                                    title="Unresolved Academic Events"
                                    academicEvents={academicEvents.unresolved}
                                    navigateToFeedback={this.navigateToFeedback}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                {
                    feedbackDataset
                        && feedbackDataset.datasets
                        // At least two days of feedback to show a trend
                        && feedbackDataset.datasets.length > 1
                        && (
                            <StudentFeedbackGraph feedbackDataset={feedbackDataset} />
                        )
                }
                <Grid className={classes.cardContent} container>
                    <Grid item xs={12}>
                        <Paper>
                            <Typography>Reflection</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> Date </TableCell>
                                        <TableCell>Comprehension</TableCell>
                                        <TableCell>Support</TableCell>
                                        <TableCell>One Word</TableCell>
                                        <TableCell>Thanks</TableCell>
                                        <TableCell>Suggest</TableCell>
                                        <TableCell>Accomplishment</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        feedback.map(studentFeedback => (
                                            <StudentFeedbackRow
                                                key={studentFeedback.feedback_on}
                                                studentFeedback={studentFeedback}
                                                viewFeedbackDetails={this.viewFeedbackDetails}
                                            />
                                        ))
                                    }

                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
                {
                    academicEvents.resolved
                    && academicEvents.resolved.length > 0
                    && (
                        <Grid className={classes.cardContent} container>
                            <Grid item xs={12}>
                                <AcademicEventsTable
                                    title="Resolved Academic Events"
                                    academicEvents={academicEvents.resolved}
                                    navigateToFeedback={() => {}}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            </div>
        );
    }
}

StudentFeedbackPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
    }).isRequired,
    student: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    academicEvents: PropTypes.instanceOf(Object).isRequired,
    feedback: PropTypes.instanceOf(Array).isRequired,
    feedbackDataset: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    cohort: state.cohort.cohort,
    student: state.student.student,
    feedbackDataset: state.student.feedbackDataset,
    courseWork: state.student.courseWork,
    modalOpen: state.assignments.modalOpen,
    selectedAssignment: state.assignments.selectedAssignment,
    selectedCourseWork: state.assignments.selectedCourseWork,
    academicEvents: state.student.academicEvents,
    feedback: state.student.feedbackSummary,
});

export default connect(mapStateToProps)(StudentFeedbackPage);
