import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Typography,
    TableSortLabel,
} from '@mui/material';
import PageTitle from '../../PageTitle/PageTitle';
import TableCellLink from '../../General/TableCellLink';
import EmployeeRoles from './EmployeeRoles';

class EmployeeListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortOrder: {
                orderBy: 'first_name',
                order: 'asc',
            },
            sortOrderInactive: {
                orderBy: 'first_name',
                order: 'asc',
            },
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_EMPLOYEE_LIST' });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
    }

    editEmployee = (selectedEmployee) => {
        const { history } = this.props;
        history.push(`/employees/${selectedEmployee.id}/edit`);
    };

    translateRoles = (string) => {
        switch (string) {
        case 'grader':
            return 'Grader';
        case 'adjunct':
            return 'Adjunct';
        case 'career':
            return 'Career Development';
        case 'student_life':
            return 'Student Life';
        case 'faculty':
            return 'Faculty';
        case 'director':
            return 'Academic Director';
        case 'admin':
            return 'Admin';
        default:
            return '';
        }
    };

    sortBy = orderBy => () => {
        const { sortOrder } = this.state;
        if (sortOrder.order === 'asc') {
            this.setState({
                sortOrder: {
                    order: 'desc',
                    orderBy,
                },
            });
        } else {
            this.setState({
                sortOrder: {
                    order: 'asc',
                    orderBy,
                },
            });
        }
    };

    sortByInactive = orderBy => () => {
        const { sortOrderInactive } = this.state;
        if (sortOrderInactive.order === 'asc') {
            this.setState({
                sortOrderInactive: {
                    order: 'desc',
                    orderBy,
                },
            });
        } else {
            this.setState({
                sortOrderInactive: {
                    order: 'asc',
                    orderBy,
                },
            });
        }
    };

    render() {
        const { employees } = this.props;
        const activeEmployees = employees.filter(employee => employee.status === 'Active');
        const inactiveEmployees = employees.filter(employee => employee.status !== 'Active');
        const { sortOrder, sortOrderInactive } = this.state;
        activeEmployees.sort((a, b) => {
            if (!a[sortOrder.orderBy] || !b[sortOrder.orderBy]) {
                return 0;
            }
            if (sortOrder.order === 'asc') {
                return a[sortOrder.orderBy].localeCompare(b[sortOrder.orderBy]);
            }
            return b[sortOrder.orderBy].localeCompare(a[sortOrder.orderBy]);
        });
        inactiveEmployees.sort((a, b) => {
            if (sortOrderInactive.order === 'asc') {
                return a[sortOrderInactive.orderBy].localeCompare(b[sortOrderInactive.orderBy]);
            }
            return b[sortOrderInactive.orderBy].localeCompare(a[sortOrderInactive.orderBy]);
        });
        return (
            <div className="container">
                <PageTitle
                    title="Employees"
                    actions={[{
                        label: 'Add New Employee',
                        // Dispatch an action...
                        type: 'UNSET_EDITING_EMPLOYEE',
                        // ...and navigate to this page.
                        path: '/employees/new',
                    }]}
                />
                <EmployeeRoles />
                <Paper>
                    <Typography variant="h5">
                        Active Employees
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sortDirection={sortOrder.orderBy === 'first_name' && sortOrder.order}
                                >
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'first_name'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('first_name')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={sortOrder.orderBy === 'email' && sortOrder.order}
                                >
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'email'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('email')}
                                    >
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={sortOrder.orderBy === 'role' && sortOrder.order}
                                >
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'role'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('role')}
                                    >
                                        Role
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                activeEmployees.map(employee => (
                                    <TableRow
                                        key={employee.id}
                                        hover
                                    >
                                        <TableCellLink to={`/employees/${employee.id}/edit`} first>
                                            {`${employee.first_name} ${employee.last_name}`}
                                        </TableCellLink>
                                        <TableCellLink to={`/employees/${employee.id}/edit`}>
                                            {employee.email}
                                        </TableCellLink>
                                        <TableCellLink to={`/employees/${employee.id}/edit`}>
                                            {this.translateRoles(employee.role)}
                                        </TableCellLink>
                                        <TableCellLink to={`/employees/${employee.id}/edit`} align="right">
                                            <Button
                                                variant="outlined"
                                                type="button"
                                                onClick={() => this.editEmployee(employee)}
                                            >
                                                Edit
                                            </Button>
                                        </TableCellLink>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
                <br />
                <br />
                <Paper>
                    <Typography variant="h5">
                        Inactive Employees
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sortDirection={sortOrderInactive.orderBy === 'first_name' && sortOrderInactive.order}
                                >
                                    <TableSortLabel
                                        active={sortOrderInactive.orderBy === 'first_name'}
                                        direction={sortOrderInactive.order}
                                        onClick={this.sortByInactive('first_name')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={sortOrderInactive.orderBy === 'email' && sortOrderInactive.order}
                                >
                                    <TableSortLabel
                                        active={sortOrderInactive.orderBy === 'email'}
                                        direction={sortOrderInactive.order}
                                        onClick={this.sortByInactive('email')}
                                    >
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={sortOrderInactive.orderBy === 'role' && sortOrderInactive.order}
                                >
                                    <TableSortLabel
                                        active={sortOrderInactive.orderBy === 'role'}
                                        direction={sortOrderInactive.order}
                                        onClick={this.sortByInactive('role')}
                                    >
                                        Role
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                inactiveEmployees.map(employee => (
                                    <TableRow
                                        hover
                                        key={employee.id}
                                    >
                                        <TableCellLink to={`/employees/${employee.id}/edit`} first>
                                            {`${employee.first_name} ${employee.last_name}`}
                                        </TableCellLink>
                                        <TableCellLink to={`/employees/${employee.id}/edit`}>
                                            {employee.email}
                                        </TableCellLink>
                                        <TableCellLink to={`/employees/${employee.id}/edit`}>
                                            {this.translateRoles(employee.role)}
                                        </TableCellLink>
                                        <TableCellLink to={`/employees/${employee.id}/edit`} align="right">
                                            <Button
                                                variant="outlined"
                                                type="button"
                                                onClick={() => this.editEmployee(employee)}
                                            >
                                                Edit
                                            </Button>
                                        </TableCellLink>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

EmployeeListPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    employees: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    employees: state.employee.employeeList,
});

export default connect(mapStateToProps)(EmployeeListPage);
