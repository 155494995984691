import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    useTheme,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Input,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = {
    feedbackContainer: {
        padding: '0px 20px',
    },
    textField: {
        width: '100%',
        minWidth: '250px',
        marginRight: '10px',
        marginTop: '20px',
    },
    helperText: {
        marginTop: '6px',
        marginLeft: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    formContainer: {
        overflow: 'hidden',
        marginBottom: '10px',
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontSize: 13,
    },
};

const SubmitCourseWorkModal = (props) => {
    const {
        dispatch,
        classes,
        open,
        onClose,
        onSubmit,
        courseWork,
        errorText,
    } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const onFileChange = async (event) => {
        dispatch({
            type: 'UNSET_STUDENT_COURSE_WORK_ERROR',
            payload: 'File is too big. File size limit is 50MB.',
        });
        const fileToUpload = event.target.files[0];
        const acceptedFileTypes = ['application/pdf', 'application/zip', 'image/jpeg', 'image/png'];
        if (fileToUpload && fileToUpload.size > 1048576 * 50) {
            dispatch({
                type: 'SET_STUDENT_COURSE_WORK_ERROR',
                payload: 'File is too big. File size limit is 50MB.',
            });
        } else if (fileToUpload && acceptedFileTypes.includes(fileToUpload.type)) {
            const copy = {
                ...courseWork,
                selectedFile: fileToUpload,
            };
            dispatch({
                type: 'SET_STUDENT_PORTAL_COURSE_WORK',
                payload: copy,
            });
        } else {
            dispatch({
                type: 'SET_STUDENT_COURSE_WORK_ERROR',
                payload: 'File type not supported. Please select a pdf, zip or png.',
            });
        }
    };

    const fileName = courseWork.file_name || '';
    const handleChangeFor = prop => (event) => {
        const copy = {
            ...courseWork,
            [prop]: event.target.value,
        };
        // Modify the selected course work directly, we may eventually want to make
        // this a separate reducer.
        dispatch({
            type: 'SET_STUDENT_PORTAL_COURSE_WORK',
            payload: copy,
        });
    };

    return (
        <Dialog fullScreen={fullScreen} open={open}>
            <DialogTitle id="responsive-dialog-title">
                {courseWork && courseWork.assignment.name}
            </DialogTitle>
            {courseWork
            && (
                <DialogContent align="left" className={classes.feedbackContainer}>
                    <form className={classes.formContainer} noValidate>
                        <TextField
                            id="repository"
                            label="Submission:"
                            type="text"
                            value={courseWork.repository}
                            onChange={handleChangeFor('repository')}
                            className={classes.textField}
                            variant="outlined"
                            inputProps={{ maxLength: 2048 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="site"
                            label="Website:"
                            type="text"
                            value={courseWork.site}
                            onChange={handleChangeFor('site')}
                            className={classes.textField}
                            variant="outlined"
                            inputProps={{ maxLength: 2048 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="student_comment"
                            label="Comment:"
                            type="text"
                            value={courseWork.student_comment}
                            onChange={handleChangeFor('student_comment')}
                            className={classes.textField}
                            multiline
                            rows="5"
                            variant="outlined"
                            inputProps={{ maxLength: 2048 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {fileName && (
                            <TextField
                                id="file_name"
                                label="Uploaded File:"
                                type="text"
                                value={courseWork.file_name}
                                className={classes.textField}
                                variant="outlined"
                                disabled="true"
                            />
                        )}
                        <Input
                            id="courseWorkUpload"
                            type="file"
                            inputProps={{ accept: 'application/pdf,application/zip,image/jpeg,image/png' }}
                            disableUnderline
                            onChange={onFileChange}
                        />
                        {errorText && errorText !== '' && (
                            <p className={classes.errorText}>
                                {errorText}
                            </p>
                        )}
                        <br />
                    </form>
                </DialogContent>
            )}

            <br />
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

SubmitCourseWorkModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    courseWork: PropTypes.instanceOf(Object).isRequired,
    errorText: PropTypes.string.isRequired,
};

const assignModal = withStyles(styles)(SubmitCourseWorkModal);
export default connect()(assignModal);
