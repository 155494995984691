import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Divider,
    Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { connect } from 'react-redux';
import InterviewPrepNote from '../../../General/Notes/InterviewPrepNote';

const styles = theme => ({
    tipsList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        position: 'relative',
        right: '18px',
    },
    heading: {
        fontWeight: 500,
        color: 'black',
    },
    question: {
        paddingTop: '20px',
        paddingBottom: '10px',
        fontWeight: 300,
        color: 'black',
        '& div': {
            paddingTop: '5px',
            paddingBottom: '5px',
        },
    },

    responseBox: {
        backgroundColor: '#EEE',
        padding: '16px',
        marginTop: '30px',
    },
    container: {
        paddingTop: '50px',
        paddingBottom: '50px',
        maxWidth: '720px',
        margin: 'auto',
    },
    selectWrapper: {
        maxWidth: '256px',
    },
    profileAnswer: {
        padding: '10px 0',
    },
    secondaryText: {
        fontWeight: 500,
    },
    notesContainer: {
        margin: '10px 0',
    },
    saveButton: {
        maxWidth: '920px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '40px',
    },

});
function ProfileTab({
    value,
    index,
    applicationProfile,
    profileScores,
    handleScoreChange,
    handleScoreSubmission,
    application,
    submitInterviewReview,
    classes,
}) {
    const [answeredQuestions, setAnsweredQuestions] = useState(0);

    const [errorText, setErrorText] = useState('');

    // renders submit button if any of the profile questions have answers
    useEffect(() => {
        if (applicationProfile.questions) {
            let numberOfAnsweredQuestions = 0;
            for (let i = 0; i < applicationProfile.questions.length; i += 1) {
                if (applicationProfile.questions[i]) {
                    numberOfAnsweredQuestions += 1;
                }
            }
            setAnsweredQuestions(numberOfAnsweredQuestions);
        }
    }, [applicationProfile]);

    // checks if any profile questions don't have grades when submit button is pressed
    // triggers prompt asking if user wants to fill out the rest of the form
    const checkIfQuestionsAreGraded = () => {
        if (applicationProfile.questions) {
            let numberOfGradedQuestions = 0;
            for (let i = 0; i < applicationProfile.questions.length; i += 1) {
                // selectValue is where the grade is stored before the put request
                if (applicationProfile.questions[i].selectValue.value !== '' && applicationProfile.questions[i].selectValue.label !== '') {
                    numberOfGradedQuestions += 1;
                }
            }
            if (numberOfGradedQuestions === applicationProfile.questions.length) {
                setErrorText('');
                handleScoreSubmission('profile');
                submitInterviewReview();
            } else {
                setErrorText('Not all questions have been graded');
            }
        }
    };

    const interviewNotes = [
        { label: 'Learning style and program alignment', questionId: 1 },
        { label: 'Collaboration, teamwork, and communication', questionId: 2 },
        { label: 'Personal strengths and career goals', questionId: 3 },
        { label: 'Grit and growth mindset', questionId: 4 },
        { label: 'External and environmental factors', questionId: 5 },
    ];

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <>
                    <Paper>
                        <div className={classes.container}>
                            <Typography className={classes.heading} variant="h2">
                                {`${application.course_id === 2 ? 'Personal' : 'Profile'}`}
                            </Typography>
                            <Typography>
                                Please respond to the following
                                questions demonstrating your interest,
                                passion, and goals in attending our program.
                            </Typography>

                            <br />

                            <Typography className={classes.heading} variant="h4">
                                Tips
                            </Typography>

                            <ul className={classes.tipsList}>
                                <li>
                                    Consider drafting your answers
                                    in a word processor like Microsoft
                                    Word, Google Docs, Notion, or Grammarly.
                                    this will let you
                                    spellcheck your responses.
                                </li>
                                <li>
                                    Try to answer each question with at least three sentences.
                                </li>
                            </ul>

                        </div>

                        <br />
                        <br />
                        <Divider />
                        <br />
                        <br />
                        {applicationProfile.questions && (
                            applicationProfile.questions.map((profileQuestion, questionIndex) => (
                                <div key={profileQuestion.id}>
                                    <div className={classes.container}>
                                        <Typography className={classes.heading} variant="h4">
                                            Question
                                            {' '}
                                            {questionIndex + 1}
                                        </Typography>

                                        <Typography className={classes.question} variant="h6" dangerouslySetInnerHTML={{ __html: profileQuestion.profile_question.question }} />
                                        <Typography variant="h6" className={classes.secondaryText}>
                                            {profileQuestion.profile_question.secondary_text}
                                        </Typography>

                                        <div className={classes.responseBox}>
                                            <Typography variant="h4" className={classes.heading}>
                                                Response
                                            </Typography>
                                            <Typography
                                                className={classes.profileAnswer}
                                                key={profileQuestion.id}
                                            >
                                                {profileQuestion.answer}
                                            </Typography>
                                            {/* Profile_question_answers will have 0 or 1 entry */}
                                            <Typography>
                                                Score
                                            </Typography>
                                            {profileQuestion
                                            && (
                                                <div className={classes.selectWrapper}>
                                                    <ReactSelect
                                                        placeholder="Rating.."
                                                        value={profileQuestion.selectValue}
                                                        onChange={event => handleScoreChange(event, 'profile', profileQuestion.id)}
                                                        options={profileScores}
                                                    />
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                    <Divider />
                                </div>
                            ))

                        )
                        }
                        <div className={classes.container}>
                            <Typography className={classes.heading} variant="h4">
                                Admissions interview preparation
                            </Typography>
                            <Typography variant="h6" className={classes.secondaryText}>
                                Check a topic to prepare notes or
                                questions for this applicant’s interview.
                            </Typography>
                            <div className={classes.notesContainer}>
                                {interviewNotes.map((note) => (
                                    <InterviewPrepNote
                                        key={note.questionId}
                                        questionId={note.questionId}
                                        label={note.label}
                                    />
                                ))}
                            </div>

                        </div>
                        {errorText.length > 0 && (
                            <Typography color="error">{errorText}</Typography>
                        )}
                        <br />
                        <Divider />
                        <br />
                        <br />
                        <br />
                        {answeredQuestions > 0 && (
                            <div className={classes.saveButton}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={checkIfQuestionsAreGraded}
                                >
                                    Save changes and update scores
                                </Button>
                            </div>

                        )}
                    </Paper>
                </>
            )}
        </div>
    );
}

ProfileTab.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    handleScoreChange: PropTypes.func.isRequired,
    submitInterviewReview: PropTypes.func.isRequired,
    handleScoreSubmission: PropTypes.func.isRequired,
    applicationProfile: PropTypes.instanceOf(Object).isRequired,
    profileScores: PropTypes.instanceOf(Array).isRequired,
    application: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    profileScores: state.application.scores,
});

export default connect(mapStateToProps)(withStyles(styles)(ProfileTab));
