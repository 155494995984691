import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
    List,
    ListItem,
    Icon,
    ListItemIcon,
    ListItemText,
    Collapse,
} from '@mui/material';
import { ReactComponent as Logo } from './logo-prime-horizontal.svg';
import {
    ADMIN,
    DIRECTOR,
    STUDENT_LIFE,
    CAREER,
    EMPLOYEE,
    ADJUNCT,
    ALL_STAFF,
} from '../General/UserTypes';
import SideNavItem from './SideNavItem';
import UserInfo from './UserInfo';
import makeSideNavStyles from './makeSideNavStyles';

const useStyles = makeSideNavStyles();

const PORTAL_FEEDBACK_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdzS4-uqN_XqRwJWkeRGs_KwP-04g87NYarQlT2Lx6rialjuw/viewform';

const EmployeeNav = () => {
    const [open, setOpen] = React.useState(false);

    const user = useSelector(store => store.user);
    const classes = useStyles();

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <div className={classes.sideNav}>
            <Logo className={classes.logo} />
            <List>
                {/* Dashboard */}
                <SideNavItem
                    to="/dashboard"
                    label="Dashboard"
                    iconClass="fa fa-tachometer-alt"
                    allowedRoles={[ADMIN, DIRECTOR, EMPLOYEE, ADJUNCT]}
                />

                <SideNavItem
                    to="/career"
                    label="Dashboard"
                    iconClass="fa fa-tachometer-alt"
                    allowedRoles={[CAREER]}
                />

                {/* Applications */}
                <SideNavItem
                    to="/admissions_applications"
                    label="Applications"
                    iconClass="fa fa-file-signature"
                    allowedRoles={[ADMIN, DIRECTOR, STUDENT_LIFE]}
                />

                {/* Students */}
                <SideNavItem
                    to="/students"
                    label="Students"
                    iconClass="fa fa-users"
                />

                {/* Cohorts */}
                <SideNavItem
                    to="/cohorts"
                    label="Cohorts"
                    iconClass="fa fa-university"
                />

                {/* Assignments */}
                <SideNavItem
                    to="/assignments"
                    label="Assignments"
                    iconClass="fa fa-code"
                />

                {/* Companies */}
                <SideNavItem
                    to="/companies"
                    label="Companies"
                    iconClass="fa fa-briefcase"
                    allowedRoles={[ADMIN, CAREER]}
                />

                {/* Placements */}
                <SideNavItem
                    to="/placements"
                    label="Placements"
                    iconClass="fa fa-graduation-cap"
                    allowedRoles={[...ALL_STAFF]}
                />

                {/* Profile */}
                <SideNavItem
                    to="/profile"
                    label="Profile"
                    iconClass="fa fa-user"
                />

                {/* Reports */}
                <SideNavItem
                    to="/reports"
                    label="Reports"
                    iconClass="fa fa-chart-line"
                    allowedRoles={[ADMIN]}
                />

                {/* Settings */}
                {
                    [ADMIN, DIRECTOR, CAREER].includes(user.role)
                    && (
                        <>
                            <ListItem onClick={handleClick} button dense>
                                <ListItemIcon>
                                    <Icon
                                        className={classNames(classes.icon, 'fa fa-cog')}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.navText} primary="Settings" />
                                {open ? (
                                    <Icon
                                        className={classNames(classes.icon, 'fa fa-chevron-up')}
                                    />
                                ) : (
                                    <Icon
                                        className={classNames(classes.icon, 'fa fa-chevron-down')}
                                    />
                                )}
                            </ListItem>
                        </>
                    )
                }
                {/* Employees */}
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <SideNavItem
                            to="/employees"
                            label="Employees"
                        />
                    </List>
                </Collapse>
                {/* Courses */}
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <SideNavItem
                            to="/courses"
                            label="Courses"
                            allowedRoles={[ADMIN]}
                        />
                    </List>
                </Collapse>

                {/* Feedback */}
                <SideNavItem
                    to={PORTAL_FEEDBACK_URL}
                    external
                    label="GIVE FEEDBACK"
                    iconClass="fa fa-comment-dots"
                />
                <UserInfo />
            </List>
        </div>
    );
};

export default EmployeeNav;
