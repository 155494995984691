import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import {
    Typography, Paper, Button, Grid, Checkbox, FormControlLabel,
} from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MissingFields from './MissingFields';
import Summary from './Summary';
import Select from '../../../../General/Select';

const styles = {
    title: {
        fontSize: '30px',
    },
    header: {
        fontSize: '25px',
    },
    body: {
        fontSize: '13px',
    },
    content: {
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '64px',
        marginBottom: '250px',
    },
    bodyText: {
        fontSize: '13px',
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontSize: 13,
    },
    yellowBackground: {
        backgroundColor: '#FFFFE0',
        marginTop: '10px',
        borderStyle: 'solid',
        borderWidth: 'thin',
        borderColor: '#EEE8AA',
        padding: '20px',
    },
    missingFieldsTitle: {
        fontWeight: 'bold',
        fontSize: '13px',
    },
    greyBackground: {
        backgroundColor: '#E8E8E8',
        marginTop: '20px',
        borderStyle: 'solid',
        borderWidth: 'thin',
        borderColor: '#A9A9A9',
        padding: '20px',
    },
    summaryTitle: {
        fontSize: '20px',
    },
    summaryBody: {
        fontSize: '13px',
        fontWeight: 'bold',
    },
    summaryAnswer: {
        fontSize: '13px',
    },
    buttonContainer: {
        marginTop: '20px',
    },
    reactSelect: {
        paddingTop: '2px',
        width: '100%',
        padding: '0px',
        '& .react-select__control': {
            height: '40px',
            borderRadius: '3px',
            width: '100%',
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
    },
    campus: {
        fontSize: '13px',
        fontWeight: 'bold',
        marginTop: '10px',
    },
    cohort: {
        fontSize: '13px',
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    // Link back to the tab to fix up any missing fields
    missingFieldLink: {
        display: 'block',
        marginLeft: 14,
        fontStyle: 'italic',
    },
};

const requiredTextFields = [
    'first_name', 'last_name', 'phone', 'address', 'city', 'state', 'zip_code',
    'employment_status', 'education', 'legal_status', 'goal',
];
const validateTextInput = (value) => {
    if (!value || value === '') {
        return false;
    }
    return true;
};

function fieldToString(field) {
    let result = field.charAt(0).toUpperCase() + field.slice(1);
    result = result.replace('_', ' ');
    return result;
}

const ReviewTab = ({
    value,
    index,
    classes,
    profileInformation,
    logicInformation,
    generalInformation,
    technicalInformation,
    courseId,
    nextTab,
    dispatch,
    activeCohorts,
    unsavedChanges,
    paymentOptions,
    programType,
}) => {
    const [errors, setErrors] = useState([]);
    const [legalNotice, setLegalNotice] = useState(false);
    const [paymentOption, setPaymentOption] = useState('');
    const [paymentError, setPaymentError] = useState('');
    const [noticeError, setNoticeError] = useState('');

    const validateQuestions = (questions) => {
        // if a question is unanswered, return true
        for (let i = 0; i < questions.length; i += 1) {
            if (!questions[i].answer) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        // Store validation error messages,
        // and which step they need to go back to to fix it up
        // eg { message: 'Phone number can't be blank', stepLink: './general', stepName: 'General' }
        const foundErrors = [];

        // Validate text fields not blank
        requiredTextFields.forEach((field) => {
            if (!validateTextInput(generalInformation[field])) {
                foundErrors.push({
                    message: `${fieldToString(field)} can't be blank.`,
                    // These text fields are all from the general tab
                    stepLink: './general',
                    stepName: 'General',
                });
            }
        });

        // if (technicalInformation.course_id === 1) {
        //     // Validate resume link (FSE only)
        //     if (!validateTextInput(technicalInformation.resume_link)) {
        //         foundErrors.push({
        //             message: 'Resume link can\'t be blank.',
        //             stepLink: './technical',
        //             stepName: 'Technical',
        //         });
        //     }
        //     if (!validateTextInput(technicalInformation.resume_explanation)) {
        //         foundErrors.push({
        //             message: 'Technical Challenge explanation can\'t be blank.',
        //             stepLink: './technical',
        //             stepName: 'Technical',
        //         });
        //     }
        // } else if (technicalInformation.course_id === 2) {
        //     // Validate creative challenge (UX only)
        //     if (!validateTextInput(technicalInformation.resume_link)) {
        //         foundErrors.push({
        //             message: 'Creative Challenge link can\'t be blank.',
        //             stepLink: './technical',
        //             stepName: 'Creative',
        //         });
        //     }
        //     if (!validateTextInput(technicalInformation.resume_explanation)) {
        //         foundErrors.push({
        //             message: 'Creative Challenge explanation can\'t be blank.',
        //             stepLink: './technical',
        //             stepName: 'Creative',
        //         });
        //     }
        // }

        // Validate logic questions all answered
        // const logicQuestionMissing = validateQuestions(logicInformation);
        // if (logicQuestionMissing) {
        //     const error = courseId === 1
        //         ? {
        //             message: 'Logic questions must all be answered.',
        //             stepLink: './logic', stepName: 'Logic',
        //         } : {
        //             message: 'Reasoning questions must all be answered.',
        //             stepLink: './logic', stepName: 'Reasoning',
        //     };
        //     foundErrors.push(error);
        // }

        // Validate profile questions all answered
        const profileQuestionMissing = validateQuestions(profileInformation);
        if (profileQuestionMissing) {
            foundErrors.push({
                message: 'Personal questions must all be answered.',
                stepLink: './personal',
                stepName: 'Personal',
            });
        }

        // Validate cohort is selected
        const selectedCohorts = activeCohorts.filter(cohort => cohort.selected);
        if (selectedCohorts.length === 0) {
            foundErrors.push({
                message: 'Graduation timing preference must be selected.',
                stepLink: './general',
                stepName: 'General',
            });
        }

        setErrors(foundErrors);
    }, [generalInformation,
        profileInformation,
        logicInformation,
        technicalInformation,
        courseId,
        activeCohorts,
    ]);

    const sendToServer = () => {
        // Collect any unsaved changes
        let updatedApplicant;
        if (unsavedChanges.general) {
            updatedApplicant = Object.assign({}, generalInformation);

            // Convert empty strings to null values
            // eslint-disable-next-line no-restricted-syntax
            for (const property in updatedApplicant) {
                if (Object.prototype.hasOwnProperty.call(updatedApplicant, property)) {
                    if (updatedApplicant[property] === '') {
                        updatedApplicant[property] = null;
                    }
                }
            }
            updatedApplicant.cohorts = activeCohorts;
        }
        let logicAnswers;
        if (unsavedChanges.logic) {
            logicAnswers = logicInformation;
        }
        let profileAnswers;
        if (unsavedChanges.profile) {
            profileAnswers = profileInformation;
        }
        let updatedApplication;
        if (unsavedChanges.technical) {
            updatedApplication = technicalInformation;
        }
        // Submit the application along with any unsaved changes.
        dispatch({
            type: 'PUT_SUBMIT_APPLICATION',
            payload: {
                courseId,
                nextTab,
                changes: {
                    paymentOption,
                    updatedApplicant,
                    logicAnswers,
                    profileAnswers,
                    updatedApplication,
                },
            },
        });
        dispatch({ type: 'UNSET_UNSAVED_GENERAL_CHANGE', payload: false });
        dispatch({ type: 'UNSET_UNSAVED_LOGIC_CHANGE', payload: false });
    };

    const submitApplication = (event) => {
        event.preventDefault();
        if (paymentOption === '') {
            setPaymentError('Payment type is required.');
        }
        if (legalNotice === false) {
            setNoticeError('This field is required.');
        }
        if (errors.length > 0) {
            dispatch({
                type: 'SET_GLOBAL_MODAL',
                payload: {
                    modalOpen: true,
                    title: 'Missing Fields',
                    body: 'Please complete all required fields before submitting your application.',
                },
            });
        } else if (programType === 'ux-academy') {
            // UX Academy does not require payment
            sendToServer();
        } else if (paymentOption === '' || legalNotice === false) {
            dispatch({
                type: 'SET_GLOBAL_MODAL',
                payload: {
                    modalOpen: true,
                    title: 'Missing Fields',
                    body: 'Please complete all required fields before submitting your application.',
                },
            });
        } else {
            sendToServer();
        }
    };

    return (
        <>
            {value === index && (
                <Paper className={classes.content}>
                    <Typography variant="h2">Review</Typography>
                    <Typography>
                        Please review your application before submitting.
                    </Typography>
                    <MissingFields
                        classes={classes}
                        errors={errors}
                    />
                    <Summary
                        classes={classes}
                        profileInformation={profileInformation}
                        logicInformation={logicInformation}
                        generalInformation={generalInformation}
                        technicalInformation={technicalInformation}
                        validateQuestions={validateQuestions}
                        courseId={courseId}
                        activeCohorts={activeCohorts}
                        programType={programType}
                    />
                    <br />
                    <form onSubmit={submitApplication}>
                        {
                            programType === 'ux-academy' ? (
                                <></>
                            ) : (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography className={classes.title}>
                                            Tuition Expectations
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <strong>How do you intend to pay for Prime? *</strong>
                                        <br />
                                        <Select
                                            required
                                            value={paymentOption}
                                            onChange={(event) => {
                                                setPaymentOption(event);
                                                setPaymentError('');
                                            }}
                                            options={paymentOptions}
                                            classNamePrefix="react-select"
                                            className={classes.reactSelect}
                                        />
                                        <br />
                                        Your payment plan will not affect our admissions decision.
                                        <br />
                                        {
                                            paymentError && paymentError !== '' && (
                                                <Typography className={classes.errorText}>
                                                    {paymentError}
                                                </Typography>
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={(
                                                <Checkbox
                                                    required
                                                    checked={legalNotice}
                                                    value="legalNotice"
                                                    onChange={(event) => {
                                                        setLegalNotice(event.target.checked);
                                                        setNoticeError('');
                                                    }}
                                                    color="primary"
                                                />
                                            )}
                                            label="If accepted, I know I will be required to provide a $1,500 deposit to secure my spot."
                                        />
                                        <br />
                                        {
                                            noticeError && noticeError !== '' && (
                                                <Typography className={classes.errorText}>
                                                    {noticeError}
                                                </Typography>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            )
                        }
                        <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.submitButton}
                                type="submit"
                            >
                                Submit Your Application
                            </Button>
                        </Grid>
                    </form>
                </Paper>
            )}
        </>
    );
};

ReviewTab.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    generalInformation: PropTypes.instanceOf(Object).isRequired,
    profileInformation: PropTypes.instanceOf(Array).isRequired,
    logicInformation: PropTypes.instanceOf(Array).isRequired,
    technicalInformation: PropTypes.instanceOf(Object).isRequired,
    nextTab: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    activeCohorts: PropTypes.instanceOf(Array).isRequired,
    unsavedChanges: PropTypes.instanceOf(Object).isRequired,
    paymentOptions: PropTypes.instanceOf(Array).isRequired,
    programType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    profileInformation: state.applicant.editingProfileInformation,
    logicInformation: state.applicant.editingLogicInformation,
    generalInformation: state.applicant.editingGeneralInformation,
    technicalInformation: state.applicant.editingTechnicalInformation,
    courseId: state.applicant.editingCourseId,
    activeCohorts: state.applicant.activeCohorts,
    paymentOptions: state.applicant.paymentOptions,
});

export default connect(mapStateToProps)(withStyles(styles)(ReviewTab));
