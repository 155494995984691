/* eslint-disable no-console */
import axios from 'axios';
import {
    put, all, takeLatest, select,
} from 'redux-saga/effects';
import { checkIfUrl } from '../../helpers/UrlHelper';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* fetchStudentList(action) {
    try {
        const response = yield axios.get('api/students/list', { params: action.payload });
        yield put({ type: 'SET_STUDENT_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student list - get request failed', error);
    }
}

function* fetchStudentStatList(action) {
    try {
        const response = yield axios.get('api/students/list/stats', { params: action.payload });
        yield put({ type: 'SET_STUDENT_STAT_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student list - get request failed', error);
    }
}

function* fetchStudentDropdownList(action) {
    try {
        const response = yield axios.get('api/students/list/all', { params: action.payload });
        yield put({ type: 'SET_STUDENT_DROPDOWN_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student list - get request failed', error);
    }
}

function* fetchStudentCohort(action) {
    try {
        const response = yield axios.get(`api/students/cohort/${action.payload.studentId}`);
        // Get cohort details for the selected student
        if (response.data.assigned_cohort_id) {
            yield put({ type: 'FETCH_COHORT_DETAILS', payload: { id: response.data.assigned_cohort_id } });
        }
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student cohort - get request failed', error);
    }
}

function* fetchStudentSummary(action) {
    console.log('in fetchStudentSummary with ', action.payload);
    try {
        const response = yield axios.get(`api/students/summary/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT', payload: response.data });
        yield put({ type: 'FETCH_STUDENT_NOTES', payload: action.payload });
        yield put({ type: 'FETCH_HUBSPOT_STUDENT_NOTES', payload: action.payload.studentId });
        yield put({ type: 'FETCH_STUDENT_TAGS', payload: action.payload });
        yield put({ type: 'FETCH_STUDENT_COHORT_HISTORY', payload: action.payload });

        if (response.data.cohortId) {
            yield put({ type: 'FETCH_STUDENT_COHORT_INFO', payload: action.payload });
        }

        if (response.data.placement_count && response.data.placement_count !== '0') {
            yield put({ type: 'FETCH_STUDENT_PLACEMENTS', payload: action.payload });
        } else {
            yield put({ type: 'UNSET_STUDENT_PLACEMENTS' });
        }
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student summary - get request failed', error);
    }
}

function* fetchStudentPlacements(action) {
    try {
        const response = yield axios.get(`api/students/placements/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_PLACEMENTS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student Placements - get request failed', error);
    }
}

function* fetchStudentCohortHistory(action) {
    try {
        const response = yield axios.get(`api/students/cohort/history/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_COHORT_HISTORY', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student Placements - get request failed', error);
    }
}

function* fetchStudentFeedback(action) {
    try {
        const response = yield axios.get(`api/students/feedback/student/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_FEEDBACK', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student feedback - get request failed', error);
    }
}

function* fetchStudentFeedbackSummary(action) {
    try {
        const response = yield axios.get(`api/students/feedback/student/summary/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_FEEDBACK_SUMMARY', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student feedback summary - get request failed', error);
    }
}

function* fetchSelectedStudentFeedback(action) {
    try {
        const response = yield axios.get('api/students/feedback/detail', { params: action.payload });
        yield put({ type: 'SET_SELECTED_DAILY_FEEDBACK', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student selected feedback - get request failed', error);
    }
}

function* fetchStudentCourseWork(action) {
    try {
        const response = yield axios.get(`api/students/work/all/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_COURSE_WORKS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student course work - get request failed', error);
    }
}

function* fetchStudentNotes(action) {
    try {
        const response = yield axios.get(`api/students/notes/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_NOTES', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student notes - get request failed', error);
    }
}

function* fetchStudentTags(action) {
    try {
        const response = yield axios.get(`api/students/status/tags/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_TAGS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student tags - get request failed', error);
    }
}

function* uploadStudentPhoto(action) {
    try {
        const { selectedFile, resizedFile, id } = action.payload;
        // The name seems to be dropped on resize, send the name from the
        // original selected file instead.
        const fileName = encodeURIComponent(selectedFile.name);
        const fileType = encodeURIComponent(resizedFile.type);
        const fileSize = encodeURIComponent(resizedFile.size);
        const formData = new FormData();
        formData.append('image', resizedFile);
        yield axios.post(`api/students/s3/${id}?name=${fileName}&type=${fileType}&size=${fileSize}`,
            formData);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student Photo Upload - post request failed', error);
    }
}

// For non-prime admission process students
function* createStudent(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const studentToAdd = action.payload;
        // check if github username input is full url, and change if so
        const result = checkIfUrl(studentToAdd.github_account);
        if (result === true) {
            const substrings = studentToAdd.github_account.split('/');
            const username = substrings[substrings.length - 1];
            studentToAdd.github_account = username;
        }
        const { data: newStudent } = yield axios.post('/api/students', studentToAdd);
        if (action.payload.selectedFile) {
            // Call a generator within a generator with yield*
            yield* uploadStudentPhoto({ payload: { ...action.payload, id: newStudent.id } });
        }
        const { searchOffset: offset, searchText } = yield select(state => state.student);
        yield put({ type: 'FETCH_STUDENT_LIST', payload: { searchText, offset } });
        // Move to page for student created
        yield delay(250);
        yield action.history.replace(`/students/${newStudent.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Create Student - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

// create student note
function* createNote(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.post('/api/students/notes', action.payload);
        yield put({ type: 'SET_EDITING_STUDENT_NOTE', payload: { display: false } });
        yield put({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: action.payload.user_id } });
        // Add delay to allow loading indicator to appear
        yield delay(250);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

// For non-prime admission process students
function* updateStudent(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const studentToAdd = action.payload;
        // check if github username input is full url, and change if so
        const urlRegex = /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
        const result = urlRegex.test(studentToAdd.github_account);
        if (result === true) {
            const substrings = studentToAdd.github_account.split('/');
            const username = substrings[substrings.length - 1];
            studentToAdd.github_account = username;
        }
        const { data: newStudent } = yield axios.put('/api/students/update', studentToAdd);
        if (action.payload.selectedFile) {
            // Call a generator within a generator with yield*
            yield* uploadStudentPhoto({ payload: { ...action.payload } });
        }
        const { searchOffset: offset, searchText } = yield select(state => state.student);
        yield put({ type: 'FETCH_STUDENT_LIST', payload: { searchText, offset } });
        // Move to page for student created
        yield delay(250);
        yield action.history.replace(`/students/${newStudent.id}`);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Create Student - post request failed', error);
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* fetchStudentForEdit(action) {
    try {
        const response = yield axios.get(`api/students/summary/${action.payload.studentId}`);
        yield put({ type: 'SET_EDITING_STUDENT', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Student to edit - get request failed', error);
    }
}

function* fetchStudentPreIncome(action) {
    try {
        const response = yield axios.get(`api/students/preincome/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_PRE_INCOME', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchAllStudentInfo(action) {
    yield all([
        put({ type: 'FETCH_STUDENT_FEEDBACK', payload: action.payload }),
        put({ type: 'FETCH_STUDENT_COURSE_WORK', payload: action.payload }),
        put({ type: 'FETCH_STUDENT_ACADEMIC_EVENTS', payload: action.payload }),
    ]);
}

function* fetchStudentAcademicEvents(action) {
    try {
        const response = yield axios.get(`api/students/academic/events/${action.payload.studentId}`);
        yield put({ type: 'SET_STUDENT_ACADEMIC_EVENT_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* upateAcademicEvent(action) {
    try {
        yield axios.put('/api/students/academic/event/update', action.payload);
        if (action.payload.studentId) {
            yield put({ type: 'FETCH_STUDENT_ACADEMIC_EVENTS', payload: action.payload });
        }
        yield put({ type: 'FETCH_EMPLOYEE_ACADEMIC_EVENTS', payload: action.payload });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
        console.log('Update Academic Event - put request failed', error);
    }
}

function* deleteNote(action) {
    try {
        // yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put(`api/students/notes/archive/${action.payload.noteId}`);
        // Add delay to allow loading indicator to appear
        yield delay(250);
        yield put({ type: 'FETCH_ALL_STUDENT_INFO', payload: { studentId: action.payload.studentId } });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

// Employee change password for student
function* changeStudentPassword(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put(`api/students/password/change/${action.payload.studentId}`, { newPassword: action.payload.newPassword });
        yield delay(250);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* userSaga() {
    yield takeLatest('FETCH_ALL_STUDENT_INFO', fetchStudentSummary);
    yield takeLatest('CREATE_STUDENT', createStudent);
    yield takeLatest('PUT_STUDENT', updateStudent);
    yield takeLatest('FETCH_STUDENT_LIST', fetchStudentList);
    yield takeLatest('FETCH_STUDENT_STAT_LIST', fetchStudentStatList);
    yield takeLatest('FETCH_STUDENT_DROPDOWN_LIST', fetchStudentDropdownList);
    yield takeLatest('FETCH_STUDENT_COHORT_INFO', fetchAllStudentInfo);
    yield takeLatest('FETCH_STUDENT_COHORT', fetchStudentCohort);
    yield takeLatest('FETCH_STUDENT_FEEDBACK', fetchStudentFeedback);
    yield takeLatest('FETCH_STUDENT_FEEDBACK_SUMMARY', fetchStudentFeedbackSummary);
    yield takeLatest('FETCH_SELECTED_STUDENT_FEEDBACK', fetchSelectedStudentFeedback);
    yield takeLatest('FETCH_STUDENT_COURSE_WORK', fetchStudentCourseWork);
    yield takeLatest('FETCH_STUDENT_NOTES', fetchStudentNotes);
    yield takeLatest('FETCH_STUDENT_TAGS', fetchStudentTags);
    yield takeLatest('FETCH_STUDENT_PLACEMENTS', fetchStudentPlacements);
    yield takeLatest('FETCH_STUDENT_COHORT_HISTORY', fetchStudentCohortHistory);
    yield takeLatest('FETCH_STUDENT_FOR_EDIT', fetchStudentForEdit);
    yield takeLatest('FETCH_STUDENT_ACADEMIC_EVENTS', fetchStudentAcademicEvents);
    yield takeLatest('PUT_ACADEMIC_EVENT', upateAcademicEvent);
    yield takeLatest('UPLOAD_STUDENT_PHOTO', uploadStudentPhoto);
    yield takeLatest('CREATE_STUDENT_NOTE', createNote);
    yield takeLatest('DELETE_STUDENT_NOTE', deleteNote);
    yield takeLatest('CHANGE_STUDENT_PASSWORD', changeStudentPassword);
    yield takeLatest('FETCH_STUDENT_PRE_INCOME', fetchStudentPreIncome);
}

export default userSaga;
