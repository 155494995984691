import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Divider, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

const styles = theme => ({
    secondaryText: {
        fontWeight: 500,
        padding: '5px 0',
    },
    container: {
        margin: '5px 0',
    },
    score: {
        maxWidth: '200px',
        margin: '10px 0',
    },
    save: {
        margin: '10px 0',
    },
    header: {
        display: 'flex',
        gap: '10px',
        padding: '10px',
    },
    bullets: {
        marginLeft: '20px',
    },

});
const InterviewNote = ({
    classes,
    applicationInterviewReview,
    questionId,

}) => {
    const [review, setReview] = useState(null);

    useEffect(() => {
        const localReview = applicationInterviewReview.get(questionId);
        if (!localReview) return;

        const date = new Date(localReview.updated_at).toLocaleDateString();
        setReview({ ...localReview, date });
    }, []);
    return (
        <div className={classes.container}>
            {review?.profile_note && review?.profile_note !== ''
            && (
                <div className={classes.wrapper}>
                    <div className={classes.header}>
                        <WarningIcon sx={{ color: 'orange' }} />
                        <Typography variant="caption">{`${review?.employee?.first_name} ${review?.employee?.last_name}`}</Typography>
                        <Typography variant="caption">—</Typography>
                        <Typography variant="caption">{review.date}</Typography>

                    </div>
                    <ul className={classes.bullets}>
                        <ReactMarkdown
                            align="left"
                            linkTarget="_blank"
                            rehypePlugins={[rehypeRaw]}
                            remarkPlugins={[remarkGfm]}
                        >
                            {review.profile_note}
                        </ReactMarkdown>
                    </ul>
                </div>
            )
            }

            <Divider />
        </div>
    );
};

InterviewNote.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    applicationInterviewReview: PropTypes.instanceOf(Map).isRequired,
    questionId: PropTypes.number.isRequired,

};
const mapStateToProps = state => ({
    applicationInterviewReview: state.application.applicationInterviewReview,
});
export default connect(mapStateToProps)(withStyles(styles)(InterviewNote));
