/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    DialogActions,
    Button,
    TextField,
    Typography,
    Grid,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PageTitle from '../../PageTitle/PageTitle';
import StateAutocomplete from '../../../helpers/StateAutocomplete';

const styles = ({
    textField: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
});

class CreateCompanyPage extends Component {
    state = {
        existing: false,
        errorText: '',
    };

    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        // dispatch({ type: 'UNSET_EDITING_COMPANY' });
        if (match.params.id) {
            this.setState({
                existing: true,
            });
            dispatch({ type: 'FETCH_COMPANY_FOR_EDIT', payload: { companyId: match.params.id } });
        }
    }

    handleCancel = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleChangeFor = propertyName => (event) => {
        const { dispatch, company } = this.props;
        let updatedCompany = '';
        if (propertyName === 'recruiter') {
            updatedCompany = {
                ...company,
                [propertyName]: !company.recruiter,
            };
        } else if (propertyName === 'active') {
            updatedCompany = {
                ...company,
                [propertyName]: !company.active,
            };
        } else {
            updatedCompany = {
                ...company,
                [propertyName]: event.target.value,
            };
        }
        this.setState({
            // changes: true,
            errorText: '',
        });
        dispatch({ type: 'SET_EDITING_COMPANY', payload: updatedCompany });
    };

    handleSubmit = () => {
        const valid = this.validateForm();
        if (!valid) {
            return;
        }
        this.sendFormDataToServer();
    };

    sendFormDataToServer = () => {
        const { dispatch, company, history } = this.props;
        const { existing } = this.state;
        if (existing) {
            dispatch({ type: 'PUT_COMPANY', payload: company, history });
        } else {
            dispatch({ type: 'CREATE_COMPANY', payload: company, history });
        }
    };

    validateForm = () => {
        const { company } = this.props;
        let valid = true;
        // Probably check others?
        if (company.name === '') {
            valid = false;
            this.setState({
                errorText: 'Company name is required.',
            });
        } else if (company.city === '') {
            valid = false;
            this.setState({
                errorText: 'Company city is required.',
            });
        } else if (company.state === '') {
            valid = false;
            this.setState({
                errorText: 'Company state is required.',
            });
        }
        return valid;
    };

    render() {
        const {
            company,
            classes,
        } = this.props;
        const {
            existing,
            errorText,
        } = this.state;
        let pageTitle = '';
        if (existing) {
            pageTitle = `Edit Company: ${company.name}`;
        } else {
            pageTitle = 'Create New Company';
        }
        return (
            <div className="container">
                <PageTitle
                    title={pageTitle}
                />
                <Paper>
                    {errorText.length > 0 && (
                        <div className={classes.errorText}>{errorText}</div>
                    )}
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>GENERAL </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="first-name"
                                    label="Company Name"
                                    className={classes.textField}
                                    value={company.name}
                                    onChange={this.handleChangeFor('name')}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="phone-number"
                                    label="Phone Number"
                                    className={classes.textField}
                                    value={company.phone}
                                    onChange={this.handleChangeFor('phone')}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="website"
                                    label="Website"
                                    className={classes.textField}
                                    value={company.website_link}
                                    onChange={this.handleChangeFor('website_link')}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="job-listings"
                                    label="Job Listings Page"
                                    className={classes.textField}
                                    value={company.jobs_link}
                                    onChange={this.handleChangeFor('jobs_link')}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={company.recruiter}
                                            onChange={this.handleChangeFor('recruiter')}
                                            name="recruiter"
                                            color="primary"
                                        />
                                    )}
                                    label="Recruiter?"
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={company.active}
                                            onChange={this.handleChangeFor('active')}
                                            name="active"
                                            color="primary"
                                        />
                                    )}
                                    label="Active?"
                                />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12}>
                                <Typography> LOCATION </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="address-1"
                                    label="Address"
                                    className={classes.textField}
                                    value={company.address1 || ''}
                                    onChange={this.handleChangeFor('address1')}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="address-2"
                                    label="Address"
                                    className={classes.textField}
                                    value={company.address2 || ''}
                                    onChange={this.handleChangeFor('address2')}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="city"
                                    label="City"
                                    className={classes.textField}
                                    value={company.city || ''}
                                    onChange={this.handleChangeFor('city')}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <StateAutocomplete
                                    value={company.state}
                                    onChange={value => this.handleChangeFor('state')({
                                        target: { value },
                                    })}
                                    variant="standard"
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="zipcode"
                                    label="Zip code"
                                    className={classes.textField}
                                    value={company.zip_code || ''}
                                    onChange={this.handleChangeFor('zip_code')}
                                    margin="normal"
                                />
                            </Grid>

                        </Grid>
                    </form>
                    <DialogActions>
                        <Button
                            onClick={this.handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                        >
                            {existing ? <span>Save</span> : <span>Create</span>}
                        </Button>
                    </DialogActions>
                </Paper>
            </div>
        );
    }
}

CreateCompanyPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    company: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
};
const mapStateToProps = state => ({
    company: state.company.editingCompany,
});
export default connect(mapStateToProps)(withStyles(styles)(CreateCompanyPage));
