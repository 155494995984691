import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    FormLabel,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TextField,
    TableBody,
    TableSortLabel,
    Hidden,
    Grid,
} from '@mui/material';
import ReactSelect from 'react-select';
import PageTitle from '../../PageTitle/PageTitle';
import ApplicationListRow from './ApplicationListRow';
import ApplicationsSummary from './ApplicationsSummary';
import { cohortOptionSelect } from '../../../helpers/CohortTextHelper';
import TablePagination from '../../../helpers/TablePagination';
import OverdueApplications from './overdueApplications';

const styles = ({
    searchInput: {
        width: '100%',
        marginTop: '20px',
    },
    selectLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    applicationSelect: {
        marginTop: '5px',
        width: '100%',
    },
    updateButton: {
        marginTop: '25px',
    },
    filterContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    },
    tableRow: {
        cursor: 'pointer',
    },
    emailColumn: {
        width: '280px',
    },
    nameColumn: {
        width: '180px',
    },
    statusColumn: {
        width: '180px',
    },
    graphTitle: {
        fontSize: '18px',
        textAlign: 'center',
        weight: '400',
    },
    chartDetails: {
        fontSize: '14px',
        color: '#5E5E5E',
        textAlign: 'left',
    },
    applicationCount: {
        textAlign: 'right',
    },
    chartCohort: {
        fontSize: '16px',
        color: '#222222',
    },
    applicationTableCount: {
        textAlign: 'center',
    },
});

class ApplicationsListPage extends Component {
    componentDidMount() {
        const {
            dispatch,
            location,
            history,
        } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_COHORT_LIST' });
        dispatch({ type: 'FETCH_APPLICATION_STATS' });
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
        const searchParams = new URLSearchParams(location.search.slice(1));
        const payload = { ...this.getPayload() };
        if (searchParams && searchParams.toString() !== '') {
            this.handleURLParameters(searchParams);
        } else {
            // If no query params, use props.
            this.handleNewSearchParam(payload);
        }
        dispatch({ type: 'UNSET_APPLICATION_DETAILS' });
        this.unlisten = history.listen((updatedLocation) => {
            this.onRouteChange(updatedLocation);
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = (updatedLocation) => {
        const {
            dispatch,
            sortOrder,
            rowsPerPage,
        } = this.props;
        if (updatedLocation.pathname === '/admissions_applications') {
            const searchParams = new URLSearchParams(updatedLocation.search.slice(1));
            // The URL changed, remove existing filters and apply only query params
            dispatch({ type: 'UNSET_APPLICATION_SEARCH_FILTERS' });
            if (searchParams && searchParams.toString() !== '') {
                this.handleURLParameters(searchParams, true);
            } else {
                // Keep sort order from props but clear everything else. This
                // code runs when the URL changes while the user is on the page.
                dispatch({
                    type: 'FETCH_APPLICATIONS',
                    payload: {
                        order: sortOrder.order,
                        orderBy: sortOrder.orderBy,
                        limit: rowsPerPage,
                    },
                });
            }
        }
    };

    handleChangePage = (_, page) => {
        const {
            rowsPerPage,
        } = this.props;
        const offset = Math.max(page * rowsPerPage, 0);
        const newParams = {
            offset,
        };
        this.handleNewSearchParam(newParams);
    };

    handleChangeRowsPerPage = (nextRowsPerPage) => {
        const newParams = {
            limit: nextRowsPerPage,
        };
        this.handleNewSearchParam(newParams);
    };

    handleSearchChange = (event) => {
        const { dispatch } = this.props;
        const searchText = event.target.value;
        this.handleNewSearchParam({ searchText });
        dispatch({ type: 'SET_APPLICATION_SEARCH_TEXT', payload: { searchText } });
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
            this.runSearch(searchText);
        }, 250);
    };

    runSearch = (searchText) => {
        const {
            dispatch,
        } = this.props;
        const payload = {
            ...this.getPayload(),
            searchText,
        };
        dispatch({ type: 'SET_APPLICATION_OFFSET', payload: { offset: 0 } });
        dispatch({ type: 'FETCH_APPLICATIONS', payload });
        this.searchTimeout = null;
    };

    handleNewSearchParam = (params) => {
        const { history, location } = this.props;
        const searchParams = new URLSearchParams(location.search);
        for (const key in params) {
            if (!params[key] || params[key] === 'All Cohorts' || params[key] === 'All+Cohorts' || params[key] === 'All') {
                searchParams.delete(key);
            } else {
                searchParams.set(key, params[key]);
            }
        }
        if (this.historyTimeout) {
            clearTimeout(this.historyTimeout);
        }
        this.historyTimeout = setTimeout(() => {
            history.push(`?${searchParams}`);
        }, 250);
    };

    handleURLParameters = (searchParams, clearPayload = false) => {
        const { dispatch } = this.props;
        let payload = { sortOrder: {} };
        if (!clearPayload) {
            payload = { ...this.getPayload() };
        }
        for (const param of searchParams.entries()) {
            const [key, value] = param;
            switch (key) {
            case 'searchText':
                dispatch({ type: 'SET_APPLICATION_SEARCH_TEXT', payload: { searchText: value } });
                payload.searchText = value;
                break;
            case 'cohortId':
                dispatch({ type: 'SET_APPLICATION_COHORT_SEARCH_TEXT', payload: value });
                payload = {
                    ...payload,
                    cohortId: value,
                };
                break;
            case 'cohortAppliedId':
                dispatch({ type: 'SET_APPLICATION_COHORT_APPLIED', payload: value });
                payload = {
                    ...payload,
                    cohortAppliedId: value,
                };
                break;
            case 'selectedStatus':
                dispatch({ type: 'SET_APPLICATION_STATUS_SEARCH_TEXT', payload: value });
                payload = {
                    ...payload,
                    selectedStatus: value,
                };
                break;
            case 'offset':
                dispatch({ type: 'SET_APPLICATION_OFFSET', payload: { offset: value } });
                payload = {
                    ...payload,
                    offset: parseInt(value, 10),
                };
                break;
            case 'order':
            case 'orderBy':
                payload[key] = value;
                dispatch({ type: 'SET_APPLICATION_SORT_ORDER', payload });
                break;
            case 'limit':
                payload = {
                    ...payload,
                    limit: parseInt(value, 10),
                };
                break;
            default:
                break;
            }
        }
        dispatch({ type: 'FETCH_APPLICATIONS', payload });
    };

    getPayload = () => {
        const {
            sortOrder,
            searchText,
            cohortId,
            selectedStatus,
            searchOffset,
            cohortAppliedId,
            rowsPerPage,
        } = this.props;
        const payload = {
            order: sortOrder.order,
            orderBy: sortOrder.orderBy,
            searchText,
            cohortId,
            cohortAppliedId,
            selectedStatus,
            offset: searchOffset,
            limit: rowsPerPage,
        };
        return payload;
    };

    sortBy = orderBy => () => {
        const {
            dispatch,
            sortOrder,
            searchOffset,
        } = this.props;
        let { order } = sortOrder;

        // Existing column selected, reverse the sort
        if (orderBy === sortOrder.orderBy) {
            if (order === 'asc') {
                order = 'desc';
            } else {
                order = 'asc';
            }
        }
        const payload = {
            ...this.getPayload(),
            orderBy,
            order,
            offset: searchOffset,
        };
        const newParams = {
            orderBy,
            order,
        };
        this.handleNewSearchParam(newParams);
        dispatch({ type: 'SET_APPLICATION_SORT_ORDER', payload });
        dispatch({ type: 'FETCH_APPLICATIONS', payload });
    };

    handleCohortSelect = (selectedOption) => {
        // const { dispatch } = this.props;
        // dispatch({ type: 'SET_APPLICATION_COHORT_SEARCH_TEXT', payload: selectedOption.value });
        const newParams = {
            cohortId: selectedOption.value,
            offset: 0,
        };
        this.handleNewSearchParam(newParams);
    };

    handleCohortAppliedSelect = (selectedOption) => {
        // const { dispatch } = this.props;
        // dispatch({ type: 'SET_APPLICATION_COHORT_APPLIED', payload: selectedOption.value });
        const newParams = {
            cohortAppliedId: selectedOption.value,
            offset: 0,
        };
        this.handleNewSearchParam(newParams);
    };

    handleStatusSelect = (selectedOption) => {
        // const { dispatch } = this.props;
        // dispatch({ type: 'SET_APPLICATION_STATUS_SEARCH_TEXT', payload: selectedOption });
        const newParams = { selectedStatus: selectedOption.value, offset: 0 };
        this.handleNewSearchParam(newParams);
    };

    viewApplicationDetails = (selectedApplication) => {
        const { history } = this.props;
        history.push(`/admissions_applications/${selectedApplication.application_id}`);
    };

    render() {
        const {
            classes,
            applications,
            searchOffset = 0,
            rowsPerPage,
            sortOrder,
            cohorts,
            searchText,
            statuses,
            cohortId,
            selectedStatus,
            cohortAppliedId,
        } = this.props;
        const page = Math.floor(searchOffset / rowsPerPage);
        // react-select requires value to be in the format { value: '', label: ''}
        // Using the id of the cohort, we can construct an object in the required format.
        const matchedCohorts = cohorts.filter(cohort => `${cohort.id}` === `${cohortId}`);
        let selectedCohortOption;
        if (matchedCohorts.length > 0) {
            [selectedCohortOption] = matchedCohorts;
            selectedCohortOption = {
                value: selectedCohortOption.id,
                label: selectedCohortOption.cohort_name,
            };
        }
        const matchedAppliedCohorts = cohorts.filter(cohort => `${cohort.id}` === `${cohortAppliedId}`);
        let selectedCohortAppliedOption;
        if (matchedAppliedCohorts.length > 0) {
            [selectedCohortAppliedOption] = matchedAppliedCohorts;
            selectedCohortAppliedOption = {
                value: selectedCohortAppliedOption.id,
                label: selectedCohortAppliedOption.cohort_name,
            };
        }
        let matchedStatuses = statuses.filter(status => `${status.value}` === `${selectedStatus}`);
        if (matchedStatuses.length === 0 && statuses[statuses.length - 1].options) {
            matchedStatuses = statuses[statuses.length - 1].options.filter(status => `${status.value}` === `${selectedStatus}`);
        }
        let selectedStatusOption;
        if (matchedStatuses.length > 0) {
            [selectedStatusOption] = matchedStatuses;
        }
        const cohortOptions = cohortOptionSelect(cohorts);
        cohortOptions.unshift({ value: '', label: 'All Cohorts' });

        const filerApplications = () => {
            // integer here represents millis for 3 days
            const threeDaysAgo = new Date().getTime() - 259200000;
            // integer represents six months in milliseconds
            const sixMonthsAgo = new Date().getTime() - 15778800000;

            if (!applications.rows) return { count: 0, rows: [] };

            const apps = applications.rows.filter((app) => {
                const completedAt = new Date(app.completed_at).getTime();

                if (completedAt < threeDaysAgo && completedAt > sixMonthsAgo) {
                    // checking if application has been fully scored
                    if ((app.profile_questions_completed_at === null)
                        || (app.logic_questions_completed_at === null)
                        || (app.technical_challenge_completed_at === null)) {
                        return app;
                    }
                }
                return null;
            });
            return { count: apps.length, rows: apps };
        };

        const overdueApps = filerApplications();

        return (
            <div className="container">
                <PageTitle
                    title="Applications"
                />
                <ApplicationsSummary />
                {overdueApps.count > 0
                    && <OverdueApplications overdueApplications={filerApplications()} />
                }
                <Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <TextField
                                className={classes.searchInput}
                                label="Search"
                                margin="dense"
                                variant="outlined"
                                value={searchText}
                                onChange={this.handleSearchChange}
                                helperText="Supported keywords: ux, dev, status and name."
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <FormLabel component="label" className={classes.selectLabel}>Cohort Assigned To</FormLabel>
                            <ReactSelect
                                placeholder="Cohort Assigned To"
                                value={selectedCohortOption}
                                onChange={this.handleCohortSelect}
                                options={cohortOptions}
                                className={classes.applicationSelect}
                                classNamePrefix="react-select"
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={3}>
                            <FormLabel component="label" className={classes.selectLabel}>Status</FormLabel>
                            <ReactSelect
                                placeholder="Status"
                                value={selectedStatusOption}
                                onChange={this.handleStatusSelect}
                                options={statuses}
                                className={classes.applicationSelect}
                                classNamePrefix="react-select"
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={3}>
                            <FormLabel component="label" className={classes.selectLabel}>Cohort Applied To</FormLabel>
                            <ReactSelect
                                placeholder="Cohort Applied To"
                                value={selectedCohortAppliedOption}
                                onChange={this.handleCohortAppliedSelect}
                                options={cohortOptions}
                                className={classes.applicationSelect}
                                classNamePrefix="react-select"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'first_name'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('first_name')}
                                    >
                                        Applicant
                                    </TableSortLabel>
                                </TableCell>
                                <Hidden mdDown>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortOrder.orderBy === 'email'}
                                            direction={sortOrder.order}
                                            onClick={this.sortBy('email')}
                                        >
                                            Email
                                        </TableSortLabel>
                                    </TableCell>
                                </Hidden>
                                <Hidden lgDown>
                                    <TableCell>
                                        Logic
                                    </TableCell>
                                    <TableCell>
                                        Profile
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortOrder.orderBy === 'resume_score'}
                                            direction={sortOrder.order}
                                            onClick={this.sortBy('resume_score')}
                                        >
                                            Resume
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortOrder.orderBy === 'interview_score'}
                                            direction={sortOrder.order}
                                            onClick={this.sortBy('interview_score')}
                                        >
                                            Interview
                                        </TableSortLabel>
                                    </TableCell>
                                </Hidden>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'application_status'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('application_status')}
                                    >
                                        Status
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    Assigned Cohort
                                </TableCell>
                                <Hidden lgDown>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortOrder.orderBy === 'created_at'}
                                            direction={sortOrder.order}
                                            onClick={this.sortBy('created_at')}
                                        >
                                            Created
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortOrder.orderBy === 'updated_at'}
                                            direction={sortOrder.order}
                                            onClick={this.sortBy('updated_at')}
                                        >
                                            Updated
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortOrder.orderBy === 'completed_at'}
                                            direction={sortOrder.order}
                                            onClick={this.sortBy('completed_at')}
                                        >
                                            Completed
                                        </TableSortLabel>
                                    </TableCell>
                                </Hidden>
                                <Hidden xlDown>
                                    <TableCell align="right">
                                        Action
                                    </TableCell>
                                </Hidden>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                applications.rows
                                && applications.rows.map(application => (
                                    <ApplicationListRow
                                        key={application.application_id}
                                        applicationInfo={application}
                                        classes={classes}
                                        viewApplicationDetails={this.viewApplicationDetails}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                    {
                        applications.count > 0
                        && (
                            <TablePagination
                                count={applications.count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        )
                    }

                </Paper>

            </div>
        );
    }
}

ApplicationsListPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    applications: PropTypes.instanceOf(Object).isRequired,
    searchOffset: PropTypes.number.isRequired,
    sortOrder: PropTypes.instanceOf(Object).isRequired,
    searchText: PropTypes.string.isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
    }).isRequired,
    statuses: PropTypes.instanceOf(Object).isRequired,
    cohorts: PropTypes.instanceOf(Array).isRequired,
    cohortId: PropTypes.string.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    cohortAppliedId: PropTypes.string.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
    applications: state.application.applicationList,
    applicationStats: state.application.applicationStatsList,
    sortOrder: state.application.sortOrder,
    searchOffset: state.application.searchOffset,
    rowsPerPage: state.settings.rowsPerPage,
    searchText: state.application.searchText,
    statuses: state.application.statuses,
    cohorts: state.cohort.cohortList,
    cohortId: state.application.selectedCohort,
    selectedStatus: state.application.selectedStatus,
    cohortAppliedId: state.application.selectedCohortApplied,
});
export default connect(mapStateToProps)(withStyles(styles)(ApplicationsListPage));
