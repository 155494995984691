import React, { useState } from 'react';
import {
    Paper,
    Grid,
    Divider,
    Typography,
    Button,
    Link,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ReactSelect from 'react-select';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CodeBlock from '../../../General/CodeBlock';
import styles from '../../../../helpers/TabStylesHelper';
import NoteContainer from '../../../General/Notes/NoteContainer';

const TechnicalTab = ({
    value,
    index,
    application,
    applicantNotes,
    handleScoreSubmission,
    handleScoreChange,
    technicalScores,
    addNote,
    handleNoteChange,
    editingNote,
    deleteNote,
    editingTechnicalScore,
}) => {
    const [selectedTab, setSelectedTab] = useState('write');
    const dispatch = useDispatch();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Grid container direction="row">
                    <Grid item xs={12} sm={6}>
                        <Paper>
                            <Typography variant="h5">
                                {`${application.course_id === 2 ? 'Creative' : 'Technical Challenges'}`}
                            </Typography>
                            <Divider />
                            {
                                application.resume_link ? (
                                    <>
                                        <Typography variant="h6">
                                            Challenge Link
                                        </Typography>
                                        <Typography>
                                            <Link href={application.resume_link}>
                                                {application.resume_link}
                                            </Link>
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="h6">
                                            Challenge Link
                                        </Typography>
                                        <Typography>
                                            Not Provided
                                        </Typography>
                                    </>
                                )}
                            {
                                application.resume_explanation ? (
                                    <>
                                        <Typography variant="h6">
                                            Challenge Explanation
                                        </Typography>
                                        <Typography>
                                            {application.resume_explanation}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <Typography variant="h6">
                                            Challenge Explanation
                                        </Typography>
                                        <Typography>
                                            Not Provided
                                        </Typography>
                                    </>
                                )}
                            <Typography variant="h6"> Challenge Score </Typography>
                            <ReactSelect
                                placeholder="Challenge Score.."
                                value={editingTechnicalScore}
                                onChange={event => handleScoreChange(event, 'technical')}
                                options={technicalScores}
                                styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: provided => ({ ...provided, zIndex: 9999 }),
                                }}
                            />
                            <br />
                            <Typography variant="h6"> Notes </Typography>
                            <ReactMde
                                value={editingNote.content}
                                onChange={event => handleNoteChange(event, 'SET_EDITING_TECHNICAL_NOTE')}
                                selectedTab={selectedTab}
                                onTabChange={setSelectedTab}
                                minEditorHeight={120}
                                minPreviewHeight={120}
                                textAreaProps={{ placeholder: 'Add a note...' }}
                                generateMarkdownPreview={markdown => (
                                    Promise.resolve(
                                        <ReactMarkdown
                                            components={{ code: CodeBlock }}
                                            remarkPlugins={[remarkGfm]}
                                        >
                                            {markdown}
                                        </ReactMarkdown>,
                                    )
                                )}
                            />
                            <br />
                            <br />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (editingNote.content && editingNote.content.length > 0 && editingTechnicalScore.value !== '') {
                                        handleScoreSubmission('technical', editingTechnicalScore.value);
                                        addNote('CREATE_TECHNICAL_NOTE');
                                    } else {
                                        dispatch({
                                            type: 'SET_GLOBAL_MODAL',
                                            payload: {
                                                modalOpen: true,
                                                title: 'Missing Fields',
                                                body: 'Score and note are required.',
                                            },
                                        });
                                    }
                                }}
                            >
                                Update Scores
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NoteContainer
                            buttonText="Add Technical Note"
                            textfieldText="Add a note..."
                            addNote={addNote}
                            onChange={handleNoteChange}
                            editAction="SET_EDITING_TECHNICAL_NOTE"
                            submitAction="CREATE_TECHNICAL_NOTE"
                            editingNote={editingNote}
                            notesToDisplay={applicantNotes}
                            deleteNote={deleteNote}
                            displayInput={false}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

TechnicalTab.propTypes = {
    application: PropTypes.instanceOf(Object).isRequired,
    applicantNotes: PropTypes.instanceOf(Object).isRequired,
    technicalScores: PropTypes.instanceOf(Array).isRequired,
    editingTechnicalScore: PropTypes.instanceOf(Object).isRequired,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    handleScoreSubmission: PropTypes.func.isRequired,
    handleScoreChange: PropTypes.func.isRequired,
    handleNoteChange: PropTypes.func.isRequired,
    addNote: PropTypes.func.isRequired,
    editingNote: PropTypes.instanceOf(Object).isRequired,
    deleteNote: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    applicantNotes: state.application.applicationTechnicalNotes,
    technicalScores: state.application.scores,
    editingTechnicalScore: state.application.editingTechnicalScore,
});

export default connect(mapStateToProps)(withStyles(styles)(TechnicalTab));
