import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ListItem,
    Icon,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import makeSideNavStyles from './makeSideNavStyles';

const useStyles = makeSideNavStyles();

function SideNavItem({
    to,
    label,
    iconClass,
    allowedRoles,
    external,
}) {
    const dispatch = useDispatch();

    const classes = useStyles();

    const user = useSelector(store => store.user);

    const closeDrawer = () => {
        dispatch({
            type: 'SET_DRAWER_STATE',
            payload: { drawerOpen: false },
        });
    };

    if (allowedRoles && !allowedRoles.includes(user.role)) {
        return null;
    }

    // Use a <a> tag for external links,
    // and a <Link> tag for internal links
    const LinkComponent = external
        // eslint-disable-next-line react/prop-types
        ? ({ children }) => (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={to} onClick={closeDrawer} target="_blank" rel="noreferrer">
                {children}
            </a>
        )
        // eslint-disable-next-line react/prop-types
        : ({ children }) => (
            <Link to={to} onClick={closeDrawer}>
                {children}
            </Link>
        );

    return (
        <LinkComponent>
            <ListItem button dense>
                <ListItemIcon>
                    <Icon
                        className={classNames(classes.icon, iconClass)}
                    />
                </ListItemIcon>
                <ListItemText className={classes.navText} primary={label} />
            </ListItem>
        </LinkComponent>
    );
}

SideNavItem.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    iconClass: PropTypes.string,
    allowedRoles: PropTypes.arrayOf(PropTypes.string),
    external: PropTypes.bool,
};

SideNavItem.defaultProps = {
    allowedRoles: null,
    external: false,
    iconClass: '',
};

export default SideNavItem;
