import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiTablePagination from '@mui/material/TablePagination';
import { useDispatch } from 'react-redux';

function findClosest(numbers, target) {
    return numbers.reduce((closest, n) => {
        const thisDiff = Math.floor(Math.abs(n - target));
        const closestDiff = Math.floor(Math.abs(n - closest));

        return thisDiff < closestDiff
            ? n : closest;
    });
}

function TablePagination({
    count,
    rowsPerPage,
    rowsPerPageOptions,
    page,
    onPageChange,
    onRowsPerPageChange,
}) {
    const dispatch = useDispatch();

    const handleChangeRowsPerPage = useCallback((evt) => {
        // Change global setting
        dispatch({
            type: 'SET_ROWS_PER_PAGE',
            payload: evt.target.value,
        });

        onRowsPerPageChange(evt.target.value);
    }, [onRowsPerPageChange, dispatch]);

    // Make sure current rowsPerPage value
    // is one of the options in rowsPerPageOptions
    useEffect(() => {
        if (!rowsPerPageOptions.includes(rowsPerPage)) {
            const closestOption = findClosest(rowsPerPageOptions, rowsPerPage);
            handleChangeRowsPerPage({
                target: { value: closestOption },
            });
        }
    }, [rowsPerPageOptions, rowsPerPage, handleChangeRowsPerPage]);

    return (
        <MuiTablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
                'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
                'aria-label': 'Next Page',
            }}
            onPageChange={onPageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
}

TablePagination.defaultProps = {
    rowsPerPageOptions: [5, 25, 50, 100, 200],
    count: 0,
};

TablePagination.propTypes = {
    count: PropTypes.number,
    rowsPerPage: PropTypes.number.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
};

export default TablePagination;
