import React, { Component } from 'react';
import {
    Paper,
    DialogActions,
    Button,
    TextField,
    Grid,
} from '@mui/material';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { withStyles } from '@mui/styles';
import PageTitle from '../../PageTitle/PageTitle';
import ConfirmationModal from '../../Modal/ConfirmationModal';

const styles = theme => ({
    textField: {
        width: '100%',
    },
    errorText: {
        color: 'red',
        textAlign: 'right',
    },
    /**
    * React doesn't provide an auto complete input field. Styling this third
    * party component takes a bit more work that standard Material UI components.
    * https://react-select.com/styles#using-classnames
    */
    select: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '0px',
        },
        '& .react-select__control': {
            height: '39px',
            borderRadius: '3px',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        '& .react-select__option': {
            padding: 10,
            fontSize: '16px',
            cursor: 'pointer',
        },
        '& .react-select__input': {
            fontSize: '16px',
        },
        '& .react-select__placeholder': {
            fontSize: '16px',
        },
        '& .react-select__single-value': {
            fontSize: '16px',
        },
        '& .react-select__multi-value__label': {
            fontSize: '16px',
        },
        '& .react-select__multi-value__remove': {
            cursor: 'pointer',
        },
        '& .react-select__menu': {
            zIndex: '1000',
        },
    },
});

class CreatePlacementPage extends Component {
    state = {
        existing: false,
        errorText: '',
        changes: false,
        confirmationModalOpen: false,
        placementId: '',
    };

    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch({ type: 'FETCH_USER' });
        dispatch({ type: 'FETCH_COMPANY_DROPDOWN_LIST' });
        dispatch({ type: 'FETCH_RECRUITER_DROPDOWN_LIST' });
        dispatch({ type: 'FETCH_STUDENT_DROPDOWN_LIST' });
        // Makes the back button visible on this page
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: true } });
        if (match.params.id) {
            this.setState({
                existing: true,
            });
            dispatch({ type: 'FETCH_PLACEMENT_FOR_EDIT', payload: { placementId: match.params.id } });
        }
    }

    handleCancel = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleCompanySelect = (selectedValue) => {
        const { dispatch, placement } = this.props;
        const updatedPlacement = {
            ...placement,
            companyId: selectedValue.value,
            selectedCompany: selectedValue,

        };
        this.setState({
            changes: true,
            errorText: '',
        });
        dispatch({ type: 'SET_EDITING_PLACEMENT', payload: updatedPlacement });
    };

    handleRecruiterSelect = (selectedValue) => {
        const { dispatch, placement } = this.props;
        const updatedPlacement = {
            ...placement,
            recruiterId: selectedValue.value,
            selectedRecruiter: selectedValue,
        };
        this.setState({
            changes: true,
            errorText: '',
        });
        dispatch({ type: 'SET_EDITING_PLACEMENT', payload: updatedPlacement });
    };

    handleTypeSelect = (selectedValue) => {
        const { dispatch, placement } = this.props;
        const updatedPlacement = {
            ...placement,
            placement_type: selectedValue.value,
            selectedType: selectedValue,
        };
        this.setState({
            changes: true,
            errorText: '',
        });
        dispatch({ type: 'SET_EDITING_PLACEMENT', payload: updatedPlacement });
    };

    handlePlaceSelect = (selectedValue) => {
        const { dispatch, placement } = this.props;
        const updatedPlacement = {
            ...placement,
            place_of_work: selectedValue.value,
            selectedPlace: selectedValue,
        };
        this.setState({
            changes: true,
            errorText: '',
        });
        dispatch({ type: 'SET_EDITING_PLACEMENT', payload: updatedPlacement });
    };

    handleStudentSelect = (selectedValue) => {
        const { dispatch, placement } = this.props;
        const updatedPlacement = {
            ...placement,
            studentId: selectedValue.value,
            selectedUser: selectedValue,
        };
        this.setState({
            changes: true,
            errorText: '',
        });
        dispatch({ type: 'SET_EDITING_PLACEMENT', payload: updatedPlacement });
        dispatch({ type: 'FETCH_STUDENT_PRE_INCOME', payload: { studentId: selectedValue.value } });
    };

    handleChangeFor = propertyName => (event) => {
        const { dispatch, placement } = this.props;
        const updatedPlacement = {
            ...placement,
            [propertyName]: event.target.value,
        };
        this.setState({
            changes: true,
            errorText: '',
        });
        dispatch({ type: 'SET_EDITING_PLACEMENT', payload: updatedPlacement });
    };

    handleSubmit = () => {
        const valid = this.validateForm();
        if (!valid) {
            return;
        }
        this.sendFormDataToServer();
    };

    sendFormDataToServer = () => {
        const { dispatch, placement, history } = this.props;
        const { existing } = this.state;
        this.setState({
            changes: false,
            errorText: '',
        });
        if (existing) {
            dispatch({ type: 'PUT_PLACEMENT', payload: placement, history });
        } else {
            dispatch({ type: 'CREATE_PLACEMENT', payload: placement, history });
        }
    };

    confirmDeletePlacement = (placementId) => {
        this.setState({
            confirmationModalOpen: true,
            placementId,
        });
    };

    deletePlacement = (placementId) => {
        const { dispatch, placement, history } = this.props;
        const { studentId } = placement;
        dispatch({ type: 'DELETE_STUDENT_PLACEMENT', payload: { placementId, studentId }, history });
        this.setState({ confirmationModalOpen: false });
    };

    validateForm = () => {
        const { placement } = this.props;
        let valid = true;
        if (placement.companyId === '') {
            valid = false;
            this.setState({
                errorText: 'Company name is required.',
            });
        } else if (placement.studentId === '') {
            valid = false;
            this.setState({
                errorText: 'Student name is required.',
            });
        } else if (placement.placement_type === '') {
            valid = false;
            this.setState({
                errorText: 'Placement type is required.',
            });
        } else if (placement.job_title === '') {
            valid = false;
            this.setState({
                errorText: 'Job title is required.',
            });
        } else if (!placement.pre_income || placement.pre_income === '') {
            valid = false;
            this.setState({
                errorText: 'Pre-Prime annual income is required.',
            });
        } else if (!placement.post_income || placement.post_income === '') {
            valid = false;
            this.setState({
                errorText: 'Post-Prime annual income is required.',
            });
        } else if (!placement.placed_at || placement.placed_at === '') {
            valid = false;
            this.setState({
                errorText: 'Offer date is required.',
            });
        } else if (!placement.started_at || placement.started_at === '') {
            valid = false;
            this.setState({
                errorText: 'Start date is required.',
            });
        }
        return valid;
    };

    render() {
        const {
            classes,
            companies,
            students,
            placement,
            recruiters,
        } = this.props;
        const {
            errorText,
            changes,
            existing,
            confirmationModalOpen,
            placementId,
        } = this.state;
        let pageTitle = '';
        if (existing) {
            pageTitle = 'Edit Placement';
        } else {
            pageTitle = 'Create New Placement';
        }
        const companyOptions = companies.map(company => company);
        companyOptions.unshift({ value: null, label: 'None' });
        const recruiterOptions = recruiters.map(recruiter => recruiter);
        recruiterOptions.unshift({ value: null, label: 'None' });
        const placementTypeOptions = [{ value: 'Salary', label: 'Salary' }, { value: 'Contract', label: 'Contract' }, { value: 'Contract to Hire', label: 'Contract to Hire' }, { value: 'Internship', label: 'Internship' }, { value: 'Freelance', label: 'Freelance' }];
        const placeOfWorkOptions = [{ value: 'In Person', label: 'In Person' }, { value: 'Remote', label: 'Remote' }, { value: 'Hybrid', label: 'Hybrid' }, { value: 'Other', label: 'Other' }];

        return (
            <>
                <Prompt
                    // Use a standard alert dialog if the user leaves the page when
                    // changes are detected.
                    when={changes}
                    message="You have unsaved edits. Are you sure you want to leave?"
                />
                <div className="container">
                    <PageTitle
                        title={pageTitle}
                    />
                    <Paper>
                        {errorText.length > 0 && (
                            <div className={classes.errorText}>{errorText}</div>
                        )}
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <ReactSelect
                                        required
                                        placeholder="Company..."
                                        // ReactSelect requires the whole object, not just value!
                                        value={placement.selectedCompany}
                                        onChange={this.handleCompanySelect}
                                        options={companyOptions}
                                        className={classes.select}
                                        classNamePrefix="react-select"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ReactSelect
                                        placeholder="Outside Recruiter..."
                                        // ReactSelect requires the whole object, not just value!
                                        value={placement.selectedRecruiter}
                                        onChange={this.handleRecruiterSelect}
                                        options={recruiterOptions}
                                        className={classes.select}
                                        classNamePrefix="react-select"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ReactSelect
                                        placeholder="Student..."
                                        // ReactSelect requires the whole object, not just value!
                                        value={placement.selectedUser}
                                        required
                                        onChange={this.handleStudentSelect}
                                        options={students}
                                        className={classes.select}
                                        classNamePrefix="react-select"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ReactSelect
                                        required
                                        placeholder="Placement Type..."
                                        // ReactSelect requires the whole object, not just value!
                                        value={placement.selectedType}
                                        onChange={this.handleTypeSelect}
                                        options={placementTypeOptions}
                                        className={classes.select}
                                        classNamePrefix="react-select"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ReactSelect
                                        required
                                        placeholder="Place of Work..."
                                        // ReactSelect requires the whole object, not just value!
                                        value={placement.selectedPlace}
                                        onChange={this.handlePlaceSelect}
                                        options={placeOfWorkOptions}
                                        className={classes.select}
                                        classNamePrefix="react-select"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="title"
                                        label="Title"
                                        className={classes.textField}
                                        value={placement.job_title || ''}
                                        onChange={this.handleChangeFor('job_title')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="position"
                                        label="How did they hear about the position?"
                                        className={classes.textField}
                                        value={placement.how_found_notes || ''}
                                        onChange={this.handleChangeFor('how_found_notes')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="duration"
                                        label="Placement Duration"
                                        className={classes.textField}
                                        value={placement.placement_duration || ''}
                                        onChange={this.handleChangeFor('placement_duration')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="hourly"
                                        label="Hourly Rate"
                                        type="number"
                                        className={classes.textField}
                                        value={placement.placement_rate || ''}
                                        onChange={this.handleChangeFor('placement_rate')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="pre-income"
                                        label="Pre-Prime Annual Income"
                                        type="number"
                                        className={classes.textField}
                                        value={placement.pre_income || ''}
                                        onChange={this.handleChangeFor('pre_income')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="post_income"
                                        label="Placement Annual Income"
                                        type="number"
                                        className={classes.textField}
                                        value={placement.post_income || ''}
                                        onChange={this.handleChangeFor('post_income')}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="offer_date"
                                        label="Offer Date"
                                        type="date"
                                        className={classes.textField}
                                        value={placement.placed_at || ''}
                                        onChange={this.handleChangeFor('placed_at')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="start_date"
                                        label="Start Date"
                                        type="date"
                                        className={classes.textField}
                                        value={placement.started_at || ''}
                                        onChange={this.handleChangeFor('started_at')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Notes"
                                        multiline
                                        rows={3}
                                        maxRows={20}
                                        value={placement.notes || ''}
                                        variant="outlined"
                                        fullWidth
                                        onChange={this.handleChangeFor('notes')}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <DialogActions>
                            {existing
                            && (
                                <Button
                                    onClick={() => this.confirmDeletePlacement(placement.id)}
                                >
                                    Delete
                                </Button>
                            )
                            }
                            <Button
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleSubmit}
                            >
                                {existing ? <span> Save </span> : <span> Create </span>}
                            </Button>
                        </DialogActions>
                    </Paper>
                    <ConfirmationModal
                        open={confirmationModalOpen}
                        dialogTitle="Delete Placement"
                        dialogMessage={"You're about to delete a student placement. Would you like to continue?"}
                        onCancel={() => this.setState({ confirmationModalOpen: false })}
                        onConfirm={() => this.deletePlacement(placementId)}
                        buttonColor="secondary"
                        buttonText="Delete"
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    companies: state.company.companyDropdownList,
    recruiters: state.company.recruiterDropdownList,
    students: state.student.studentDropdownList,
    placement: state.placement.editingPlacement,
});

CreatePlacementPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    companies: PropTypes.instanceOf(Array).isRequired,
    recruiters: PropTypes.instanceOf(Array).isRequired,
    placement: PropTypes.instanceOf(Object).isRequired,
    students: PropTypes.instanceOf(Array).isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(CreatePlacementPage));
