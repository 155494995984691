import React from 'react';
import { Line } from 'react-chartjs-2';
import { Paper } from '@mui/material';
import PropTypes from 'prop-types';

const StudentDetailPage = (props) => {
    const {
        feedbackDataset,
    } = props;
    const chartOptions = {
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    tooltipFormat: 'MM/DD/YY',
                    unit: 'week',
                    displayFormats: {
                        day: 'MM/DD/YY',
                    },
                },
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: 6,
                },
            }],
        },
        responsive: true,
        maintainAspectRatio: false,
    };
    return (
        <Paper>
            <h5>Progress Trends</h5>
            {
                // Note: responsive-chart styles are defined in App.css
                feedbackDataset.datasets
                && feedbackDataset.datasets.length > 0
                && (
                    <div className="responsive-chart">
                        <Line
                            data={feedbackDataset}
                            options={chartOptions}
                        />
                    </div>
                )
            }
        </Paper>
    );
};

StudentDetailPage.propTypes = {
    feedbackDataset: PropTypes.instanceOf(Object).isRequired,
};

export default StudentDetailPage;
