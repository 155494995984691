import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

const delay = ms => new Promise(res => setTimeout(res, ms));

function* fetchApplications(action) {
    try {
        const params = { ...action.payload };

        // The "In Progress" options is actually a request to show
        // all applications that have been completed, but not yet accepted/rejected
        if (params.selectedStatus === 'initiated') {
            params.selectedStatus = [
                'started',
                'not_started',
                'completed',
                'scheduled',
                'second_review',
                'needs_scheduling',
                'placed',
                'confirmed',
                'declined',
                'declined_by_applicant',
                'declined_reapplicant',
                'on_hold',
                'dropped_out',
            ];
        }
        if (params.selectedStatus === 'received') {
            params.selectedStatus = [
                'completed',
                'scheduled',
                'second_review',
                'needs_scheduling',
                'placed',
                'confirmed',
                'declined',
                'declined_by_applicant',
                'declined_reapplicant',
                'on_hold',
                'dropped_out',
            ];
        }
        if (params.selectedStatus === 'offered') {
            params.selectedStatus = [
                'placed',
                'confirmed',
                'declined_by_applicant',
                'dropped_out',
            ];
        }
        if (params.selectedStatus === 'in_progress') {
            params.selectedStatus = [
                'completed',
                'scheduled',
                'second_review',
                'needs_scheduling',
                'placed',
            ];
        }
        if (params.selectedStatus === 'lost') {
            params.selectedStatus = [
                'declined',
                'declined_by_applicant',
                'declined_reapplicant',
                'on_hold',
            ];
        }
        const response = yield axios.get('api/applications', { params });
        yield put({ type: 'SET_APPLICATION_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchApplicationDetails(action) {
    try {
        const response = yield axios.get(`api/applications/detail/${action.payload.id}`);
        yield put({ type: 'SET_APPLICATION_DETAILS', payload: response.data });
        yield put({ type: 'SET_EDITING_APPLICATION_DETAILS', payload: response.data });
        yield put({ type: 'SET_TECHNICAL_SCORE', payload: response.data.resume_score });
        yield put({ type: 'SET_INTERVIEW_SCORE', payload: response.data.interview_score });
        yield put({ type: 'FETCH_APPLICATION_CHANGE_LOG', payload: action.payload.id });
        yield put({ type: 'FETCH_APPLICATION_LOGIC_QUESTIONS', payload: action.payload.id });
        yield put({ type: 'FETCH_APPLICATION_PROFILE_QUESTIONS', payload: action.payload.id });
        yield put({ type: 'FETCH_APPLICATION_INTERVIEW_REVIEW', payload: action.payload.id });
        yield put({ type: 'FETCH_APPLICATION_NOTES', payload: action.payload.id });
        yield put({ type: 'FETCH_HUBSPOT_STUDENT_NOTES', payload: response.data.user_id });
        yield put({ type: 'FETCH_HUBSPOT_USER_COMMUNICATION', payload: response.data.user_id });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchApplicationChangeLog(action) {
    try {
        const response = yield axios.get(`api/applications/log/${action.payload}`);
        yield put({ type: 'SET_APPLICATION_CHANGE_LOG', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchApplicationLogicQuestions(action) {
    try {
        const response = yield axios.get(`api/applications/logic/${action.payload}`);
        yield put({ type: 'SET_APPLICATION_LOGIC_QUESTIONS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchApplicationProfileQuestions(action) {
    try {
        const response = yield axios.get(`api/applications/profile/${action.payload}`);
        yield put({ type: 'SET_APPLICATION_PROFILE_QUESTIONS', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}
function* fetchApplicationInterviewReview(action) {
    try {
        const response = yield axios.get(`api/applications/interview/${action.payload}`);
        yield put({ type: 'SET_APPLICATION_INTERVIEW_REVIEW', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}
function* fetchApplicationNotes(action) {
    try {
        const response = yield axios.get(`api/applications/notes/${action.payload}`);
        yield put({ type: 'SET_APPLICATION_CALL_NOTES', payload: response.data.general });
        yield put({ type: 'SET_APPLICATION_INTERVIEW_NOTES', payload: response.data.interview });
        yield put({ type: 'SET_APPLICATION_TECHNICAL_NOTES', payload: response.data.technical });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* fetchApplicationStats(action) {
    try {
        const response = yield axios.get('api/applications/stats');
        yield put({ type: 'SET_APPLICATION_STATS_LIST', payload: response.data });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* updateTechnicalScore(action) {
    try {
        yield axios.put('/api/applications/technical_scores', action.payload);
        yield put({ type: 'FETCH_APPLICATION_DETAILS', payload: { id: action.payload.applicationId } });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* updateInterviewScore(action) {
    try {
        yield axios.put('/api/applications/interview_scores', action.payload);
        yield put({ type: 'FETCH_APPLICATION_DETAILS', payload: { id: action.payload.applicationId } });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}

function* updateProfileScore(action) {
    try {
        yield axios.put('/api/applications/profile_scores', action.payload);
        // sets the reducer to default value
        yield put({ type: 'UNSET_APPLICATION_PROFILE_QUESTIONS' });
        // because reducer is default value, this will update reducer
        yield put({ type: 'FETCH_APPLICATION_PROFILE_QUESTIONS', payload: action.payload.applicationId });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    }
}
function* updateInterviewReview(action) {
    yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });

    try {
        const interviewToReview = { ...action.payload };
        // converting map back into payload before we send to backend
        interviewToReview.applicationInterviewReview = Array.from(
            interviewToReview.applicationInterviewReview,
            ([name, value]) => (value));

        yield axios.put('/api/applications/interview', interviewToReview);
        yield put({
            type: 'FETCH_APPLICATION_INTERVIEW_REVIEW',
            payload: action.payload.applicationId,
        });
        yield delay(250);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}
function* updateGeneralInformation(action) {
    try {
        const application = action.payload;

        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });

        // Upload the contract file, if it exists

        if (application.contractFile) {
            const formData = new FormData();
            formData.append('contract', application.contractFile);
            yield axios.post(`/api/applications/contract/${application.application_id}`, formData);

            // Remove the file from the payload
            // so it's not passed to other API endpoints
            delete application.contractFile;
        }

        yield axios.put('/api/applications/details', application);
        yield put({ type: 'FETCH_APPLICATION_DETAILS', payload: { id: application.application_id } });
        yield delay(250);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* createNote(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        const response = yield axios.post('api/applications/notes', action.payload);
        if (action.payload.type === 'technical') {
            yield put({ type: 'UNSET_EDITING_TECHNICAL_NOTE' });
        } if (action.payload.type === 'interview') {
            yield put({ type: 'UNSET_EDITING_INTERVIEW_NOTE' });
        } if (action.payload.type === 'call') {
            yield put({ type: 'UNSET_EDITING_GENERAL_NOTE' });
        }
        yield put({ type: 'FETCH_APPLICATION_NOTES', payload: response.data.is_commentable_id });
        // Add delay to allow loading indicator to appear
        yield delay(250);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* deleteNote(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        yield axios.put(`api/applications/notes/archive/${action.payload.noteId}`);
        // Add delay to allow loading indicator to appear
        yield delay(250);
        yield put({ type: 'FETCH_APPLICATION_NOTES', payload: action.payload.applicationId });
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* updateApplicantCohortWaitlist(action) {
    try {
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: true } });
        if (action.payload.cohortId === undefined || action.payload.cohortId === '') {
            // TODO: Remove from waitlist
            yield axios.delete(`/api/cohorts/waitlist/${action.payload.cohortId}/${action.payload.application.application_id}`);
        } else {
            yield axios.put(`/api/cohorts/waitlist/${action.payload.cohortId}`, action.payload.application);
        }
        yield put({ type: 'FETCH_APPLICATION_DETAILS', payload: { id: action.payload.application.application_id } });
        // Add delay to allow loading indicator to appear
        yield delay(250);
    } catch (error) {
        yield put({ type: 'SET_ERROR', payload: error.response });
        yield put({ type: 'SET_ERROR_MODAL', payload: { modalOpen: true } });
    } finally {
        // Hide the loading indicator
        yield put({ type: 'SET_SAVE_MODAL_OPEN', payload: { modalOpen: false } });
    }
}

function* applicationSaga() {
    yield takeLatest('FETCH_APPLICATIONS', fetchApplications);
    yield takeLatest('FETCH_APPLICATION_DETAILS', fetchApplicationDetails);
    yield takeLatest('FETCH_APPLICATION_CHANGE_LOG', fetchApplicationChangeLog);
    yield takeLatest('FETCH_APPLICATION_LOGIC_QUESTIONS', fetchApplicationLogicQuestions);
    yield takeLatest('FETCH_APPLICATION_PROFILE_QUESTIONS', fetchApplicationProfileQuestions);
    yield takeLatest('FETCH_APPLICATION_INTERVIEW_REVIEW', fetchApplicationInterviewReview);
    yield takeLatest('FETCH_APPLICATION_NOTES', fetchApplicationNotes);
    yield takeLatest('FETCH_APPLICATION_STATS', fetchApplicationStats);
    yield takeLatest('PUT_TECHNICAL_SCORE', updateTechnicalScore);
    yield takeLatest('PUT_INTERVIEW_SCORE', updateInterviewScore);
    yield takeLatest('PUT_APPLICATION_DETAILS', updateGeneralInformation);
    yield takeLatest('PUT_PROFILE_SCORE', updateProfileScore);
    yield takeLatest('PUT_INTERVIEW_REVIEW', updateInterviewReview);
    yield takeLatest('CREATE_APPLICATION_NOTE', createNote);
    yield takeLatest('DELETE_APPLICATION_NOTE', deleteNote);
    yield takeLatest('PUT_APPLICANT_COHORT_WAITLIST', updateApplicantCohortWaitlist);
}
export default applicationSaga;
