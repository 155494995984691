import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CodeBlock from '../CodeBlock';

const AddNote = ({
    buttonText,
    textfieldText,
    addNote,
    editAction,
    submitAction,
    onChange,
    editingNote,
}) => {
    const [selectedTab, setSelectedTab] = useState('write');
    return (
        <div>
            <ReactMde
                value={editingNote.content}
                onChange={event => onChange(event, editAction)}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                placeholder={textfieldText}
                minEditorHeight={120}
                minPreviewHeight={120}
                textAreaProps={{ placeholder: textfieldText }}
                generateMarkdownPreview={markdown => (
                    Promise.resolve(
                        <ReactMarkdown
                            components={{ code: CodeBlock }}
                            remarkPlugins={[remarkGfm]}
                        >
                            {markdown}
                        </ReactMarkdown>,
                    )
                )}
            />
            <br />
            <br />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addNote(submitAction)}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

AddNote.propTypes = {
    buttonText: PropTypes.string.isRequired,
    textfieldText: PropTypes.string.isRequired,
    addNote: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    editAction: PropTypes.string.isRequired,
    submitAction: PropTypes.string.isRequired,
    editingNote: PropTypes.instanceOf(Object).isRequired,
};
export default AddNote;
