import React from 'react';
import { ReactComponent as HubspotLogo } from './hubspot.svg';

const HubspotIcon = () => (
    <HubspotLogo
        className="clickable-icon"
        style={{
            marginLeft: '10px',
            marginBottom: '-4px',
            width: '21px',
            height: '21px',
        }}
    />
);

export default HubspotIcon;
