import React from 'react';
import {
    Grid, Typography, FormLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
    monthName,
    year,
} from '../../../../../helpers/DateFormatHelper';

const getCohortType = (type) => {
    let result = '';
    switch (type) {
    case 'full_time':
        result = 'Full-time';
        break;
    case 'part_time':
        result = 'Part-time';
        break;
    default:
        result = '';
    }
    return result;
};

const getCohortName = (name) => (
    name.split(' (')[0]
);

const Summary = ({
    classes,
    generalInformation,
    profileInformation,
    logicInformation,
    technicalInformation,
    validateQuestions,
    courseId,
    activeCohorts,
    programType,
}) => {
    // const findLogicLanguage = () => {
    //     if (courseId === 1) {
    //         return 'logic';
    //     }
    //     return 'logic';
    // };

    // const challengeLanguage = () => {
    //     if (courseId === 1) {
    //         return 'Technical';
    //     }
    //     return 'Creative';
    // };

    const selectedCohorts = activeCohorts.filter(cohort => cohort.selected);

    const a11yProps = index => ({
        id: `cohort-selection-${index}`,
        'aria-controls': `cohort-selection-${index}`,
    });

    return (
        <Grid container spacing={2} className={classes.greyBackground}>
            <Grid item xs={12} sm={6}>
                <Typography className={classes.summaryTitle}> Name and Contact </Typography>
                <Typography variant="h6"> Name </Typography>
                <Typography>
                    {generalInformation.first_name}
                    {' '}
                    {generalInformation.last_name}
                </Typography>
                <Typography variant="h6"> Phone </Typography>
                <Typography>
                    {generalInformation.phone}
                </Typography>
                <Typography variant="h6"> Address </Typography>
                <Typography>
                    {generalInformation.address}
                </Typography>
                <Typography>
                    {generalInformation.city}
                    {' '}
                    {generalInformation.state}
                    {' '}
                    {generalInformation.zip_code}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography className={classes.summaryTitle}> Education and Employment </Typography>
                <Typography variant="h6"> Legal Status </Typography>
                {
                    generalInformation.legal_status
                    && generalInformation.legal_status
                        ? (
                            <Typography>
                                {generalInformation.legal_status}
                            </Typography>
                        )
                        : (
                            <Typography>
                                Incomplete
                            </Typography>
                        )
                }
                <Typography variant="h6"> Education </Typography>
                {
                    generalInformation.education
                        ? (
                            <Typography>
                                {generalInformation.education}
                            </Typography>
                        )
                        : (
                            <Typography>
                                Incomplete
                            </Typography>
                        )
                }
                <Typography variant="h6"> Employment Status </Typography>
                {
                    generalInformation.employment_status
                        ? (
                            <Typography>
                                {generalInformation.employment_status}
                            </Typography>
                        )
                        : (
                            <Typography>
                                Incomplete
                            </Typography>
                        )
                }
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography className={classes.summaryTitle}> Income </Typography>
                <Typography variant="h6"> Annual Income </Typography>
                {generalInformation.income
                    ? (
                        <Typography>
                            {generalInformation.income}
                        </Typography>
                    )
                    : (
                        <Typography>
                            Not Provided
                        </Typography>
                    )
                }
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography className={classes.summaryTitle}> Personal Questions </Typography>
                {
                    validateQuestions(profileInformation)
                        ? (
                            <Typography>
                                You must complete the personal questions to be able to submit
                                your application.
                            </Typography>
                        )
                        : (
                            <Typography>
                                You&apos;ve completed all the personal questions.
                            </Typography>
                        )
                }
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography className={classes.summaryTitle}>
                    Links and Social Accounts
                </Typography>
                <Typography variant="h6"> Linkedin </Typography>
                {generalInformation.linkedin_account
                    ? (
                        <Typography>
                            {generalInformation.linkedin_account}
                        </Typography>
                    )
                    : (
                        <Typography>
                            Not Provided
                        </Typography>
                    )
                }
                {/* <Typography variant="h6"> Twitter </Typography>
                {generalInformation.twitter_account
                    ? (
                        <Typography>
                            {generalInformation.twitter_account}
                        </Typography>
                    )
                    : (
                        <Typography>
                            Not Provided
                        </Typography>
                    )
                } */}
                <Typography variant="h6"> Your Website </Typography>
                {generalInformation.website_link
                    ? (
                        <Typography>
                            {generalInformation.website_link}
                        </Typography>
                    )
                    : (
                        <Typography>
                            Not Provided
                        </Typography>
                    )
                }
            </Grid>
            {/* {
                programType === 'ux-academy' ? (
                    <></>
                ) : (
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.summaryTitle}>
                            Logic Questions
                        </Typography>
                        {
                            validateQuestions(logicInformation)
                                ? (
                                    <Typography>
                                        You must complete the
                                        {' '}
                                        {findLogicLanguage()}
                                        {' '}
                                        questions to be able to submit
                                        your application.
                                    </Typography>
                                )
                                : (
                                    <Typography>
                                        You&apos;ve completed all the
                                        {' '}
                                        {findLogicLanguage()}
                                        {' '}
                                        questions.
                                    </Typography>
                                )
                        }
                    </Grid>
                )
            } */}
            <Grid item xs={12} sm={6}>
                <Typography className={classes.summaryTitle}> Selected Cohort </Typography>
                {
                    selectedCohorts.length > 0 ? (
                        selectedCohorts.map(cohort => (
                            <div key={a11yProps(cohort.id)}>
                                <FormLabel component="label" className={classes.campus}>
                                    {`${getCohortName(cohort.name)} `}
                                    <span className={classes.italicText}>
                                        {`(${monthName(cohort.prework_start)} - ${monthName(cohort.graduation)} ${year(cohort.graduation)})`}
                                    </span>
                                </FormLabel>
                                <Typography className={classes.boldPadding}>
                                    {getCohortType(cohort.cohort_type)}
                                </Typography>
                                <Typography className={classes.cohort}>
                                    {cohort.schedule}
                                </Typography>
                            </div>
                        ))
                    ) : (
                        <Typography>
                            Incomplete
                        </Typography>
                    )
                }
            </Grid>
            {/* <Grid item xs={12} sm={6}>
                <Typography className={classes.summaryTitle}>
                    {`${challengeLanguage()} Challenge`}
                </Typography>
                {
                    technicalInformation.course_id === 1
                        ? (
                            <>
                                {
                                    !technicalInformation.resume_link
                                        ? (
                                            <Typography>
                                                You have not yet submitted your technical challenge.
                                            </Typography>
                                        )
                                        : (
                                            <Typography>
                                                Your work is viewable at
                                                {' '}
                                                <a
                                                    href={technicalInformation.resume_link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {technicalInformation.resume_link}
                                                </a>
                                            </Typography>
                                        )
                                }
                                {
                                    !technicalInformation.resume_explanation
                                        ? (
                                            <Typography>
                                                You have not yet submitted your technical
                                                challenge explanation.
                                            </Typography>
                                        )
                                        : (
                                            <Typography>
                                                You have submitted your technical challenge
                                                explanation.
                                            </Typography>
                                        )
                                }
                            </>
                        )
                        : (
                            <>
                                {
                                    !technicalInformation.resume_link
                                        ? (
                                            <Typography>
                                                You have not yet submitted your creative challenge.
                                            </Typography>
                                        )
                                        : (
                                            <Typography>
                                                Your work is viewable at
                                                {' '}
                                                <a
                                                    href={technicalInformation.resume_link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {technicalInformation.resume_link}
                                                </a>
                                            </Typography>
                                        )
                                }
                                {
                                    !technicalInformation.resume_explanation
                                        ? (
                                            <Typography>
                                                You have not yet submitted your creative
                                                challenge explanation.
                                            </Typography>
                                        )
                                        : (
                                            <Typography>
                                                You have submitted your creative challenge
                                                explanation.
                                            </Typography>
                                        )
                                }
                            </>
                        )
                }
            </Grid> */}
        </Grid>
    );
};

Summary.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
    generalInformation: PropTypes.instanceOf(Object).isRequired,
    profileInformation: PropTypes.instanceOf(Array).isRequired,
    logicInformation: PropTypes.instanceOf(Array).isRequired,
    technicalInformation: PropTypes.instanceOf(Object).isRequired,
    validateQuestions: PropTypes.func.isRequired,
    courseId: PropTypes.number.isRequired,
    activeCohorts: PropTypes.instanceOf(Array).isRequired,
    programType: PropTypes.string.isRequired,
};

export default Summary;
