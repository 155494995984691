/* eslint-disable prefer-destructuring */
import { combineReducers } from 'redux';

const scaleOptions = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
];

const scores = (state = scaleOptions, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const interviewOptions = [
    { value: 0, label: '0 - Not Interviewed' },
    { value: 1, label: '1 - Unsatisfactory' },
    { value: 3, label: '3 - Meets Expectations' },
    { value: 5, label: '5 - Exceptional' },
];

const interviewScores = (state = interviewOptions, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const applicationList = (state = [], action) => {
    switch (action.type) {
    case 'SET_APPLICATION_LIST':
        return action.payload;
    case 'UNSET_APPLICATION_LIST':
        return [];
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const applicationStatsList = (state = [], action) => {
    switch (action.type) {
    case 'SET_APPLICATION_STATS_LIST':
        return action.payload;
    default:
        return state;
    }
};

const searchOffset = (state = 0, action) => {
    switch (action.type) {
    case 'SET_APPLICATION_OFFSET':
        return parseInt(action.payload.offset, 10);
    case 'UNSET_APPLICATION_SEARCH_FILTERS':
    case 'UNSET_APPLICATION_OFFSET':
        return 0;
    default:
        return state;
    }
};

let defaultSortOrder = { orderBy: 'first_name', order: 'asc' };

try {
    if (localStorage.getItem('applicationSortOrder')) {
        defaultSortOrder = JSON.parse(localStorage.getItem('applicationSortOrder'));
    }
} catch {
    defaultSortOrder = { orderBy: 'first_name', order: 'asc' };
}

const sortOrder = (state = defaultSortOrder, action) => {
    switch (action.type) {
    case 'SET_APPLICATION_SORT_ORDER':
        localStorage.setItem('applicationSortOrder', JSON.stringify(action.payload));
        return action.payload;
    case 'UNSET_APPLICATION_SEARCH_FILTERS':
    case 'UNSET_APPLICATION_SORT_ORDER':
        return defaultSortOrder;
    default:
        return state;
    }
};

const searchText = (state = '', action) => {
    switch (action.type) {
    case 'SET_APPLICATION_SEARCH_TEXT':
        return action.payload.searchText;
    case 'UNSET_APPLICATION_SEARCH_TEXT':
    case 'UNSET_APPLICATION_SEARCH_FILTERS':
    case 'UNSET_USER':
        return '';
    default:
        return state;
    }
};

const graphExpanded = (state = true, action) => {
    switch (action.type) {
    case 'SET_APPLICATION_GRAPH_EXPANDED':
        return action.payload;
    case 'UNSET_USER':
        return '';
    default:
        return state;
    }
};

const defaultCohortSelect = '';

// Cohort selected on the assignment page
const selectedCohort = (state = defaultCohortSelect, action) => {
    switch (action.type) {
    case 'SET_APPLICATION_COHORT_SEARCH_TEXT':
        return action.payload;
    case 'UNSET_APPLICATION_COHORT_SEARCH_TEXT':
    case 'UNSET_APPLICATION_SEARCH_FILTERS':
        return '';
    default:
        return state;
    }
};

const defaultCohortAppliedSelect = '';

// Cohort selected on the assignment page
const selectedCohortApplied = (state = defaultCohortAppliedSelect, action) => {
    switch (action.type) {
    case 'SET_APPLICATION_COHORT_APPLIED':
        return action.payload;
    case 'UNSET_APPLICATION_COHORT_APPLIED':
    case 'UNSET_APPLICATION_SEARCH_FILTERS':
        return '';
    default:
        return state;
    }
};

const defaultStatusSelect = '';

// Cohort selected on the assignment page
const selectedStatus = (state = defaultStatusSelect, action) => {
    switch (action.type) {
    case 'SET_APPLICATION_STATUS_SEARCH_TEXT':
        return action.payload;
    case 'UNSET_APPLICATION_STATUS_SEARCH_TEXT':
    case 'UNSET_APPLICATION_SEARCH_FILTERS':
        return '';
    default:
        return state;
    }
};

const defaultScore = {
    value: '',
    label: '',
};

const changeScoreToSelectValue = (question) => {
    if (question) {
        const selectValue = scaleOptions.find(option => option.value === question);
        return selectValue;
    }

    return defaultScore;
};

const changeInterviewScoreToSelectValue = (question) => {
    if (question) {
        const selectValue = interviewOptions.find(option => option.value === question);
        return selectValue;
    }

    return defaultScore;
};

const applicationDetails = (state = { user: {} }, action) => {
    switch (action.type) {
    case 'SET_APPLICATION_DETAILS':
        return action.payload;
    case 'UNSET_APPLICATION_DETAILS':
        return { user: {} };
    case 'UNSET_USER':
        return { user: {} };
    default:
        return state;
    }
};

const defaultApplication = {
    user: {
        first_name: '',
        last_name: '',
        middle_name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        education: '',
        employment_status: '',
        gender: '',
        gender_description: '',
        geography: '',
        goal: '',
        phone: '',
        income: '',
        personal_income: '',
        pronouns: '',
        legal_status: '',
        level_of_interest: '',
        linkedin_account: '',
        twitter_account: '',
        website_link: '',
        birthdate: '',
        dependents: '',
        race_hispanic: false,
        race_nativeamerican: false,
        race_asian: false,
        race_black: false,
        race_islander: false,
        race_white: false,
        race_other: false,
        referral_source: '',
        veteran: false,
        mac_experience: '',
        gsuite_experience: '',
        team_exp: '',
        learning_exp: '',
        learning_format: '',
    },
};

const editingApplicationDetails = (state = defaultApplication, action) => {
    const details = { ...action.payload };

    switch (action.type) {
    case 'SET_EDITING_APPLICATION_DETAILS':
        // Replace null/undefined values with empty strings
        for (const [key, val] of Object.entries(details)) {
            if (val === null || val === undefined) {
                details[key] = '';
            }
        }
        for (const [key, val] of Object.entries(details.user)) {
            if (val === null || val === undefined) {
                details.user[key] = '';
            }
        }
        return details;
    case 'UNSET_APPLICATION_DETAILS':
        return { ...defaultApplication, user: { ...defaultApplication.user } };
    case 'UNSET_USER':
        return { ...defaultApplication, user: { ...defaultApplication.user } };
    default:
        return state;
    }
};

const editingTechnicalScore = (state = defaultScore, action) => {
    switch (action.type) {
    case 'SET_TECHNICAL_SCORE':
        if (state === defaultScore) {
            return changeScoreToSelectValue(action.payload);
        }
        return state;
    case 'UPDATE_TECHNICAL_SCORE':
        return action.payload;
    case 'UNSET_APPLICATION_DETAILS':
        return defaultScore;
    case 'UNSET_USER':
        return defaultScore;
    default:
        return state;
    }
};

const editingInterviewScore = (state = defaultScore, action) => {
    switch (action.type) {
    case 'SET_INTERVIEW_SCORE':
        if (state === defaultScore) {
            return changeInterviewScoreToSelectValue(action.payload);
        }
        return state;
    case 'UPDATE_INTERVIEW_SCORE':
        return action.payload;
    case 'UNSET_APPLICATION_DETAILS':
        return defaultScore;
    case 'UNSET_USER':
        return defaultScore;
    default:
        return state;
    }
};

const applicationChangeLog = (state = [], action) => {
    switch (action.type) {
    case 'SET_APPLICATION_CHANGE_LOG':
        return action.payload;
    case 'UNSET_APPLICATION_CHANGE_LOG':
        return [];
    case 'UNSET_APPLICATION_DETAILS':
        return [];
    case 'UNSET_USER':
        return [];
    default:
        return state;
    }
};

const applicationLogicQuestions = (state = {}, action) => {
    switch (action.type) {
    case 'SET_APPLICATION_LOGIC_QUESTIONS':
        return action.payload;
    case 'UNSET_APPLICATION_LOGIC_QUESTIONS':
        return {};
    case 'UNSET_APPLICATION_DETAILS':
        return {};
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const updateSelectedValueInArray = (array, action) => array.map((item, index) => {
    if (index !== action.index) {
        return item;
    }
    return {
        ...item,
        selectValue: action.item,
    };
});

const defaultProfileQuestions = {};

const applicationProfileQuestions = (state = defaultProfileQuestions, action) => {
    let profileQuestions = {};
    let questionArray;
    let index;
    let updatedArray;
    switch (action.type) {
    case 'SET_APPLICATION_PROFILE_QUESTIONS':
        if (state === defaultProfileQuestions) {
            profileQuestions = Object.assign({}, action.payload);
            questionArray = action.payload.questions.map((question) => {
                if (question) {
                    return changeScoreToSelectValue(question.score);
                }
                return null;
            });
            for (let i = 0; i < profileQuestions.questions.length; i += 1) {
                profileQuestions.questions[i].selectValue = questionArray[i];
            }
            return profileQuestions;
        }

        return state;
    case 'UNSET_APPLICATION_PROFILE_QUESTIONS':
        return defaultProfileQuestions;
    case 'UNSET_APPLICATION_DETAILS':
        return defaultProfileQuestions;
    case 'UNSET_USER':
        return defaultProfileQuestions;
    case 'UPDATE_PROFILE_SCORE':
        index = state.questions.findIndex(element => element.id === action.payload.id);
        updatedArray = updateSelectedValueInArray(
            state.questions, { index, item: action.payload.event },
        );
        return {
            ...state,
            questions: updatedArray,
        };
    default:
        return state;
    }
};

const applicationInterviewReview = (state = new Map(), action) => {
    switch (action.type) {
    case 'SET_APPLICATION_INTERVIEW_REVIEW':
        // creating map for quick lookup
        return new Map(action.payload.map(i => [i.question_id, i]));
    case 'UNSET_APPLICATION_INTERVIEW_REVIEW':
        return new Map();
    case 'UPDATE_INTERVIEW_REVIEW':
        return action.payload;
    default:
        return state;
    }
};

const applicationCallNotes = (state = {}, action) => {
    switch (action.type) {
    case 'SET_APPLICATION_CALL_NOTES':
        return action.payload;
    case 'UNSET_APPLICATION_NOTES':
        return {};
    case 'UNSET_APPLICATION_DETAILS':
        return {};
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const applicationTechnicalNotes = (state = {}, action) => {
    switch (action.type) {
    case 'SET_APPLICATION_TECHNICAL_NOTES':
        return action.payload;
    case 'UNSET_APPLICATION_NOTES':
        return {};
    case 'UNSET_APPLICATION_DETAILS':
        return {};
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const applicationInterviewNotes = (state = {}, action) => {
    switch (action.type) {
    case 'SET_APPLICATION_INTERVIEW_NOTES':
        return action.payload;
    case 'UNSET_APPLICATION_NOTES':
        return {};
    case 'UNSET_APPLICATION_DETAILS':
        return {};
    case 'UNSET_USER':
        return {};
    default:
        return state;
    }
};

const statusOptions = [
    { value: '', label: 'All' },
    // In progress selections does a search for all applications
    // that have been completed, but not yet confirmed / declined
    {
        options:
        [
            { value: 'in_progress', label: 'In Progress' },
            { value: 'initiated', label: 'Initiated' },
            { value: 'received', label: 'Received' },
            { value: 'offered', label: 'Offered' },
            { value: 'lost', label: 'Lost' },
        ],
        label: 'Pipeline Groups',
    },
    {
        options:
        [
            { value: 'not_started', label: 'Not Started' },
            { value: 'started', label: 'Started' },
            { value: 'completed', label: 'Completed' },
            { value: 'scheduled', label: 'Scheduled' },
            { value: 'second_review', label: 'Second Review' },
            { value: 'needs_scheduling', label: 'Needs Scheduling' },
            { value: 'placed', label: 'Accepted' },
            { value: 'confirmed', label: 'Confirmed' },
            { value: 'declined', label: 'Declined' },
            { value: 'declined_by_applicant', label: 'Withdrew Application' },
            { value: 'declined_reapplicant', label: 'Declined - Reapplicant' },
            { value: 'dropped_out', label: 'Withdrew from Cohort' },
            { value: 'on_hold', label: 'Deferred' },
        ],
        label: 'Status',
    },

];

const statuses = (state = statusOptions, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

const defaultNote = {
    content: '',
    admissionId: '',
    type: '',
    // userId is the id of the user associated with the application
    userId: '',
};

const editingGeneralNote = (state = defaultNote, action) => {
    let copy;
    switch (action.type) {
    case 'SET_EDITING_GENERAL_NOTE':
        copy = Object.assign({}, action.payload);
        return copy;
    case 'UNSET_EDITING_GENERAL_NOTE':
        return defaultNote;
    default:
        return state;
    }
};

const editingInterviewNote = (state = defaultNote, action) => {
    let copy;
    switch (action.type) {
    case 'SET_EDITING_INTERVIEW_NOTE':
        copy = Object.assign({}, action.payload);
        return copy;
    case 'UNSET_EDITING_INTERVIEW_NOTE':
        return defaultNote;
    default:
        return state;
    }
};
const editingTechnicalNote = (state = defaultNote, action) => {
    let copy;
    switch (action.type) {
    case 'SET_EDITING_TECHNICAL_NOTE':
        copy = Object.assign({}, action.payload);
        return copy;
    case 'UNSET_EDITING_TECHNICAL_NOTE':
        return defaultNote;
    default:
        return state;
    }
};

export default combineReducers({
    applicationList,
    applicationStatsList,
    searchOffset,
    sortOrder,
    searchText,
    applicationDetails,
    applicationChangeLog,
    applicationLogicQuestions,
    applicationProfileQuestions,
    applicationInterviewReview,
    applicationCallNotes,
    applicationTechnicalNotes,
    applicationInterviewNotes,
    statuses,
    scores,
    interviewScores,
    editingGeneralNote,
    editingInterviewNote,
    editingTechnicalNote,
    selectedCohort,
    selectedStatus,
    editingTechnicalScore,
    editingInterviewScore,
    editingApplicationDetails,
    selectedCohortApplied,
    graphExpanded,
});
