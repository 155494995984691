import React from 'react';
import moment from 'moment-timezone';

const endOfDay = dateIn => (
    // Set time to 12pm to avoid going back to yesterday when converting to central time
    moment.utc(dateIn).set({ hour: 12, minute: 0 }).tz('US/Central').endOf('day')
);

const startOfDay = dateIn => (
    // Set time to 12pm to avoid going back to yesterday when converting to central time
    moment.utc(dateIn).set({ hour: 12, minute: 0 }).tz('US/Central').startOf('day')
);

export const shortStatusText = (cohort) => {
    const graduationDate = endOfDay(cohort.graduation);
    const tier3StartDate = cohort.tier3_start && startOfDay(cohort.tier3_start);
    const classroomStartDate = startOfDay(cohort.classroom_start);
    const preworkStartDate = startOfDay(cohort.prework_start);
    const applicationsCloseDate = endOfDay(cohort.applications_close);
    const applicationsOpenDate = startOfDay(cohort.applications_open);
    const now = moment().tz('US/Central');
    let result = <span className="status-text status-graduated">Pre-Applications</span>;
    if (cohort.archived === true) {
        result = <span className="status-text status-archived">Archived</span>;
    } else if (graduationDate.isBefore(now)) {
        result = <span className="status-text status-graduated">Graduated</span>;
    } else if (tier3StartDate && tier3StartDate.isBefore(now)) {
        result = <span className="status-text status-classroom">Tier 3</span>;
    } else if (classroomStartDate.isBefore(now)) {
        result = <span className="status-text status-classroom">Tier 2</span>;
    } else if (preworkStartDate.isBefore(now)) {
        result = <span className="status-text status-classroom">Tier 1</span>;
    } else if (applicationsCloseDate.isBefore(now)) {
        result = <span className="status-text status-application-closed">Applications Closed</span>;
    } else if (applicationsOpenDate.isBefore(now)) {
        result = <span className="status-text status-graduated">Applications Open</span>;
    }
    return result;
};

export const longStatusText = (cohort) => {
    const graduationDate = endOfDay(cohort.graduation);
    const classroomStartDate = startOfDay(cohort.classroom_start);
    const preworkStartDate = startOfDay(cohort.prework_start);
    const applicationsCloseDate = endOfDay(cohort.applications_close);
    const applicationsOpenDate = startOfDay(cohort.applications_open);
    const now = moment().tz('US/Central');
    let result = `Applications open in ${applicationsOpenDate.to(now)}`;
    if (cohort.archived) {
        result = 'Archived';
    } else if (graduationDate.isBefore(now)) {
        result = `Graduated ${graduationDate.from(now)}`;
    } else if (classroomStartDate.isBefore(now)) {
        result = `Graduates in ${graduationDate.to(now, true)}`;
    } else if (preworkStartDate.isBefore(now)) {
        result = `Tier 2 starts in ${classroomStartDate.to(now, true)}`;
    } else if (applicationsCloseDate.isBefore(now)) {
        result = `Tier 1 starts in ${preworkStartDate.to(now, true)}`;
    } else if (applicationsOpenDate.isBefore(now)) {
        result = `Applications close in ${applicationsCloseDate.to(now, true)}`;
    }
    return result;
};

export const cohortOptionSelect = (cohorts) => {
    const graduated = [];
    const inTier3 = [];
    const inTier2 = [];
    const inTier1 = [];
    const preTier1 = [];
    const archived = [];
    const now = moment().tz('US/Central');
    cohorts.map((cohort) => {
        const graduation = endOfDay(cohort.graduation);
        const tier1Start = startOfDay(cohort.prework_start);
        const tier2Start = startOfDay(cohort.classroom_start);
        const tier3Start = cohort.tier3_start && startOfDay(cohort.tier3_start);
        const item = { value: cohort.id, label: cohort.cohort_name };
        if (cohort.archived) {
            archived.push(item);
        } else if (graduation.isBefore(now)) {
            graduated.push(item);
        } else if (tier3Start && tier3Start.isBefore(now)) {
            inTier3.push(item);
        } else if (tier2Start.isBefore(now)) {
            inTier2.push(item);
        } else if (tier1Start.isBefore(now)) {
            inTier1.push(item);
        } else {
            preTier1.push(item);
        }
        return cohort;
    });
    const cohortOptions = [
        // { value: '', label: 'All Cohorts' },
        { options: inTier3, label: 'In Tier 3' },
        { options: inTier2, label: 'In Tier 2' },
        { options: inTier1, label: 'In Tier 1' },
        { options: preTier1, label: 'Pre Tier 1' },
        { options: graduated, label: 'Graduated' },
        { options: archived, label: 'Archived' },
    ];
    return cohortOptions;
};

export default {
    shortStatusText,
    longStatusText,
    cohortOptionSelect,
};
