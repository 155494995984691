import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel,
    TextField,
    Grid,
    MenuItem,
    Select,
    InputLabel,
    FormControlLabel,
    Switch,
    IconButton,
    Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PageTitle from '../../PageTitle/PageTitle';
import PlacementListRow from './PlacementListRow';
import TablePagination from '../../../helpers/TablePagination';
import { DateRangeSearchBy } from '../../../redux/reducers/placementsReducer';
import PlacementsSummary from './PlacementsSummary';
import { getPlacementQueryParams } from '../../../helpers/QueryParamsHelper';

const {
    ADMIN,
    CAREER,
} = require('../../General/UserTypes');

const styles = {
    firstPlacementsToggle: {
        paddingBottom: 10,
        paddingLeft: 5,
        '& .MuiFormControlLabel-label': {
            fontSize: 16,
            fontWeight: 'bold',
            marginTop: -3,
        },
    },
    clearDateIcon: {
        padding: 0,
        '& .MuiIcon-root': {
            fontSize: 18,
        },
    },
    tableRow: {
        cursor: 'pointer',
    },
    tableCell: {
        padding: '15px',
    },
};

class PlacementListPage extends Component {
    componentDidMount() {
        const {
            location,
            history,
            searchText,
            firstPlacementsOnly,
            dateRange,
            dispatch,
            sortOrder,
        } = this.props;
        dispatch({ type: 'SET_DISPLAY_BACK', payload: { displayBack: false } });
        const searchParams = new URLSearchParams(location.search.slice(1));
        if (searchParams && searchParams.toString() !== '') {
            this.handleURLParameters(searchParams);
        } else {
            // If no query params, use props.
            this.handleNewSearchParam({
                searchText,
                firstPlacementsOnly,
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                searchBy: dateRange.searchBy,
                order: sortOrder.order,
                orderBy: sortOrder.orderBy,
            });
            this.fetchPlacements();
        }
        history.listen(() => {
            this.onRouteChange();
        });
    }

    // Handles changes made directly in the address bar or when the user presses back
    onRouteChange = () => {
        const { location, sortOrder } = this.props;
        const searchParams = new URLSearchParams(location.search.slice(1));
        if (searchParams && searchParams.toString() !== '') {
            this.handleURLParameters(searchParams);
        } else {
            // If no query params, use default data.
            this.fetchPlacements(
                {
                    searchText: '',
                    firstPlacementsOnly: true,
                    startDate: null,
                    endDate: null,
                    searchBy: DateRangeSearchBy.GraduationDate,
                    order: sortOrder.order,
                    orderBy: sortOrder.orderBy,
                },
            );
        }
    };

    handleNewSearchParam = (params) => {
        const { history, location } = this.props;
        const searchParams = new URLSearchParams(location.search);
        for (const key in params) {
            if (!params[key] || params[key] === 'All Cohorts' || params[key] === 'All+Cohorts' || params[key] === 'All') {
                searchParams.delete(key);
            } else {
                searchParams.set(key, params[key]);
            }
        }
        if (this.historyTimeout) {
            clearTimeout(this.historyTimeout);
        }
        this.historyTimeout = setTimeout(() => { history.push(`?${searchParams}`); }, 250);
    };

    handleURLParameters = (searchParams) => {
        const { dispatch, dateRange } = this.props;
        const payload = { dateRange };
        let nextDateRange;
        for (const param of searchParams.entries()) {
            const [key, value] = param;
            switch (key) {
            case 'searchText':
                dispatch({ type: 'SET_PLACEMENT_SEARCH_TEXT', payload: { text: value } });
                payload.searchText = value;
                break;
            case 'startDate':
            case 'endDate':
            case 'searchBy':
                nextDateRange = {
                    ...dateRange,
                    [key]: value,
                };
                dispatch({
                    type: 'SET_PLACEMENT_DATE_RANGE',
                    payload: nextDateRange,
                });
                payload.dateRange[key] = value;
                break;
            case 'offset':
                dispatch({ type: 'SET_PLACEMENT_OFFSET', payload: { offset: value } });
                payload.searchOffset = parseInt(value, 10);
                break;
            case 'order':
            case 'orderBy':
                payload[key] = value;
                dispatch({ type: 'SET_PLACEMENT_SORT_ORDER', payload });
                break;
            default:
                break;
            }
        }
        this.fetchPlacements(payload);
    };

    handleChangePage = (_, page) => {
        const {
            dispatch,
            rowsPerPage,
        } = this.props;
        const offset = Math.floor(Math.max(page * rowsPerPage, 0));
        dispatch({ type: 'SET_PLACEMENT_OFFSET', payload: { offset } });
        this.handleSearchParam('offset', offset);
        this.fetchPlacements({
            searchOffset: offset,
        });
    };

    handleChangeRowsPerPage = (nextRowsPerPage) => {
        this.fetchPlacements({
            rowsPerPage: nextRowsPerPage,
        });
    };

    handleSearchChange = (event) => {
        const { dispatch } = this.props;
        const searchText = event.target.value;
        this.handleSearchParam('searchText', searchText);
        dispatch({ type: 'SET_PLACEMENT_SEARCH_TEXT', payload: { text: searchText } });
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => { this.runSearch(searchText); }, 250);
    };

    handleSearchParam = (name, value) => {
        const { history, location } = this.props;
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(name, value);
        if (!value) {
            searchParams.delete(name);
        }
        if (this.historyTimeout) {
            clearTimeout(this.historyTimeout);
        }
        this.historyTimeout = setTimeout(() => { history.replace(`?${searchParams}`); }, 250);
    };

    handleDateRangeChange = field => (event) => {
        const { dispatch, dateRange } = this.props;

        const nextDateRange = {
            ...dateRange,
            // Arg can be event, or raw value
            [field]: event && event.target ? event.target.value : event,
        };
        this.handleSearchParam(field, nextDateRange[field]);
        dispatch({
            type: 'SET_PLACEMENT_DATE_RANGE',
            payload: nextDateRange,
        });

        this.fetchPlacements({
            dateRange: nextDateRange,
        });
    };

    fetchPlacements = (params) => {
        const {
            dispatch,
            sortOrder,
            searchOffset,
            rowsPerPage,
            searchText,
            dateRange,
            firstPlacementsOnly,
        } = {
            // Extend props with any provided params
            ...this.props,
            ...params,
        };
        dispatch({
            type: 'FETCH_PLACEMENTS',
            payload: {
                sortOrder,
                searchOffset,
                rowsPerPage,
                searchText,
                dateRange,
                firstPlacementsOnly,
            },
        });
    };

    editPlacement = (placementInfo) => {
        const { user, history, dispatch } = this.props;
        if (user.role === ADMIN || user.role === CAREER) {
            dispatch({ type: 'SET_EDITING_PLACEMENT', payload: placementInfo });
            history.push(`/placements/edit/${placementInfo.id}`);
        } else {
            history.push(`/students/${placementInfo['user.id']}`);
        }
    };

    runSearch = (searchText) => {
        const { dispatch } = this.props;

        // Reset to page 0
        dispatch({
            type: 'SET_PLACEMENTS_SEARCH_OFFSET',
            payload: { offset: 0 },
        });

        this.fetchPlacements({
            searchText,
            searchOffset: 0,
        });

        this.searchTimeout = null;
    };

    sortBy = orderBy => () => {
        const {
            dispatch,
            sortOrder,
            searchOffset,
            searchText,
        } = this.props;
        let { order } = sortOrder;

        // Existing column selected, reverse the sort
        if (orderBy === sortOrder.orderBy) {
            if (order === 'asc') {
                order = 'desc';
            } else {
                order = 'asc';
            }
        }
        const payload = {
            orderBy,
            order,
            offset: searchOffset,
            searchText,
        };
        const newParams = {
            orderBy,
            order,
        };
        this.handleNewSearchParam(newParams);
        dispatch({ type: 'SET_PLACEMENT_SORT_ORDER', payload });

        this.fetchPlacements({
            sortOrder: {
                order,
                orderBy,
            },
        });
    };

    renderDateRangePicker = ({ field, label }) => {
        const { dateRange, classes } = this.props;

        return (
            <TextField
                label={label}
                type="date"
                value={dateRange[field] || ''}
                onChange={this.handleDateRangeChange(field)}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    // Button to clear date value
                    endAdornment: dateRange[field] && (
                        <IconButton
                            className={classes.clearDateIcon}
                            onClick={e => this.handleDateRangeChange(field)(null)}
                            size="large"
                        >
                            <Icon className="fas fa-minus-circle" />
                        </IconButton>
                    ),
                }}
            />
        );
    };

    downloadCSV = () => {
        const {
            sortOrder,
            searchText,
            dateRange,
            firstPlacementsOnly,
        } = this.props;
        const params = getPlacementQueryParams({
            sortOrder,
            searchText,
            dateRange,
            firstPlacementsOnly,
        });
        params.t = new Date().getTime();
        const qs = Object.keys(params)
            .filter(key => params[key] && params[key] !== '')
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
        let prefix = '';
        if (process.env.NODE_ENV !== 'production') {
            prefix = 'http://localhost:5000/';
        }
        window.open(`${prefix}api/placements/csv?${qs}`);
    };

    render() {
        const {
            placements,
            searchOffset = 0,
            sortOrder,
            classes,
            searchText,
            dateRange,
            firstPlacementsOnly,
            rowsPerPage,
            dispatch,
            user,
        } = this.props;
        const page = searchOffset / rowsPerPage;
        const actions = [{
            label: 'Download CSV',
            onClick: this.downloadCSV,
        }, {
            label: 'Add New Placement',
            // Dispatch an action...
            type: 'UNSET_EDITING_PLACEMENT',
            // ...and navigate to this page.
            path: '/placements/new',
        }];

        return (
            <>
                <PageTitle
                    title="Placements"
                    actions={actions}
                />
                <Paper>
                    <Grid container spacing={4}>

                        {/* Search Field */}
                        <Grid item xs={4} lg={3}>
                            <TextField
                                helperText={`
                                    Search by company name, student name, cohort, type of work, or job title.
                                    Eg. "fse, ux, Wells Fargo"
                                `}
                                label="Search"
                                margin="dense"
                                variant="outlined"
                                value={searchText}
                                onChange={this.handleSearchChange}
                            />
                        </Grid>

                        {/* Date range */}
                        <Grid item xs={3} lg={2}>
                            {this.renderDateRangePicker({
                                label: 'Start Date',
                                field: 'startDate',
                            })}
                        </Grid>
                        <Grid item xs={3} lg={2}>
                            {this.renderDateRangePicker({
                                label: 'End Date',
                                field: 'endDate',
                            })}
                        </Grid>

                        {/* Date search by (graduation vs. placement date) */}
                        <Grid item xs={2}>
                            <InputLabel shrink>Search By</InputLabel>
                            <Select
                                value={dateRange.searchBy}
                                disableUnderline
                                onChange={this.handleDateRangeChange('searchBy')}
                            >
                                <MenuItem
                                    value={DateRangeSearchBy.GraduationDate}
                                >
                                    Graduation Date
                                </MenuItem>
                                <MenuItem
                                    value={DateRangeSearchBy.PlacementDate}
                                >
                                    Placement Date
                                </MenuItem>
                            </Select>
                        </Grid>

                    </Grid>

                    {/* Placement summary */}
                    <PlacementsSummary />

                    <FormControlLabel
                        className={classes.firstPlacementsToggle}
                        label="First placements only"
                        control={(
                            <Switch
                                color="primary"
                                checked={firstPlacementsOnly}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'SET_PLACEMENT_FIRST_PLACEMENTS_ONLY',
                                        payload: e.target.checked,
                                    });
                                    this.fetchPlacements({
                                        firstPlacementsOnly: e.target.checked,
                                    });
                                }}
                            />
                        )}
                    />

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={sortOrder.orderBy === 'company_name' && sortOrder.order}>
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'company_name'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('company_name')}
                                    >
                                        Company
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={sortOrder.orderBy === 'student_first_name' && sortOrder.order}>
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'student_first_name'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('student_first_name')}
                                    >
                                        Student
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={sortOrder.orderBy === 'cohort_name' && sortOrder.order}>
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'cohort_name'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('cohort_name')}
                                    >
                                        Cohort
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={sortOrder.orderBy === 'placement_type' && sortOrder.order}>
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'placement_type'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('placement_type')}
                                    >
                                        Type
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={sortOrder.orderBy === 'job_title' && sortOrder.order}>
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'job_title'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('job_title')}
                                    >
                                        Title
                                    </TableSortLabel>
                                </TableCell>
                                {(user.role === ADMIN || user.role === CAREER) && (
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortOrder.orderBy === 'post_income'}
                                            direction={sortOrder.order}
                                            onClick={this.sortBy('post_income')}
                                        >
                                            Salary
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell sortDirection={sortOrder.orderBy === 'placed_at' && sortOrder.order}>
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'placed_at'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('placed_at')}
                                    >
                                        Offer Date
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={sortOrder.orderBy === 'cohort_graduation_date' && sortOrder.order}>
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'cohort_graduation_date'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('cohort_graduation_date')}
                                    >
                                        Graduation Date
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={sortOrder.orderBy === 'days_to_placement' && sortOrder.order}>
                                    <TableSortLabel
                                        active={sortOrder.orderBy === 'days_to_placement'}
                                        direction={sortOrder.order}
                                        onClick={this.sortBy('days_to_placement')}
                                    >
                                        Days to Placement
                                    </TableSortLabel>
                                </TableCell>
                                { (user.role === ADMIN || user.role === CAREER) && (
                                    <TableCell align="right" sortDirection={false}>
                                        Action
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                placements.rows
                                && placements.rows.map(placementInfo => (
                                    <PlacementListRow
                                        key={placementInfo.id}
                                        placementInfo={placementInfo}
                                        classes={classes}
                                        user={user}
                                        editPlacement={this.editPlacement}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={placements.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Paper>
            </>
        );
    }
}
PlacementListPage.propTypes = {
    history: PropTypes.shape({
        replace: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    placements: PropTypes.instanceOf(Object).isRequired,
    location: PropTypes.instanceOf(Object).isRequired,
    searchOffset: PropTypes.number.isRequired,
    sortOrder: PropTypes.instanceOf(Object).isRequired,
    classes: PropTypes.instanceOf(Object).isRequired,
    searchText: PropTypes.string.isRequired,
    dateRange: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        searchBy: PropTypes.string, // See DateRangeSearchBy.GraduationDate
    }).isRequired,
    firstPlacementsOnly: PropTypes.bool.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    user: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
    placements: state.placement.placements,
    searchOffset: state.placement.searchOffset,
    sortOrder: state.placement.sortOrder,
    searchText: state.placement.searchText,
    dateRange: state.placement.dateRange,
    firstPlacementsOnly: state.placement.firstPlacementsOnly,
    rowsPerPage: state.settings.rowsPerPage,
    user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(PlacementListPage));
